import React, { useEffect, useState } from "react";
import NoIndexMeta from "../../utils/NoIndexMeta";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { type } from "@testing-library/user-event/dist/type";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function ResetPassword({ onClose }) {
    const [openPopup, setOpenPopup] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmPassword] = useState(false);
    const { loginType } = useParams(); // Extract 'agent' from the URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const navigate = useNavigate();

    const validate = () => {
        const errors = {};
        // Add validation logic for each form field
        // if (currentStep === 1) {
        if (!formData?.email.trim()) {
            errors.email = "required";
        }
        // if (!formData?.password.trim()) {
        //   errors.password = "required";
        // }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const resetPasswordHandler2 = async (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append(
            "Cookie",
            process.env.REACT_APP_COOKIE
        );

        const raw = JSON.stringify({
            type: "vendor",
            email: formData?.email,
            callbackUrl: "http://localhost:3000/reset-password",
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/send-password-reset-link`,
                requestOptions
            );
            const result = await response.json();
            console.log(result);
            if (result.success) {
                showSuccessToast(result.message || "Please Check Your Email.");
                setOpenPopup(false);
            } else {
                showErrorToast(result.message || "Password Reset Failed");
            }
        } catch (error) {
            console.error("Error sending reset password link:", error);
        }
    };

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        if (!token) {
            showErrorToast('Token not found !')
            return
        }
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append(
            "Cookie",
            process.env.REACT_APP_COOKIE
        );

        if (formData.password !== formData.confirmPassword) {
            showErrorToast("Passwords do not match");
            return;
        }

        const raw = JSON.stringify({
            type: loginType || "agent",
            email: formData.email,
            token: token,
            password: formData.password,
            password_confirmation: formData.confirmPassword,
            callbackUrl: "https://mountainmysteries.com",
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/password-reset`,
                requestOptions
            );
            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message || "Password Reset Successfully!");
                if (loginType === "vendor") {
                    navigate('/login/vendor')
                } else if (loginType === "agent") {
                    navigate('/login/agent')
                } else {
                    navigate('/')
                }
            } else {
                showErrorToast(result.message || "Error resetting password")
            }
        } catch (error) {
            console.error("Error resetting password:", error);
        }
    };

    return (
        <>
            <NoIndexMeta />
            <div className="popup_backdrop">
                <div className="popup_section">
                    <form onSubmit={resetPasswordHandler} className="registration-form">
                        {/* <form onSubmit={}> */}
                        <div className="flex justify_content_between align_items_center">
                            <h4 className="text-left">
                                <b>Reset Your Password</b>
                            </h4>
                            <svg
                                onClick={() => setOpenPopup(false)}
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                            </svg>
                        </div>
                        <div className="LoginVendor_form_cont mt-10">
                            <label htmlFor="">Email</label>
                            <input
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                type="email"
                                required
                            />
                        </div>
                        <div className="input-flex w-100">
                            <div className="" style={{ position: "relative" }}>
                                <label htmlFor="" className="text-black">
                                    Password
                                </label>
                                <input
                                    className="w-100"
                                    onChange={handleInputChange}
                                    name="password"
                                    value={formData?.password}
                                    style={
                                        formErrors.password
                                            ? {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }
                                            : {}
                                    }
                                    type={
                                        showPassword
                                            ? (type = "text")
                                            : (type = "password")
                                    }
                                />

                                {showPassword ? (
                                    <svg
                                        onClick={() => setShowPassword(false)}
                                        style={{
                                            position: "absolute",
                                            top: "32px",
                                            right: "5px",
                                            cursor: "pointer",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M2 2L22 22"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        onClick={() => setShowPassword(true)}
                                        style={{
                                            position: "absolute",
                                            top: "32px",
                                            right: "5px",
                                            cursor: "pointer",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 14 10"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_1_298)">
                                            <path
                                                d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                                                fill="#888888"
                                            ></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_298">
                                                <rect width="14" height="10" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                )}
                            </div>
                            <div className="" style={{ position: "relative" }}>
                                <label htmlFor="" className="text-black">
                                    Confirm Password
                                </label>
                                <input
                                    className="w-100"
                                    style={
                                        formErrors.confirmPassword
                                            ? {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }
                                            : {}
                                    }
                                    onChange={handleInputChange}
                                    name="confirmPassword"
                                    value={formData?.confirmPassword}
                                    type={showConfirmPassword ? "text" : "password"}
                                />

                                {showConfirmPassword ? (
                                    <svg
                                        onClick={() => setConfirmPassword(false)}
                                        style={{
                                            position: "absolute",
                                            top: "32px",
                                            right: "5px",
                                            cursor: "pointer",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M2 2L22 22"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                                            stroke="#888888"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        onClick={() => setConfirmPassword(true)}
                                        style={{
                                            position: "absolute",
                                            top: "32px",
                                            right: "5px",
                                            cursor: "pointer",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 14 10"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_1_298)">
                                            <path
                                                d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                                                fill="#888888"
                                            ></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_298">
                                                <rect width="14" height="10" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                )}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="LoginVendor_form_submit red_new_btn_black"
                        // onClick={resetPasswordHandler}
                        >
                            Send{" "}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
