import React, { useState, useEffect, useRef } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import ListingBanner from "../../CustomComponents/ListingBanner/ListingBanner";
import FilterHoliday from "../../CustomComponents/FilterSection/FilterHoliday";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import PackageSearchForm from "../../CustomComponents/PackageSearchForm/PackageSearchForm";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import { scrollToTop } from "../../utils/utils";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
import imgNotFound from "../../assets/images/single-image.png";
import SendEnquiryPopup from "../../CustomComponents/SendEnquiryPopup/SendEnquiryPopup";
import NewSendEnquiryPopup from "../../CustomComponents/NewSendEnquiryPopup/NewSendEnquiryPopup";

function ListingHolidays() {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 9;
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderFilter, setFilterLoader] = useState(false);
  const location = useLocation();
  const [sortedFilter, setSortedFilter] = useState({
    show: "Popular",
    value: "polpular",
  });
  const [showSendEnquiryPopup, setShowSendEnquiryPopup] = useState(false);
  const [date, setDate] = useState({
    setDay: "",
    setMonth: "",
    setYear: "",
  });

  const [packageData, setPackageData] = useState([]);
  // console.log(packageData, "packageData");
  // Calculate start and end index of items to display
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    packageData && packageData && packageData.length > 0
      ? packageData.slice(startIndex, endIndex)
      : [];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      guest: params.get("guest"),

      day: params.get("day"),
      month: params.get("month"),
      year: params.get("year"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchPackageData(data);
    setDate({
      setDay: data.day,
      setMonth: data.month,
      setYear: data.year,
    });
  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const fetchPackageData = async (data, filters) => {
    // console.log(data, "fetchPackageData");
    setFilterLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "key",
      process.env.REACT_APP_BASE_API_KEY
    );
    const formdata = new FormData();
    formdata.append("location", data.location);
    formdata.append("guest", data.guest ? data.guest : 1);
    formdata.append("children", data.children ? data.children : 0);
    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    // ?destination=Manali,Dharamshala,Mandi&duration=3-4"
    try {
      // const response = await fetch(
      //   `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch`,
      //   requestOptions
      // );
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch?destination=${filters?.destinations ? filters?.destinations : ""
        }&price_range=${filters?.budget ? filters?.budget : ""}&duration=${filters?.duration ? filters?.duration : ""
        }&theme=${filters?.themes ? filters?.themes : ""}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);
      setPackageData(result.data);
      scrollToTop();
    } catch (error) {
      setPackageData([]);
      scrollToTop();
      setLoader(false);
      console.error(error);
    } finally {
      scrollToTop();
      setFilterLoader(false);
    }
  };

  const sendDataToParent = (data) => {
    fetchPackageData(bookingData, data);
    // console.log(data, "sendDataToParent");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // console.log(sortedFilter?.value);
    let dummyData = [...packageData];
    if (sortedFilter?.value === "l2hByp") {
      dummyData.sort((a, b) => a.startingPrice - b.startingPrice);
      setPackageData(dummyData);
    }

    if (sortedFilter?.value === "h2lByp") {
      dummyData.sort((a, b) => b.startingPrice - a.startingPrice);
      setPackageData(dummyData);
    }
    // duration
    if (sortedFilter?.value === "l2hByd") {
      dummyData.sort((a, b) => a.totalNights - b.totalNights);
      setPackageData(dummyData);
    }
    if (sortedFilter?.value === "h2lByd") {
      dummyData.sort((a, b) => b.totalNights - a.totalNights);
      setPackageData(dummyData);
    }
  }, [sortedFilter?.value]);

  const [isVisible, setIsVisible] = useState(false);

  const handleHover = (status) => {
    if (window.innerWidth > 768) {
      // For larger screens (like desktops)
      setIsVisible(status);
    }
  };

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      // For mobile screens
      setIsVisible(false); // Close the div when clicked on mobile
    } else {
      setIsVisible(!isVisible); // Toggle for desktop
    }
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

    const [markupData, setMarkupData] = useState(null);
  
    const fetchMarkup = async () => {
      const myHeaders = new Headers();
      myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
      myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
  
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            const findObject = result?.data?.find(
              (mark) => mark?.markupCustomer === "both"
            );
            setMarkupData(findObject || null);
          } else {
            setMarkupData("No data found");
          }
        })
        .catch((error) => console.error(error));
    };
  
    useEffect(() => {
      fetchMarkup();
    }, []);
  return (
    <div id="listingHolidays">
      <PageMetaData
        title="Mountain Mysteries Packages"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title="Mountain Mysteries Packages"
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url="https://www.mountainmysteries.com/listing-holidays?location=shimla&guest=2&children=0&&radio="
      />
      <Header />
      <PackageSearchForm />
      <ListingBanner heading={`${bookingData.location}`} />
      <div className="container">
        <main>
          {loader ? (
            <>
              <div className="filters loading-filter-main">
                <div className="agent border-bottom-one">
                  <div>
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="filter_checkbox border-bottom-one">
                  <Skeleton width={150} />
                  <Skeleton count={3} width={90} />
                </div>
                <div className="filter_checkbox border-bottom-one">
                  <Skeleton width={150} />
                  <Skeleton count={3} width={90} />
                </div>
                <div className="filter_checkbox border-bottom-one">
                  <Skeleton width={150} />
                  <Skeleton count={3} width={90} />
                </div>
                <div className="filter_checkbox ">
                  <Skeleton width={150} />
                  <Skeleton count={3} width={90} />
                </div>
              </div>

              <div className="loading-filter-responsive">
                <Skeleton width={500} height={50} />
              </div>
            </>
          ) : (
            <FilterHoliday sendDataToParent={sendDataToParent} />
          )}
          <div
            className="listing-right-container"
            style={{ paddingTop: "0px" }}
          >
            {/* <div className="flex align_items_center justify_content_between flex_wrap">
              <h3 style={{ textAlign: "left", textTransform: "capitalize" }}>
                Top {bookingData.location} Tour Packages
              </h3>
              {window.innerWidth > 768 ? (
                <div className="sorted_div">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_5040_6776"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="14"
                      height="14"
                    >
                      <rect width="14" height="14" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_5040_6776)">
                      <path
                        d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                        fill="#1C1B1F"
                      />
                    </g>
                  </svg>
                  Sort by:{" "}
                  <span className="cursor_pointer">
                    <b>{sortedFilter?.show}</b>
                  </span>
                  <div className="sorted_selected">
                    <div
                      onClick={() => {
                        setSortedFilter({
                          value: "l2hByp",
                          show: "Price - Low to High",
                        });
                        setCurrentPage(1);
                        handleClick();
                      }}
                      className="cursor_pointer p-5"
                    >
                      {" "}
                      <span>Price - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => [
                        setSortedFilter({
                          value: "h2lByp",
                          show: "Price - high to Low",
                        }),
                        setCurrentPage(1),
                      ]}
                    >
                      {" "}
                      <span>Price - High to Low</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => [
                        setSortedFilter({
                          value: "l2hByd",
                          show: "Duration - Low to High",
                        }),
                        setCurrentPage(1),
                      ]}
                    >
                      {" "}
                      <span>Duration - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => [
                        setSortedFilter({
                          value: "h2lByd",
                          show: "Duration - High to Low",
                        }),
                        setCurrentPage(1),
                      ]}
                    >
                      {" "}
                      <span>Duration - High to Low</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sorted_div">
                  <div
                    onClick={() => {
                      const sortedDiv =
                        document.querySelector(".sorted_selected");
                        if (sortedDiv) {
                          // Toggle display between "block" and "none"
                          sortedDiv.style.display === "block"
                            ? (sortedDiv.style.display = "none")
                            : (sortedDiv.style.display = "block");
                        }
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_5040_6776"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="14"
                      >
                        <rect width="14" height="14" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_5040_6776)">
                        <path
                          d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    Sort by:{" "}
                    <span className="cursor_pointer">
                      <b>{sortedFilter?.show}</b>
                    </span>
                  </div>

                  <div className="sorted_selected">
                    <div
                      onClick={() => {
                        setSortedFilter({
                          value: "l2hByp",
                          show: "Price - Low to High",
                        });
                        setCurrentPage(1);
                        handleClick();

                        // Add styles to sorted_div to hide it
                        const sortedDiv =
                          document.querySelector(".sorted_selected");
                        if (sortedDiv) {
                          sortedDiv.style.display = "none" ? "none" : "block";
                        }
                      }}
                      className="cursor_pointer p-5"
                    >
                      {" "}
                      <span>Price - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "h2lByp",
                          show: "Price - high to Low",
                        });
                        setCurrentPage(1);
                        handleClick();

                        // Add styles to sorted_div to hide it
                        const sortedDiv =
                          document.querySelector(".sorted_selected");
                        if (sortedDiv) {
                          sortedDiv.style.display = "none" ? "none" : "block";
                        }
                      }}
                    >
                      {" "}
                      <span>Price - High to Low</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "l2hByd",
                          show: "Duration - Low to High",
                        });
                        setCurrentPage(1);
                        handleClick();

                        // Add styles to sorted_div to hide it
                        const sortedDiv =
                          document.querySelector(".sorted_selected");
                        if (sortedDiv) {
                          sortedDiv.style.display = "none" ? "none" : "block";
                        }
                      }}
                    >
                      {" "}
                      <span>Duration - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "h2lByd",
                          show: "Duration - High to Low",
                        });
                        setCurrentPage(1);
                        handleClick();

                        // Add styles to sorted_div to hide it
                        const sortedDiv =
                          document.querySelector(".sorted_selected");
                        if (sortedDiv) {
                          sortedDiv.style.display = "none" ? "none" : "block";
                        }
                      }}
                    >
                      {" "}
                      <span>Duration - High to Low</span>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            <div className="flex align_items_center justify_content_between flex_wrap">
              <h3 style={{ textAlign: "left", textTransform: "capitalize" }}>
                Top {bookingData.location} Tour Packages
              </h3>

              {window.innerWidth > 768 ? (
                <div className="sorted_div">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_5040_6776"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="14"
                      height="14"
                    >
                      <rect width="14" height="14" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_5040_6776)">
                      <path
                        d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                        fill="#1C1B1F"
                      />
                    </g>
                  </svg>
                  Sort by:{" "}
                  <span className="cursor_pointer">
                    <b>{sortedFilter?.show}</b>
                  </span>
                  <div className="sorted_selected" ref={dropdownRef}>
                    <div
                      onClick={() => {
                        setSortedFilter({
                          value: "l2hByp",
                          show: "Price - Low to High",
                        });
                        setCurrentPage(1);
                        handleClick();
                      }}
                      className="cursor_pointer p-5"
                    >
                      <span>Price - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "h2lByp",
                          show: "Price - High to Low",
                        });
                        setCurrentPage(1);
                        handleClick();
                      }}
                    >
                      <span>Price - High to Low</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "l2hByd",
                          show: "Duration - Low to High",
                        });
                        setCurrentPage(1);
                        handleClick();
                      }}
                    >
                      <span>Duration - Low to High</span>
                    </div>
                    <div
                      className="cursor_pointer p-5"
                      onClick={() => {
                        setSortedFilter({
                          value: "h2lByd",
                          show: "Duration - High to Low",
                        });
                        setCurrentPage(1);
                        handleClick();
                      }}
                    >
                      <span>Duration - High to Low</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sorted_div">
                  <div onClick={() => setIsDropdownVisible((prev) => !prev)}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_5040_6776"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="14"
                      >
                        <rect width="14" height="14" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_5040_6776)">
                        <path
                          d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    Sort by:{" "}
                    <span className="cursor_pointer">
                      <b>{sortedFilter?.show}</b>
                    </span>
                  </div>
                  {isDropdownVisible && (
                    <div className="sorted_selected" ref={dropdownRef}>
                      <div
                        onClick={() => {
                          setSortedFilter({
                            value: "l2hByp",
                            show: "Price - Low to High",
                          });
                          setCurrentPage(1);
                          setIsDropdownVisible(false);
                          handleClick();
                        }}
                        className="cursor_pointer p-5"
                      >
                        <span>Price - Low to High</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "h2lByp",
                            show: "Price - High to Low",
                          });
                          setCurrentPage(1);
                          setIsDropdownVisible(false);
                          handleClick();
                        }}
                      >
                        <span>Price - High to Low</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "l2hByd",
                            show: "Duration - Low to High",
                          });
                          setCurrentPage(1);
                          setIsDropdownVisible(false);
                          handleClick();
                        }}
                      >
                        <span>Duration - Low to High</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "h2lByd",
                            show: "Duration - High to Low",
                          });
                          setCurrentPage(1);
                          setIsDropdownVisible(false);
                          handleClick();
                        }}
                      >
                        <span>Duration - High to Low</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="cards-flex">
              {loader || loaderFilter ? (
                Array.from({ length: 9 }).map((items, index) => (
                  <div
                    key={index}
                    className="card-listing"
                    style={{
                      background: "none",
                      borderRadius: "8px",
                      marginBottom: "0px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      padding: "0px",
                    }}
                  >
                    <div>
                      <Skeleton
                        style={{ borderRadius: "8px 8px 0px 0px" }}
                        height={150}
                      />
                    </div>
                    <div className="card_content" style={{ marginTop: "0px" }}>
                      <h6>
                        <Skeleton width="60%" />
                      </h6>
                      <h6>
                        <Skeleton width="40%" />
                      </h6>
                      <h6>
                        <Skeleton width="20%" />
                      </h6>
                      <h6>
                        <Skeleton width="24%" />
                      </h6>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {" "}
                  {displayedData?.length === 0 ? (
                    <NoDataFound />
                  ) : (
                    <>
                      {displayedData?.length &&
                        displayedData?.map((data, index) => (
                          <CustomListingCard
                            date={date}
                            key={index}
                            // imgSrc={
                            //   data.packageImages[0]
                            //     ? data.packageImages[0]
                            //     : data.packageDestination[0].images
                            // }
                            imgSrc={
                              data?.featuredImage
                                ? data?.featuredImage
                                : imgNotFound
                            }
                            title={data.packageName}
                            packageHub={data.packageHub}
                            duration={data.totalDays}
                            nightDuration={data.totalNights}
                            price={data.startingPrice}
                            bookingLink={data.bookingLink}
                            detailsLink={data.detailsLink}
                            packagehandle={data.packagehandle}
                            packageHotels={data?.packageHotels}
                            canBookable={data?.canBookable}
                            packageId={data?.packageId}
                            setShowSendEnquiryPopup={setShowSendEnquiryPopup}
                            markupData={markupData}
                          />
                        ))}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="communication_pagination">
              <CustomPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={packageData?.length}
                onPageChange={handlePageChange}
                clearSelection={clearSelection}
              />
            </div>
          </div>
        </main>
      </div>
      {/* <DestinationLinks /> */}
      {/* 
      {showSendEnquiryPopup && (
        <SendEnquiryPopup
          package_id={""}
          setshowRatePopup={setShowSendEnquiryPopup}
          typeDetail="package"
        />
      )} */}

      {showSendEnquiryPopup && (
        <NewSendEnquiryPopup
          package_id={""}
          setshowRatePopup={setShowSendEnquiryPopup}
          typeDetail="package"
        />
      )}
      <Footer />
    </div>
  );
}

export default ListingHolidays;
