import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import "../SinglePackageHeader/SinglePackageHeader.css"
import GetQuoteDownloadPopup from "../SendEnquiryPopup/GetQuoteDownloadPopup";
import NewGetQuoteDownloadPopup from "../NewGetQuoteDownloadPopup/NewGetQuoteDownloadPopup";

const ButtonToDownloadTablePDF = ({
  itemToDownloadID,
  // setOpenGetQuotePopup,
  ignoreFromPrint,
  pdfOrientation,
  packageData
}) => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [openGetQuotePopup, setOpenGetQuotePopup] = useState(false);

  const handleDownload = () => {
    const originalData = document.getElementById(itemToDownloadID);


    const liElements = document.getElementById(itemToDownloadID).getElementsByTagName("li");

    for (let i = 0; i < liElements.length; i++) {
      liElements[i].style.display = "list-item";
      liElements[i].style.listStyleType = "disc";
      liElements[i].style.marginTop = "5px";
      liElements[i].style.paddingLeft = "20px";
    }

    originalData.style.display = "block";

    const clonedData = originalData.cloneNode(true);

    if (ignoreFromPrint) {
      const itemToRemove = clonedData.querySelector(`#${ignoreFromPrint}`);
      if (itemToRemove) {
        itemToRemove.remove();
      }
    }

    const tempContainer = document.createElement("div");
    tempContainer.appendChild(clonedData);

    originalData.style.display = "none";
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `${packageData?.packageName}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, letterRendering: true, useCORS: true },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: pdfOrientation || "portrait",
      },
    };

    html2pdf()
      .from(tempContainer)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        // const totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i);
        //   pdf.setFontSize(8);
        //   const pageWidth = pdf.internal.pageSize.getWidth();
        //   const pageHeight = pdf.internal.pageSize.getHeight();
        //   const margin = 0.5; // Adjust margin for footer text
        //   pdf.text(
        //     `Page ${i} of ${totalPages}`,
        //     pageWidth / 2,
        //     pageHeight - margin,
        //     { align: "center" }
        //   );
        // }
      })
      .save()
      .finally(() => {
        originalData.style.display = "none";
      });
  };

  const handleDownloadHandler = () => {
    setOpenGetQuotePopup(true);
  }

  useEffect(() => {
    // Check if token is present in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <div
        style={{
          // cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "5px",
        }}
      >
        <button
          onClick={isLoggedIn ? handleDownload : handleDownloadHandler}
          className="Single_pkg_header-btn"
          style={{ marginRight: "10px" }}
        >
          <strong className="Single_pkg_header_modify ">Download </strong>{" "}
          <span className="Single_pkg_header_modify_icon Single_pkg_header_download">
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_4548_4758" style={{ maskType: "alpha", transform: "rotate(180deg)" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4548_4758)">
                <path d="M6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0209 4 18.55 4 18V16C4 15.7167 4.09583 15.4792 4.2875 15.2875C4.47917 15.0959 4.71667 15 5 15C5.28333 15 5.52083 15.0959 5.7125 15.2875C5.90417 15.4792 6 15.7167 6 16V18H18V16C18 15.7167 18.0958 15.4792 18.2875 15.2875C18.4792 15.0959 18.7167 15 19 15C19.2833 15 19.5208 15.0959 19.7125 15.2875C19.9042 15.4792 20 15.7167 20 16V18C20 18.55 19.8042 19.0209 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6ZM11 7.85005L9.125 9.72505C8.925 9.92505 8.6875 10.0209 8.4125 10.0125C8.1375 10.0042 7.9 9.90005 7.7 9.70005C7.51667 9.50005 7.42083 9.26672 7.4125 9.00005C7.40417 8.73338 7.5 8.50005 7.7 8.30005L11.3 4.70005C11.4 4.60005 11.5083 4.52922 11.625 4.48755C11.7417 4.44588 11.8667 4.42505 12 4.42505C12.1333 4.42505 12.2583 4.44588 12.375 4.48755C12.4917 4.52922 12.6 4.60005 12.7 4.70005L16.3 8.30005C16.5 8.50005 16.5958 8.73338 16.5875 9.00005C16.5792 9.26672 16.4833 9.50005 16.3 9.70005C16.1 9.90005 15.8625 10.0042 15.5875 10.0125C15.3125 10.0209 15.075 9.92505 14.875 9.72505L13 7.85005V15C13 15.2834 12.9042 15.5209 12.7125 15.7125C12.5208 15.9042 12.2833 16 12 16C11.7167 16 11.4792 15.9042 11.2875 15.7125C11.0958 15.5209 11 15.2834 11 15V7.85005Z" fill="#1C1B1F" />
              </g>
            </svg>
          </span>
        </button>
      </div>
      {/* {openGetQuotePopup &&
        <GetQuoteDownloadPopup
          package_id={packageData?.packageId}
          setshowRatePopup={setOpenGetQuotePopup}
          handleDownload={handleDownload}
          packageData={packageData}
        />
      } */}

      {openGetQuotePopup &&
        <NewGetQuoteDownloadPopup
          package_id={packageData?.packageId}
          setshowRatePopup={setOpenGetQuotePopup}
          handleDownload={handleDownload}
          packageData={packageData}
        />
      }
    </>

    // <div
    //   onClick={handleDownload}
    //   // onClick={longPdf ?  handleDownload   :  handleDownloadWithJspdf}

    //   style={{
    //     cursor: "pointer",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "end",
    //     gap: "5px",
    //   }}
    // >
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     width="14"
    //     height="14"
    //     viewBox="0 0 14 14"
    //     fill="none"
    //   >
    //     <path
    //       d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
    //       fill="#3968ED"
    //     />
    //   </svg>
    //   <span style={{ color: "#3968ED", fontSize: "14px", fontStyle: "normal" }}>
    //     Download
    //   </span>
    // </div>
  );
};

export default ButtonToDownloadTablePDF;
