import React from "react";
import "./Loader.css";

function FullWidthLoader({ size, color }) {
  return (
    <div className="full_width_loader_overlay">
      <span className="full_width_loader"></span>
    </div>
  );
}

export default FullWidthLoader;
