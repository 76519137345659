import React, { useEffect, useState } from "react";
import "./PackageDetails.css";
import ButtonToDownloadTablePDF from "./ButtonToDownload";
import PkgItinerary from "../../Pages/PkgItinerary/PkgItinerary";

import logo from "../../assets/images/Mountain-mystries-logo.png";
import { textDateFormatter } from "../../utils/utils";

const PackageDetails = ({ packageData: pkgDetail, setOpenGetQuotePopup, selectedCategory, allPackageHotels }) => {
  const [baseImages, setBaseImages] = useState([]);

  const [encodedUrl, setEncodedUrl] = useState("");

  async function imageUrlToBase64Canvas(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // This is important to handle CORS
      img.src = url;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png"); // Convert to base64
        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(new Error("Error loading image: " + error));
      };
    });
  }

  // Usage example
  // imageUrlToBase64Canvas('https://travcatalyst.s3.ap-south-1.amazonaws.com/2564/Shimla-Ridge.jpg')
  //     .then(base64Url => {
  //         console.log('Base64 Encoded URL:', base64Url);
  //         setEncodedUrl(base64Url);
  //     })
  //     .catch(error => {
  //         console.error('Error:', error);
  //     });

  // Convert image URLs to base64
  //   useEffect(() => {
  //     const convertImagesToBase64 = async () => {
  //         if (pkgDetail?.packageDestination) {
  //           try {
  //             // Iterate over packageDestination array and convert images
  //             const promises = pkgDetail.packageDestination.map(async (item) => {
  //               const convertToBase64 = async (url) => {
  //                 try {
  //                   console.log(`Attempting to fetch image from: ${url}`);

  //                   // Fetch the image from the provided URL
  //                   const response = await fetch(url, { mode: 'no-cors' });

  //                   // Check if the response is OK
  //                   if (!response.ok) {
  //                     throw new Error(`Failed to fetch image: ${response.statusText}`);
  //                   }

  //                   // Read the response as a Blob
  //                   const blob = await response.blob();
  //                   console.log('Blob created:', blob);

  //                   return new Promise((resolve, reject) => {
  //                     const reader = new FileReader();

  //                     // Handle successful reading
  //                     reader.onloadend = () => {
  //                       console.log(`Successfully converted image from ${url} to base64`);
  //                       resolve(reader.result); // Base64 image
  //                     };

  //                     // Handle reading errors
  //                     reader.onerror = () => {
  //                       console.error(`Failed to convert image from ${url} to base64`);
  //                       reject("Failed to convert image to base64");
  //                     };

  //                     // Read the blob as a data URL
  //                     reader.readAsDataURL(blob);
  //                   });
  //                 } catch (error) {
  //                   console.error(`Error fetching the image at ${url}:`, error);
  //                   return ''; // Return an empty string on failure
  //                 }
  //               };

  //               // Convert image URL to base64
  //               const base64Image = await convertToBase64(item?.image);
  //               console.log("base64Image", base64Image); // Log the base64Image

  //               return { ...item, base64Image }; // Return updated object with base64 image
  //             });

  //             // Wait for all promises to resolve
  //             const result = await Promise.all(promises);

  //             // Log the result
  //             console.log("Base64 conversion result:", result);

  //             // Update the state with the converted images
  //             setBase64Images(result);
  //           } catch (error) {
  //             console.error("Error converting images to base64:", error);
  //           }
  //         }
  //       };

  //     // Call the function to convert images when pkgDetail changes
  //     convertImagesToBase64();
  //   }, [pkgDetail]);
  // console.log(pkgDetail, "pkg");

  const encodeImageToBase64 = async (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Enable CORS
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);

        const dataURL = canvas.toDataURL("image/jpeg");
        resolve(dataURL);
      };

      img.onerror = (err) => reject(err);
    });
  };

  const packageHotels = pkgDetail?.packageItinerary?.flatMap(
    (item) => item.accommodation
  );

  const groupByCategory = (accommodations) => {
    return (
      accommodations &&
      accommodations?.reduce((acc, accommodation) => {
        const category = accommodation.hotelcategory;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(accommodation);
        return acc;
      }, {})
    );
  };

  return (
    <div>
      <ButtonToDownloadTablePDF
        itemToDownloadID={"package-details"}
        setOpenGetQuotePopup={setOpenGetQuotePopup}
        packageData={pkgDetail}
      // longPdf={true}
      />

      {/* <PkgItinerary style={{ display: "one" }} idToAdd="package-detaeils" packageId={packageData?.packageId}  /> */}

      <div id="package-details">
        <table className="PkgItinerary_table">
          <thead>
            <tr>
              <td>
                <div className="PkgItinerary_logo">
                  <img src={logo} alt="" />
                  {/* <img src={encodedUrl} alt="" /> */}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="PkgItinerary_content">
                  <div className="PkgItinerary_details">
                    <h3 className="PkgItinerary_details_heading">
                      {pkgDetail?.packageName}
                    </h3>
                    <p className="PkgItinerary_details_validity">
                      Validity : {textDateFormatter(pkgDetail.validFrom)} /{" "}
                      {textDateFormatter(pkgDetail.validTo)}{" "}
                    </p>
                    <p className="PkgItinerary_details_tourDuration">
                      Duration : {pkgDetail?.totalNights} Nights /{" "}
                      {pkgDetail?.totalDays} Days
                    </p>
                  </div>
                </div>
                <div className="PkgItinerary_wrapBox">
                  <div className="PkgItinerary_titleHead">Destination</div>
                  <div className="PkgItinerary_destinationWrap">
                    {pkgDetail?.packageDestination?.map((items, index) => {
                      return (
                        <div className="PkgItinerary_imgWrapDest" key={index}>
                          <img src={items?.image} alt={items?.name} crossorigin="anonymous" />
                          <div className="PkgItinerary_destTitle">
                            {items?.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="PkgItinerary_wrapBox">
                  <div className="PkgItinerary_titleHead">Day Wise Details</div>
                  <table
                    cellSpacing={0}
                    className="w-full PkgItinerary_itinearyTable"
                  >
                    <tbody>
                      {pkgDetail?.packageItinerary?.map((items, index) => {
                        return (
                          <tr key={index} className="p_top_0">
                            <td>
                              <h2 className="PkgItinerary_dayBox">
                                <h2 className="daytxt">Day </h2>
                                <h2 className="dayNumber"> {items?.day} </h2>
                              </h2>
                              <div className="PkgItinerary_itinearyContent">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      items?.additionalDetails
                                        ?.short_description,
                                  }}
                                ></p>
                                {/* <p>{items?.shortDescription}</p> */}
                                <div className="PkgItinerary_summaryCol">
                                  <p>
                                    <b>Starts :</b> {items.startDestination}
                                  </p>
                                  <p>
                                    <b>End :</b> {items.endDestination}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 1 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 2 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 3 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 4 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b>  Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="PkgItinerary_wrapBox">
                  <div className="PkgItinerary_titleHead">Proposed Hotel</div>
                  <table
                    cellSpacing={0}
                    className="PkgItinerary_itineary_details"
                    style={{ width: "100%", padding: "10px 0px" }}
                  >
                    <tbody>
                      <tr className="headerRow">
                        <td>
                          <b>Destination</b>
                        </td>
                        <td>
                          <b>Hotel</b>
                        </td>
                        <td>
                          <b>Category</b>
                        </td>
                      </tr>
                      {/* {packageHotels?.map((hotel, index) => {
                        return (
                          <tr className="contentRow" key={index}>
                            <td className="py-5">{hotel?.city}</td>
                            <td className="py-5">{hotel?.name}</td>
                            <td className="py-5">{hotel?.hotelcategory}</td>
                          </tr>
                        );
                      })} */}

                      {groupByCategory(allPackageHotels)[
                        selectedCategory
                      ]?.map((itemData, itemIndex) => {
                        return (
                          <tr className="contentRow" key={itemIndex}>
                            <td className="py-5">{itemData?.city}</td>
                            <td className="py-5">{itemData?.name}</td>
                            <td className="py-5">{itemData?.hotelcategory}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Cost per person for the proposed tour</div>
                                <table cellSpacing={0} className="PkgItinerary_itineary_details" style={{ width: "100%", padding: "10px 0px" }}>
                                    <tbody>
                                        <tr className="headerRow">
                                            <td><b>No of Pax</b></td>
                                            <td><b>Hotel Category</b></td>
                                            <td><b>Price</b></td>
                                            <td><b>Extra Adult/Child with bed</b></td>
                                            <td><b>Child without Bed</b></td>
                                            <td><b>Meal Plan</b></td>
                                        </tr>
                                        {pkgDetail?.packageRates?.map((items) => {
                                            return (
                                                <tr className="contentRow">
                                                    <td>{items?.no_pax}</td>
                                                    <td>Standard</td>
                                                    <td>18288.00</td>
                                                    <td>9017.00</td>
                                                    <td>N/A</td>
                                                    <td>MAP</td>
                                                </tr>
                                            )
                                        })}

                                        <tr className="contentRow">
                                            <td>4 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>6 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                <div className="PkgItinerary_legalWrap">

                  {pkgDetail?.packagePolicies?.filter(
                    (item) => item.type === "inclusion"
                  ).length > 0 ? (
                    <div className="PkgItinerary_inclusion">
                      <div>
                        <div className="PkgItinerary_titleHead">Inclusions</div>
                        <div className="PkgItinerary_legalContent">
                          <ul style={{ listStyleType: "disc" }}>
                            {pkgDetail?.packagePolicies?.map((items, index) => {
                              // console.log(items);
                              return (
                                <div key={index}>
                                  {items?.type === "inclusion" && (
                                    <>
                                      {/* <h4>{items.name}</h4> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: items?.content,
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                  ) : ""}
                  {pkgDetail?.packagePolicies?.filter(
                    (item) => item.type === "inclusion"
                  ).length > 0 ? (
                    <div className="PkgItinerary_exclusion">
                      <div>
                        <div className="PkgItinerary_titleHead">Exclusions</div>
                        <div className="PkgItinerary_legalContent">
                          <ul style={{ listStyleType: "disc" }}>
                            {pkgDetail?.packagePolicies?.map((items, index) => {
                              // console.log(items);
                              return (
                                <div key={index}>
                                  {items?.type === "exclusion" && (
                                    <>
                                      {/* <h4>{items.name}</h4> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: items?.content,
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : ""}



                  {pkgDetail?.packagePolicies?.filter(
                    (item) => item.type === "terms_and_conditions"
                  ).length > 0 ? (
                    <div className="PkgItinerary_term">
                      <div>
                        <div className="PkgItinerary_titleHead">
                          Terms and Conditions
                        </div>
                        <div className="PkgItinerary_legalContent">
                          <ul>
                            {pkgDetail?.packagePolicies?.map((items, index) => {
                              // console.log(items);
                              return (
                                <div key={index}>
                                  {items?.type === "terms_and_conditions" && (
                                    <>
                                      {/* <h4>{items.name}</h4> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: items?.content,
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {pkgDetail?.packagePolicies?.filter(
                    (item) => item.type === "cancellation_policy"
                  ).length > 0 ? (
                    <div className="PkgItinerary_cancellation">
                      <div>
                        <div className="PkgItinerary_titleHead">
                          Cancellation Policy
                        </div>
                        <div className="PkgItinerary_legalContent">
                          <ul>
                            {pkgDetail?.packagePolicies?.map((items, index) => {
                              // console.log(items);
                              return (
                                <div key={index}>
                                  {items?.type === "cancellation_policy" && (
                                    <>
                                      {/* <h4>{items.name}</h4> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: items?.content,
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {pkgDetail?.packagePolicies?.filter(
                    (item) => item.type === "points_to_remember"
                  ).length > 0 ? (
                    <div className="PkgItinerary_remember">
                      <div>
                        <div className="PkgItinerary_titleHead">
                          Points to remember
                        </div>
                        <div className="PkgItinerary_legalContent">
                          {pkgDetail?.packagePolicies?.map(
                            (item, index) =>
                              item.type === "points_to_remember" && (
                                <div key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item?.content,
                                    }}
                                  ></div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : ""}
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className="PkgItinerary_titleHead"
                >
                  Get in touch
                </div>
                <div className="PkgItinerary_contactInfo">
                  <div className="PkgItinerary_c_details">
                    <div>
                      <b>Phone : </b>+91-98050-21966 , 0177-2627995
                    </div>
                    <div>
                      <b>Email : </b>info@mountainmysteries.com
                    </div>
                  </div>
                </div>

                <div className="PkgItinerary_notice">
                  This is one of our most popular holiday package to your chosen
                  destination. The pricing is indicative and can change
                  depending on hotels etc. chosen. Once your Travel Expert gets
                  in touch with you, feel free to ask for any customisation in
                  this itinerary or have a fresh itinerary designed, as per your
                  preference. When you are making the booking, please do check
                  that all the selected package inclusions are showing on the
                  package review page and confirm that all elements including
                  transportation, hotels and sightseeing are exactly as
                  finalised by you.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PackageDetails;
