import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GooglePlaceApi from "../GoogleApi/GooglePlaceApi/GooglePlaceApi.jsx";
import "../SingleSearchForm/SingleSearchForm.css";
import Flatpickr from "react-flatpickr";
import PackagesStateInput from "../PackagesStateInput/PackagesStateInput.jsx";

function ListingActivitiesSearchForm({ searchType }) {
  const [modifyPkg, setModiyPkg] = useState(false);
  const [mobileHead, setMobileHead] = useState(false);
  const toggleModify = () => {
    setModiyPkg(!modifyPkg);
  };
  const [dataFromChild, setDataFromChild] = useState("");

  const getDataFromChild = (data, resultArray) => {
    setDataFromChild(data);
    // setStateResultArray(resultArray)
    // console.log(resultArray);
  };
  //   console.log(dataFromChild, "getDataFromChild");

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [data, setData] = useState({ fromCity: "", toCity: "" });
  const [childAge, setChildAge] = useState([]);

  const [guest, setGuest] = useState(1);

  const [guestDetails, setIsguestDetails] = useState(false);
  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };

  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setIsguestDetails(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const showErrorToast = (message) => {
    alert(message);
  };

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const data = {
      // hotelID: params.get("hotelID"),
      location: params.get("location"),
      adult: params.get("adult"),
    };

    setData({
      fromCity: params.get("location"),
    });
    setGuest(params.get("adult") || 1)
    setBookingData(data);
    // postBookingData(data);
  }, [location.search]);

  const navigate = useNavigate();

  // Date alterations
  const [loader, setLoader] = useState(false);

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  };

  const handleSubmitButtonClickHandlerListingHotels = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }
    setLoader(false);
    setModiyPkg(false);

    try {
      await navigate(
        `/listing-activities?location=${dataFromChild}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      setLoader(false);
      setModiyPkg(false);
    } finally {
      setLoader(false);
    }
  };
  const handleSubmitButtonClickHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    if (hasChildAgeError) {
      return;
    }
    setLoader(false);
    setModiyPkg(false);

    try {
      await navigate(`/listing-activities?location=${dataFromChild}&adult=${guest}`, undefined, {
        shallow: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
      setModiyPkg(false);
    } finally {
      setLoader(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setMobileHead(true);
    } else {
      setMobileHead(false);
    }
  }, []);

  return (
    <section className="checkout-hotels">
      <div className="test_form" id="singleSearch">
        <div className="container">
          <form
            action=""
            className="SingleSearch-mainform test_form main-form"
            id="main-form"
            onSubmit={
              searchType === "hotel_listing"
                ? handleSubmitButtonClickHandlerListingHotels
                : handleSubmitButtonClickHandler
            }
          >
            {mobileHead ? (
              <>
                <div className="SingleSearch-Resp flex_align_center justify_content_between">
                  <div className="flex_align_center">
                    <span onClick={() => window.history.back()}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                          fill="#333333"
                        />
                      </svg>
                    </span>
                    <div className="SingleSearch-resp_content">
                      <h4>
                        {data?.fromCity ? (
                          capitalizeFirstLetter(data.fromCity)
                        ) : (
                          <p>Enter a destination</p>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div onClick={toggleModify}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_2797_7470"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2797_7470)">
                        <path
                          d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                          fill="#333333"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                {modifyPkg && (
                  <>
                    <div>
                      <label htmlFor="">City/Destination</label>
                      {/* <GooglePlaceApi
                        setSelectedLocation={setSelectedLocation}
                        data={data}
                        defaultValue={data?.fromCity && data.fromCity}
                        setData={setData}
                        showErrorToast={showErrorToast}
                        position={"SingleSearchForm"}
                      /> */}
                      <PackagesStateInput onDataChange={getDataFromChild} />
                    </div>
                    <div className="search-btn-width">
                      <button className="common_btn">SEARCH</button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div>
                  <label htmlFor="">City/Destination</label>
                  {/* <GooglePlaceApi
                    setSelectedLocation={setSelectedLocation}
                    data={data}
                    defaultValue={data?.fromCity && data.fromCity}
                    setData={setData}
                    showErrorToast={showErrorToast}
                    position={"SingleSearchForm"}
                  /> */}
                  <PackagesStateInput onDataChange={getDataFromChild} />
                </div>
                <div className="search_form_div p_0 icon_button_only">
                  <label
                    onClick={dropdownGuestDetails}
                    className="search_label"
                  >
                    Guests
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 0.75L4.75 4.25L1.25 0.75"
                        stroke="#BB735D"
                      />
                    </svg>
                  </label>
                  <p
                    id="guest_input"
                    style={{ position: "relative" }}
                    className="SingleSearch-Guests dropdown-toggle search_form_input testform_input"
                  >
                    <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                      <div className="optionCounter"></div>
                      <span id="adult_count">{guest}</span>
                      <span> Guests</span>
                    </div>
                    {guestDetails && (
                      <div
                        style={{ left: "0px" }}
                        className="guest_details_dropdown"
                        id="guestDetailsPopup"
                        ref={popupsRef}
                      >
                        <div className="agent">
                          <span>Guests</span>
                          <div>
                            <button
                              type="button"
                              disabled={guest <= 1}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={() => {
                                if (guest <= 1) return;
                                setGuest(Number(guest) - 1);
                              }}
                            >
                              <span className="onclick_btn" id="adult_minus">
                                -
                              </span>
                            </button>

                            <input
                              className="optionCounterNumber"
                              value={guest}
                              min={1}
                              type="number"
                              onChange={(e) => setGuest(e.target.value)}
                            />

                            <button
                              type="button"
                              disabled={guest >= 11}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={() => {
                                if (guest >= 11) return;
                                setGuest(Number(guest) + 1);
                              }}
                            >
                              <span className="onclick_btn" id="adult_plus">
                                +
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </p>
                </div>
                <div className="search-btn-width">
                  <button className="common_btn">SEARCH</button>
                </div>
                {/* <div className="search-btn-width">
                  <button className="common_btn" onClick={clearAllFilters}>
                    Clear
                  </button>
                </div> */}
              </>
            )}
          </form>
        </div>
        <input type="hidden" name="hotelId" value="{{ $hotelId }}" />
      </div>
    </section>
  );
}

export default ListingActivitiesSearchForm;
