import React, { useEffect, useState } from "react";
import "../SingleActivities/SingleActivities.css";
import Footer from "../../CustomComponents/Footer/Footer";
import SendItinerary from "../../CustomComponents/SendItinerary/SendItinerary";
import ChooseUs from "../../CustomComponents/ChooseUs/ChooseUs";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import Slider from "react-slick";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import SingleActivitySearchForm from "../../CustomComponents/SingleActivitySearchForm/SingleActivitySearchForm";
import {
  currentDateSingle,
  formatIndianCurrency,
  scrollToTop,
} from "../../utils/utils";
import BgBackdrop from "../../CustomComponents/BgBackDrop/BgBackDrop";
import Skeleton from "react-loading-skeleton";
import { Fancybox } from "@fancyapps/ui";
import { animateScroll as scroll } from "react-scroll";
import SendEnquiryPopup from "../../CustomComponents/SendEnquiryPopup/SendEnquiryPopup";
import ListingActivitiesSearchForm from "../../CustomComponents/ListingActivitiesSearchForm/ListingActivitiesSearchForm";
import SinglectivityModifyHeader from "../../CustomComponents/SinglePackageHeader/SingleActivityModifyHeader";
import imgNotFound from "../../assets/images/single-image.png";
import NewSendEnquiryPopup from "../../CustomComponents/NewSendEnquiryPopup/NewSendEnquiryPopup";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowRight slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowLeft slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SingleActivities() {
  const [singleActivityData, setSingleActivityData] = useState({});
  const location = useLocation();
  const [bookingData, setBookingData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [loader, setLoader] = useState(true);
  const [bookngDate, setBookingDate] = useState(currentDateSingle);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [markupData, setMarkupData] = useState([]);
  const [commission, setCommission] = useState({});
  const userType = localStorage.getItem("userType");
  const userToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const { slug } = useParams();
  const { id } = useParams();
  const [showRatePopup, setshowRatePopup] = useState(false);

  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };

  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchSingleActivityData = () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);

    // Set loader to true when the request starts
    setLoader(true);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/activities/fetch/single/${slug}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSingleActivityData(result?.data);
        // Set loader to false when the data is fetched successfully
        scrollToTop();
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        // Set loader to false in case of an error
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchSingleActivityData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const data = {
      location: params.get("location"),
      adult: params.get("adult") || 1,
      date: params.get("date"),
      month: params.get("month"),
      year: params.get("year"),
    };

    const bookingDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;

    if (data.date && data.month && data.year) {
      setBookingDate(bookingDate);
    } else {
      setBookingDate(currentDateSingle);
    }
    setBookingData({ ...data, bookingDate });
  }, [location.search]);

  const settings = {
    dots: false,
    infinite: singleActivityData?.activityImages > 1,
    speed: 500,
    slidesToShow: Math.min(singleActivityData?.activityImages, 3),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slider = React.useRef(null);

  const totalAddonsCostWithoutCommission = selectedAddOns?.reduce((total, addon) => {
    if (addon.addonPriceWithMarkup) {
      if (addon.perPerson && bookingData?.adult) {
        return total + Number(addon.addonPriceWithMarkup) * Number(bookingData?.adult);
      } else {
        return total + Number(addon.addonPriceWithMarkup);
      }
    }
  }, 0);

  const totalAddonsCost = selectedAddOns.reduce((total, addon) => {
    if (addon.perPerson && bookingData?.adult) {
      return total + Number(addon.finalAddonPrice) * Number(bookingData.adult);
    } else {
      return total + Number(addon.finalAddonPrice);
    }
  }, 0);

  const navigateToCheckOutActivity = () => {
    // let hasChildAgeError = false;
    // const childAge = bookingData?.childAge;
    // const sanitizedChildAges = childAge
    //   .map((age) => (age !== "" ? age : null))
    //   .filter((age) => age !== null);

    // const childAgeParams = sanitizedChildAges
    //   .map((age, index) => {
    //     if (age > 12) {
    //       alert("Child age must be between 0 and 12");
    //       hasChildAgeError = true;
    //       return;
    //     }
    //     return `&childAge[${index + 1}]=${age}`;
    //   })
    //   .join("");

    navigate(
      `/checkout-activities/${singleActivityData?.activityHandle}&guest=${bookingData?.adult}`,
      {
        state: {
          activityData: singleActivityData,
          bookingData: bookingData,
          activityPrice: displayedRate,
          selectedAddOns: selectedAddOns,
          totalAddonsCost: totalAddonsCost,
          totalAddonsCostWithoutCommission: totalAddonsCostWithoutCommission,
          markupData: markupData,
          activityPriceWithMarkup, activityPriceWithMarkup,
          // selectedCategory: selectedCategory,
          // calculationsOfPackages: calculationsOfPackages,
          // customized: false,
        },
      }
    );
  };

  const [displayedRate, setDisplayedRate] = useState(null);
  const [activityPriceWithMarkup, setActivityPriceWithmarkup] = useState(null);

  const [agentSavingsonAddons, setAgentSavingsonAddons] = useState(null);

  const filterRatesByAdults = (activityRates, numberOfAdults) => {
    if (!activityRates || activityRates.length === 0) return null;
    const adults = parseInt(numberOfAdults, 10);
    const matchingRate = activityRates.find((rate) => rate.pax === adults);
    return matchingRate ? matchingRate.amount : null;
  };

  const handleSelectedAddOns = (addon) => {
    setSelectedAddOns((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.id === addon.id
      );
      if (isSelected) {
        return prevSelected.filter((selected) => selected.id !== addon.id);
      } else {
        return [...prevSelected, addon];
      }
    });
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleAccordionPolicies = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  // markup fetch api
  const fetchMarkup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const findObject = result?.data?.find(
            (mark) => mark?.markupCustomer === "both"
          );
          if (findObject) {
            setMarkupData(findObject);
          } else {
            setMarkupData(null);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchCommissions = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/commission/activity/${singleActivityData?.activityId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        if (data?.data?.commissionType != "none") {
          setCommission(data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (singleActivityData?.activityId && userType === "agent") {
      fetchCommissions();
    }
  }, [singleActivityData?.activityId, userType]);

  useEffect(() => {
    fetchMarkup();
  }, []);

  useEffect(() => {
    if (singleActivityData?.activityRates && bookingData?.adult) {
      const rate = filterRatesByAdults(
        singleActivityData.activityRates,
        bookingData.adult
      );

      if (rate === null) {
        setDisplayedRate(null);
        return;
      }

      let finalRate = rate;
      let activityRateWithMarkup = rate;

      if (markupData) {
        const markup = markupData;
        if (markup.markupType === "percentage") {
          finalRate += (rate * parseFloat(markup.amount)) / 100;
        } else {
          finalRate += parseFloat(markup.amount);
        }
      }

      activityRateWithMarkup = finalRate;

      // if (commission?.commissionAmount && commission?.commissionType) {
      //   if (commission?.commissionType === "percentage") {
      //     finalRate -=
      //       (Number(finalRate) * Number(commission?.commissionAmount)) / 100;
      //   } else if (commission?.commissionType === "fixed") {
      //     finalRate = Number(commission?.commissionAmount);
      //   }
      // }
      // const savings = activityRateWithMarkup - finalRate;
      setDisplayedRate(finalRate);
      setActivityPriceWithmarkup(activityRateWithMarkup);
      // setAgentSavingsonActivity(savings);
    }
  }, [singleActivityData, bookingData, commission, markupData]);

  return (
    <div className="singleActivity">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <Header />
      {/* <SingleActivitySearchForm /> */}
      <div className="container">
        <SinglectivityModifyHeader activityData={singleActivityData} />
      </div>

      {/* <div className="flex align_items_center flex_gap_10">
        <span
          onClick={() => [
            scroll.scrollToTop({
              duration: 500,
              smooth: "easeInOutQuad",
            }),
            navigate(-1),
          ]}
          style={{
            background: "#cccccc7e",
            padding: "5px 10px 10px",
            borderRadius: "6px",
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
              fill="#333333"
            ></path>
          </svg>
        </span>{" "}
        <h4>
          <b>{singleActivityData?.activityName}</b>
        </h4>
      </div> */}

      <section>
        <div className="container">
          {loader ? (
            <>
              {Array.from({ length: 1 }).map((_, index) => (
                <div className="gallery" key={index}>
                  <div>
                    <Skeleton height={350} />
                  </div>
                  <div className="gallery2">
                    <div>
                      <Skeleton height={170} />
                    </div>
                    <div className="SingleHotel-gallary-sm">
                      <Skeleton height={170} />
                    </div>
                    <div className="SingleHotel-gallary-sm">
                      <Skeleton height={170} />
                    </div>
                    <div className="SingleHotel-gallary-sm">
                      <Skeleton height={170} />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="relative ">
              {singleActivityData?.activityImages &&
                singleActivityData?.activityImages.length > 0 && (
                  <>
                    <div className="gallery">
                      <div>
                        {singleActivityData?.activityImages?.length > 0 ? (
                          <img
                            src={singleActivityData.activityImages[0]}
                            alt="hotel-image"
                          />
                        ) : (
                          <img src={imgNotFound} alt="Image not found" />
                        )}
                        {singleActivityData?.activityImages?.length > 1 && (
                          <div>
                            <button
                              className="images_btn"
                              onClick={() => {
                                Fancybox.show(
                                  singleActivityData?.activityImages?.map(
                                    (src) => ({
                                      src,
                                      type: "image",
                                    })
                                  )
                                );
                              }}
                            >
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 16 15"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z" />
                              </svg>
                              See all images
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="gallery2">
                        <div>
                          {singleActivityData?.activityImages?.length > 1 ? (
                            <img
                              src={singleActivityData.activityImages[1]}
                              alt="hotel-image"
                            />
                          ) : (
                            <img src={imgNotFound} alt="Image not found" />
                          )}
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          {singleActivityData?.activityImages?.length > 1 ? (
                            <img
                              src={singleActivityData.activityImages[2]}
                              alt="hotel-image"
                            />
                          ) : (
                            <img src={imgNotFound} alt="Image not found" />
                          )}
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          {singleActivityData?.activityImages?.length > 1 ? (
                            <img
                              src={singleActivityData.activityImages[3]}
                              alt="hotel-image"
                            />
                          ) : (
                            <img src={imgNotFound} alt="Image not found" />
                          )}
                        </div>
                        <div className="SingleHotel-gallary-sm">
                          {singleActivityData?.activityImages?.length > 1 ? (
                            <img
                              src={singleActivityData.activityImages[4]}
                              alt="hotel-image"
                            />
                          ) : (
                            <img src={imgNotFound} alt="Image not found" />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      </section>
      {/* Activities Details */}
      <section>
        <div className="container">
          <main>
            <div className="overview-content SingleActivities-overview">
              <div>
                <p>
                  <b>Activity Location: </b>
                  {bookingData.location === null
                    ? singleActivityData?.activityDestination
                    : bookingData?.location}
                </p>
                {/* <p>
                  <b>Activity Timing: </b> Between 08:00 AM to 05:00 PM
                </p>
                <p>
                  <b>Pickup Location: </b>Nehru Kund, Manali
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: singleActivityData?.activityDescription,
                  }}
                ></div>

                {/* <b>How To Reach?</b>
                <li>
                  The distance between Manali to Nehru Kund is 20-23 km by road.
                  You can also find the distance from Manali to Nehru Kund using
                  other travel options like bus, subway, tram, train, and rail.
                </li>
                <li>
                  Drive down to your paragliding spot in Nehru Kund which is
                  around 23 km from Manali on the Kullu road.
                </li>
                <li>
                  {" "}
                  The package includes a transfer from the Nehru Kund
                  paragliding counter on the National highway to the start
                  point. To reach the pickup point on the national highway one
                  can access public or a private mode of transportation.
                </li>{" "} */}
                {/* <div className="mt-10">
                  {" "}
                  <b>Note</b>
                  <li>
                    Drive down to your paragliding spot in Nehru Kund which is
                    around 23 km from Manali on the Kullu road.
                  </li>
                </div> */}
              </div>

              <div className="package_policies_main">
                {singleActivityData?.activityLegals?.length > 0 &&
                  singleActivityData?.activityLegals?.map((item, i) => {
                    return (
                      <div className="list_details p_10">
                        <div
                          className="flex justify_content_between align_items_center cursor_pointer"
                          onClick={() => toggleAccordionPolicies(i)}
                        >
                          {" "}
                          <h6 style={{ textTransform: "capitalize" }}>
                            {item.type
                              .replace(/_/g, " ")
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </h6>
                          <svg
                            style={{
                              transform:
                                expandedIndex === i
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                            width="10"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 1.38002C0 1.64002 0.1 1.89002 0.29 2.09002L7.23 9.03002C7.7 9.50002 8.33 9.76002 9 9.76002C9.67 9.76002 10.3 9.50002 10.77 9.02002L17.71 2.05002C18.1 1.66002 18.1 1.02002 17.71 0.630024C17.32 0.240024 16.69 0.240024 16.3 0.630024L9.36 7.60002C9.23 7.73002 9.08 7.75002 9.01 7.75002C8.93 7.75002 8.78 7.73002 8.66 7.60002L1.72 0.660024C1.33 0.270024 0.7 0.270024 0.31 0.660024C0.11 0.860024 0.02 1.11002 0.02 1.37002L0 1.38002Z"
                              fill="#5C5C5C"
                            />
                          </svg>
                        </div>
                        {expandedIndex === i && (
                          <div
                            className="py-5"
                            dangerouslySetInnerHTML={{
                              __html: item?.content,
                            }}
                          ></div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {/* {activityLegalsData && activityLegalsData?.length > 0 && (
                <div className="package_policies_main">
                  {activityLegalsData?.map((item, i) => (
                    <div className="list_details p_10">
                      <div
                        className="flex justify_content_between align_items_center cursor_pointer"
                        onClick={() => toggleAccordion(i)}
                      >
                        {" "}
                        <h5 style={{ textTransform: "capitalize" }}>
                          {item.type
                            .replace(/_/g, " ")
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </h5>
                        <svg
                          style={{
                            transform:
                              expandedIndex === i
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                          width="10"
                          height="10"
                          viewBox="0 0 18 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 1.38002C0 1.64002 0.1 1.89002 0.29 2.09002L7.23 9.03002C7.7 9.50002 8.33 9.76002 9 9.76002C9.67 9.76002 10.3 9.50002 10.77 9.02002L17.71 2.05002C18.1 1.66002 18.1 1.02002 17.71 0.630024C17.32 0.240024 16.69 0.240024 16.3 0.630024L9.36 7.60002C9.23 7.73002 9.08 7.75002 9.01 7.75002C8.93 7.75002 8.78 7.73002 8.66 7.60002L1.72 0.660024C1.33 0.270024 0.7 0.270024 0.31 0.660024C0.11 0.860024 0.02 1.11002 0.02 1.37002L0 1.38002Z"
                            fill="#5C5C5C"
                          />
                        </svg>
                      </div>
                      {expandedIndex === i && (
                        <div
                          className="py-5"
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </div>
              )} */}

              {/* {singleActivityData?.rules?.map((item, index) => {
                return (
                  <div key={index}>
                    {item.rule_type === "cancellation-policy" && (
                      <div className="list_details">
                        <h4 className="p_b_10">Cancellation Policies</h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                );
              })} */}

              {singleActivityData?.activityFaqs &&
                singleActivityData?.activityFaqs.length > 0 && (
                  <>
                    <h4 className="py-15">FAQs</h4>
                    {singleActivityData?.activityFaqs?.map((item, index) => (
                      <div className="accordion mb-20" key={index}>
                        <div className="accordion-item" key={index}>
                          <div
                            className="flex justify_content_between p_10 cursor_pointer accordion_icon align_items_center"
                            onClick={() => toggleAccordion(index)}
                          >
                            <div className="accordion-item-header">
                              {item?.question}
                            </div>
                            <svg
                              className={`accordion-icon ${activeIndex === index ? "rotated" : ""
                                }`}
                              width="10"
                              height="10"
                              viewBox="0 0 9 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.25 0.75L4.75 4.25L1.25 0.75"
                                stroke="#000"
                              />
                            </svg>
                          </div>
                          <div
                            className={`accordion-item-body ${activeIndex === index ? "active" : ""
                              }`}
                          >
                            <div
                              className="accordion-item-body-content"
                              dangerouslySetInnerHTML={{
                                __html: item?.answer,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
            </div>
            <aside>
              <div className="common_background" id="activityPrice">
                {/* <h4>
                  <b>
                    {displayedRate !== null
                      ? formatIndianCurrency(displayedRate)
                      : "Rate not available, please update adults"}
                  </b>
                  <small> per Adult</small>
                </h4> */}

                {displayedRate !== null ? (
                  <>
                    <p>Starting Price</p>
                    <h4>
                      <b>
                        {/* {commission && Object.keys(commission).length > 0 && activityPriceWithMarkup && (
                          <small style={{ color: 'red', textDecoration: 'line-through', fontSize: '12px' }}>
                            {formatIndianCurrency(activityPriceWithMarkup)}
                            {" "}
                          </small>
                        )}{' '} */}
                        {displayedRate !== null
                          ? formatIndianCurrency(displayedRate)
                          : "Rate not available, please update adults"}
                      </b>{" "}
                      <small>per Adult</small>
                    </h4>
                  </>
                ) : (
                  <p> Rate not available, please modify adults</p>
                )}

                {selectedAddOns && selectedAddOns.length > 0 && (
                  <>
                    <p className="mt-10">Addons Price</p>
                    <h4>
                      <b>
                        {/* {formatIndianCurrency(
                          selectedAddOns.reduce(
                            (total, addon) => total + addon.price,
                            0
                          )
                        )} */}
                        {commission && Object.keys(commission).length > 0  && (
                          <small style={{ color: 'red', textDecoration: 'line-through', fontSize: '12px' }}>
                            {formatIndianCurrency(totalAddonsCostWithoutCommission)}
                          </small>
                        )}
                        {formatIndianCurrency(totalAddonsCost)}
                      </b>
                    </h4>
                  </>
                )}

                <div>
                  {displayedRate && displayedRate !== null && (
                    <button
                      style={{ marginTop: "10px", borderRadius: "15px" }}
                      className="primary_btn"
                      onClick={navigateToCheckOutActivity}
                    >
                      Book Now
                    </button>
                  )}
                  <button
                    className="secondary_btn mt-10 border-radius"
                    onClick={(e) => handleshowRatePopup(e)}
                  >
                    Send Enquiry
                  </button>
                </div>

                {/* {singleActivityData?.additionalAddons &&
                  singleActivityData?.additionalAddons.length > 0 && (
                    <div className="mt-20">
                      <h5 className="mb-10">Addons</h5>
                      {singleActivityData.additionalAddons.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="flex justify_content_between w-100 mt-10 align_items_start"
                          >
                            <div
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              <div style={{ cursor: "pointer", width: "100%" }}>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {item.detail}{" "}
                                  {formatIndianCurrency(item.price)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex justify_content_end"
                              style={{
                                width: "60%",
                              }}
                            >
                              <button
                                style={{
                                  fontSize: "10px",
                                }}
                                onClick={() => handleSelectedAddOns(item)}
                              >
                                {selectedAddOns.some(
                                  (addon) => addon.id === item.id
                                ) ? (
                                  <div style={{ color: "red" }}>
                                    <svg
                                      fill="red"
                                      width="20px"
                                      height="14px"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <defs></defs>
                                      <path
                                        id="cancel"
                                        className="cls-1"
                                        d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                        transform="translate(-924 -96)"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      background: "#016937",
                                      color: "#fff",
                                      borderRadius: "4px",
                                      padding: "5px 3px 5px 7px",
                                      display: "flex",
                                    }}
                                  >
                                    {" "}
                                    Add
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="16px"
                                      viewBox="0 -960 960 960"
                                      width="16px"
                                      fill="#ffffff"
                                    >
                                      <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                    </svg>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )} */}

                {singleActivityData?.additionalAddons &&
                  singleActivityData?.additionalAddons.length > 0 && (
                    <div className="mt-20">
                      <h5 className="mb-10">Addons</h5>
                      {singleActivityData.additionalAddons.map((item, index) => {
                        let finalAddonPrice = item?.price;
                        let addonPriceWithMarkup = item?.price

                        if (markupData) {
                          const markup = markupData;
                          if (markup.markupType === "percentage") {
                            finalAddonPrice += (item.price * parseFloat(markup.amount)) / 100;
                          } else if (markup.markupType === "fixed") {
                            finalAddonPrice += parseFloat(markup.amount);
                          }
                        }
                        addonPriceWithMarkup = finalAddonPrice;

                        if (commission?.commissionAmount && commission?.commissionType) {
                          if (commission?.commissionType === "percentage") {
                            finalAddonPrice -=
                              (Number(finalAddonPrice) * Number(commission?.commissionAmount)) / 100;
                          } else if (commission?.commissionType === "fixed") {
                            finalAddonPrice = Number(commission?.commissionAmount);
                          }
                        }

                        return (
                          <div
                            key={index}
                            className="flex justify_content_between w-100 mt-10 align_items_start"
                          >
                            <div style={{ textTransform: "capitalize" }}>
                              <div style={{ cursor: "pointer", width: "100%" }}>
                                <div style={{ fontSize: "15px", fontWeight: 500 }}>
                                  {item.detail}{' '}
                                  {commission?.commissionAmount && commission?.commissionType && (
                                    <span style={{ fontSize: "12px", color: 'red', textDecoration: 'line-through' }}>
                                      {formatIndianCurrency(addonPriceWithMarkup)}
                                    </span>
                                  )}{' '}
                                  <span style={{ fontSize: "12px", color: '#222222' }}>
                                    {formatIndianCurrency(finalAddonPrice)} {item.perPerson ? `* ${bookingData?.adult}` : ''}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify_content_end" style={{ width: "60%" }}>
                              <button
                                style={{ fontSize: "10px" }}
                                onClick={() => handleSelectedAddOns({ ...item, finalAddonPrice, addonPriceWithMarkup })}
                              >
                                {selectedAddOns.some((addon) => addon.id === item.id) ? (
                                  <div style={{ color: "red" }}>
                                    <svg
                                      fill="red"
                                      width="20px"
                                      height="14px"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <defs></defs>
                                      <path
                                        id="cancel"
                                        className="cls-1"
                                        d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                        transform="translate(-924 -96)"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      background: "#016937",
                                      color: "#fff",
                                      borderRadius: "4px",
                                      padding: "5px 3px 5px 7px",
                                      display: "flex",
                                    }}
                                  >
                                    {" "}
                                    Add
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="16px"
                                      viewBox="0 -960 960 960"
                                      width="16px"
                                      fill="#ffffff"
                                    >
                                      <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                    </svg>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
              <OurQuery />
              <ChooseUs />
              {/* <SendItinerary /> */}
            </aside>
          </main>
        </div>

        {showRatePopup && (
          <NewSendEnquiryPopup
            package_id={singleActivityData?.id}
            setshowRatePopup={setshowRatePopup}
            typeDetail="activity"
          />
        )}
      </section>
      <Footer />
    </div>
  );
}

export default SingleActivities;
