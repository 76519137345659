import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../CustomComponents/Footer/Footer";
import CustomPagination from "../../../CustomComponents/CustomPagination/CustomPagination";
import CustomListingCard from "../../../CustomComponents/CustomListingCard/CustomListingCard";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../CustomComponents/Header/Header";
import ListingBanner from "../../../CustomComponents/ListingBanner/ListingBanner";
import FilterHoliday from "../../../CustomComponents/FilterSection/FilterHoliday";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../../CustomComponents/PageMetaData/PageMetaData";
import PackageSearchForm from "../../../CustomComponents/PackageSearchForm/PackageSearchForm";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import { formatIndianCurrency, scrollToTop } from "../../../utils/utils";
import AgentHeader from "../AgentHeader/AgentHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import StarRating from "../../../CustomComponents/StarRating/StarRating";
import { Fancybox } from "@fancyapps/ui";
import imgNotFound from "../../../assets/images/single-image.png";

function AgentListingHotels() {
  const [currentPage, setCurrentPage] = useState(1);

  const [hotelData, setHotelData] = useState([]);
  const navigate = useNavigate();
  const itemsPerPage = 9;
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderFilter, setFilterLoader] = useState(false);
  const location = useLocation();
  const [sortedFilter, setSortedFilter] = useState({
    show: "Popular",
    value: "polpular",
  });

  const [packageData, setPackageData] = useState([]);
  // console.log(packageData, "packageData");
  // Calculate start and end index of items to display
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    packageData && packageData && packageData.length > 0
      ? packageData.slice(startIndex, endIndex)
      : [];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      guest: params.get("guest"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchHotelsData(data.location);
    // fetchPackageData(data);

  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const fetchPackageData = async (data, filters) => {
    // console.log(data, "fetchPackageData");

    setFilterLoader(true);
    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    formdata.append("location", data.location);
    formdata.append("guest", data.guest ? data.guest : 1);
    formdata.append("children", data.children ? data.children : 0);
    formdata.append("limit", 8);
    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    // ?destination=Manali,Dharamshala,Mandi&duration=3-4"
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch?destination=${filters?.destinations ? filters?.destinations : ""
        }&price_range=${filters?.budget ? filters?.budget : ""}&duration=${filters?.duration ? filters?.duration : ""
        }&noOfNight=${filters?.noOfNights ? filters?.noOfNights : ""}&theme=${filters?.themes ? filters?.themes : ""
        }`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);
      setPackageData(result.data);
      scrollToTop();
    } catch (error) {
      setPackageData([]);
      scrollToTop();
      setLoader(false);
      console.error(error);
    } finally {
      scrollToTop();
      setFilterLoader(false);
    }
  };

  const sendDataToParent = (data) => {
    // console.log(data);
    fetchPackageData(bookingData, data);
    // console.log(data, "sendDataToParent");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    console.log(sortedFilter?.value);
    let dummyData = [...packageData];
    if (sortedFilter?.value === "l2hByp") {
      dummyData.sort((a, b) => a.startingPrice - b.startingPrice);
      setPackageData(dummyData);
    }

    if (sortedFilter?.value === "h2lByp") {
      dummyData.sort((a, b) => b.startingPrice - a.startingPrice);
      setPackageData(dummyData);
    }
    // duration
    if (sortedFilter?.value === "l2hByd") {
      dummyData.sort((a, b) => a.totalNights - b.totalNights);
      setPackageData(dummyData);
    }
    if (sortedFilter?.value === "h2lByd") {
      dummyData.sort((a, b) => b.totalNights - a.totalNights);
      setPackageData(dummyData);
    }
  }, [sortedFilter?.value]);

  const [isVisible, setIsVisible] = useState(false);

  const handleHover = (status) => {
    if (window.innerWidth > 768) {
      // For larger screens (like desktops)
      setIsVisible(status);
    }
  };

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      // For mobile screens
      setIsVisible(false); // Close the div when clicked on mobile
    } else {
      setIsVisible(!isVisible); // Toggle for desktop
    }
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleViewRoomsClickHandler = async (item) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    try {
      await navigate(
        `/single-hotel?hotelId=${item?.hotel_id}&location=${bookingData.location}&room=1&adult=1&children=0&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`,
        { state: { item: item } }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const fetchHotelsData = async (destination) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    formdata.append("location", destination);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability?star_rating=&price_range=`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);

      // const filteredData = result?.data?.filter(
      //   (item) => item.canBookable === 1
      // );
      console.log("result", result);

      setHotelData(result.data.properties);
    } catch (error) {
      setPackageData([]);
      setLoader(false);
      console.error(error);
    }
  };

  console.log("hotelData", hotelData);


  const postBookingData = async (destination) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    // const checkInDate = `${data.checkinYear}-${String(
    //   data.checkinMonth
    // ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    // const checkOutDate = `${data.checkoutYear}-${String(
    //   data.checkoutMonth
    // ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("location", bookingData.location);
    formdata.append("limit", 8);

    // formdata.append("checkIn", checkInDate);
    // formdata.append("checkOut", checkOutDate);
    // formdata.append("adults", data.adult);
    // formdata.append("child", data.children);
    // formdata.append("location", data.location);
    // formdata.append("rooms", data.room);
    // formdata.append("PropertyType", data?.radio ? data?.radio : "hotel");

    // Append childAge values dynamically
    // if (data.children > 0) {
    //   for (let i = 1; i <= data.children; i++) {
    //     formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
    //   }
    // }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
      fetchPackageData(destination);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // showErrorToast(error.message);
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   postBookingData();
  // }, []);



  return (
    <div id="themePackages">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <AgentHeader showSelectProperty={true} vendorProperty={false} />
      <div className="sidebar_main_div">
        <AgentSideBar />
        <div className="agent-info-container">
          <div className="pb_20">
            <div>
              <div className="flex align_items_center justify_content_between flex_wrap">
                <h4 className="text-left">
                  Top {bookingData?.location} Tour Hotels
                </h4>

                {window.innerWidth > 768 ? (
                  <div className="sorted_div">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_5040_6776"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="14"
                        height="14"
                      >
                        <rect width="14" height="14" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_5040_6776)">
                        <path
                          d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>
                    Sort by:{" "}
                    <span className="cursor_pointer">
                      <b>{sortedFilter?.show}</b>
                    </span>
                    <div className="sorted_selected" ref={dropdownRef}>
                      <div
                        onClick={() => {
                          setSortedFilter({
                            value: "l2hByp",
                            show: "Price - Low to High",
                          });
                          setCurrentPage(1);
                          handleClick();
                        }}
                        className="cursor_pointer p-5"
                      >
                        <span>Price - Low to High</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "h2lByp",
                            show: "Price - High to Low",
                          });
                          setCurrentPage(1);
                          handleClick();
                        }}
                      >
                        <span>Price - High to Low</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "l2hByd",
                            show: "Duration - Low to High",
                          });
                          setCurrentPage(1);
                          handleClick();
                        }}
                      >
                        <span>Duration - Low to High</span>
                      </div>
                      <div
                        className="cursor_pointer p-5"
                        onClick={() => {
                          setSortedFilter({
                            value: "h2lByd",
                            show: "Duration - High to Low",
                          });
                          setCurrentPage(1);
                          handleClick();
                        }}
                      >
                        <span>Duration - High to Low</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sorted_div">
                    <div onClick={() => setIsDropdownVisible((prev) => !prev)}>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_5040_6776"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="14"
                          height="14"
                        >
                          <rect width="14" height="14" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_5040_6776)">
                          <path
                            d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                            fill="#1C1B1F"
                          />
                        </g>
                      </svg>
                      Sort by:{" "}
                      <span className="cursor_pointer">
                        <b>{sortedFilter?.show}</b>
                      </span>
                    </div>
                    {isDropdownVisible && (
                      <div className="sorted_selected" ref={dropdownRef}>
                        <div
                          onClick={() => {
                            setSortedFilter({
                              value: "l2hByp",
                              show: "Price - Low to High",
                            });
                            setCurrentPage(1);
                            setIsDropdownVisible(false);
                            handleClick();
                          }}
                          className="cursor_pointer p-5"
                        >
                          <span>Price - Low to High</span>
                        </div>
                        <div
                          className="cursor_pointer p-5"
                          onClick={() => {
                            setSortedFilter({
                              value: "h2lByp",
                              show: "Price - High to Low",
                            });
                            setCurrentPage(1);
                            setIsDropdownVisible(false);
                            handleClick();
                          }}
                        >
                          <span>Price - High to Low</span>
                        </div>
                        <div
                          className="cursor_pointer p-5"
                          onClick={() => {
                            setSortedFilter({
                              value: "l2hByd",
                              show: "Duration - Low to High",
                            });
                            setCurrentPage(1);
                            setIsDropdownVisible(false);
                            handleClick();
                          }}
                        >
                          <span>Duration - Low to High</span>
                        </div>
                        <div
                          className="cursor_pointer p-5"
                          onClick={() => {
                            setSortedFilter({
                              value: "h2lByd",
                              show: "Duration - High to Low",
                            });
                            setCurrentPage(1);
                            setIsDropdownVisible(false);
                            handleClick();
                          }}
                        >
                          <span>Duration - High to Low</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="cards-flex">
                {loader || loaderFilter ? (
                  Array.from({ length: 9 }).map((items, index) => (
                    <div
                      key={index}
                      className="card-listing"
                      style={{
                        background: "none",
                        borderRadius: "8px",
                        marginBottom: "0px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        padding: "0px",
                      }}
                    >
                      <div>
                        <Skeleton
                          style={{ borderRadius: "8px 8px 0px 0px" }}
                          height={150}
                        />
                      </div>
                      <div
                        className="card_content"
                        style={{ marginTop: "0px" }}
                      >
                        <h6>
                          <Skeleton width="60%" />
                        </h6>
                        <h6>
                          <Skeleton width="40%" />
                        </h6>
                        <h6>
                          <Skeleton width="20%" />
                        </h6>
                        <h6>
                          <Skeleton width="24%" />
                        </h6>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="destinationsCard mt-10">
                      {hotelData && hotelData?.length > 0 ? (
                        hotelData?.slice(0, 8).map((items) => {
                          return (
                            <div className="card">
                              <div className="hotelCardImg">
                                <img
                                  src={
                                    items.hotel_image[0]
                                      ? items.hotel_image[0]
                                      : imgNotFound
                                  }
                                  alt="hotel-img"
                                />

                                {items.hotel_image.length > 1 && (
                                  <div>
                                    <button
                                      className="view_images_btn"
                                      onClick={() => {
                                        Fancybox.show(
                                          items.hotel_image.map((src) => ({
                                            src,
                                            type: "image",
                                          }))
                                        );
                                      }}
                                    >
                                      <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 16 15"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 11.625C8.9375 11.625 9.73438 11.2969 10.3906 10.6406C11.0469 9.98438 11.375 9.1875 11.375 8.25C11.375 7.3125 11.0469 6.51562 10.3906 5.85938C9.73438 5.20312 8.9375 4.875 8 4.875C7.0625 4.875 6.26562 5.20312 5.60938 5.85938C4.95312 6.51562 4.625 7.3125 4.625 8.25C4.625 9.1875 4.95312 9.98438 5.60938 10.6406C6.26562 11.2969 7.0625 11.625 8 11.625ZM8 10.125C7.475 10.125 7.03125 9.94375 6.66875 9.58125C6.30625 9.21875 6.125 8.775 6.125 8.25C6.125 7.725 6.30625 7.28125 6.66875 6.91875C7.03125 6.55625 7.475 6.375 8 6.375C8.525 6.375 8.96875 6.55625 9.33125 6.91875C9.69375 7.28125 9.875 7.725 9.875 8.25C9.875 8.775 9.69375 9.21875 9.33125 9.58125C8.96875 9.94375 8.525 10.125 8 10.125ZM2 14.25C1.5875 14.25 1.23438 14.1031 0.940625 13.8094C0.646875 13.5156 0.5 13.1625 0.5 12.75V3.75C0.5 3.3375 0.646875 2.98438 0.940625 2.69063C1.23438 2.39688 1.5875 2.25 2 2.25H4.3625L5.75 0.75H10.25L11.6375 2.25H14C14.4125 2.25 14.7656 2.39688 15.0594 2.69063C15.3531 2.98438 15.5 3.3375 15.5 3.75V12.75C15.5 13.1625 15.3531 13.5156 15.0594 13.8094C14.7656 14.1031 14.4125 14.25 14 14.25H2ZM2 12.75H14V3.75H10.9625L9.59375 2.25H6.40625L5.0375 3.75H2V12.75Z"
                                          fill="white"
                                        />
                                      </svg>
                                      View all images
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="card-content p_10">
                                <h6>
                                  <b>{items.hotel_name}</b>
                                </h6>
                                <div className="">
                                  {
                                    <StarRating
                                      value={
                                        items.hotel_rating
                                          ? items.hotel_rating
                                          : 0
                                      }
                                    />
                                  }
                                </div>
                                <small className="location_text mb_5">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                      fill="#888888"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                      fill="#888888"
                                    />
                                  </svg>
                                  {items.hotel_address}
                                </small>

                                <small>{items.distance}</small>
                                <div className=" flex justify_content_between w-full align_items_end">
                                  <div>
                                    <p>Starting From</p>
                                    <h5>
                                      {formatIndianCurrency(
                                        items.starting_price
                                      )}{" "}
                                      <small> + GST</small>
                                    </h5>
                                  </div>
                                  <div className="view_room_btn">
                                    <button
                                      onClick={() =>
                                        handleViewRoomsClickHandler(items)
                                      }
                                      className="primary_btn"
                                      type="submit"
                                    >
                                      View Rooms
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="communication_pagination">
                <CustomPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={packageData?.length}
                  onPageChange={handlePageChange}
                  clearSelection={clearSelection}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentListingHotels;
