import React, { useEffect, useRef, useState } from "react";
import {
  DateFormatMoment,
  calculateToDatePlus,
  formatIndianCurrency,
  textDateFormatter,
  textDateFormatterNoAddInIteration,
} from "../../utils/utils";
import moment from "moment";

function TourBookings({
  onClose,
  setIsSliderVisible,
  singleBookingData: singleSliderBookingData,
}) {
  const bookingDetailsPopupRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    setIsSliderVisible(true);
    document.body.style.overflow = "hidden";
    const handleClickOutside = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      setIsSliderVisible(false);
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsSliderVisible]);

  const handleScroll = () => {
    if (bookingDetailsPopupRef.current.scrollTop > 50) {
      headerRef.current.classList.add("is-fixed");
    } else {
      headerRef.current.classList.remove("is-fixed");
    }
  };

  useEffect(() => {
    const sliderContainer = bookingDetailsPopupRef.current;
    sliderContainer.addEventListener("scroll", handleScroll);

    return () => {
      sliderContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeTab, setActiveTab] = useState("package-information");

  const noOfRooms = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_of_rooms);
    },
    0
  );

  const noOfChild = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_child);
    },
    0
  );

  const noOfAdults = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_adult);
    },
    0
  );

  const extraAdult = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.extra_adult);
    },
    0
  );

  const handleClose = () => {
    onClose();
  };
  console.log(singleSliderBookingData);

  return (
    <>
      <div className="bg-backdrop">
        <div
          id="booking_details_slider_container_new"
          ref={bookingDetailsPopupRef}
        >
          <div className="popup" id="popup">
            <div
              className="d_flex booking_detail_popup_head fixed_header"
              ref={headerRef}
              style={{ flexDirection: "column" }}
            >
              <div className="flex justify_content_between">
                <div onClick={handleClose} className="close_popup">
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 12.5L13 0.5"
                      stroke="#AAAAAA"
                      strokeWidth="1.2"
                    />
                    <path
                      d="M13 12.5L1 0.5"
                      stroke="#AAAAAA"
                      strokeWidth="1.2"
                    />
                  </svg>
                </div>
                <div className="d_flex booking_id">
                  <p style={{ marginRight: "6px" }}>Booking No:</p>
                  <b>#{singleSliderBookingData?.bookingNo}</b>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  Package Name
                </h6>
                <p className="p_20">{singleSliderBookingData?.name}</p>
              </div>
              <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  OverView
                </h6>
                <div className="p_20">
                  {/* {singleSliderBookingData?.get_guest?.length > 0 && ( */}
                  <div className="flex flex_wrap justify_content_between sidebar_data">
                    <div>
                      <h6>Guest Name</h6>
                      {/* <p>
                          {singleSliderBookingData?.get_guest &&
                            singleSliderBookingData?.get_guest[0] &&
                            singleSliderBookingData?.get_guest[0].name &&
                            singleSliderBookingData?.get_guest[0].name}
                        </p> */}
                      <p>{singleSliderBookingData?.customerDetails?.name}</p>
                    </div>
                    <div>
                      <h6>Guest Email</h6>
                      <p>{singleSliderBookingData?.customerDetails?.email}</p>
                    </div>
                    <div>
                      <h6>Phone No.</h6>
                      <p>{singleSliderBookingData?.customerDetails?.phone}</p></div>
                  </div>
                  {/* )} */}
                  <div className="flex flex_wrap justify_content_between mt-10 sidebar_data">
                    <div>
                      <h6>Customized</h6>
                      <p>
                        {singleSliderBookingData?.is_customizable === "1"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                    <div>
                      <h6>No. of Adult</h6>
                      {singleSliderBookingData?.adults}
                    </div>
                    <div>
                      <h6>No. of Child</h6>
                      {singleSliderBookingData?.children}
                    </div>
                  </div>
                  <div className="mt-10 flex justify_content_between flex_wrap  sidebar_data">
                    <div>
                      {" "}
                      <h6>Tour start </h6>
                      <p>
                        {textDateFormatterNoAddInIteration(
                          singleSliderBookingData?.startDate
                        )}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Tour End </h6>
                      <p>
                        {textDateFormatterNoAddInIteration(
                          singleSliderBookingData?.endDate
                        )}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Tour Duration </h6>
                      <p>{singleSliderBookingData?.days}</p>
                    </div>
                  </div>{" "}
                  <div className="mt-10 flex flex_wrap  justify_content_between sidebar_data">
                    <div>
                      {" "}
                      <h6>Booking Source </h6>
                      <p style={{ textTransform: "capitalize" }}>
                        {singleSliderBookingData?.booking_source}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Booking Date </h6>
                      <p>
                        {moment(singleSliderBookingData?.created_at).format(
                          "MMM Do YYYY"
                        )}

                        {/* {textDateFormatterNoAddInIteration(
                          singleSliderBookingData?.created_at
                        )} */}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Booking Status </h6>
                      <p className="alert_text text-capitalize">
                        {singleSliderBookingData?.status}
                      </p>
                    </div>
                  </div>{" "}
                  <div className="mt-10 flex flex_wrap  justify_content_between sidebar_data">
                    <div>
                      {" "}
                      <h6>Paid Amount </h6>
                      <p style={{ textTransform: "capitalize" }}>
                        {formatIndianCurrency(
                          singleSliderBookingData?.paidAmount
                        )}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Payment Mode </h6>
                      <p style={{ textTransform: "capitalize" }}>
                        {singleSliderBookingData?.payment_mode}
                      </p>
                    </div>
                    <div>
                      {" "}
                      <h6>Payment Status </h6>
                      <p className="alert_text text-capitalize">
                        {singleSliderBookingData?.payment_status}
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </div>

              {singleSliderBookingData?.itineraryDetails.length > 0 && (
                <div>
                  <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                    Itinerary
                  </h6>
                  <table className="m_0">
                    <thead>
                      <tr>
                        <th className="">Itinerary Title</th>
                        <th className="">Hotel Details</th>
                        <th className="">End Point</th>
                      </tr>
                      {singleSliderBookingData?.itineraryDetails.map(
                        (item, index) => {
                          return (
                            <tr>
                              {" "}
                              <th className="font-3">
                                <div>
                                  <b>
                                    {moment(
                                      calculateToDatePlus(
                                        singleSliderBookingData?.startDate,
                                        Number(
                                          item.day
                                            ? item.day - 1
                                            : 0
                                        )
                                      )
                                    ).format("MMM Do YYYY")}

                                    {/* {textDateFormatterNoAddInIteration(
                                      calculateToDatePlus(
                                        singleSliderBookingData?.tour_start,
                                        Number(
                                          item.day_count
                                            ? item.day_count - 1
                                            : 0
                                        )
                                      )
                                    )} */}
                                  </b>
                                </div>
                                <div>{item?.additionalDetails?.heading}</div>
                              </th>
                              <th className="font-3">
                                <div className="text-capitalize">
                                  {item?.accommodation[0]?.name}
                                </div>

                                {item.roomType ? (
                                  <div className="text-capitalize">
                                    {item.roomType} ({item.mealPlan})
                                  </div>
                                ) : (
                                  <div>-</div>
                                )}
                              </th>
                              <th className="font-3">{item?.endDestination}</th>
                            </tr>
                          );
                        }
                      )}
                    </thead>
                  </table>
                </div>
              )}

              {singleSliderBookingData?.itineraryDetails.length > 0 &&
                singleSliderBookingData?.itineraryDetails.some(
                  (item) => item.activity.length > 0
                ) && (
                  <div>
                    <h6
                      className="bg text-white"
                      style={{ padding: "5px 20px" }}
                    >
                      Activity
                    </h6>
                    <table className="m_0">
                      <thead>
                        <tr>
                          <th className="">Activity Name</th>
                          <th className="">Adults</th>
                          <th className="">Activity Date</th>
                          <th className="">Price</th>
                        </tr>
                        {singleSliderBookingData?.itineraryDetails.map(
                          (item, indexUp) => {
                            return (
                              <>
                                {item.activity.map(
                                  (activity, index) => {
                                    return (
                                      <tr>
                                        <th className="font-3">
                                          {activity?.name}
                                        </th>

                                        <th className="font-3">
                                          {activity?.pax}
                                        </th>

                                        <th>

                                          {moment(
                                            calculateToDatePlus(
                                              singleSliderBookingData?.startDate,
                                              Number(
                                                item.day
                                                  ? item.day - 1
                                                  : 0
                                              )
                                            )
                                          ).format("MMM Do YYYY")}
                                        </th>


                                        <th className="font-3">
                                          {activity?.price}{" "}
                                        </th>
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        )}
                      </thead>
                    </table>
                  </div>
                )}

              <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  Price Breakup
                </h6>
                <div className="p_20 p_top_0">
                  <div className="flex justify_content_between flex_wrap">
                    <div className="mt-10">
                      <h6>Subtotal</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.total
                        )}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Discount Amt.</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.total_discount_amount
                        )}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6 className="">Tax Amount</h6>
                      <p className="">
                        {formatIndianCurrency(
                          singleSliderBookingData?.tax_details[0]?.amount
                        )}
                      </p>
                    </div>

                    <div className="mt-10">
                      <h6>Grand Total</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.grandTotal
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TourBookings;

