import React, { useEffect, useState } from "react";
import "./AllThemes.css";
import { Link } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
import imgNotFound from "../../assets/images/single-image.png";

function AllThemes() {
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");
  const [themes, setThemes] = useState([]);

  // const fetchThemesDetails = (token) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   fetch(`${process.env.REACT_APP_APP_URL}/api/tour-themes`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(data, 'themes');
  //       if (data.success) {
  //         setThemes(data?.themes);
  //         setLoader(false);
  //       } else {
  //         // console.log("Error fetching data");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching  details:", error);
  //     });
  // };

  const fetchThemesDetails = () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/themes/list`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setThemes(data?.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching  details:", error);
      });
  };

  useEffect(() => {
    fetchThemesDetails();
  }, []);

  return (
    <>
      <PageMetaData
        title={`All Themes - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
        canonicalUrl={"https://www.mountainmysteries.com/themes"}
      />
      <OgMetaTitles
        title={`All Themes - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/themes`}
      />

      <Header />
      {loader ? (
        <>
          <div className="container">
            <div>
              <div className="mt-20">
                <Skeleton width={300} height={30} />
              </div>
              <div className="destinationsCard mt-10 evenly-align">
                {Array.from({ length: 9 }).map((_, index) => (
                  <div
                    className="destination_link destination-image"
                    key={index}
                  >
                    <Skeleton height={280} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <section>
          <div className="container pb_30">
            <h3 className="text-left mt-20">All Themes</h3>
            <div className="NewFile_Content">
              {themes.map(
                (items, index) =>
                  items && (
                    <div className="NewFile_catgoryWrap" key={index}>
                      <div className="NewFile_imageBox">
                        <Link
                          to={`/themes/${items?.handle}`}
                          className="w-full h-full"
                        >
                          <img src={items.featuredImage ? items.featuredImage : imgNotFound} alt={themes.title} />
                        </Link>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
}

export default AllThemes;
