import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import Dashboard from "../../CustomComponents/Property/Dashboard/Dashboard";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import VendorDashboard from "../../CustomComponents/Property/VendorDashboard/VendorDashboard";

function PropertyOverview({ allPropertiesPage }) {
  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />
        <VendorDashboard />
      </div>
    </>
  );
}

export default PropertyOverview;
