import React from "react";
import { Link } from "react-router-dom";
import "../CustomListingCard/CustomListingCard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { formatIndianCurrency } from "../../utils/utils";
import BgBackdrop from "../BgBackDrop/BgBackDrop";
import { animateScroll as scroll } from "react-scroll";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";

function CustomListingCardActivities({
  imgSrc,
  id,
  title,
  packagehandle,
  duration,
  nightDuration,
  packageHub,
  price,
  bookingLink,
  detailsLink,
  packageHotels,
  canBookable,
  description,
  slug,
  setShowSendEnquiryPopup,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState({ fromCity: "", toCity: "" });
  const [departureDate, setdepartureDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState(new Date());
  const [hotelData, setHotelData] = useState([]);
  const [guest, setGuest] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const checkinDay = queryParams.get("checkinDay") || "";
  const checkinMonth = queryParams.get("checkinMonth") || "";
  const checkinYear = queryParams.get("checkinYear") || "";
  const checkoutDay = queryParams.get("checkoutDay") || "";
  const checkoutMonth = queryParams.get("checkoutMonth") || "";
  const checkoutYear = queryParams.get("checkoutYear") || "";

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      hotelID: params.get("hotelID"),
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("adult"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      checkoutDay: params.get("checkoutDay"),
      checkoutMonth: params.get("checkoutMonth"),
      checkoutYear: params.get("checkoutYear"),
      date: params.get("date"),
      year: params.get("year"),
      month: params.get("month"),
      radio: params.get("radio"),
      childAge: childAges,
    };

    const checkInDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
      // toCity: params.get("location"),
    });

    setGuest(data.guest ? data.guest : 2);
    setChildren(data?.children ? data?.children : 0);
    setRooms(data.room);
    setChildAge(childAges);
    setdepartureDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    // Default to current date, month, and year if not in params
    const currentDate = new Date();
    const data = {
      hotelID: params.get("hotelID"),
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("adult"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      checkoutDay: params.get("checkoutDay"),
      checkoutMonth: params.get("checkoutMonth"),
      checkoutYear: params.get("checkoutYear"),
      date: params.get("date") || currentDate.getDate(),
      month: params.get("month") || currentDate.getMonth() + 1, // Months are 0-indexed
      year: params.get("year") || currentDate.getFullYear(),
      radio: params.get("radio"),
      childAge: childAges,
    };

    // Construct check-in and check-out dates
    const checkInDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
      // toCity: params.get("location"),
    });

    setGuest(data.guest ? data.guest : 2);
    setChildren(data?.children ? data?.children : 0);
    setRooms(data.room);
    setChildAge(childAges);
    setdepartureDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);
  }, [location.search]);

  const handleSelectRoomClickHandler = async (item, hotelData, room_id) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    // console.log(children, 'jjj');

    // Calculate booking values before navigating
    try {
      // http://localhost:3000/package/complete-himachal?location=shimla&guest=2&packagehandle=complete-himachal
      await navigate(
        `/single-activity/${slug}?${id}?location=${
          data.fromCity ? data.fromCity : ""
        }&adult=${guest}&date=${bookingData.date}&month=${
          bookingData.month
        }&year=${bookingData.year}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showRatePopup, setshowRatePopup] = useState(false);
  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // console.log(showRatePopup);
  return (
    <>
      <div
        className=" card-listing"
        style={{
          background: "none",
          borderRadius: "8px",
          marginBottom: "0px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: "0px",
        }}
      >
        <img
          onClick={() => {
            handleSelectRoomClickHandler();
            scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
          }}
          src={imgSrc}
          alt={title}
          style={{ borderRadius: "8px 8px 0px 0px", cursor: "pointer" }}
        />
        <div className="card_content" style={{ marginTop: "0px" }}>
          <h6
            onClick={() => {
              handleSelectRoomClickHandler();
              scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
            }}
            className="cursor_pointer"
          >
            {title}
          </h6>
          <p className="m_0">{packageHub}</p>
          {/* {duration && ( */}
          <div className="flex justify_content_between align_items_center">
            <h4>{formatIndianCurrency(price)}</h4>
          </div>
          <small className="trucket-text">{description}</small>
          {/* )} */}

          <div className="buttons">
            {canBookable === 1 ? (
              // <Link onClick={() => scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })} to="/checkout-package" className="primary_btn">
              //   Book now
              // </Link>
              <div
                className="primary_btn cursor_pointer"
                onClick={() => {
                  handleSelectRoomClickHandler();
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  });
                }}
              >
                Book Now
              </div>
            ) : (
              <button
                onClick={() => setShowSendEnquiryPopup((prev) => !prev)}
                className="primary_btn"
              >
                Send Enquiry
              </button>

              // <div
              //   className="primary_btn cursor_pointer"
              //   onClick={() => {
              //     handleSelectRoomClickHandler();
              //     scroll.scrollToTop({
              //       duration: 500,
              //       smooth: "easeInOutQuad",
              //     });
              //   }}
              // >
              //   Book Now
              // </div>
            )}

            <div
              className="secondary_btn cursor_pointer"
              onClick={() => {
                handleSelectRoomClickHandler();
                scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
              }}
            >
              View Details
            </div>
          </div>
        </div>
      </div>

      {/* {showRatePopup && (
        <SendEnquiryPopup
          package_id={id}
          setshowRatePopup={setshowRatePopup}
          typeDetail="activity"

        />
      )} */}
    </>
  );
}

export default CustomListingCardActivities;
