import React, { useEffect, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "flatpickr/dist/themes/material_green.css";
import "./App.css";
import Loader from "./CustomComponents/Loader/Loader.jsx";
import Skeleton from "react-loading-skeleton";
import CheckOutPackage from "./Pages/CheckOutPackage/CheckOutPackage.jsx";
import SinglePackage from "./CustomComponents/SinglePackage/SinglePackage.jsx";
import NoSlug from "./CustomComponents/NoSlug/NoSlug.jsx";
import CheckOutHotelNew from "./Pages/CheckOutHotelNew/CheckOutHotelNew.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.jsx";
import ContactUs from "./Pages/ContactUs/ContactUs.jsx";
import PropertyDetails from "./Pages/Property/PropertyDetails/PropertyDetails.jsx";
import PkgItinerary from "./Pages/PkgItinerary/PkgItinerary.jsx";
import Destinations from "./Pages/Destinations/Destinations.jsx";
// import NewFile from "./Pages/NewFile/NewFile.jsx";
import CustomizeSinglePackage from "./CustomComponents/SinglePackage/CustomizeSinglePackage.jsx";
import LoginVendors from "./Pages/LoginVendors/LoginVendors.jsx";
import AddPropertyVendor from "./Pages/Property/AddPropertyVendor/AddPropertyVendor.jsx";
// import AllThemesPackages from "./Pages/AllThemesPackages/AllThemesPackages.jsx";
// import AllThemes from "./Pages/AllThemes/AllThemes.jsx";
import RatesBulkUpdate from "./Pages/RateBulkUpdate/RateBulkUpdate.jsx";
import EditProperty from "./CustomComponents/Property/EditProperty/EditProperty.jsx";
import PropertyOverview from "./Pages/PropertyOverview/PropertyOverview.jsx";
import AllRoomsPage from "./Pages/AllRoomsPage/AllRoomsPage.jsx";
import RatePlans from "./CustomComponents/Property/RatePlans/RatePlans.jsx";
import RatePlansPage from "./Pages/RatePlansPage/RatePlansPage.jsx";
import Inventory from "./Pages/Inventory/Inventory.jsx";
import Rates from "./Pages/Rates/Rates.jsx";
import Bookings from "./Pages/Bookings/Bookings.jsx";
import AddRoomVendor from "./Pages/Property/AddRoomVendor/AddRoomVendor.jsx";
import Promotion from "./Pages/Promotion/Promotion.jsx";
import Rules from "./Pages/Rules/Rules.jsx";
import EditVendorRoom from "./CustomComponents/Property/EditVendorRoom/EditVendorRoom.jsx";
import NewCustomizeSinglePackage from "./CustomComponents/SinglePackage/NewCustomizeSinglePackage.jsx";
import AgentLoginNew from "./Pages/Agent/AgentLoginNew/AgentLoginNew.jsx";
import VendorUpdate from "./Pages/VendorUpdate/VendorUpdate.jsx";
import AgentCreditLogs from "./Pages/Agent/AgentCreditLogs/AgentCreditLogs.jsx";
import AgentDashboard from "./Pages/Agent/AgentDashboard/AgentDashboard.jsx";
import AgentBooking from "./Pages/Agent/AgentBooking/AgentBooking.jsx";
import AgentPromotion from "./Pages/Agent/AgentPromotion/AgentPromotion.jsx";
import AgentProfile from "./Pages/Agent/AgentProfile/AgentProfile.jsx";
import CustomerDashboard from "./Pages/Customer/CustomerDashboard/CustomerDashbaord.jsx";
import CustomerBooking from "./Pages/Customer/CustomerBooking/CustomerBooking.jsx";
import AgentAdvanceLogs from "./Pages/Agent/AgentAdvanceLogs/AgentAdvanceLogs.jsx";
import AgentPromotionAdd from "./Pages/Agent/AgentPromotionAdd/AgentPromotionAdd.jsx";
import AgentPromotionAddNext from "./Pages/Agent/AgentPromotionAdd/AgentPromotionAddNext.jsx";
import VendorPromotion from "./Pages/Agent/VendorPromotion/VendorPromotion.jsx";
import VendorPromotionAdd from "./Pages/Agent/VendorPromotionAdd/VendorPromotionAdd.jsx";
import VendorPromotionAddNext from "./Pages/Agent/VendorPromotionAdd/VendorPromotionAddNext.jsx";
import ThankYouPage from "./Pages/ThankYouPage/ThankYouPage.jsx";
import CancelPage from "./Pages/ThankYouPage/CancelPage.jsx";
import ThankYouPagePackage from "./Pages/ThankYouPage/ThankYouPagePackage.jsx";
import AgentPackages from "./Pages/Agent/AgentPackages/AgentPackages.jsx";
import FeedBackForm from "./Pages/FeedBackForm/FeedBacKForm.jsx";
import WhatsappBot from "./CustomComponents/WhatsappBot/WhatsappBot.jsx";
import FeedBacKFormThankYou from "./Pages/FeedBackForm/FeedBacKFormThankYou.jsx";
import AllActivities from "./Pages/AllActivities/AllActivities.jsx";
import AgentListingPackages from "./Pages/Agent/AgentListingPackages/AgentListingPackages.jsx";
import AgentListingHotels from "./Pages/Agent/AgentListingHotels/AgentListingHotels.jsx";
import Accommodation from "./Pages/Accommodation/Accommodation.jsx";
import Activities from "./Pages/Activities/Activities.jsx";
import Home from "./Pages/Home/Home.jsx";
import AllHotelsData from "./Pages/AllHotelsData/AllHotelsData.jsx";
import SingleDestinationsData from "./Pages/SingleDestinationsData/SingleDestinationsData.jsx";
import AllPackagesData from "./Pages/AllPackagesData/AllPackagesData.jsx";
import ListingHolidays from "./Pages/ListingHolidays/ListingHolidays.jsx";
import ListingHotels from "./Pages/ListingHotels/ListingHotels.jsx";
import ListingActivities from "./Pages/ListingActivities/ListingActivities.jsx";
import SingleHoliday from "./Pages/SingleHoliday/SingleHoliday.jsx";
import SingleHotel from "./Pages/SingleHotel/SingleHotel.jsx";
import SingleActivities from "./Pages/SingleActivities/SingleActivities.jsx";
import CheckOutActivities from "./Pages/CheckOutActivities/CheckOutActivities.jsx";
import CheckOutHoliday from "./Pages/CheckOutHoliday/CheckOutHoliday.jsx";
import CustomerProfile from "./Pages/Customer/CustomerProfile/CustomerProfile.jsx";
import AgentAccount from "./Pages/Agent/AgentAccount/AgentAccount.jsx";
import CancellationPolicy from "./Pages/CancellationPolicy/CancellationPolicy.jsx";
import TermConditions from "./Pages/TermConditions/TermConditions.jsx";
import Disclaimer from "./Pages/Disclaimer/Disclaimer.jsx";
import HealthSafety from "./Pages/HealthSafety/HealthSafety.jsx";
import AboutUs from "./Pages/AboutUs/AboutUs.jsx";
import AllThemes from "./Pages/AllThemes/AllThemes.jsx";
import AllThemesPackages from "./Pages/AllThemesPackages/AllThemesPackages.jsx";
import { getDecryptedData } from "./utils/crypto.js";
import EmailSignature from "./CustomComponents/EmailSIg/EmailSignature";
import MMSignature from "./CustomComponents/EmailSIg/Signature";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import ManageRatesPlansPage from "./Pages/ManageRatesPlansPage/ManageRatesPlansPage";
import NewRules from "./Pages/Rules/NewRules";
import VendorAccount from "./Pages/Agent/AgentAccount/VendorAccount";
import ActivityThankYouPage from "./Pages/ThankYouPage/ActivityThankyouPage";
import HotelThankYouPage from "./Pages/ThankYouPage/HotelThankyouPage";
import CancelPagePackage from "./Pages/ThankYouPage/CancelPagePackage";
import ActivityCancelPage from "./Pages/ThankYouPage/ActivityCancelPage";
import HotelCancelPage from "./Pages/ThankYouPage/HotelCancelPage";
import Transport from "./Pages/Transport/Transport";
import CheckOutTransport from "./Pages/Transport/CheckoutTransport";
import ListingTransportItinerary from "./Pages/Transport/ListingTransportItinerary";
import ListingTransportCabs from "./Pages/Transport/ListingTransportCabs";
import SingleTransport from "./Pages/Transport/SingleTransport";

// Home Pages
// const Home = React.lazy(() => import("./Pages/Home/Home.jsx"));
// const Accommodation = React.lazy(() =>
//   import("./Pages/Accommodation/Accommodation.jsx")
// );
// const Activities = React.lazy(() =>
//   import("./Pages/Activities/Activities.jsx")
// );

//all hotels data
// const AllHotelsData = React.lazy(() =>
//   import("./Pages/AllHotelsData/AllHotelsData.jsx")
// );
//all packages data
// const AllPackagesData = React.lazy(() =>
//   import("./Pages/AllPackagesData/AllPackagesData.jsx")
// );
// const SingleDestinationsData = React.lazy(() =>
//   import("./Pages/SingleDestinationsData/SingleDestinationsData.jsx")
// );

// Listing Pages
// const ListingHolidays = React.lazy(() =>
//   import("./Pages/ListingHolidays/ListingHolidays.jsx")
// );
// const ListingHotels = React.lazy(() =>
//   import("./Pages/ListingHotels/ListingHotels.jsx")
// );
// const ListingActivities = React.lazy(() =>
//   import("./Pages/ListingActivities/ListingActivities.jsx")
// );

// Single Pages
// const SingleHoliday = React.lazy(() =>
//   import("./Pages/SingleHoliday/SingleHoliday.jsx")
// );
// const SingleHotel = React.lazy(() =>
//   import("./Pages/SingleHotel/SingleHotel.jsx")
// );
// const SingleActivities = React.lazy(() =>
//   import("./Pages/SingleActivities/SingleActivities.jsx")
// );

// Checkout Pages
// const CheckOutHotelNew = React.lazy(() =>
//   import("./Pages/CheckOutHotelNew/CheckOutHotelNew.jsx")
// );

// const CheckOutActivities = React.lazy(() =>
//   import("./Pages/CheckOutActivities/CheckOutActivities.jsx")
// );
// const CheckOutHoliday = React.lazy(() =>
//   import("./Pages/CheckOutHoliday/CheckOutHoliday.jsx")
// );

// Login
// const Auth = React.lazy(() => import("./Pages/Auth/Auth.jsx"));
// const CustomerProfile = React.lazy(() =>
//   import("./Pages/Customer/CustomerProfile/CustomerProfile.jsx")
// );
// const AgentAccount = React.lazy(() =>
//   import("./Pages/Agent/AgentAccount/AgentAccount.jsx")
// );

// Links
// const AboutUs = React.lazy(() => import("./Pages/AboutUs/AboutUs.jsx"));
// const CancellationPolicy = React.lazy(() =>
//   import("./Pages/CancellationPolicy/CancellationPolicy.jsx")
// );
// const TermConditions = React.lazy(() =>
//   import("./Pages/TermConditions/TermConditions.jsx")
// );
// const Disclaimer = React.lazy(() =>
//   import("./Pages/Disclaimer/Disclaimer.jsx")
// );
// const HealthSafety = React.lazy(() =>
//   import("./Pages/HealthSafety/HealthSafety.jsx")
// );
// const AllThemes = React.lazy(() => import("./Pages/AllThemes/AllThemes.jsx"));
// const AllThemesPackages = React.lazy(() =>
//   import("./Pages/AllThemesPackages/AllThemesPackages.jsx")
// );

function App() {
  // const ProtectedRoute = ({ element }) => {
  //   const authenticated = localStorage.getItem("authenticated");
  //   const customerData = localStorage.getItem("customerData");
  //   const userToken = localStorage.getItem("userToken");
  //   return authenticated && userToken && customerData !== "undefined" ? (
  //     element
  //   ) : (
  //     <Navigate to="/login" replace />
  //   );
  // };
  const isVerifiedToken = localStorage.getItem("isVerified");
  const token = getDecryptedData("userToken");
  const userToken = getDecryptedData("userToken");
  const userType = localStorage.getItem("userType");

  // ProtectedRoute component to check authentication and verification

  const ProtectedRoute = ({ element }) => {
    const isVerified = localStorage.getItem("isVerified");
    if (userToken && isVerified === "verified") {
      return element;
    } else {
      // Redirect to login if not authenticated or verified
      // <Navigate to="/agent-account" replace />;
      return element;
    }
  };

  const ProtectedRouteVendor = ({ element }) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails?.accountId) {
      return element;
    } else {
      // Redirect to login if not authenticated or verified
      return <Navigate to="/login/vendor" replace />;
      // return element;
    }
  };

  // useEffect(() => {
  //   if (isVerifiedToken === "disable") {
  //     <Navigate to="/agent-account" replace />
  //     console.log(isVerifiedToken, "checkkaro");
  //   }
  // }, [isVerifiedToken])

  const [agentDetails, setAgentData] = useState();
  const [customerDataa, setCustomerData] = useState(null);

  const fetchAgentDetails = (token) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAgentData(data.data);
        localStorage.setItem(
          "AccountStatus",
          JSON.stringify(data.data.accountStatus)
        );

        if (data.success) {
          // if (data.data.agent.AccountStatus === "disable") {
          //     navigate("/agent-account");
          // } else {
          //     navigate("/");
          // }
          localStorage.setItem("userDetails", JSON.stringify(data.data));
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  const fetchCustomerDetails = (token) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCustomerData(data.data);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(data.data)
          );
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  useEffect(() => {
    if (token && userType === "agent") {
      fetchAgentDetails(token);
    } else if (userType === "customer") {
      // window.location.reload()
      fetchCustomerDetails(token);
    }
  }, [token]);

  return (
    <div className="App">
      {/* <Skeleton count={10} baseColor="#313131" highlightColor="#525252"> */}

      <Router>
        <Routes>
          {/* HomePages */}
          <Route
            path="/login/vendor"
            element={
              <Suspense>
                <LoginVendors />
              </Suspense>
            }
          />{" "}
          <Route
            path="/"
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />{" "}
          <Route
            path="/partner/inventory/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <Inventory />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/feedback-form"
            element={
              <Suspense>
                <FeedBackForm />
              </Suspense>
            }
          />
          <Route
            path="/feedback-form/thankyou"
            element={
              <Suspense>
                <FeedBacKFormThankYou />
              </Suspense>
            }
          />
          <Route
            path="/vendor/update/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <VendorUpdate />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/overview/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <PropertyOverview />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/all-rooms/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <AllRoomsPage />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/partner/all-rooms/rate-plans/:id/:roomId"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <ManageRatesPlansPage />
                  </Suspense>
                }
              />
            }
          />

          <Route
            path="/partner/promotion/:id?"
            element={
              <Suspense>
                <VendorPromotion />
              </Suspense>
            }
          />
          <Route
            path="/partner/rules/:id?"
            element={
              <Suspense>
                {/* <Rules /> */}
                <NewRules />
              </Suspense>
            }
          />
          <Route
            path="/partner/all-room-rates/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <RatePlansPage />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/rate/bulk-update/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <RatesBulkUpdate />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/rates/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <Rates />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/bookings/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <Bookings />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/packages/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <Bookings />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/accommodation"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <Accommodation />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/transport"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <Transport />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/transport/listing?"
            element={
              <Suspense>
                <ListingTransportItinerary />
              </Suspense>
            }
          />
           <Route
            path="/transport/:transportHandle"
            element={
              <Suspense>
                <SingleTransport />
              </Suspense>
            }
          />
          <Route
            path="/transport/itenaries/cabs?"
            element={
              <Suspense>
                <ListingTransportCabs />
              </Suspense>
            }
          />
          <Route
            path="/checkout-transport"
            element={
              <Suspense>
                <CheckOutTransport />
              </Suspense>
            }
          />
          <Route
            path="/all-activities"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <AllActivities />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/all-hotels"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <AllHotelsData />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/all-packages"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <AllPackagesData />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/destination/:destination"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <SingleDestinationsData />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/single-destination"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <SingleDestinationsData />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/all-destinations"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <Destinations />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/activities"
            element={
              <ProtectedRoute
                element={
                  <Suspense>
                    <Activities />
                  </Suspense>
                }
              />
            }
          />
          {/* ListingPages */}
          <Route
            path="/listing-holidays"
            element={
              <Suspense>
                <ListingHolidays />
              </Suspense>
            }
          />

          <Route
            path="/listing-hotels"
            element={
              <Suspense>
                <ListingHotels />
              </Suspense>
            }
          />
          <Route
            path="/listing-activities"
            element={
              <Suspense>
                <ListingActivities />
              </Suspense>
            }
          />
          {/* SinglePages */}
          <Route
            path="/single-holiday"
            element={
              <Suspense>
                <SingleHoliday />
              </Suspense>
            }
          />
          <Route
            path="/single-hotel/:id?"
            element={
              <Suspense>
                <SingleHotel />
              </Suspense>
            }
          />
          <Route
            path="/single-activity/:slug"
            element={
              <Suspense>
                <SingleActivities />
              </Suspense>
            }
          />
          <Route
            path="/customer-dashboard"
            element={
              <Suspense>
                <CustomerDashboard />
              </Suspense>
            }
          />
          <Route
            path="/customer-booking"
            element={
              <Suspense>
                <CustomerBooking />
              </Suspense>
            }
          />
          <Route
            path="/agent/credit-logs"
            element={
              <Suspense>
                <AgentCreditLogs />
              </Suspense>
            }
          />
          {/* CheckoutPages */}
          <Route path="/checkout-hotel" element={<CheckOutHotelNew />} />
          <Route
            path="/checkout-package/:packageHandle?"
            element={
              <Suspense>
                <CheckOutPackage />
              </Suspense>
            }
          />
          <Route
            path="/package/:packageHandle"
            element={
              <Suspense>
                <SinglePackage />
              </Suspense>
            }
          />
          <Route
            path="/customize-package/:packageHandle?"
            element={
              <Suspense>
                <NewCustomizeSinglePackage />
                {/* <CustomizeSinglePackage /> */}
              </Suspense>
            }
          />
          <Route
            path="/checkout-activities/:activityHandle"
            element={
              <Suspense>
                <CheckOutActivities />
              </Suspense>
            }
          />
          <Route
            path="/checkout-holiday"
            element={
              <Suspense>
                <CheckOutHoliday />
              </Suspense>
            }
          />
          {/* contact-us */}
          <Route
            path="/contact-us"
            element={
              <Suspense>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path="/customer-profile"
            element={
              <Suspense>
                <CustomerProfile />
              </Suspense>
            }
          />
          <Route
            path="/agent-account/:id?"
            element={
              <Suspense>
                <AgentAccount />
              </Suspense>
            }
          />
          <Route
            path="/vendor-account/:id"
            element={
              <Suspense>
                <VendorAccount />
              </Suspense>
            }
          />
          <Route
            path="/agent-profile/:id?"
            element={
              <Suspense>
                <AgentProfile />
              </Suspense>
            }
          />
          <Route
            path="/agent-booking/:id?"
            element={
              <Suspense>
                <AgentBooking />
              </Suspense>
            }
          />
          <Route
            path="/agent-packages/:id?"
            element={
              <Suspense>
                <AgentPackages />
              </Suspense>
            }
          />
          <Route
            path="/agent-promotion"
            element={
              <Suspense>
                <AgentPromotion />
              </Suspense>
            }
          />
          <Route
            path="/agent-credit-logs/:id?"
            element={
              <Suspense>
                <AgentCreditLogs />
              </Suspense>
            }
          />
          <Route
            path="/agent-advance-logs"
            element={
              <Suspense>
                <AgentAdvanceLogs />
              </Suspense>
            }
          />
          {/* Links */}
          <Route
            path="*"
            element={
              <Suspense>
                <NoSlug />
              </Suspense>
            }
          />
          <Route
            path="/about"
            element={
              <Suspense>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/cancellation-policy"
            element={
              <Suspense>
                <CancellationPolicy />
              </Suspense>
            }
          />
          <Route
            path="/term-conditions"
            element={
              <Suspense>
                <TermConditions />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/disclaimer"
            element={
              <Suspense>
                <Disclaimer />
              </Suspense>
            }
          />
          <Route
            path="/health-safety"
            element={
              <Suspense>
                <HealthSafety />
              </Suspense>
            }
          />
          <Route
            path="/agent-dashboard/:id?"
            element={
              <Suspense>
                <AgentDashboard />
              </Suspense>
            }
          />
          <Route
            path="/agent/all-packages"
            element={
              <Suspense>
                <AgentListingPackages />
              </Suspense>
            }
          />
          <Route
            path="/agent/all-Hotels"
            element={
              <Suspense>
                <AgentListingHotels />
              </Suspense>
            }
          />
          <Route
            path="/preview-package/:id?"
            element={
              <Suspense>
                <PkgItinerary />
              </Suspense>
            }
          />
          {/* <Route
            path="/new-file"
            element={
              <Suspense>
                <NewFile />
              </Suspense>
            }
          /> */}
          <Route
            path="/themes"
            element={
              <Suspense>
                <AllThemes />
              </Suspense>
            }
          />
          <Route
            path="/themes/:handle"
            element={
              <Suspense>
                <AllThemesPackages />
              </Suspense>
            }
          />
          <Route
            path="/login/agent"
            element={
              <Suspense>
                <AgentLoginNew />
              </Suspense>
            }
          />
          {/* Vendor */}
          <Route
            path="/vendor/property/list"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <PropertyDetails />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/partner/rooms/edit/:room_id/:id?"
            element={
              <Suspense>
                <EditVendorRoom />
              </Suspense>
            }
          />
          {/* <Route
            path="/partner/rooms/edit/:room_id/:id?"
            element={
              <Suspense>
                <EditVendorRoom />
              </Suspense>
            }
          /> */}
          <Route
            path="/vendor/property/add-room/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <AddRoomVendor />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/vendor/property/add"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <AddPropertyVendor />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/vendor/property/edit/:id?"
            element={
              <ProtectedRouteVendor
                element={
                  <Suspense>
                    <EditProperty />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/agent/promotion/add/:id?"
            element={
              <Suspense>
                <AgentPromotionAdd />
              </Suspense>
            }
          />
          <Route
            path="/agent/promotion/add/next/:promotionName?"
            element={
              <Suspense>
                <AgentPromotionAddNext />
              </Suspense>
            }
          />
          <Route
            path="/vendor/promotion/add/:id?"
            element={
              <Suspense>
                <VendorPromotionAdd />
              </Suspense>
            }
          />
          <Route
            path="/thank-you/:id"
            element={
              <Suspense>
                <ThankYouPage />
              </Suspense>
            }
          />
          <Route
            path="/thank-you"
            element={
              <Suspense>
                <ThankYouPage />
              </Suspense>
            }
          />

          <Route
            path="/cancel/:id"
            element={
              <Suspense>
                <CancelPage />
              </Suspense>
            }
          />
          <Route
            path="/thank-you/package/:id"
            element={
              <Suspense>
                <ThankYouPagePackage />
              </Suspense>
            }
          />
          <Route
            path="/cancel/package/:id"
            element={
              <Suspense>
                <CancelPage />
              </Suspense>
            }
          />
          <Route
            path="/vendor/promotion/add/next/:promotionName?/:id?"
            element={
              <Suspense>
                <VendorPromotionAddNext />
              </Suspense>
            }
          />

          <Route
            path="/signature"
            element={<MMSignature />}
          />
          <Route
            path="/reset-password/:loginType"
            element={<ResetPassword />}
          />


          <Route
            path="/activity/thankyou/:id"
            element={<ActivityThankYouPage />}
          />
          <Route
            path="/package/thankyou/:id"
            element={<ThankYouPagePackage />}
          />
          <Route
            path="/hotel/thankyou/:id"
            element={<HotelThankYouPage />}
          />

          <Route
            path="/activity/cancel/:id"
            element={<ActivityCancelPage />}
          />
          <Route
            path="/package/cancel/:id"
            element={<CancelPagePackage />}
          />
          <Route
            path="/hotel/cancel/:id"
            element={<HotelCancelPage />}
          />
        </Routes>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
        <WhatsappBot />
      </Router>
      {/* </Skeleton> */}
    </div>
  );
}

export default App;
