import React from "react";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useLocation } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter } from "../../../utils/utils";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Loader from "../../../CustomComponents/Loader/Loader";
import AddressAutocomplete from "../../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import SeeBookingSlider from "../../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import AgentHeader from "../AgentHeader/AgentHeader";
import { getDecryptedData } from "../../../utils/crypto";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";

function VendorAccount() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const role = query.get("role");
    const [loader, setLoader] = useState(false);
    const [allBookings, setAllBookings] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadResult, setUploadResult] = useState("");
    const BearerToken = getDecryptedData('userToken');
    const vendorToken = getDecryptedData("userToken");
    const [editData, setEditData] = useState(false);

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        setSelectedFile(event.target.files[0]);
        handleSubmitCertificate(file, type);
    };

    const slug = window.location.pathname.split("/");
    // console.log(role ,"slug")

    const [activeTab, setActiveTab] = useState("Profile");

    useEffect(() => {
        if (role === "bookings") {
            setActiveTab("Bookings");
        } else {
            setActiveTab("Profile");
        }
    }, []);

    const [isSliderVisible, setIsSliderVisible] = useState(false);

    const handleCloseSlider = () => {
        setIsSliderVisible(false);
    };

    const handleChangeClick = async () => {
        setIsSliderVisible(true);
    };

    const [formData, setFormData] = useState({
        company_name: "",
        phone: "",
        email: "",
        gst_information: "",
        company_information: "",
        gst_number: "",
        zipcode: "",

        // country: "",
        // state: "",
        // city: "",
        // address: "",
        // agent_category: "",
        // pan: "",
        // agent_category_discount: "",
        // agent_discount_type: "",
        // tan: "",
        // tac: "",
    });

    const [agentDetails, setAgentDetails] = useState(null);
    const [agentUpdateData, setAgentUpdateData] = useState(null);
    const userToken = localStorage.getItem("token");
    const agent_id = agentDetails?.AgentId;
    const account_id = agentDetails?.AccountId;
    const status = agentUpdateData?.data?.status;

    //fetch categories
    const [categoryDetail, setCategoryDetail] = useState(null);
    const [singleBookingData, setSingleBookingData] = useState(null);
    const [error, setError] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [addressData, setAddressData] = useState({
        country: "",
        state: "",
        city: "",
        address: "",
        latitude: "",
        longitude: "",
        placeId: "",
    });

    const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));
    // console.log(userDetails);

    const [agentAccountDetails, setAgentAccountDetails] = useState({});
    // console.log(agentAccountDetails);

    const fetchAccountDetails = (id) => {
        setLoader(true);
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            method: "GET",
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/v1/agents/credit-balance/${id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result) {
                    setAgentAccountDetails(result.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((error) => console.error("Error fetching single rate:", error));
    };

    useEffect(() => {
        if (userDetails?.AgentId) {
            fetchAccountDetails(userDetails?.AgentId);
        }
    }, [userDetails?.AgentId]);
    // console.log(userDetails);

    const handleAddressData = (data) => {
        // console.log(data, "data");
        setAddressData(data);
    };


    const handleSubmit2 = async (e) => {
        e.preventDefault();
        if (!validate()) {
            showErrorToast("Please fill the above details.");
            return;
        }
        setFormErrors({});
        setTimeout(() => setFormErrors({}), 1500);

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/update`;

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append(
            "Authorization",
            `Bearer ${BearerToken}`
        );
        headers.append(
            "Cookie", process.env.REACT_APP_COOKIE
        );

        const payload = JSON.stringify({ ...formData, ...addressData });

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers,
                body: payload,
                redirect: "follow",
            });

            const result = await response.json();
            if (result?.success) {
                showSuccessToast(result.message || "Updated Successfully")
                setAgentUpdateData(result);
                localStorage.setItem("isVerified", result?.data?.status || "false");
                fetchVendorDetails();
            } else {
                showErrorToast(result.message || "Login Failed");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            showErrorToast("An error occurred while updating your profile.");
        }
    };

    const profileFilter = (tab) => {
        setActiveTab(tab);
    };

    const fetchVendorDetails = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/supplier/user-profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${vendorToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setFormData({
                        company_name: data?.data?.name || "",
                        name: data?.data?.name || "",
                        phone: data?.data?.phoneNumber || "",
                        email: data?.data?.email || "",
                        zipcode: data?.data.zipcode || "",
                        gst_information: data?.data?.additional_details?.gst || "",
                        company_information: data?.data?.additional_details?.company || "",
                        // gst_number: data?.data?.GST_Number || "",
                        // country: data?.data?.country || "",
                        // state: data?.data?.state || "",
                        // city: data?.data?.city || "",
                        // address: data?.data?.address || "",
                        // agent_category_discount:
                        //     +data?.data?.agent_category_discount || "",
                        // agent_discount_type: data?.data.agent_discount_type || "",
                        // agent_category: data?.data.AgentCategoryName || "",
                        // pan: data?.data.PAN_Number || "",
                        // tan: data?.data.TAN_Number || "",
                        // tac: data?.data.tac || "",
                    });
                    setAddressData({
                        country: data?.data?.country || "",
                        state: data?.data?.state || "",
                        city: data?.data?.city || "",
                        address: data?.data?.address || "",
                        latitude: data?.data?.latitude || "",
                        longitude: data?.data?.longitude || "",
                        placeId: data?.data?.placeId || "",
                    });
                } else {
                }
            })
            .catch((error) => {
                console.error("Error fetching agent details:", error);
            });
    };

    const updateSupplier = async () => {
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${vendorToken}`,
                Cookie: process.env.REACT_APP_COOKIE,
            };

            const body = JSON.stringify({
                name: formData?.name,
                phone: formData?.phone,
                city: addressData?.city,
                state: addressData?.state,
                country: addressData?.country,
                zipcode: formData?.zipcode,
                address: addressData?.country,
                gst_information: formData?.gst_information,
                company_information: formData?.company_information,
            });

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/supplier/update`,
                {
                    method: "POST",
                    headers,
                    body,
                    redirect: "follow",
                }
            );

            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message);
                setEditData(false);
                fetchVendorDetails();
            } else {
                showErrorToast(result.message);
                // setEditData(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchVendorDetails();
    }, [])

    const fetchCategoryDetail = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/agents/category-list/${account_id}`,
            requestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json(); // Assuming the API returns JSON
            })
            .then((result) => {
                setCategoryDetail(result);
            })
            .catch((error) => {
                setError(error);
                console.error(
                    "There was an error fetching the category details:",
                    error
                );
            });
    };

    const handleSubmitCertificate = (file, type) => {
        if (!file) {
            alert("Please select a file first!");
            return;
        }

        const formData = new FormData();
        formData.append("pdf", file);
        formData.append("collection", type);

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            body: formData,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/v1/agents/agent-doc-upload/${agent_id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                if (result.success) {
                    setUploadResult(result);
                    fetchVendorDetails();
                    toast.success("File uploaded successfully");
                } else {
                    toast.error(
                        result.message ? result.message : "Error uploading file."
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                setUploadResult("Error uploading file.");
            });
    };

    const panImageData = agentDetails?.imageUrl?.find(
        (image) => image.collection === "PAN"
    );

    const gstImageData = agentDetails?.imageUrl?.find(
        (image) => image.collection === "gst_number"
    );
    const deleteImageHandler = async (mediaId) => {
        // console.log(mediaId);
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_API_KEY);
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("token")
        );

        const raw = JSON.stringify({ mediaId });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_APP_URL}/api/v1/agents/delete-image`,
                requestOptions
            );
            const result = await response.json();

            if (result.success) {
                toast.success("Image deleted successfully");
                fetchVendorDetails();
            } else {
                toast.error(result.message ? result.message : "Error deleting image.");
            }
        } catch (error) {
            console.error(error);
        }
    };


    const handleOnClick = (e) => {
        e.preventDefault();
        setEditData(!editData);
    };

    const [formErrors, setFormErrors] = useState({});

    const validate = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = "required";
        }
        if (!formData.email.trim()) {
            errors.email = "required";
        }
        // if (!formData.phone.trim()) {
        //     errors.phone = "is required";
        // }
        // if (!formData.gst_number) {
        //     errors.gst_number = "required";
        // }
        // if (!formData.pan) {
        //     errors.pan = "required";
        // }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    return (
        <div id="Listingholidays">
            <VendorHeader showSelectProperty={true} />
            <div className="sidebar_main_div">
                <SideBar allPropertiesPage={false} />
                <section className="w-full p-sm">
                    <div id="common_background_profile m_20">
                        <h4 className=" mb-10 ">My Account</h4>
                        {loader ? (
                            <Loader size="4em" color="#016937" />
                        ) : (
                            <form onSubmit={updateSupplier}>
                                {editData ? (
                                    <>
                                        <div className="flex-div">
                                            <div className="w-half-div">
                                                <div className="common_background">
                                                    <h4>General Details</h4>
                                                    <div className="profile_content Profile mt-10">
                                                        <div>
                                                            {currentStep === 1 && (
                                                                <div className="input-flex">
                                                                    <div>
                                                                        <label htmlFor="company_name">Name</label>
                                                                        <input
                                                                            style={
                                                                                formErrors.name && {
                                                                                    backgroundColor: "#ff000040",
                                                                                    border: "1px solid red",
                                                                                }
                                                                            }
                                                                            className="w-full"
                                                                            onChange={handleInputChange}
                                                                            type="text"
                                                                            placeholder="Name"
                                                                            name="name"
                                                                            value={formData.name}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="phone">Phone</label>
                                                                        <input
                                                                            style={
                                                                                formErrors.phone && {
                                                                                    backgroundColor: "#ff000040",
                                                                                    border: "1px solid red",
                                                                                }
                                                                            }
                                                                            className="w-full"
                                                                            onChange={handleInputChange}
                                                                            type="text"
                                                                            placeholder="Phone"
                                                                            name="phone"
                                                                            value={formData.phone}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="email">Email</label>
                                                                        <input
                                                                            style={
                                                                                formErrors.email && {
                                                                                    backgroundColor: "#ff000040",
                                                                                    border: "1px solid red",
                                                                                }
                                                                            }
                                                                            className="w-full"
                                                                            disabled={true}
                                                                            onChange={handleInputChange}
                                                                            type="email"
                                                                            placeholder="Email"
                                                                            name="email"
                                                                            value={formData.email}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="common_background mt-10">
                                                    <h4>Address</h4>
                                                    <div className="profile_content Profile mt-10">
                                                        <div>
                                                            <label htmlFor="address">Address</label>
                                                            <AddressAutocomplete
                                                                formdata={addressData}
                                                                style={
                                                                    formErrors.address && {
                                                                        backgroundColor: "#ff000040",
                                                                        border: "1px solid red",
                                                                    }
                                                                }
                                                                onAddressChange={handleAddressData}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="email">Zip Code</label>
                                                            <input
                                                                style={
                                                                    formErrors.email && {
                                                                        backgroundColor: "#ff000040",
                                                                        border: "1px solid red",
                                                                    }
                                                                }
                                                                className="w-full"
                                                                // disabled={true}
                                                                onChange={handleInputChange}
                                                                type="number"
                                                                placeholder="Zip Code"
                                                                name="zipcode"
                                                                value={formData.zipcode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="common_background mt-10">
                                                    <h4>Documentation</h4>
                                                    <div className="profile_content Profile">
                                                        <div >
                                                            <div className="input-flex">
                                                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                                                    <label htmlFor="discount mb-10">
                                                                        Gst Certificate
                                                                    </label>

                                                                    {gstImageData?.url ? (
                                                                        <>
                                                                            <div className="featureImages_uploadFile_img_container">
                                                                                <img src={gstImageData.url} alt="pan" />
                                                                                <div
                                                                                    onClick={() =>
                                                                                        deleteImageHandler(gstImageData?.id)
                                                                                    }
                                                                                    className="featureImages_uploadFile_delete"
                                                                                >
                                                                                    <svg
                                                                                        width="20"
                                                                                        height="20"
                                                                                        viewBox="0 0 14 13"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            d="M1 12.5L13 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                        <path
                                                                                            d="M13 12.5L1 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div style={{ position: "relative" }}>
                                                                                <div className="featureImages_uplaodFile_displaytext_container">
                                                                                    <p className="featured_image_text">
                                                                                        Add Files
                                                                                    </p>
                                                                                    <p className="featured_image_text_dragdrop">
                                                                                        or drop JPG, PNG images here
                                                                                    </p>
                                                                                    <input
                                                                                        accept="image/*"
                                                                                        type="file"
                                                                                        id="select-image"
                                                                                        className="featureImages_upload_file_input"
                                                                                        onChange={(e) =>
                                                                                            handleFileChange(e, "gst_number")
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                                                    <label htmlFor="discount mb-10">
                                                                        PAN Certificate
                                                                    </label>
                                                                    {panImageData?.url ? (
                                                                        <>
                                                                            <div className="featureImages_uploadFile_img_container">
                                                                                <img src={panImageData.url} alt="pan" />
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        deleteImageHandler(panImageData?.id)
                                                                                    }
                                                                                    className="featureImages_uploadFile_delete"
                                                                                >
                                                                                    <svg
                                                                                        width="20"
                                                                                        height="20"
                                                                                        viewBox="0 0 14 13"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            d="M1 12.5L13 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                        <path
                                                                                            d="M13 12.5L1 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div style={{ position: "relative" }}>
                                                                                <div className="featureImages_uplaodFile_displaytext_container">
                                                                                    <p className="featured_image_text">
                                                                                        Add Files
                                                                                    </p>
                                                                                    <p className="featured_image_text_dragdrop">
                                                                                        or drop JPG, PNG images here
                                                                                    </p>
                                                                                    <div>
                                                                                        <input
                                                                                            accept="image/*"
                                                                                            type="file"
                                                                                            id="select-image"
                                                                                            className="featureImages_upload_file_input"
                                                                                            onChange={(e) =>
                                                                                                handleFileChange(e, "PAN")
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="button mt-10">
                                                    <button
                                                        className="primary_btn search-btn-width sm-btn"
                                                        onClick={updateSupplier}
                                                        type="button"
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="w-half-div">
                                                <div className="common_background">
                                                    <h4>Business Information</h4>
                                                    <div className="profile_content Profile mt-10">
                                                        <div>
                                                            <div className="input-flex">
                                                                <div>
                                                                    <label htmlFor="gst_information">GST Information.</label>
                                                                    <input
                                                                        className="w-full"
                                                                        onChange={handleInputChange}
                                                                        type="text"
                                                                        placeholder="gst_number No."
                                                                        name="gst_information"
                                                                        value={formData.gst_information}
                                                                        style={
                                                                            formErrors.gst_information && {
                                                                                backgroundColor: "#ff000040",
                                                                                border: "1px solid red",
                                                                            }
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="company_information">Company Information.</label>
                                                                    <input
                                                                        className="w-full"
                                                                        style={
                                                                            formErrors.company_information && {
                                                                                backgroundColor: "#ff000040",
                                                                                border: "1px solid red",
                                                                            }
                                                                        }
                                                                        onChange={handleInputChange}
                                                                        type="text"
                                                                        placeholder="Company Information"
                                                                        name="company_information"
                                                                        value={formData.company_information}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button mt-10">
                                                    <button
                                                        className="primary_btn sm-btn-block"
                                                        onClick={updateSupplier}
                                                        type="button"
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex-div">
                                            <div className="w-half-div">
                                                <div className="common_background">
                                                    <h4>General Details</h4>
                                                    <div className="profile_content Profile">
                                                        <div>
                                                            <div className="input-flex">
                                                                <div className="mt-10">
                                                                    <p className="py-5">Name</p>
                                                                    <p className="py-5">Email</p>
                                                                    <p className="py-5">Phone</p>
                                                                </div>
                                                                <div className="mt-10">
                                                                    <p className="py-5">
                                                                        {formData?.company_name}
                                                                    </p>
                                                                    <p className="py-5">{formData.email}</p>
                                                                    <p className="py-5">{formData.phone}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="common_background mt-10">
                                                    <h4>Address</h4>
                                                    <div className="profile_content Profile">
                                                        <div >
                                                            <div className="input-flex">
                                                                <div className="mt-10">
                                                                    <p className="py-5">Country</p>
                                                                    <p className="py-5">City</p>
                                                                    <p className="py-5">State</p>
                                                                    <p className="py-5">Address</p>
                                                                    <p className="py-5">Zip Code</p>
                                                                </div>
                                                                <div className="mt-10">
                                                                    <p className="py-5">{addressData?.country}</p>
                                                                    <p className="py-5">{addressData?.city}</p>
                                                                    <p className="py-5">{addressData?.state}</p>
                                                                    <p className="py-5">{addressData?.address}</p>
                                                                    <p className="py-5">{formData?.zipcode ? formData?.zipcode : "NA"}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="common_background mt-10">
                                                    <h4>Documentation</h4>
                                                    <div className="profile_content Profile">
                                                        <div >
                                                            <div className="input-flex">
                                                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                                                    <label htmlFor="discount mb-10">
                                                                        Gst Certificate
                                                                    </label>

                                                                    {gstImageData?.url ? (
                                                                        <>
                                                                            <div className="featureImages_uploadFile_img_container">
                                                                                <img src={gstImageData.url} alt="pan" />
                                                                                <div
                                                                                    onClick={() =>
                                                                                        deleteImageHandler(gstImageData?.id)
                                                                                    }
                                                                                    className="featureImages_uploadFile_delete"
                                                                                >
                                                                                    <svg
                                                                                        width="20"
                                                                                        height="20"
                                                                                        viewBox="0 0 14 13"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            d="M1 12.5L13 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                        <path
                                                                                            d="M13 12.5L1 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div style={{ position: "relative" }}>
                                                                                <div className="featureImages_uplaodFile_displaytext_container">
                                                                                    <p className="featured_image_text">
                                                                                        Add Files
                                                                                    </p>
                                                                                    <p className="featured_image_text_dragdrop">
                                                                                        or drop JPG, PNG images here
                                                                                    </p>
                                                                                    <input
                                                                                        accept="image/*"
                                                                                        type="file"
                                                                                        id="select-image"
                                                                                        className="featureImages_upload_file_input"
                                                                                        onChange={(e) =>
                                                                                            handleFileChange(e, "gst_number")
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                                                    <label htmlFor="discount mb-10">
                                                                        PAN Certificate
                                                                    </label>
                                                                    {panImageData?.url ? (
                                                                        <>
                                                                            <div className="featureImages_uploadFile_img_container">
                                                                                <img src={panImageData.url} alt="pan" />
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        deleteImageHandler(panImageData?.id)
                                                                                    }
                                                                                    className="featureImages_uploadFile_delete"
                                                                                >
                                                                                    <svg
                                                                                        width="20"
                                                                                        height="20"
                                                                                        viewBox="0 0 14 13"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            d="M1 12.5L13 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                        <path
                                                                                            d="M13 12.5L1 0.5"
                                                                                            stroke="#AAAAAA"
                                                                                            strokeWidth="1.2"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div style={{ position: "relative" }}>
                                                                                <div className="featureImages_uplaodFile_displaytext_container">
                                                                                    <p className="featured_image_text">
                                                                                        Add Files
                                                                                    </p>
                                                                                    <p className="featured_image_text_dragdrop">
                                                                                        or drop JPG, PNG images here
                                                                                    </p>
                                                                                    <div>
                                                                                        <input
                                                                                            accept="image/*"
                                                                                            type="file"
                                                                                            id="select-image"
                                                                                            className="featureImages_upload_file_input"
                                                                                            onChange={(e) =>
                                                                                                handleFileChange(e, "PAN")
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="button mt-10">
                                                    <button
                                                        className="primary_btn search-btn-width sm-btn"
                                                        onClick={handleOnClick}
                                                        type="button"
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="w-half-div">
                                                <div className="common_background">
                                                    <h4>Business Information</h4>
                                                    <div className="profile_content Profile">
                                                        <div>
                                                            <div className="input-flex">
                                                                <div className="mt-10">
                                                                    <p className="py-5">GST Information.</p>
                                                                    <p className="py-5">Company Information.</p>
                                                                    {/* <p className="py-5">TAN Number</p> */}
                                                                    {/* <p className="py-5">TAC %</p> */}
                                                                    {/* 
                                  <p className="py-5">Agent Category Name</p>
                                  <p className="py-5">
                                    Agent Category Discount
                                  </p> */}
                                                                </div>
                                                                <div className="mt-10">
                                                                    <p className="py-5">
                                                                        {formData?.gst_information
                                                                            ? formData?.gst_information
                                                                            : "-"}
                                                                    </p>
                                                                    <p className="py-5">
                                                                        {formData?.company_information ? formData?.company_information : "-"}{" "}
                                                                    </p>
                                                                    {/* <p className="py-5">{formData?.TAN_Number ? formData?.TAN_Number : "-"} </p> */}
                                                                    {/* <p className="py-5">
                                                                        {formData?.tac ? formData?.tac : "-"}
                                                                    </p> */}
                                                                    <p className="py-5">
                                                                        {/* {formData?.agent_category ? formData?.agent_category : "-"}  */}
                                                                    </p>
                                                                    <p className="py-5">
                                                                        {/* {formData?.agent_category_discount ? formData?.agent_category_discount : "-"}  */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button mt-10">
                                                    <button
                                                        className="primary_btn sm-btn-block"
                                                        onClick={handleOnClick}
                                                        type="button"
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default VendorAccount;
