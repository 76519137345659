import React, { useEffect } from "react";
import { toast } from "react-toastify";
 
const VideoList = ({ imageData, fetchMediaRefetch, multiple = false }) => {
  // Convert the data object into an array of values
  const images = Object.values(imageData);
  const deleteData = () => {
    
  }
   
//   const [
//     deleteData,
//     { isLoading, isSuccess: isSuccessDelete, isError, error },
//   ] = useDeleteMediaMutation();
 
//   useEffect(() => {
//     if (isSuccessDelete) {
//       fetchMediaRefetch();
//       toast.success("Deleted successfully");
//     } else if (isError) {
//     }
//   }, [isSuccessDelete, isError, error]);
 
  const deleteMediaHandler = async (mediaId) => {
    try {
      await deleteData({ mediaId });
    } catch (err) {
      console.error("Delete failed: ", err);
    }
  };
 
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        maxHeight: "300px",
        overflowY: "auto",
      }}
    >
      {multiple ? (
        <>
          {" "}
          {images.length > 0 ? (
            images.map((image) => (
              <div
                key={image.uuid}
                
                style={{ textAlign: "center", position: "relative" , width:"150px", height:"150px"}}
              >
                <video
                controls
                  src={image.original_url}
                  alt={image.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
 
                <button
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "red",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteMediaHandler(image.uuid)}
                >
                  X
                </button>
              </div>
            ))
          ) : (
            <p>No Data Added</p>
          )}
        </>
      ) : (
        <div
        key={imageData}
        style={{ textAlign: "center", position: "relative" }}
      >
        <img
          src={imageData}
          alt={"image.name"}
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
 
        {/* <button
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "red",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => deleteMediaHandler("image.uuid")}
        >
          X
        </button> */}
      </div>
      )}
    </div>
  );
};
 
export default VideoList;
 