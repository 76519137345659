import React from "react";
import NoDataFound from "../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";

function TransportOverviewContent({ singleTransportData, loader }) {

  return (
    <div className="package-update-cards">
      {loader ? (

        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              {Array.from({ length: 5 }).map((_, index) =>
                <div className="day-container" key={index}>
                  <div className="day-column flex align_items_center justify_content_center">
                    <h3>
                      <Skeleton width={40} />
                    </h3>
                  </div>
                  <div className="itinerary-column">
                    <div className="day-itinerary">
                      <h4><Skeleton width={200} /></h4>
                      <div className="flex flex_gap_20">
                        <p><Skeleton width={70} /></p>|<p><Skeleton width={70} /></p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      ) : (
        <>
          <div className="package-card-body">
            {singleTransportData?.packageItinerary?.length > 0 ? (
              <>
                {" "}
                {singleTransportData?.packageItinerary?.map((items, index) => {
                  return (
                    <div className="day-container" key={index}>
                      <div className="day-column flex align_items_center justify_content_center">
                        <h3>Day {items.day}</h3>
                      </div>
                      <div className="itinerary-column">
                        <div className="day-itinerary">
                          <h4>{items?.additionalDetails?.heading}</h4>
                          <div className="flex flex_gap_20">
                            <p>{items.startDestination}</p>|<p>{items.endDestination}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoDataFound />
            )}
          </div>

        </>
      )}


    </div>
  );
}

export default TransportOverviewContent;
