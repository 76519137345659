import React, { useEffect, useState } from "react";
import "./AdultDetailsInPackage.css";
import AddressAutocomplete from "../CustomGooglePlaceAPi/AddressAutocomplete";
import { useLocation } from "react-router-dom";
import { currentDateSingle } from "../../utils/utils";

function AdultDetailsInPackage({ sendDataToParent }) {
  const location = useLocation();
  const [travellers, setTravellers] = useState(1);
  const [isTravellerDetailsPopup, setIsTravellerDetailPopup] = useState(false);
  const [selectedTraveller, setSelectedTraveller] = useState({
    index: 1,
    // traveller: null,
  });
  const [travellerDetails, setTravellerDetails] = useState(
    Array(travellers).fill({
      guest_name: "", guest_email: "", guest_phone: "", isPrimary: false, guest_address: "",
      guest_country: "",
      guest_city: "",
      guest_state: "",
      zipcode: ""
    })
  );

  const [bookingData, setBookingData] = useState({});
  const [guestDetails, setGuestDetails] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(false);
  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
  });

  const [detailsFilled, setDetailsFilled] = useState(0);


  const toggleBusinessProfile = () => {
    setBusinessProfile(!businessProfile);
  };

  const [guestData, setGuestData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    guest_address: "",
    guest_country: "",
    guest_city: "",
    guest_state: "",
    gst_number: "",
    company_number: "",
    business_email: "",
  });

  const [formData, setFormData] = useState({
    amount: bookingValues.grandTotal,
    guest_name: guestData.guest_name,
    guest_phone: guestData.guest_phone,
    guest_email: guestData.guest_email,
    pincode: guestData.pincode,
    guest_state: "stateInputValue",
    guest_country: "countryInputValue",
    guest_city: guestData.guest_city,
    guest_address: guestData.guest_address,
    visitDate: "checkInDate",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      packageId: params.get("packageId"),
      packagehandle: params.get("packagehandle"),
      // tourDate : params.get("checkinYear")-params.get("checkinMonth")-params.get("checkinDay")
    };

    setTravellers(data.guest ? data.guest : 2);

    const guestArray = Array(Number(data.guest)).fill({
      guest_name: "",
      guest_email: "",
      guest_phone: "",
      isPrimary: false,
      pax_type: "adult",
      index: i,
    });

    // const childArray = Array(Number(childAges.length)).fill({
    //   name: "",
    //   type:"children",
    //   age:"",
    // });

    const childArray = childAges?.map((child, index) => ({
      name: "",
      pax_type: "children",
      age: child,
      index: index + 1,
    }));

    setTravellerDetails([...guestArray, ...childArray]);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    // setGuest(data.guest);
    // setChildren(data.children);
    // setRooms(data.room);
    // setChildAge(childAges);

    if (data.checkinYear && data.checkinMonth && data.checkinDay) {
      setBookingData({ ...data, tourDate: checkInDate });
    } else {
      setBookingData({ ...data, tourDate: currentDateSingle });
    }

    // setStartDate(checkInDate);
    // setEndDate(checkOutDate);
    // setBookingData({...data, tourDate: checkInDate});
  }, [location.search]);

  const guestDataHandleChange = (event) => {
    const { name, value } = event.target;
    setGuestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const [allTravellerDetailsAdd, setAllTravellerDetailsAdd] = useState(true);

  const handleAddressData = (data) => {
    // console.log(data, "data");
    setAddressData(data);

    setTravellerDetails((prev) => {
      const updatedDetails = prev.map((traveller) => ({
        ...traveller, // Preserve existing properties
        guest_address: data.address,
        guest_city: data.city,
        guest_country: data.country,
        guest_state: data.state,
      }));

      return updatedDetails;
    });

    setGuestData((prevState) => ({
      ...prevState,
      guest_address: data.address,
      guest_city: data.city,
      guest_country: data.country,
      guest_state: data.state,
    }));
  };

  useEffect(() => {
    const dataToSend = allTravellerDetailsAdd ? travellerDetails : [guestData];
    sendDataToParent(dataToSend, allTravellerDetailsAdd);
  }, [travellerDetails, allTravellerDetailsAdd, guestData]);

  const handleTravellerClick = (e, traveller, index) => {
    e.stopPropagation();
    setSelectedTraveller({
      index: index,
      traveller: traveller,
    });
    setIsTravellerDetailPopup(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTravellerDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[selectedTraveller.index] = {
        ...newDetails[selectedTraveller.index],
        [name]: value,
      };
      return newDetails;
    });
  };

  const handlePrimaryChange = (e, index) => {
    e.stopPropagation();
    setTravellerDetails((prevDetails) => {
      const newDetails = prevDetails.map((traveller, i) => ({
        ...traveller,
        isPrimary: i === index,
      }));
      return newDetails;
    });
  };

  // useEffect(() => {
  //   const filledGuestsCount = travellerDetails?.filter(
  //     (guest) =>
  //       guest.name.trim() !== "" &&
  //       guest.email.trim() !== "" &&
  //       guest.phone.trim() !== ""
  //   ).length;

  //   setDetailsFilled(filledGuestsCount);
  // }, [travellerDetails]);

  return (
    <div className="no_of_travellers_parent">
      {/* <h2>Adult Details</h2> */}
      {/* {Array.from({ length: travellers }, (_, index) => ( */}

      <div className="no_of_travellers_checkbox_parent">
        <div className="no_of_travellers_checkbox">
          <input
            id="allTravelers"
            type="radio"
            defaultChecked
            onChange={() => setAllTravellerDetailsAdd(true)}
            checked={allTravellerDetailsAdd}
          />
          <label htmlFor="allTravelers">Enter All Traveler Details</label>
        </div>
        <div className="no_of_travellers_checkbox">
          <input
            id="oneTravelers"
            type="radio"
            onChange={() => setAllTravellerDetailsAdd(false)}
            checked={!allTravellerDetailsAdd}
          />
          <label htmlFor="oneTravelers">Enter Only Primary Guest Detail</label>
        </div>
      </div>

      {allTravellerDetailsAdd && (
        <div className="no_of_travellers">
          {/* <span>Traveller {index + 1}</span> */}
          <h5
            onClick={(e) =>
              handleTravellerClick(
                e,
                { pax_type: "adult", guest_name: "", guest_email: "", guest_phone: "" },
                0
              )
            }
          >
            Add Traveler Details
          </h5>{" "}
          <small>
            (out of {travellers} travellers,{" "}
            {
              travellerDetails.filter((traveller) => traveller.guest_name !== "")
                .length
            }{" "}
            traveller details are added)
          </small>
          <div>
            <div className="overflow_x_scroll_y_hidden">
              {travellerDetails?.length > 0 &&
                travellerDetails.every(
                  (item) =>
                    item.guest_name !== "" && item.guest_phone !== "" && item.guest_phone !== ""
                ) && (
                  <table className="rates_table">
                    {" "}
                    <tr>
                      <th className="">Name</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Phone no.</th>
                      <th></th>
                    </tr>{" "}
                    {travellerDetails.map((item, index) => {
                      const allFeildExists =
                        item?.guest_name !== "" && item.guest_phone !== "" && item?.guest_phone
                          ? true
                          : false;
                      return (
                        <>
                          <tr>
                            <td className="p-5">
                              {item?.guest_name}{" "}
                              {item?.pax_type === "children" && (
                                <span style={{ border: "0" }}>
                                  {" "}
                                  ( Age: {item?.age}){" "}
                                </span>
                              )}
                            </td>
                            <td className="p-5 text-center text-capitalize">
                              {item?.pax_type}
                            </td>
                            <td className="p-5 text-center">
                              {item?.guest_email ? item?.guest_email : "-"}
                            </td>
                            <td className="p-5 text-center">
                              {item?.guest_phone ? item.guest_phone : "-"}
                            </td>
                            <td>
                              <svg
                                onClick={(e) => [
                                  handleTravellerClick(
                                    e,
                                    {
                                      pax_type: "adult",
                                      guest_name: "",
                                      guest_email: "",
                                      guest_phone: "",
                                    },
                                    0
                                  ),
                                  setSelectedTraveller({
                                    index: index,
                                    traveller: item,
                                  }),
                                ]}
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_2810_13923"
                                  style={{ maskType: "alpha" }}
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="20"
                                >
                                  <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_2810_13923)">
                                  <path
                                    d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                                    fill="#333333"
                                  />
                                </g>
                              </svg>
                            </td>
                          </tr>

                        </>
                      );
                    })}
                  </table>
                )}
            </div>
          </div>
        </div>
      )}

      {!allTravellerDetailsAdd && (
        <div className="no_of_travellers_primary">
          <>
            <form action="">
              <div className="" id="padding">
                <div className="input-flex">
                  <div className="HotelCheckout-form">
                    <label htmlFor="">Full Name</label>
                    <input
                      className="HotelCheckout-Input"
                      id="guest_name"
                      type="text"
                      name="guest_name"
                      value={guestData.guest_name}
                      onChange={guestDataHandleChange}
                      required
                      placeholder="Name"
                    />
                  </div>
                  <div className="CheckoutHotel-Fields">
                    <label htmlFor="">Phone</label>
                    <input
                      className="HotelCheckout-Input"
                      type="number"
                      id="guest_phone"
                      name="guest_phone"
                      value={guestData.guest_phone}
                      onChange={guestDataHandleChange}
                      required
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="input-flex">
                  <div className="CheckoutHotel-Fields">
                    <label htmlFor="">
                      Email Address{" "}
                    </label>
                    <input
                      className="HotelCheckout-Input"
                      id="guest_email"
                      name="guest_email"
                      value={guestData.guest_email}
                      onChange={guestDataHandleChange}
                      required
                      placeholder="Email"
                    />
                  </div>

                  <div className="CheckoutHotel-Fields">
                    <label htmlFor="">Zipcode</label>
                    <input
                      className="HotelCheckout-Input"
                      type="number"
                      id="zipcode"
                      name="zipcode"
                      value={guestData.zipcode}
                      onChange={guestDataHandleChange}
                      required
                      placeholder="Zipcode"
                    />
                  </div>
                </div>
                {/* <div className="mt-10">
                  <label htmlFor="address">Address</label>
                  <AddressAutocomplete
                    formdata={formData}
                    onAddressChange={handleAddressData}
                  />
                </div> */}
              </div>
            </form>
            {/* <div
              onClick={toggleBusinessProfile}
              className=" flex_align_center flex_gap_10"
              style={{ margin: "20px 0px" }}
              id="padding"
            >
              <input
                type="checkbox"
                className="cursor_pointer"
                checked={businessProfile}
              />
              <div className="cursor_pointer">
                Enter GST Details
                <small> (Optional)</small>
              </div>
            </div>
            {businessProfile && (
              <form>
                <div className=" p_top_0" id="padding">
                  <h5 className="text-left">BUSINESS PROFILE</h5>
                  <div className="input-flex mt-10">
                    <div>
                      <input
                        className="w-full"
                        id="gst_number"
                        name="gst_number"
                        value={guestData.gst_number}
                        onChange={guestDataHandleChange}
                        required
                        placeholder="GST Number"
                      />
                    </div>
                    <div>
                      <input
                        className="w-full"
                        id="business_email"
                        name="business_email"
                        value={guestData.business_email}
                        onChange={guestDataHandleChange}
                        required
                        placeholder="Business Email ID"
                      />
                    </div>
                  </div>
                  <div className="input-flex">
                    <div>
                      <input
                        className="w-full"
                        id="company_number"
                        type="number"
                        name="company_number"
                        value={guestData.company_number}
                        onChange={guestDataHandleChange}
                        required
                        placeholder="Company Phone Number"
                      />
                    </div>
                  </div>
                </div>
              </form>
            )} */}
          </>
        </div>
      )}
      {/* ))} */}

      {isTravellerDetailsPopup && (
        <>
          <div
            onClick={() => setIsTravellerDetailPopup(false)}
            className="ScreenDimmer"
          ></div>

          <div className="traveller_details_popup">
            <div className="traveller_details_popup_heading flex justify_content_between">
              <h3>Add Traveler Details</h3>
              <button onClick={() => setIsTravellerDetailPopup(false)}>
                X
              </button>
            </div>

            <div className="traveller_adult_main">
              <h2>
                Traveler {Number(selectedTraveller.index) + 1}{" "}
                <sub>/{Number(travellers) + Number(bookingData?.children)}</sub>
              </h2>
              <div className="flex flex_gap_10 mb-2 flex_wrap">
                {travellerDetails.map((traveller, index) => {
                  let adultCount = 1;
                  let childrenCount = 1;

                  // Determine the label based on traveller type
                  if (traveller.pax_type === "adult") {
                    //  label = `adult ${bookingData?.guest}`;
                    adultCount++;
                    //  setAdultCount(bookingData?.guest + 1); // Increment adult count
                  } else if (traveller.pax_type === "children") {
                    childrenCount++;
                    //  label = `children ${bookingData?.children}`;
                    //  setChildrenCount(bookingData?.children + 1); // Increment children count
                  }

                  return (
                    <div
                      key={index}
                      // style={  ? {backgroundColor:"red"} : {}}
                      className={`traveller_adult_card ${selectedTraveller.index === index ? "selected" : ""
                        }`}
                      onClick={(e) => handleTravellerClick(e, traveller, index)}
                    >
                      <span className="text-capitalize">
                        {traveller.pax_type}{" "}
                        {traveller.pax_type === "adult"
                          ? index + 1
                          : traveller.index}
                      </span>

                      {travellerDetails[index]?.pax_type === "adult" ? (
                        travellerDetails[index]?.guest_name?.trim() !== "" &&
                          travellerDetails[index]?.guest_email?.trim() !== "" &&
                          travellerDetails[index]?.guest_phone?.trim() !== "" ? (
                          <div className="traveller_adult_card_tick">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="#25d366"
                            >
                              <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                            </svg>
                          </div>
                        ) : null
                      ) : travellerDetails[index]?.pax_type === "children" ? (
                        travellerDetails[index]?.name?.trim() !== "" &&
                          travellerDetails[index]?.age?.trim() !== "" ? (
                          <div className="traveller_adult_card_tick">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="#25d366"
                            >
                              <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                            </svg>
                          </div>
                        ) : null
                      ) : null}

                      {travellerDetails[index]?.pax_type === "adult" && (
                        <div>
                          <input
                            type="checkbox"
                            className="primary_checkbox"
                            checked={travellerDetails[index]?.isPrimary}
                            onChange={(e) => handlePrimaryChange(e, index)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="traveller_adult_form_details">
              <h4>Enter Information</h4>
              {selectedTraveller.traveller.pax_type === "adult" && (
                <>
                  <form className="flex flex_wrap flex_gap_10">
                    <input
                      type="text"
                      placeholder="Name"
                      name="guest_name"
                      value={travellerDetails[selectedTraveller.index]?.guest_name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      name="guest_email"
                      value={travellerDetails[selectedTraveller.index]?.guest_email}
                      onChange={handleInputChange}
                    />
                    <input
                      type="number"
                      placeholder="Phone"
                      name="guest_phone"
                      value={travellerDetails[selectedTraveller.index]?.guest_phone}
                      onChange={handleInputChange}
                    />
                  </form>

                </>
              )}

              {selectedTraveller.traveller.pax_type === "children" && (
                <form className="flex flex_wrap flex_gap_10">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={travellerDetails[selectedTraveller.index]?.name}
                    onChange={handleInputChange}
                  />
                  <input
                    //  type="number"
                    placeholder="Age"
                    name="age"
                    disabled
                    value={`Age = ${travellerDetails[selectedTraveller.index]?.age
                      }`}
                    onChange={handleInputChange}
                  />
                </form>
              )}

              {/* <div className="mt-10">
                <label htmlFor="address">Address</label>
                <AddressAutocomplete
                  formdata={formData}
                  onAddressChange={handleAddressData}
                />

                <input
                  className="HotelCheckout-Input"
                  type="number"
                  id="zipcode"
                  name="zipcode"
                  value={travellerDetails[selectedTraveller.index]?.zipcode}
                  onChange={handleInputChange}
                  required
                  placeholder="Zipcode"
                />
              </div> */}
              <button
                className="primary_btn fit-width mt-10"
                onClick={() => setIsTravellerDetailPopup(false)}
              >
                Save
              </button>

              {/* <form className="flex flex_wrap flex_gap_10">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={travellerDetails[selectedTraveller.index]?.name}
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={travellerDetails[selectedTraveller.index]?.email}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  placeholder="Phone"
                  name="phone"
                  value={travellerDetails[selectedTraveller.index]?.phone}
                  onChange={handleInputChange}
                />
              </form> */}
            </div>
          </div>
        </>
      )}

      <div className="mt-10">
        <label htmlFor="address">Address</label>
        <AddressAutocomplete
          formdata={formData}
          onAddressChange={handleAddressData}
        />
        <label htmlFor="zipcode">Zipcode</label>
        <input
          className="HotelCheckout-Input"
          type="number"
          id="zipcode"
          name="zipcode"
          // value={guestData.zipcode}
          // onChange={guestDataHandleChange}

          value={travellerDetails[selectedTraveller.index]?.zipcode}
          onChange={handleInputChange}

          required
          placeholder="Zipcode"
        />
      </div>
    </div>
  );
}

export default AdultDetailsInPackage;
