import React from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial";
import FilterPackages from "../../CustomComponents/FilterPackages/FilterPackages";
import BannerMain from "../../CustomComponents/BannerMain/BannerMain";
import activitiesImg1 from "../../assets/images/activities-one.jpg";
import activitiesImg2 from "../../assets/images/activities-for-you-3.jpg";
import activitiesImg3 from "../../assets/images/activities-for-you-2.jpg";
import activitiesImg4 from "../../assets/images/activities-for-you-4.jpg";
import CustomSearchFormActivities from "../../CustomComponents/SearchForm/CustomSearchFormActivities/CustomSearchFormActivities";
import { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import Header from "../../CustomComponents/Header/Header";
import Highlights from "../../CustomComponents/Highlights/Highlights";
import FilterActivities from "../../CustomComponents/FilterSection/FilterActivities";
import FilterActivitiesComponent from "../../CustomComponents/FilterActivitiesComponent/FilterActivitiesComponent";

function Activities() {
  const isVerifiedToken = localStorage.getItem("isVerified");
  const role = localStorage.getItem("role");
  // const userToken = localStorage.getItem("userToken");

  const navigate = useNavigate();

  useEffect(() => {
    // const isVerifiedToken = localStorage.getItem('isVerified');
    const AccountStatus = localStorage.getItem("AccountStatus");
    // console.log(AccountStatus, "AccountStatus")
    // const role = localStorage.getItem('role');

    // console.log(isVerifiedToken, "token");
    // console.log(role, "role");

    if (AccountStatus === "disable") {
      console.error("Error: Invalid token or undefined for agent role");
      // showErrorToast("First Fill all required ")
      navigate("/agent-account");
    }
  }, [navigate]);

  return (
    <div id="activities">
      <PageMetaData
        title="Top Things to Do in Himachal Pradesh - Mountain Mysteries "
        description="Explore the best things to do in Himachal Pradesh, from thrilling adventures like trekking and paragliding to serene escapes in charming hill stations"
        keywords="Things to do in Himchal Pradesh "
      />
      <Header />
      <CustomSearchFormActivities
        topRadios={["Hotel", "B&B", "Camping", "HomeStay"]}
      />
      {/* <BannerMain /> */}
      <FilterActivitiesComponent />

      {/* <section>
        <div className="container">
          <div className="common_padding">
            <h3>Activities we offer</h3>
          </div>
          <div className="activities-for-you">
            <div className="activities-offer-main ">
              <div className="activities-offer-card">
                <Link to="">
                  <img src={activitiesImg1} alt="" />
                  <h2 className="activities-card-content">
                    Trekking & Camping
                  </h2>
                  <div className="activities-gradient"></div>
                </Link>
              </div>
              <div className="activities-offer-card">
                <Link href="">
                  <img src={activitiesImg2} alt="" />
                  <h2 className="activities-card-content">Adventure & Fun</h2>
                  <div className="activities-gradient"></div>
                </Link>
              </div>
              <div className="activities-offer-card">
                <Link href="">
                  <img src={activitiesImg3} alt="" />
                  <h2 className="activities-card-content">Wildlife & Nature</h2>
                  <div className="activities-gradient"></div>
                </Link>
              </div>
              <div className="activities-offer-card">
                <Link href="">
                  <img src={activitiesImg4} alt="" />
                  <h2 className="activities-card-content">
                    Culture & Exploration
                  </h2>
                  <div className="activities-gradient"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Testimonial />
      <Highlights />
      <Footer />
    </div>
  );
}

export default Activities;
