import React from "react";
import PropTypes from "prop-types";

function StarRating({ value }) {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (value >= i) {
      stars.push(
        <svg
          key={`full-${i}`}
          style={{ margin: "0px" }}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
        >
          <path
            d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z"
            fill="#ECB119"
          />
        </svg>
      );
    } else if (value >= i - 0.5) {
      stars.push(
        <svg
        key={`half-${i}`}
          style={{ margin: "0px" }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
        >
          <g clip-path="url(#clip0_3502_4408)">
            <path
              d="M10.8951 8.65288L12.6354 14L8.08534 10.6971L3.53527 14L5.27273 8.65288L0.722656 5.34712H6.34502L8.08534 0L9.8228 5.34712H15.4452L10.8951 8.65288Z"
              fill="#ECB119"
            />
            <path
              d="M10.8952 8.65288L12.6355 14L8.08545 10.6971V0L9.82291 5.34712H15.4453L10.8952 8.65288Z"
              fill="#D5D5D5"
            />
          </g>
          <defs>
            <clipPath id="clip0_3502_4408">
              <rect
                width="14.7225"
                height="14"
                fill="white"
                transform="translate(0.722656)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    } else {
      stars.push(
        <svg
        key={`empty-${i}`} 
          style={{ margin: "0px" }}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="13"
          viewBox="0 0 15 13"
          fill="none"
        >
          <path
            d="M7.44482 0L9.01642 4.83688H14.1022L9.98772 7.82624L11.5593 12.6631L7.44482 9.67376L3.33033 12.6631L4.90193 7.82624L0.787428 4.83688H5.87323L7.44482 0Z"
            fill="#D5D5D5"
          />
        </svg>
      );
    }
  }
  return (
    <div key={1} className="rating">
      {stars}
    </div>
  );
}

StarRating.propTypes = {
  value: PropTypes.number.isRequired,
};

export default StarRating;
