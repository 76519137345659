import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import loginImg from "../../assets/images/login-new.jpeg";
import { saveEncryptedData } from "../../utils/crypto";

function Login({ onClose }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialRole = queryParams.get("role") || "customer";
  const [activeForm, setActiveForm] = useState(initialRole);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};
    if (!formData?.email.trim()) {
      errors.email = "required";
    }
    if (!formData?.password.trim()) {
      errors.password = "required";
    }
    // if (!formData?.name.trim()) {
    //   errors.name = "required";
    // }
    // if (!formData?.phone.trim()) {
    //   errors.phone = "required";
    // }
    // if (!formData?.confirmPassword.trim()) {
    //   errors.confirmPassword = "required";
    // }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }

    return Object.keys(errors).length === 0;
  };

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const role = queryParams.get("role") || "customer";
    setActiveForm(role);
  }, [location.search]);

  const showCreateAccount = () => {
    setIsCreatingAccount(true);
    setResetForm(false);
  };

  const showLoginAccount = (e) => {
    e.preventDefault();
    setIsCreatingAccount(false);
    setResetForm(false);
  };

  const showResetForm = (e) => {
    e.preventDefault();
    setResetForm(true);
    setIsCreatingAccount(false);
  };

  const switchForm = (form) => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setActiveForm(form);
    setIsCreatingAccount(false);
    setResetForm(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const userToken = localStorage.getItem("token");
  // console.log(userToken, "tokkkk");
  const [agentDetails, setAgentData] = useState();

  const fetchAgentDetails = (token) => {
    // console.log("agent");
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAgentData(data.data);

        if (data.success) {
          if (data.data.accountStatus === "disable") {
            navigate("/agent-account");
          } else {
            navigate("/");
          }
          localStorage.setItem("userDetails", JSON.stringify(data.data.agent));
        } else {
        }
      })
      .catch((error) => {
        // Handle fetch errors
        console.error("Error fetching agent details:", error);
      });
  };

  const fetchCustomerDetails2 = (token) => {
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/customers/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.agent, "test");
        setAgentData(data.data.agent);

        if (data.success) {
          // if (data.data.agent.AccountStatus === "disable") {
          //     navigate("/agent-account");
          // } else {
          // }
          localStorage.setItem(
            "userDetails",
            JSON.stringify(data.data.customer)
          );
          navigate("/customer-dashboard");
        } else {
        }
      })
      .catch((error) => {
        // Handle fetch errors
        console.error("Error fetching agent details:", error);
      });
  };

  const fetchCustomerDetails = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${token}`
    );
    const cookie = process.env.REACT_APP_COOKIE || '';
    myHeaders.append("Cookie", cookie);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/user-profile`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        localStorage.setItem(
          "userDetails",
          JSON.stringify(result.data)
        );
        navigate("/customer-dashboard");
      } else {
        showErrorToast(result.message || "User Profile not fetched successfully..")
      }

    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  const handleCustomerSubmit = async (event) => {
    event.preventDefault();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the required details");
      return;
    }

    if (isCreatingAccount && formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YzZhOGFjMi1mMzY2LTQ3NWMtYTVhZi1iMDNiMDRhZDY1ZWMiLCJqdGkiOiI1NDM5YmM0NTA4ODIyZTgyMzZjYmNhN2NlODdjZjk0NjBlMjY3ZWE0ZWNkNTQzYTAyMjFlNDE0NmE1NTc1ZjA4ZTkyYWE0NmM2YTc1ZjBlYSIsImlhdCI6MTcxOTgxNTI2NC43NDcxNDMsIm5iZiI6MTcxOTgxNTI2NC43NDcxNTEsImV4cCI6MTc1MTM1MTI2NC43MzQxNTcsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.d5Vk5mqWxr1CMXrV45qHvwLWygjTqf9HsV-jt2XOD_iIdWehrRCLOVaCewHGWTs493UkI8TlAeL_3g2zci-JSUVsfN2GnU-HxuTwWdNRdIf2E64d1GXH3olWVUEuyPpdaWSyqYGRT33zi9Z2blrY1hFULrGNWe2P1c5ksntTbmn5U8XDX3KIGTBkmhy9U0G3hMPfSIPIi96bdKIVi1LMKOTu6y3p6Pxk9k5doMX82qRlMyAQV8_hLs3P8MGTijL3j3Drqub_NhhTx5xafg3KuKeFHrmQUqF2Ddtx9fLHB-EEch_LnEG3ZVtD2ThObXrKcBxgwgQesa5GHNpFP555JElBfw3Cgk-rIHVWflFzUZsaK7FmD3EH_qkg91OR0TUy0snvD_FqhEPDDbuLwW78JWhqPhjz8X8q1FsgtonN4uYB2gZa1tHWMopWm8TItaK9EO1TNM-CHsQPFS-hiBT8Ul6bcngIiY-HPGH6GXz6oWI896K9KPHUjW1JSNI2sA5Jyg9I7Gt5ccDhyuGLYtxvPPujfKDctbeQbMQomBMHWxSfwpvYHMQ3tqlpTrkdYL6HGBa5mTto6-glwQ06TXYCZPsYvquAzXg43jiS1Uu7gqYMcQT57oLhfpFCmC5KV3KiX90eISuId6_z0awIb7ZfCjXmEvtKzK9RZbOIepeo9Gc"
    );

    const formdata = new FormData();
    if (isCreatingAccount) {
      formdata.append("name", formData.name);
      formdata.append("email", formData.email);
      formdata.append("password", formData.password);
      formdata.append("c_password", formData.confirmPassword);
      formdata.append("account_id", "1");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/customers/sign-up`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        // console.log("Customer Registration API response:", result);

        if (result) {
          showSuccessToast(result.data.message || "Registration Successful");
          localStorage.setItem("customerData", JSON.stringify(formData));
          setIsCreatingAccount(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        showErrorToast("Check Credentials");
      }
    } else {
      formdata.append("email", formData.email);
      formdata.append("password", formData.password);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/customers/login`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        // console.log("Customer Login API response:", result);

        if (result) {
          // alert("test")
          // Save token to localStorage after successful login
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("userType", "customer");
          // Navigate to the homepage
          showSuccessToast(result.data.message || "Login Successful");
          localStorage.setItem("customerData", JSON.stringify(formData));
          fetchCustomerDetails(result.data.token);
          // navigate("/customer-dashboard");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        showErrorToast("Unauthorized access");
      }
    }
  };

  const registerCustomer = async (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "key", process.env.REACT_APP_BASE_API_KEY
    );
    myHeaders.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );

    const raw = JSON.stringify({
      type: "customer",
      name: formData?.name,
      email: formData?.email,
      callbackUrl: "https://mountainmysteries.com"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/register`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        showSuccessToast(result.message);
        setFormData({
          name: '',
          email: ''
        })

      } else {
        showErrorToast(result.message)
      }
    } catch (error) {
      showErrorToast('Error registering Customer')
      console.error("Error registering user:", error);
    } finally {
      setLoading(false);
    }
  };

  const loginCustomer = async (e) => {
    e.preventDefault();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "key", process.env.REACT_APP_BASE_API_KEY
    );
    myHeaders.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );

    const raw = JSON.stringify({
      type: "customer",
      email: formData?.email,
      password: formData?.password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/login`,
        requestOptions
      );
      const result = await response.json();

      if (result?.success) {
        showSuccessToast(result.message || "Login Successful");
        localStorage.setItem("token", result.data.token);
        saveEncryptedData("userToken", result.data.token);
        localStorage.setItem("userType", "customer");
        localStorage.setItem("customerData", JSON.stringify(result.data));
        fetchCustomerDetails(result.data.token);
      } else {
        showErrorToast(result.message || "Login Failed");
      }
      if (!result?.success) {
        showErrorToast(result.message || "Login Failed");
      }
    } catch (error) {
      showErrorToast('Login Failed')
      console.error("Error Login customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleconfirmpasss = async (event) => {
    event.preventDefault();
  };

  const handleAgentSubmit = async (event) => {
    event.preventDefault();

    if (isCreatingAccount && formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YzZhZTMyZS01N2U1LTRiNTktYTNiZS1lN2I1NTU5NTUyNTQiLCJqdGkiOiI5OGQ3MDk5MjRlYjkxZWYwYWYwMWRiOWRjMzRiMDdmYjk0MmFhZjBhNTkxMGRlMTE5OWY2MzUyMDE1NzBkZDg5ODRiMTI3YTE2YzI3YTZkOCIsImlhdCI6MTcyMDQ0MDQ5Ny41ODg1ODIsIm5iZiI6MTcyMDQ0MDQ5Ny41ODg1ODgsImV4cCI6MTc1MTk3NjQ5Ny41Njg5NTYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.yUfgInQFlDZPGpK8oAy5W8IG1KYZyRjBiB8G2Kz2I9doQBxJvxCiJR4HQ1HlBhx8-R9EL1EzvJzAOfhhe3Sxoz0YIJEhrXUTx_6kHuk5xIt2Ky1G6XCP_M3jPVf7y4yv8fJBPEgPILGOSKVPQI9kizWu_vozz4_hkg3-1oMDEYTUiDyt-YlyM688ckprCp7ScSL8irSKxLDcv5su4ssa_T2Ln7WpDVEcTa2O0Sa41IepEhQg8jGpja10QbPlU2vM2zreYcxsmSELzZgFCAbJqVc9oNnYIi-qY19baj3mmze-8hB796gLyysnBWrPflXUjXl9fC_ojK33Mx-MOH_62txvyAV3NjKFiCq6Hn9-68f4gi_TN67H7yUf9ZiX7SoH6WtiwKfIP2_rhnyt3Fj8xZGGisAE9POn6VrwoIoz5kXYGFfhpwYNkw3CZ1M8XWw3yq-DvQveAusONPLrKhkdmJXVBZKwCYq7dARUfqBgfT_TOOzCTTblwI4UFgdYhF8ihLcdkg-vmSRK5IT-c_pHolbDx8TGvbRZ1vyebltu7xKkHN4NbksCxRRYfMAFhm8geFGTdfSlMx0v4-6VDPDW1zAe1_6tsYhvyDbgUuVTdR9UVhSY5XOFnNsVZ3ph5VvYvIs3hjACWKeKiXUWHwgDa0pm4FBsdy1knZRCbmNanmE"
    );

    const formdata = new FormData();
    if (isCreatingAccount) {
      formdata.append("name", formData.name);
      formdata.append("email", formData.email);
      formdata.append("password", formData.password);
      formdata.append("c_password", formData.confirmPassword);
      formdata.append("account_id", "1");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/agents/sign-up`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        // console.log("Agent Registration API response:", result);

        // Save token to localStorage after successful registration
        localStorage.setItem("token", result.data.token);

        if (result) {
          showSuccessToast(result.data.message || "Registration Successful");
          setIsCreatingAccount(false);
          localStorage.setItem("agentData", JSON.stringify(formData));
        }

        // Handle API response as needed
      } catch (error) {
        showErrorToast("Something went wrong", error.message);
      }
    } else {
      formdata.append("email", formData.email);
      formdata.append("password", formData.password);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/agents/login`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result?.success) {
          // console.log("Agent Login API response:", result.data.token);

          // Save token to localStorage after successful login
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("agentData", JSON.stringify(formData));
          localStorage.setItem("userType", "agent");
          fetchAgentDetails(result.data.token);

          showSuccessToast("Login Successful");
        } else {
          showErrorToast(result.data.message || "Login Failed");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        showErrorToast("Unauthorized access");
      }
    }
  };

  const handleResetPasswordAgent = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const email = formData.email; // Assuming there's an input field with ID 'email'

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/agent/send-reset-link`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      // console.log(result);
      showSuccessToast("Password reset link has been sent to your email.");
    } catch (error) {
      console.error(error);
      showErrorToast(
        "An error occurred while trying to reset the password. Please try again."
      );
    }
  };

  const [resetEmail, setResetEmail] = useState("");

  // const handleResetPasswordCustomer = async (event) => {
  //   event.preventDefault(); // Prevent the default form submission

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ email: resetEmail }),
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_APP_URL}/api/customer/send-reset-link`,
  //       requestOptions
  //     );
  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.statusText}`);
  //     }
  //     const result = await response.json();
  //     // console.log(result);
  //     onClose();
  //     showSuccessToast("Password reset link has been sent to your email.");
  //   } catch (error) {
  //     console.error(error);
  //     showErrorToast(
  //       "An error occurred while trying to reset the password. Please try again."
  //     );
  //   }
  // };

  // console.log(formData);

  const handleClosePopup = (e) => {
    onClose();
  };

  const handleResetPasswordCustomer = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);

    const raw = JSON.stringify({
      email: resetEmail,
      type: "customer",
      callbackUrl: "https://www.mountainmysteries.com/reset-password/customer"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/send-password-reset-link`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result.success) {
        showSuccessToast(result.message || "Please Check Your Email.");
      } else {
        showErrorToast(result.message || "Password Reset Failed");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      showErrorToast("Password Reset Failed");
    }
  };

  return (
    <section id="login_form">
      <div style={{ position: "relative" }}>
        <div className="customer_form">
          <div onClick={handleClosePopup} className="closeBtn">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 13L13 1" stroke="#000" strokeWidth="1.2" />
              <path d="M13 13L1 1" stroke="#AAAAAA" strokeWidth="1.2" />
            </svg>
          </div>
          <img src="/login.png" alt="Logo" className="login-img" />
          <div>
            <div className="login_filter_btn">
              <button
                className={`filter_form_btn ${activeForm === "customer" ? "active" : ""
                  }`}
                onClick={() => switchForm("customer")}
              >
                Customer
              </button>
              {/* <button
                className={`filter_form_btn ${activeForm === "agent" ? "active" : ""
                  }`}
                onClick={() => switchForm("agent")}
              >
                B2B Agent
              </button> */}
            </div>

            {activeForm === "customer" && (
              <form
                className={`login-form-customer customer ${activeForm === "customer" ? "active" : ""
                  }`}
              // onSubmit={handleCustomerSubmit}
              >
                {isCreatingAccount ? (
                  <>
                    <div>
                      <label htmlFor="name">Your Name</label>
                      <input
                        className="w-full"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        style={
                          formErrors.name && {
                            backgroundColor: "#ff000040",
                            border: "1px solid red",
                          }
                        }
                      />
                    </div>
                    <div >
                      {/* <div>
                        <label htmlFor="phone">Phone</label>
                        <input
                          className="w-full"
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          style={
                            formErrors.phone && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div> */}
                      <div>
                        <label htmlFor="email">Email Address</label>
                        <input
                          className="w-full"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          style={
                            formErrors.email && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="input-flex">
                      <div>
                        <label htmlFor="password">Password</label>
                        <input
                          className="w-full"
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          style={
                            formErrors.password && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          className="w-full"
                          type="password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                          style={
                            formErrors.confirmPassword && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                    </div> */}
                    <div className="button">
                      <button
                        type="button"
                        onClick={(e) => registerCustomer(e)}
                        className="common_btn m_0_auto"
                      >
                        {resetForm ? "Register" : "Register"}
                      </button>
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Already have an account?{" "}
                        <button
                          type="button"
                          onClick={showLoginAccount}
                          style={{ color: "#016937" }}
                        >
                          Log in
                        </button>
                      </small>
                    </div>
                  </>
                ) : resetForm ? (
                  <>
                    <div>
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="w-full"
                        type="email"
                        placeholder="Enter Email ID"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                      />
                    </div>
                    <div className="button">
                      <button
                        onClick={handleResetPasswordCustomer}
                        className="common_btn m_0_auto"
                      >
                        CONTINUE
                      </button>
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Already have an account?{" "}
                        <button
                          className=""
                          onClick={showCreateAccount}
                          style={{ color: "#016937" }}
                        >
                          Create account
                        </button>
                      </small>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="w-full"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter Email ID"
                        style={
                          formErrors.email && {
                            backgroundColor: "#ff000040",
                            border: "1px solid red",
                          }
                        }
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <label htmlFor="password">Password</label>
                      <input
                        className="w-full"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder="Enter Password"
                        style={
                          formErrors.password && {
                            backgroundColor: "#ff000040",
                            border: "1px solid red",
                          }
                        }
                      />
                      {showPassword ? (
                        <svg
                          onClick={() => setShowPassword(false)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M2 2L22 22"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          onClick={() => setShowPassword(true)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 14 10"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_298)">
                            <path
                              d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                              fill="#888888"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_298">
                              <rect width="14" height="10" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </div>
                    <div className="button">
                      <button
                        onClick={(e) => loginCustomer(e)}
                        type="submit"
                        className="common_btn m_0_auto"
                      >
                        LOGIN
                      </button>
                    </div>
                    <div className="mt-10 text-center">
                      <small>
                        Forgot your password?{" "}
                        <button
                          className=""
                          onClick={showResetForm}
                          style={{ color: "#016937" }}
                        >
                          Reset Here
                        </button>
                      </small>
                    </div>
                    <div className="flex mt-10 align_items_center">
                      <hr />
                      <small>or</small>
                      <hr />
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Don’t have an account?{" "}
                        <button
                          className=""
                          onClick={showCreateAccount}
                          style={{ color: "#016937" }}
                        >
                          Create account
                        </button>
                      </small>
                    </div>
                  </>
                )}
              </form>
            )}

            {activeForm === "agent" && (
              <div
                className={`login-form-business business ${activeForm === "business" ? "active" : ""
                  }`}
                onSubmit={handleAgentSubmit}
              >
                {isCreatingAccount ? (
                  <>
                    <div>
                      <label htmlFor="name">Agent Name</label>
                      <input
                        className="w-full"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter Agent Name"
                        style={
                          formErrors.name && {
                            backgroundColor: "#ff000040",
                            border: "1px solid red",
                          }
                        }
                      />
                    </div>
                    <div className="input-flex">
                      <div>
                        <label htmlFor="phone">Phone</label>
                        <input
                          className="w-full"
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          placeholder="Enter Phone Number"
                          style={
                            formErrors.phone && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="email">Email Address</label>
                        <input
                          className="w-full"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="Enter Email ID"
                          style={
                            formErrors.email && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="input-flex">
                      <div>
                        <label htmlFor="password">Password</label>
                        <input
                          className="w-full"
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          style={
                            formErrors.password && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          className="w-full"
                          type="password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleInputChange}
                          style={
                            formErrors.confirmPassword && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="button">
                      <button type="submit" className="common_btn m_0_auto">
                        {resetForm ? "CONTINUE" : "CONTINUE"}
                      </button>
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Already have an account?{" "}
                        <button
                          style={{ color: "#016937" }}
                          className=""
                          onClick={() => setIsCreatingAccount(false)}
                        >
                          Log in
                        </button>
                      </small>
                    </div>
                  </>
                ) : resetForm ? (
                  <>
                    <div>
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="w-full"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter Email ID"
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        onClick={handleResetPasswordAgent}
                        type="submit"
                        className="common_btn m_0_auto"
                      >
                        CONTINUE
                      </button>
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Already have an account?{" "}
                        <button
                          style={{ color: "#016937" }}
                          className=""
                          onClick={showCreateAccount}
                        >
                          Create account
                        </button>
                      </small>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <label htmlFor="email">Email Address</label>
                      <input
                        className="w-full"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter Email ID"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="password">Password</label>
                      <input
                        className="w-full"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder="Enter Password"
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        onClick={handleAgentSubmit}
                        type="button"
                        className="common_btn m_0_auto"
                      >
                        CONTINUE
                      </button>
                    </div>
                    <div className="mt-10 text-center">
                      <small>
                        Forgot your password?{" "}
                        <button
                          className=""
                          onClick={showResetForm}
                          style={{ color: "#016937" }}
                        >
                          Reset Here
                        </button>
                      </small>
                    </div>
                    <div className="flex mt-10 align_items_center">
                      <hr />
                      <small>or</small>
                      <hr />
                    </div>
                    <div className="text-center mt-10">
                      <small>
                        Don’t have an account?{" "}
                        <button
                          style={{ color: "#016937" }}
                          className=""
                          onClick={showCreateAccount}
                        >
                          Create account
                        </button>
                      </small>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
