import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GooglePlaceApi from "../GoogleApi/GooglePlaceApi/GooglePlaceApi.jsx";
import Flatpickr from "react-flatpickr";
import PackagesStateInput from "../PackagesStateInput/PackagesStateInput.jsx";

function PackageSearchForm({ searchType }) {
  const [modifyPkg, setModiyPkg] = useState(false);
  const [mobileHead, setMobileHead] = useState(false);
  const [dataFromChild, setDataFromChild] = useState("");

  const toggleModify = () => {
    setModiyPkg(!modifyPkg);
  };
  const [guestDetails, setIsguestDetails] = useState(false);
  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [data, setData] = useState({ fromCity: "", toCity: "" });
  // console.log(data, "Location");

  const showErrorToast = (message) => {
    alert(message);
  };
  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = Number(children) - 1;
    setChildren(updatedChildren);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = Number(children) + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      radio: params.get("radio"),
      childAge: childAges,
    };

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
      // toCity: params.get("location"),
    });

    setGuest(data.guest);
    setChildren(data.children);
    setRooms(data.room);
    setChildAge(childAges);

    setBookingData(data);
    // postBookingData(data);
  }, [location.search]);

  const navigate = useNavigate();

  // STATES
  const [selectedRadio, setSelectedRadio] = useState("");

  //
  const [guest, setGuest] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);
  // console.log(children, "children");

  // Date alterations
  const [loader, setLoader] = useState(false);

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };

  const getDataFromChild = (data, resultArray) => {
    setDataFromChild(data);
  };
  // console.log(dataFromChild, "getDataFromChild")

  const handleSubmitButtonClickHandler = async (e) => {
    // console.log("fghjgkhllj");

    setIsguestDetails(false);
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    // if (endDate <= startDate) {
    //   alert("Check out must be a date after check in");
    //   return;
    // }

    setLoader(true);
    setModiyPkg(false);

    try {
      await navigate(
        `/listing-holidays?hotelID=1&location=${dataFromChild}&guest=${guest}&children=${children}${childAgeParams}&radio=${selectedRadio}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
      setModiyPkg(false);
    } finally {
      setLoader(false);
    }
  };

  const [filteredData, setFilteredData] = useState({
    location: "",
    rooms: "",
    adults: "",
    children: "",
    radio: "",
    childAge: [],
  });

  useEffect(() => {
    setFilteredData({
      location: data.fromCity,
      guest: guest,
      radio: selectedRadio,
    });
  }, [data.fromCity, rooms, guest, children, selectedRadio, childAge]);

  // console.log(filteredData);

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setMobileHead(true);
    } else {
      setMobileHead(false);
    }
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setIsguestDetails(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <section className="checkout-hotels">
      <div className="test_form" id="singleSearch">
        <div className="container">
          <form
            action=""
            className="SingleSearch-mainform test_form main-form"
            id="main-form"
            onSubmit={handleSubmitButtonClickHandler}
          >
            {mobileHead ? (
              <>
                <div className="SingleSearch-Resp flex_align_center justify_content_between">
                  <div className="flex_align_center">
                    <span onClick={() => window.history.back()}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                          fill="#333333"
                        />
                      </svg>
                    </span>
                    <div className="SingleSearch-resp_content">
                      <h4>
                        {data?.fromCity ? (
                          capitalizeFirstLetter(data.fromCity)
                        ) : (
                          <p>Enter a destination</p>
                        )}
                      </h4>
                      <div>
                        <span>{guest} Guests </span>
                        <span id="child_count"> {children} </span>
                        <span> Children </span>
                      </div>
                    </div>
                  </div>
                  <div onClick={toggleModify}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_2797_7470"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2797_7470)">
                        <path
                          d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                          fill="#333333"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                {modifyPkg && (
                  <>
                    <div>
                      <label htmlFor="">City/Destination</label>
                      {/* <GooglePlaceApi
                        setSelectedLocation={setSelectedLocation}
                        data={data}
                        defaultValue={data?.fromCity && data.fromCity}
                        setData={setData}
                        showErrorToast={showErrorToast}
                        position={"SingleSearchForm"}
                      /> */}
                      <PackagesStateInput onDataChange={getDataFromChild} />
                    </div>
                    <div className="search_form_div p_0 icon_button_only">
                      <label
                        onClick={dropdownGuestDetails}
                        className="search_label"
                      >
                        Guests{" "}
                        <svg
                          width="9"
                          height="5"
                          viewBox="0 0 9 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.25 0.75L4.75 4.25L1.25 0.75"
                            stroke="#BB735D"
                          />
                        </svg>
                      </label>
                      <p
                        id="guest_input"
                        style={{ position: "relative" }}
                        className="SingleSearch-Guests dropdown-toggle search_form_input testform_input"
                      >
                        <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                          <div className="optionCounter"></div>
                          <span id="adult_count">{guest}</span>
                          <span> Guests</span>
                          <span id="child_count"> {children}</span>
                          <span> Children</span>
                        </div>
                        {guestDetails && (
                          <div
                            style={{ left: "0px" }}
                            className="guest_details_dropdown"
                            id="guestDetailsPopup"
                            ref={popupsRef}
                          >
                            <div className="agent">
                              <span>Guests</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => setGuest(Number(guest) - 1)}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={guest}
                                  min={1}
                                  type="number"
                                  onChange={(e) => setGuest(e.target.value)}
                                />

                                <button
                                  type="button"
                                  // disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => setGuest(Number(guest) + 1)}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="agent">
                              <span> Children</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={children <= 0}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildDecrease}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={children}
                                  min={1}
                                />
                                <button
                                  type="button"
                                  // disabled={children <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildIncrease}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="search-btn-width">
                      <button className="common_btn" type="submit">
                        SEARCH
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div>
                  <label htmlFor="">City/Destination</label>
                  {/* <GooglePlaceApi
                    setSelectedLocation={setSelectedLocation}
                    data={data}
                    defaultValue={data?.fromCity && data.fromCity}
                    setData={setData}
                    showErrorToast={showErrorToast}
                    position={"SingleSearchForm"}
                  /> */}
                  <PackagesStateInput onDataChange={getDataFromChild} />
                </div>

                <div className="search_form_div p_0 icon_button_only">
                  <label
                    onClick={dropdownGuestDetails}
                    className="search_label"
                  >
                    Guests{" "}
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 0.75L4.75 4.25L1.25 0.75"
                        stroke="#BB735D"
                      />
                    </svg>
                  </label>
                  <p
                    id="guest_input"
                    style={{ position: "relative" }}
                    className="SingleSearch-Guests dropdown-toggle search_form_input testform_input"
                  >
                    <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                      <div className="optionCounter"></div>
                      <span id="adult_count">{guest}</span>
                      <span> Guests</span>
                      <span id="child_count"> {children} </span>
                      <span> Children</span>
                    </div>
                    {guestDetails && (
                      <div
                        style={{ left: "0px" }}
                        className="guest_details_dropdown"
                        id="guestDetailsPopup"
                        ref={popupsRef}
                      >
                        <div className="agent">
                          <span>Guests</span>
                          <div>
                            <button
                              type="button"
                              disabled={guest <= 2}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={() => {
                                if (guest <= 2) return;
                                setGuest(Number(guest) - 1);
                              }}
                            >
                              <span className="onclick_btn" id="adult_minus">
                                -
                              </span>
                            </button>

                            <input
                              className="optionCounterNumber"
                              value={guest}
                              min={1}
                              type="number"
                              onChange={(e) => setGuest(e.target.value)}
                            />

                            <button
                              type="button"
                              disabled={guest >= 11}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={() => {
                                if (guest >= 11) return;
                                setGuest(Number(guest) + 1);
                              }}
                            >
                              <span className="onclick_btn" id="adult_plus">
                                +
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="agent">
                          <span> Children</span>
                          <div>
                            <button
                              type="button"
                              disabled={children <= 0}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={onChildDecrease}
                            >
                              <span className="onclick_btn" id="adult_minus">
                                -
                              </span>
                            </button>

                            <input
                              className="optionCounterNumber"
                              value={children}
                              min={1}
                            />
                            <button
                              type="button"
                              // disabled={children <= 1}
                              className="optionCounterButton optionCounterButtonWithBorder"
                              onClick={onChildIncrease}
                            >
                              <span className="onclick_btn" id="adult_plus">
                                +
                              </span>
                            </button>
                          </div>
                        </div>
                        {childAge?.map((age, index) => (
                          <div className="navbar agent">
                            <div>
                              <span>Child Age</span>
                            </div>
                            <div>
                              <input
                                className="childAge_input"
                                type="number"
                                name="childAge[]"
                                min="1"
                                value={age}
                                onChange={(event) =>
                                  handleChildrenAgeChange(index, event)
                                }
                                required
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </p>
                </div>

                <div className="search-btn-width">
                  <button className="common_btn" type="submit">
                    SEARCH
                  </button>
                </div>
                {/* <div className="search-btn-width">
                    <button className="common_btn" onClick={clearAllFilters}>
                      Clear
                    </button>
                  </div> */}
              </>
            )}
          </form>
        </div>
        <input type="hidden" name="hotelId" value="{{ $hotelId }}" />
      </div>
    </section>
  );
}

export default PackageSearchForm;
