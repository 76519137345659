import React, { useEffect, useState } from "react";
import { Link, Navigate, parsePath, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import ItInerarySlider from "../../CustomComponents/ItInerarySlider/ItInerarySlider";
import {
  DateFormatMoment,
  calculateToDatePlus,
  currentDateSingle,
  formatDate,
  formatIndianCurrency,
  scrollToTop,
  textDateFormatter,
} from "../../utils/utils";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import AdultDetailsInPackage from "../../CustomComponents/AdultDetailsInPackage/AdultDetailsInPackage";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import ItnerarySideBar from "../../CustomComponents/ItnerarySideBar/ItnerarySideBar";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import NTTData from "../../CustomComponents/PaymentGateway/NTTData";
import FullWidthLoader from "../../CustomComponents/Loader/FullWidthLoader";

function CheckOutPackage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [isItineraryDetailsVisible, setIsItineraryDetailsVisible] =
    useState(false);
  const [bookingData, setBookingData] = useState({});
  const [guestDetails, setGuestDetails] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(false);
  const [customizableRatesLoader, setCustomizableRatesLoader] = useState(false);

  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
  });

  const userType = localStorage.getItem("userType");

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userToken = localStorage.getItem("token");

  const [guestDetailsArray, setGuestDetailsArray] = useState([]);
  const [loginWithGoogleCustomer, setLoginWithGoogleCustomer] = useState(null);

  const [allGuestDetailsAdd, setAddGuestDetailsAdd] = useState(true);

  const [guestData, setGuestData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    pincode: "",
    guest_city: "",
    guest_address: "",
    gst_number: "",
    company_number: "",
    admin_email: "",
    business_email: "",
    company_address: "",
  });

  const [formData, setFormData] = useState({
    amount: bookingValues.grandTotal,
    guest_name: guestData.guest_name,
    guest_phone: guestData.guest_phone,
    guest_email: guestData.guest_email,
    pincode: guestData.pincode,
    guest_state: "stateInputValue",
    guest_country: "countryInputValue",
    guest_city: guestData.guest_city,
    guest_address: guestData.guest_address,
    visitDate: "checkInDate",
  });

  const guestDataHandleChange = (event) => {
    const { name, value } = event.target;
    setGuestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);

  const {
    packageData: singlePackageData,
    // bookingData,
    selectedCategory,
    calculationsOfPackages,
    hotelDayByDay,
    vehicleDetails,
    customized,
    ratesOfPackages,
    selectedAddOns,
    markupData,
    selectedMealPlan,
    allPackageHotels,
    commission,
    // roomsDetails,
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    selectedAutomaticDiscount,
    selectedDiscount
  } = location?.state || {};

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const handleAddressData = (data) => {
    setAddressData(data);
  };

  const toggleBusinessProfile = () => {
    setBusinessProfile(!businessProfile);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      packageId: params.get("packageId"),
      packagehandle: params.get("packagehandle"),

      departureDay: params.get("checkinDay"),
      departureMonth: params.get("checkinMonth"),
      departureYear: params.get("checkinYear"),
      // tourDate : params.get("checkinYear")-params.get("checkinMonth")-params.get("checkinDay")
    };
    const checkInDate = `${data.departureYear}-${String(
      data.departureMonth
    ).padStart(2, "0")}-${String(data.departureDay).padStart(2, "0")}`;

    // setGuest(data.guest);
    // setChildren(data.children);
    // setRooms(data.room);
    // setChildAge(childAges);

    if (data.checkinYear && data.checkinMonth && data.checkinDay) {
      setBookingData({ ...data, tourDate: checkInDate });
    } else {
      setBookingData({ ...data, tourDate: currentDateSingle });
    }

    // setStartDate(checkInDate);
    // setEndDate(checkOutDate);
    // setBookingData({...data, tourDate: checkInDate});
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const handleChangeClick = async () => {
    setIsSliderVisible(true);
  };

  const toggleGuestDetails = () => {
    setGuestDetails(!guestDetails);
  };

  const [atomAccessToken, setAtomAccessToken] = useState(null);
  const [packageBookingData, setPackageBookingData] = useState(null);

  const sendDataToParent = (data, type) => {
    setGuestDetailsArray(data);
    setAddGuestDetailsAdd(type);
  };

  const under6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) < 6
  );

  const over6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) >= 6
  );

  // payment Gateway
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);
  const [paymentGatewayLoader, setPaymentGatewayLoader] = useState(false);

  const fetchPaymentGateway = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/fetch/active`,
        {
          method: "GET",
          headers: headers,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setPaymentGateways(result.data);

      const firstActiveGateway = result.data.find(
        (gateway) => gateway.gatewayName === "NTTData"
      );
      if (firstActiveGateway) {
        setSelectedPaymentGateway(firstActiveGateway);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaymentGatewayChange = (item) => {
    setSelectedPaymentGateway(item);
  };

  useEffect(() => {
    fetchPaymentGateway();
  }, []);

  const bookPackageHandle = async () => {
    setPaymentGatewayLoader(true);

    const formattedDestination = singlePackageData?.packageDestination?.map(
      (destination) => ({
        id: destination.destinationId,
      })
    );

    let childrenLessThanSix = 0;
    let childrenGreaterThanSix = 0;

    if (bookingData.children && parseInt(bookingData.children) > 0) {
      childrenLessThanSix = bookingData.childAge.filter(
        (age) => parseInt(age) < 6
      ).length;
      childrenGreaterThanSix = bookingData.childAge.filter(
        (age) => parseInt(age) > 6
      ).length;
    }

    const transformedData = singlePackageData?.packageItinerary?.map(
      (item, index) => ({
        day: item.day,
        start_destination: item.startDestination,
        end_destination: item.endDestination,
        pickup: item.pickup,
        drop: item.drop,
        heading_information: item?.additionalDetails?.heading,
        description_information: item.additionalDetails?.long_description,
        accommodations: item.accommodation.map((acc) => {
          return {
            accommodation_id: acc.accommodationId,
            name: acc.name,
            destination: acc.city,
            hotel_category: acc.hotelcategory,
            star_rating: acc.starRating,
            featured_image: acc.featuredImage,
            supplier_id: acc.supplierId ? acc.supplierId : "",
            room_id: acc?.roomDetails[0]?.roomId ? acc?.roomDetails[0]?.roomId : "",
            room_type: acc?.roomDetails[0]?.name ? acc?.roomDetails[0]?.name : "As per package",
            // room_cost: acc.room_cost ? acc.room_cost : 500.00,
            meal_plan: ratesOfPackages.MealPlan ? ratesOfPackages.MealPlan : "ep",
            rooms: acc.rooms ? acc.rooms : "1",
            nights: acc.nights ? acc.nights : 1,
            adults: bookingData.guest,
            extra_adult: ratesOfPackages.ExtraAdultCount
              ? ratesOfPackages.ExtraAdultCount
              : 0,
            child_w_bed: childrenLessThanSix,
            child_wo_bed: childrenGreaterThanSix,
            extra_adult_cost: ratesOfPackages.ExtraAdult
              ? ratesOfPackages.ExtraAdult
              : 0,
            child_w_bed_cost: ratesOfPackages.ChildWithBedCost
              ? ratesOfPackages.ChildWithBedCost
              : 0,
            child_wo_bed_cost: ratesOfPackages.ChildWithOutBedCost
              ? ratesOfPackages.ChildWithOutBedCost
              : 0,
            adhoc: false,
            check_in: calculateToDatePlus(bookingData?.tourDate, index),
            check_out: calculateToDatePlus(bookingData?.tourDate, index + 1),
          }
        }),
        activities: item.activity.map((act, actIndex) => {
          const ratesArray = act?.rates;
          let accPrice = ratesArray?.find(
            (rate) => Number(rate?.pax) === Number(bookingData?.guest)
          )?.amount;

          if (!accPrice && ratesArray?.length > 0) {
            accPrice = ratesArray[0]?.amount;
          }

          return {
            activity_id: act.activityId,
            name: act.name,
            featured_image: act.featuredImage,
            supplier_id: act?.vendorId,
            destination: act.destinationName,
            validity: calculateToDatePlus(bookingData?.tourDate, actIndex),
            time: "10:00 AM",
            pax: bookingData?.guest,
            price: accPrice,
            adhoc: false,
          };
        }),
      })
    );

    const automaticDiscountToSend = (selectedAutomaticDiscount || []).map((item) => ({
      rate: item?.promotionMode || "",
      amount: item?.promotionAmount || 0,
    }));

    const selectedDiscountToSend = (selectedDiscount || []).map((item) => ({
      rate: item?.promotionMode || "",
      amount: item?.promotionAmount || 0,
    }));

    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
        Cookie: process.env.REACT_APP_COOKIE,
      };

      const raw = JSON.stringify({
        name: singlePackageData?.packageName,
        booking_type: "package",
        customer_type: userType === "agent" ? "agent" : "customer",
        customer_name: guestDetailsArray[0]?.guest_name,
        customer_email: guestDetailsArray[0]?.guest_email,
        customer_phone: guestDetailsArray[0]?.guest_phone,
        customer_address: guestDetailsArray[0]?.guest_address,
        customer_country: guestDetailsArray[0]?.guest_country,
        customer_state: guestDetailsArray[0]?.guest_state,
        customer_city: guestDetailsArray[0]?.guest_city,
        customer_zipcode: guestDetailsArray[0]?.zipcode,
        start_date: bookingData?.tourDate,
        end_date: calculateToDatePlus(
          bookingData?.tourDate,
          singlePackageData?.totalDays
        ),
        pickup_point: "City Center",
        drop_point: "Airport",
        nights: Number(singlePackageData?.totalNights),
        days: Number(singlePackageData?.totalDays),
        adults: Number(bookingData?.guest),
        children: Number(bookingData?.children),
        total: calculationsOfPackages?.basePrice,
        grand_total: calculationsOfPackages?.totalAmount,
        status: "hold",
        accommodation_notes_information: "",
        activity_notes_information: "",
        transport_notes_information: "",
        misc_details: [],
        tax_details: [
          {
            rate: 5,
            amount: calculationsOfPackages?.taxAmount,
          },
        ],
        discount_details_information: [
          ...(automaticDiscountToSend || []),
          ...(selectedDiscountToSend || []),
        ],
        markup_details: [
          {
            type: markupData?.markupType,
            rate: 0,
            amount: markupData?.amount,
          },
        ],
        itineraries: transformedData,
        addons: selectedAddOns?.map((addon) => ({
          account_addon_id: addon.addonId,
          name: addon.detail,
          price: addon.finalAddonPrice ? addon.finalAddonPrice : addon.price,
        })),
        transports: [],
        destinations: formattedDestination,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/booking/create`,
        {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      );

      const result = await response.json();
      if (result.success) {
        setPackageBookingData(result.data);
        // processPayment(result.data);
        setStartPayment(true);
        setPaymentGatewayLoader(false);
      } else {
        showErrorToast(result.message);
        setPaymentGatewayLoader(false);
      }
    } catch (error) {
      console.error("Error creating package booking:", error);
    }
  };

  // NTT Data
  const [startPayment, setStartPayment] = useState(false);
  const mainData = [
    {
      apiStartValue: startPayment,
      selectedPaymentGateway: selectedPaymentGateway?.gatewayName,
      order_amount: packageBookingData?.grandTotal,
      order_number: packageBookingData?.bookingNo,
      order_id: packageBookingData?.id,
      email: packageBookingData?.customerDetails?.email,
      phone: packageBookingData?.customerDetails?.phone,
      callbackUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${packageBookingData?.id}`,
      custEmail: packageBookingData?.customerDetails?.email,
      custMobile: packageBookingData?.customerDetails?.phone,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${packageBookingData?.id}`,
    },
  ];

  return (
    <div id="checkOutHotels">
      {paymentGatewayLoader && <FullWidthLoader />}
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <Header />
      {/* <ReviewReservationBanner id="test" heading="Review Reservation Details" /> */}

      {/* <SingleSearchForm /> */}
      {/* <PageLinks
        links={[
          "Home",
          `Properties In shimla`,
          `Woodland`,
        ]}
      /> */}
      <section>
        <div className="container">
          <main className="justify_content_center align_items_start">
            <div className="reservation-content">
              <div className="package-booking-header">
                <div className="package-update-cards">
                  <div
                    className="package-card-body day-container"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      <div className="checkout-package-header-top">
                        <div className="checkout-package-header-left">
                          <div className="flex justify_content_between align_items_center flex_gap_10">
                            <div onClick={() => [navigate(-1), scrollToTop()]}>
                              <span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                                    fill="#333333"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <h4>
                              {singlePackageData?.packageName}{" "}
                              <span
                                style={{ paddingLeft: "5px", fontSize: "10px" }}
                              >
                                {singlePackageData?.totalNights} Nights /{" "}
                                {singlePackageData?.totalDays} Days
                              </span>
                            </h4>
                          </div>
                        </div>
                        {/* <div className="right">
                          <a className="/cancellation-policy" href="#send-query-modal2" style={{ color: "#BB735D", fontSize: "12px" }}>

                            Cancellation Policy</a>
                        </div> */}
                      </div>
                    </div>

                    <div className="package-update-cards">
                      <div
                        className="package-card-body checkout-day-container flex justify_content_between "
                        style={{
                          textAlign: "start",
                          borderBottom: "1px solid #ccc",
                          flexWrap: "wrap",
                        }}
                      >
                        {/* <div className="day-containers">
                          <div className="itinerary-column">
                            <div className="day-itinerary">
                              <h4>From City</h4>
                              <p><span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{bookingData?.location}</span> </p>
                            </div>
                          </div>
                        </div> */}
                        <div className="day-containers">
                          <div className="itinerary-column">
                            <div className="day-itinerary">
                              <h4>Tour Date</h4>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  {/* {bookingData?.tourDate} */}
                                  {DateFormatMoment(bookingData?.tourDate)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="day-containers">
                          <div className="itinerary-column">
                            <div className="day-itinerary">
                              <h4>Guests & Children</h4>
                              <p>
                                {" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {bookingData?.guest}
                                </span>{" "}
                                Guests &{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {bookingData?.children === "null"
                                    ? 0
                                    : bookingData?.children}
                                </span>{" "}
                                Children
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="day-containers">
                          <div className="itinerary-column">
                            <div className="day-itinerary">
                              <h4>Price Category</h4>
                              <p>
                                <span
                                  style={{ fontWeight: "bold" }}
                                  className="text-capitalize"
                                >
                                  {selectedCategory}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex"
                        style={{
                          justifyContent: "flex-end",
                          padding: "0px 10px 10px ",
                          fontSize: "10px",
                        }}
                      >
                        <button
                          // onClick={() => setIsItineraryDetailsVisible(true)}
                          onClick={() => setIsItineraryDetailsVisible(true)}
                          style={{ color: "#016937" }}
                        >
                          REVIEW YOUR ITINERARY
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="list_details p_0">
                    <div
                      className="flex justify_content_between p_20 cursor_pointer align_items_center"
                      onClick={toggleGuestDetails}
                      id="padding"
                    >
                      <h5 className="text-left">GUEST DETAILS</h5>
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          transition: "transform 0.3s",
                          transform: guestDetails
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <path
                          d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                          stroke="#222222"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    {guestDetails && (
                      <div style={{ padding: "0 20px 20px 20px" }}>
                        <AdultDetailsInPackage
                          sendDataToParent={sendDataToParent}
                        />
                      </div>
                    )}
                  </div>

                  {paymentGateways.length > 0 ? (
                    <>
                      <div className="mt-20">
                        <label htmlFor="">
                          <strong>Payment gateway</strong>
                        </label>
                        {paymentGateways &&
                          paymentGateways.map((item, index) => (
                            <>
                              <div
                                key={index}
                                className="flex discountSingleDiv justify_content_between w-100"
                              >
                                <div className="flex">
                                  <label
                                    style={{
                                      textTransform: "capitalize",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    htmlFor={item?.gatewayName}
                                  >
                                    <input
                                      checked={
                                        selectedPaymentGateway?.gatewayName ===
                                        item?.gatewayName
                                      }
                                      onChange={() =>
                                        handlePaymentGatewayChange(item)
                                      }
                                      type="radio"
                                      id={item?.gatewayName}
                                    />
                                    <div style={{ cursor: "pointer" }}>
                                      <div>{item.gatewayName}</div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>

                      <div className="buttons">
                        {/* {userDetails && userToken ? (
                      <button
                        onClick={bookPackageHandle}
                        // disabled={
                        //   singlePackageData?.packageName !==
                        //   "Bir-Billing with Pong"
                        //     ? true
                        //     : false
                        // }
                        className="common_btn"
                      >
                        Proceed to Payment
                      </button>
                    ) : (
                      <button
                        onClick={bookPackageHandle}
                        disabled={true}
                        className="common_btn"
                        style={{ cursor: "not-allowed" }}
                      >
                        Please Login First
                      </button>
                    )} */}

                        <button onClick={bookPackageHandle} className="common_btn">
                          Proceed to Payment
                        </button>
                      </div>
                    </>
                  ) :
                    <div className="buttons">
                      <button
                        onClick={bookPackageHandle}
                        disabled={true}
                        className="common_btn"
                        style={{ cursor: 'not-allowed' }}
                      >
                        Proceed to Payment
                      </button>
                    </div>
                  }

                  {/* {!userDetails && !userToken && (
                    <>
                      <p style={{ textAlign: 'center', margin: '5px 0' }}>or</p>
                      <GoogleLogin
                        onSuccess={handleSuccessLoginWithGoogle}
                        onError={() => showErrorToast('Login Failed')}
                      />
                    </>
                  )} */}
                </div>
              </div>
            </div>
            <aside id="Price-Break">
              <div className="common_background common_background_parent_sticky">
                <div>
                  <h4 style={{ fontSize: "14px" }}>Fare Summary</h4>

                  <div className="flex justify_content_between mt-10">
                    <p>
                      Adult Cost
                      <small>
                        {" "}
                        (
                        {/* {commission && Object.keys(commission).length > 0 && (
                          <span style={{ color: 'red', textDecoration: 'line-through' }}>
                            {(calculationsOfPackages?.onlyAdultCost /
                              calculationsOfPackages?.adultNumber) +
                              calculationsOfPackages?.agentSavings}
                            {" "}
                          </span>
                        )} */}

                        {calculationsOfPackages?.onlyAdultCost /
                          calculationsOfPackages?.adultNumber}{" "}
                        X {calculationsOfPackages?.adultNumber})
                      </small>
                    </p>
                    <span
                      className={`room_text-right ${Number(calculationsOfPackages?.onlyAdultCost) <= 0 &&
                        "filterBlur3"
                        }`}
                    >
                      {formatIndianCurrency(
                        Number(calculationsOfPackages?.onlyAdultCost)
                      )}
                    </span>
                  </div>

                  {calculationsOfPackages?.extraAdultNumber > 0 && (
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Extra Adult Cost{" "}
                        <small>
                          {" "}
                          (
                          {/* {commission && Object.keys(commission).length > 0 && (
                            <span style={{ color: 'red', textDecoration: 'line-through' }}>
                              {(calculationsOfPackages?.ExtraAdultCost /
                                calculationsOfPackages?.extraAdultNumber) +
                                calculationsOfPackages?.extraAdultSavings}
                              {" "}
                            </span>
                          )} */}
                          {calculationsOfPackages?.ExtraAdultCost /
                            calculationsOfPackages?.extraAdultNumber}{" "}
                          X {calculationsOfPackages?.extraAdultNumber})
                        </small>
                      </p>
                      <span
                        className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.ExtraAdultCost
                        )}
                        {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                      </span>
                    </div>
                  )}

                  {under6AgeChildren?.length > 0 &&
                    calculationsOfPackages?.childNumber > 0 &&
                    calculationsOfPackages?.childCostWithOutBed ? (
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Child Cost ({`< 6 yrs`}){" "}
                        <small>
                          ̀ (
                          {/* {commission && Object.keys(commission).length > 0 && (
                              <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                {(calculationsOfPackages?.childCostWithOutBed /
                                  under6AgeChildren.length) +
                                  calculationsOfPackages?.childSavingsWithoutBed}
                                {" "}
                              </span>
                            )} */}
                          {calculationsOfPackages?.childCostWithOutBed /
                            under6AgeChildren?.length}{" "}
                          X {under6AgeChildren?.length})
                        </small>
                      </p>
                      <span
                        className={`room_text-right ${Number(
                          calculationsOfPackages?.childCostWithOutBed
                        ) <= 0 && "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.childCostWithOutBed
                        )}
                      </span>
                    </div>
                  ) : null}

                  {over6AgeChildren?.length > 0 &&
                    calculationsOfPackages?.childNumber > 0 &&
                    calculationsOfPackages?.childCostWithBed ? (
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Child Cost ({`> 6 yrs`}){" "}
                        <small>
                          ̀ (
                          {/* {commission && Object.keys(commission).length > 0 && (
                              <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                {(calculationsOfPackages?.childCostWithBed /
                                  under6AgeChildren.length) +
                                  calculationsOfPackages?.childSavingsWithBed}
                                {" "}
                              </span>
                            )} */}

                          {calculationsOfPackages?.childCostWithBed /
                            over6AgeChildren?.length}{" "}
                          X {over6AgeChildren?.length})
                        </small>
                      </p>
                      <span
                        className={`room_text-right ${Number(calculationsOfPackages?.childCostWithBed) <=
                          0 && "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.childCostWithBed
                        )}
                      </span>
                    </div>
                  ) : null}

                  {calculationsOfPackages?.transportationCost ? (
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Transportation Cost{" "}
                        <span className="customizedSinglePackageInfoIcon">
                          <div className="customizedSinglePackageInfoIconChild">
                            <div className="flex justify_content_between">
                              <div>
                                New Vehicle Price{" "}
                                {vehicleDetails &&
                                  vehicleDetails.length > 0 && (
                                    <small>
                                      ({vehicleDetails[0]?.Vehicle})
                                    </small>
                                  )}
                              </div>
                            </div>
                          </div>
                        </span>
                      </p>
                      <span className="room_text-right ">
                        {customizableRatesLoader === true ? (
                          <div style={{ width: "100px", filter: "blur(3px)" }}>
                            loader
                          </div>
                        ) : (
                          <>
                            {" "}
                            {formatIndianCurrency(
                              calculationsOfPackages?.transportationCost
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  ) : null}

                  {calculationsOfPackages?.totalActivityCost ? (
                    <div className="flex justify_content_between mt-10">
                      <p>
                        Activity Cost
                        <small>
                          {/* {" "}
                            (
                            {calculationsOfPackages?.totalActivityCost /
                              calculationsOfPackages?.adultNumber}{" "}
                            X {calculationsOfPackages?.adultNumber}) */}
                        </small>
                      </p>
                      <span className="room_text-right ">
                        {customizableRatesLoader === true ? (
                          <div style={{ width: "100px", filter: "blur(3px)" }}>
                            loader
                          </div>
                        ) : (
                          <>
                            {" "}
                            {formatIndianCurrency(
                              calculationsOfPackages?.totalActivityCost
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  ) : null}

                  <div className="flex justify_content_between mt-10">
                    <p>
                      <b>Total Package Cost</b>
                    </p>
                    <span className="room_text-right alert_text">
                      {formatIndianCurrency(calculationsOfPackages?.basePrice)}
                    </span>
                  </div>

                  {/* <div className="flex justify_content_between mt-10">
                    <p>
                      <b> Markup </b>
                    </p>
                    <span className="room_text-right">
                      {markupData?.amount}
                    </span>
                  </div> */}

                  {/* {calculationsOfPackages?.discount > 0 && (
                    <>
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Discount
                          {calculationsOfPackages?.discountType === "percentage"
                            ? `${calculationsOfPackages?.discountPercentage}%`
                            : formatIndianCurrency(
                              calculationsOfPackages?.discountAmount
                            )}
                          )
                        </p>
                        <span className="room_text-right">
                          {formatIndianCurrency(
                            calculationsOfPackages?.discountAmount
                          )}
                        </span>
                      </div>
                    </>
                  )}

                  {calculationsOfPackages?.discount > 0 && (
                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                      <p>Package Cost (After Disc.)</p>
                      <span className="room_text-right">
                        {formatIndianCurrency(
                          calculationsOfPackages?.priceAfterDiscount
                        )}
                      </span>
                    </div>
                  )} */}

                  {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && (
                    <div className="mt-10">
                      <p><b>Package Discount{selectedAutomaticDiscount?.length > 1 ? 's' : ''}</b></p>
                      {selectedAutomaticDiscount?.map((discount, index) => (
                        <div className="flex justify_content_between mt-2" key={discount.id}>
                          <span className="discount-name">{discount.promotionName}</span>
                          <span className="room_text-right green">
                            {discount.promotionMode === "percentage"
                              ? `${+discount.promotionAmount}%`
                              : `${formatIndianCurrency(discount.promotionAmount)}`}
                          </span>
                        </div>
                      ))}

                      {selectedDiscount && selectedDiscount?.length > 0 && selectedDiscount?.map((discount, index) => (
                        <div className="flex justify_content_between mt-2" key={discount.id}>
                          <span className="discount-name">{discount.promotionName}</span>
                          <span className="room_text-right green">
                            {discount.promotionMode === "percentage"
                              ? `${+discount.promotionAmount}%`
                              : `${formatIndianCurrency(discount.promotionAmount)}`}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}

                  {calculationsOfPackages?.discount > 0 && (
                    <div className="flex justify_content_between mt-10 border-bottom-one ">
                      <p>
                        Package Cost (After Disc.)
                      </p>
                      <span
                        className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.priceAfterDiscount
                        )}
                      </span>
                    </div>
                  )}

                  {commission && Object.keys(commission).length > 0 && (
                    <>
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Commission {" "}
                          <small>
                            ( {commission.commissionType === "fixed" ? (
                              <span className="room_text-right">
                                {formatIndianCurrency(
                                  commission?.commissionAmount
                                )}
                              </span>
                            ) : (
                              <span className="room_text-right">
                                {commission?.commissionAmount}%
                              </span>
                            )})
                          </small>
                        </p>

                        {formatIndianCurrency(
                          calculationsOfPackages?.calculatedCommissionAmount
                        )}
                      </div>
                    </>
                  )}

                  {selectedAddOns && selectedAddOns.length > 0 && (
                    <div className="flex justify_content_between mt-10">
                      <p>Addons Price</p>
                      <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        {commission && Object.keys(commission).length > 0 && (
                          <small style={{ color: 'red', textDecoration: 'line-through' }}>
                            {formatIndianCurrency(totalAddonsCostWithoutCommission)}
                          </small>
                        )}
                        <b>
                          {formatIndianCurrency(totalAddonsCost)}
                        </b>
                      </span>
                    </div>
                  )}

                  <div className="flex justify_content_between mt-10">
                    <p>
                      Subtotal {" "}
                    </p>

                    {formatIndianCurrency(
                      calculationsOfPackages?.subtotal
                    )}
                  </div>
                  <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                    <p>GST(5%) </p>
                    <span className="room_text-right">
                      {formatIndianCurrency(calculationsOfPackages?.taxAmount)}
                    </span>
                  </div>

                  <div className="flex justify_content_between mt-10">
                    <h6>
                      <b>Total Amount to be paid</b>
                    </h6>
                    <h6>
                      <span
                        className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
                      >
                        {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.totalAgentSavings && (
                          <small style={{ color: 'red', textDecoration: 'line-through' }}>
                            {formatIndianCurrency(
                              Number(calculationsOfPackages?.totalAgentSavings) + Number(calculationsOfPackages?.totalAmount)
                            )}
                          </small>
                        )}
                        <b>
                          {formatIndianCurrency(
                            calculationsOfPackages?.totalAmount
                          )}
                        </b>
                      </span>
                    </h6>
                  </div>
                </div>

                <div className="buttons flex_direction_col">
                  {/* <Link to={"/checkout-package"}> */}
                  {/* <button onClick={navigateToCheckOutPackage} className="common_btn">Book Now</button>{" "} */}
                  {/* </Link> */}
                  {/* <button
                    onClick={(e) => handleshowRatePopup(e)}
                    className="secondary_btn mt-10 border-radius"
                  >
                    Send Enquiry
                  </button> */}
                </div>
              </div>
              {/* <OurQuery /> */}
              {/* <ChooseUs /> */}
              {/* <SendItinerary singlePackageData={singlePackageData} /> */}
            </aside>

            {mobilePriceBreakup && (
              <>
                <div className="flex justify_content_between total_amount align_items_center">
                  <div>
                    <h6 className="font_12">Total Amount to be paid </h6>
                    <h6>
                      <span
                        className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.totalAmount
                        )}{" "}
                        <svg
                          onClick={() => setPriceBreakupPopup(true)}
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0,0,256,256"
                          width="20px"
                          height="20px"
                          fillRule="nonzero"
                        >
                          <g
                            fill="#ffffff"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            stroke-miterlimit="10"
                            stroke-dasharray=""
                            stroke-dashoffset="0"
                            font-family="none"
                            font-weight="none"
                            font-size="none"
                            text-anchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(8,8)">
                              <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </h6>{" "}
                  </div>
                  {/* <button
                    style={{ background: "white", color: "#016937" }}
                    onClick={navigateToCheckOutPackage}
                    className="primary_btn fit-width"
                  >
                    <b> Book Now</b>
                  </button> */}
                </div>
                {priceBreakupPopup && (
                  <div className="bg_back_drop_overlay">
                    <div
                      className="common_background common_background_parent_sticky"
                      id="PriceBreak"
                    >
                      {" "}
                      <div className="flex justify_content_end p_b_10 ">
                        <svg
                          onClick={() => setPriceBreakupPopup(false)}
                          width="14"
                          height="13"
                          viewBox="0 0 14 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 12.5L13 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                          <path
                            d="M13 12.5L1 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 style={{ fontSize: "14px", padding: "10px 0px" }}>
                          Fare Summary
                        </h4>

                        <div className="flex justify_content_between mt-10">
                          <p>
                            Adult Cost
                            <small>
                              {" "}
                              (
                              {/* {commission && Object.keys(commission).length > 0 && (
                                <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                  {(calculationsOfPackages?.onlyAdultCost /
                                    calculationsOfPackages?.adultNumber) +
                                    calculationsOfPackages?.agentSavings}
                                  {" "}
                                </span>
                              )} */}
                              {calculationsOfPackages?.onlyAdultCost /
                                calculationsOfPackages?.adultNumber}{" "}
                              X {calculationsOfPackages?.adultNumber})
                            </small>
                          </p>
                          <span
                            className={`room_text-right ${Number(calculationsOfPackages?.onlyAdultCost) <=
                              0 && "filterBlur3"
                              }`}
                          >
                            {formatIndianCurrency(
                              Number(calculationsOfPackages?.onlyAdultCost)
                            )}
                          </span>
                        </div>

                        {calculationsOfPackages?.extraAdultNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Extra Adult Cost{" "}
                              <small>
                                {" "}
                                (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {(calculationsOfPackages?.ExtraAdultCost /
                                      calculationsOfPackages?.extraAdultNumber) +
                                      calculationsOfPackages?.extraAdultSavings}
                                    {" "}
                                  </span>
                                )} */}
                                {calculationsOfPackages?.ExtraAdultCost /
                                  calculationsOfPackages?.extraAdultNumber}{" "}
                                X {calculationsOfPackages?.extraAdultNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.ExtraAdultCost
                              )}
                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        )}

                        {/* {calculationsOfPackages?.extraAdultNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              <b>Total Adult Cost</b>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              <b>
                                {formatIndianCurrency(
                                  calculationsOfPackages?.ExtraAdultCost +
                                  calculationsOfPackages?.onlyAdultCost
                                )}
                              </b>
                            </span>
                          </div>
                        )} */}

                        {under6AgeChildren?.length > 0 &&
                          calculationsOfPackages?.childNumber > 0 &&
                          calculationsOfPackages?.childCostWithOutBed ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`< 6 yrs`}){" "}
                              <small>
                                ̀ (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                    <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                      {(calculationsOfPackages?.childCostWithOutBed /
                                        under6AgeChildren.length) +
                                        calculationsOfPackages?.childSavingsWithoutBed}
                                      {" "}
                                    </span>
                                  )} */}
                                {calculationsOfPackages?.childCostWithOutBed /
                                  under6AgeChildren?.length}{" "}
                                X {under6AgeChildren?.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(
                                calculationsOfPackages?.childCostWithOutBed
                              ) <= 0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCostWithOutBed
                              )}
                            </span>
                          </div>
                        ) : null}

                        {over6AgeChildren?.length > 0 &&
                          calculationsOfPackages?.childNumber > 0 &&
                          calculationsOfPackages?.childCostWithBed ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`> 6 yrs`}){" "}
                              <small>
                                ̀ (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                    <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                      {(calculationsOfPackages?.childCostWithBed /
                                        under6AgeChildren.length) +
                                        calculationsOfPackages?.childSavingsWithBed}
                                      {" "}
                                    </span>
                                  )} */}
                                {calculationsOfPackages?.childCostWithBed /
                                  over6AgeChildren?.length}{" "}
                                X {over6AgeChildren?.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(
                                calculationsOfPackages?.childCostWithBed
                              ) <= 0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCostWithBed
                              )}
                            </span>
                          </div>
                        ) : null}

                        <div className="flex justify_content_between mt-10">
                          <p>Package Cost</p>
                          <span className="room_text-right alert_text">
                            {formatIndianCurrency(
                              calculationsOfPackages?.basePrice
                            )}
                          </span>
                        </div>

                        {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && (
                          <div className="mt-10">
                            <p><b>Package Discount{selectedAutomaticDiscount?.length > 1 ? 's' : ''}</b></p>
                            {selectedAutomaticDiscount?.map((discount, index) => (
                              <div className="flex justify_content_between mt-2" key={discount.id}>
                                <span className="discount-name">{discount.promotionName}</span>
                                <span className="room_text-right green">
                                  {discount.promotionMode === "percentage"
                                    ? `${+discount.promotionAmount}%`
                                    : `${formatIndianCurrency(discount.promotionAmount)}`}
                                </span>
                              </div>
                            ))}

                            {selectedDiscount && selectedDiscount?.length > 0 && selectedDiscount?.map((discount, index) => (
                              <div className="flex justify_content_between mt-2" key={discount.id}>
                                <span className="discount-name">{discount.promotionName}</span>
                                <span className="room_text-right green">
                                  {discount.promotionMode === "percentage"
                                    ? `${+discount.promotionAmount}%`
                                    : `${formatIndianCurrency(discount.promotionAmount)}`}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}

                        {calculationsOfPackages?.discount > 0 && (
                          <div className="flex justify_content_between mt-10 border-bottom-one ">
                            <p>
                              Package Cost (After Disc.)
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                                "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.priceAfterDiscount
                              )}
                            </span>
                          </div>
                        )}

                        {commission && Object.keys(commission).length > 0 && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Commission {" "}
                                <small>
                                  ( {commission.commissionType === "fixed" ? (
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        commission?.commissionAmount
                                      )}
                                    </span>
                                  ) : (
                                    <span className="room_text-right">
                                      {commission?.commissionAmount}%
                                    </span>
                                  )})
                                </small>
                              </p>

                              {formatIndianCurrency(
                                calculationsOfPackages?.calculatedCommissionAmount
                              )}
                            </div>
                          </>
                        )}

                        {selectedAddOns && selectedAddOns.length > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>Addons Price</p>
                            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                              {commission && Object.keys(commission).length > 0 && (
                                <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                  {formatIndianCurrency(totalAddonsCostWithoutCommission)}
                                </small>
                              )}
                              <b>
                                {formatIndianCurrency(totalAddonsCost)}
                              </b>
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <p>
                            Subtotal {" "}
                          </p>

                          {formatIndianCurrency(
                            calculationsOfPackages?.subtotal
                          )}
                        </div>

                        <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>GST(5%) </p>
                          <span className="room_text-right">
                            {formatIndianCurrency(
                              calculationsOfPackages?.taxAmount
                            )}
                          </span>
                        </div>

                        {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.totalAgentSavings && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Your Savings
                              </p>
                              <span className="room_text-right">
                                {formatIndianCurrency(
                                  calculationsOfPackages?.totalAgentSavings
                                )}
                              </span>
                            </div>
                          </>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Total Amount To Be Paid</b>
                          </h6>
                          <h6>
                            <span
                              className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                                "filterBlur3"
                                }`}
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
                            >
                              {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.totalAgentSavings && (
                                <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                  {formatIndianCurrency(
                                    Number(calculationsOfPackages?.totalAgentSavings) + Number(calculationsOfPackages?.totalAmount)
                                  )}
                                </small>
                              )}
                              <b>
                                {formatIndianCurrency(
                                  calculationsOfPackages?.totalAmount
                                )}
                              </b>
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className="buttons flex_direction_col">
                        {/* <Link to={"/checkout-package"}> */}
                        {/* <button onClick={navigateToCheckOutPackage} className="common_btn">Book Now</button>{" "} */}
                        {/* </Link> */}
                        {/* <button
                      onClick={(e) => handleshowRatePopup(e)}
                      className="secondary_btn mt-10 border-radius"
                    >
                      Send Enquiry
                    </button> */}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </main>
        </div>
        {isItineraryDetailsVisible && (
          <ItnerarySideBar
            // singleBookingData={singleBookingData}
            onClose={handleCloseSlider}
            setIsSliderVisible={setIsSliderVisible}
            singlePackageData={singlePackageData}
            isItineraryDetailsVisible={isItineraryDetailsVisible}
            setIsItineraryDetailsVisible={setIsItineraryDetailsVisible}
            allPackageHotels={allPackageHotels}
            selectedCategory={selectedCategory}
          />
        )}

        <NTTData
          mainData={mainData}
          selectedPaymentGateway={selectedPaymentGateway}
          setPaymentGatewayLoader={setPaymentGatewayLoader}
        />
      </section>
      <Footer />
    </div>
  );
}

export default CheckOutPackage;

// const handleSubmit = async (e) => {
// console.log("userDetails", userDetails);
// if (!guestData.guest_name) {
//   toast.error("Please enter Name");
//   return;
// }
// if (!guestData.guest_phone) {
//   toast.error("Please enter Phone Number");
//   return;
// }
// //
// if (!guestData.guest_email) {
//   toast.error("Please enter email");
//   return;
// }
// // console.log("ppp");
// // e.preventDefault();
// setLoader(true);
// var myHeaders = new Headers();
// const formatedDate = new Date(checkInDate).toISOString().split("T")[0];
// myHeaders.append("Accept", "application/json");
// myHeaders.append("Content-Type", "application/json");
// myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
// const rateData = ratesData?.rates;
// const bookingPaxes = rateData.map((rate) => {
//   let paxes = [];
//   const childPrices = rate.child_price || [];
//   const childAges = rate.childAges || [];
//   if (Array.isArray(childPrices)) {
//     childPrices.forEach((price, index) => {
//       const is_complimentary = price === "0.00" ? 1 : 0;
//       const childObject = {
//         pax_type: "child",
//         no_of_pax: 1,
//         rate: price,
//         child_age: bookingData.childAge[0] || 0,
//         is_complimentary: is_complimentary,
//       };
//       paxes.push(childObject);
//     });
//   }
//   // Process extra adults
//   for (let i = 0; i < rate.extra_adult_count; i++) {
//     const adultObject = {
//       pax_type: "adult",
//       no_of_pax: 1,
//       rate: rate.extra_adult,
//       child_age: bookingData.childAge[0] || 0,
//       is_complimentary: 0,
//     };
//     paxes.push(adultObject);
//   }
//   return paxes;
// });
// console.log(bookingPaxes, "pax");
// // JSON.stringify
// // const roomInputData = [{}];
// const raw = JSON.stringify({
//   hotelId: bookingData?.hotelId,
//   agent_id: userDetails?.AgentId,
//   customer_id: "",
//   check_in: checkInDate,
//   check_out: checkOutDate,
//   guest_name: guestData.guest_name,
//   guest_phone: guestData.guest_phone,
//   guest_email: guestData.guest_email,
//   pincode: guestData.pincode,
//   guest_state: addressData.state,
//   guest_country: addressData.country,
//   guest_city: addressData.city,
//   guest_address: addressData.address,
//   gst_type: "exclusive",
//   sub_total: bookingValues.subtotal,
//   grand_total: bookingValues.grandTotal,
//   paid_amount: 0,
//   total_tax_amount: bookingValues.taxAmount,
//   total_discount_amount: bookingValues.discountAmt,
//   company_number: guestData?.company_number,
//   company_email: guestData.business_email,
//   gst_number: guestData?.gst_number,
//   roomInputData: [
//     {
//       room_id: selectedPlan?.room_id,
//       room_name: ratesData?.room_name,
//       rate_plan_id: selectedPlan?.rate_plan_id,
//       room_plan: selectedPlan?.meal_plan,
//       no_of_rooms: bookingData?.room,
//       no_nights: noOfNights,
//       room_rate: selectedPlan?.rate_per_night,
//       no_adult: bookingData?.adult,
//       // extra_adult: roomData?.rateplan?.extra_adult_count,
//       no_child: bookingData.children,
//       // extra_child: roomData?.rateplan?.child_price
//       //   ? roomData?.rateplan?.child_price.length
//       //   : 0,
//       // childAge: childAge,
//       // child_price: roomData?.rateplan?.child_price,
//       // adult_price: roomData?.rateplan?.extra_adult,
//       discount_rate: bookingValues.bookingDiscount,
//       tac_rate: bookingValues.agentDiscount,
//       discount_amount: bookingValues.discountAmt,
//       tax_rate: bookingValues?.taxSlab,
//       tax_amount: bookingValues.taxAmount,
//       tax_type: "exclusive",
//       room_total_amount: selectedPlan?.rate_per_night,
//       room_grand_total: bookingValues.grandTotal,
//       check_in: checkInDate,
//       check_out: checkOutDate,
//       booking_paxs: bookingPaxes.length > 0 ? bookingPaxes[0] : [],
//     },
//   ],
// });
// // console.log(raw);
// var requestOptions = {
//   method: "POST",
//   headers: myHeaders,
//   body: raw,
// };
// setAtomAccessToken(15000016755291);
// fetch(
//   `https://extranet.travcatalyst.com/api/v1/online/booking/checkout/${userDetails?.AccountId}`,
//   requestOptions
// )
//   .then((response) => response.json())
//   .then((data) => {
//     if (data.success) {
//       setLoader(false);
//       setAtomAccessToken(data?.atomTokenId);
//       setTicketData(data?.booking);
//       // localStorage.removeItem("cart");
//     } else {
//       setLoader(false);
//       alert("Something Went Wrong");
//     }
//   })
//   .catch((error) => {
//     setLoader(false);
//     console.log("error", error);
//   });
// };

// const bookPackageHandle = () => {
//   const myHeaders = new Headers();
//   myHeaders.append("key", process.env.REACT_APP_API_KEY);
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append(
//     "Cookie",
//     "XSRF-TOKEN=eyJpdiI6IjVramd2UGtBVHc0dlNSS2tQVy9lK0E9PSIsInZhbHVlIjoibnRxUHRLYWI5OXRlVllWNGthR0Q0WDdvNnFHV1Q3bUh0MnV5TTlJdVBhdVdjZHZUS1hCb0hLMHRQWUQ3d0Nyb2ZxbEZxSzcxK3hFOWQ5QTNJcEdEamRzdGZwKzNvb0k1K2VBTm42dXF1SXM0N21ZKzVOazNOdkNBV0tsZ2JLQk4iLCJtYWMiOiJmNGU3NTE1ZjExMWEzM2NhOTA2Yjc3MDc2NzEyMTc2MzE1ZDhkMWQ3Y2FlMzRkYjkyNDdjNWJiYzRmMDMxN2I1IiwidGFnIjoiIn0%3D; laravel_session=eyJpdiI6Ikxnb1ZZMkNtS1lObFpud2hMN3A3Q1E9PSIsInZhbHVlIjoiRlJNS3ZTa0JZSzFkVkVtb1FUWUZFRXB2eDF4STNCS3ovVVFvS3BWMjhQUlZSQTBRRHBIVXI4UjNPazZ1RWJvZDQxMDVXTzJTZXpTcXJJbkdMRnM1VVpmd1BIR2pVSktzU0FpYWlSdXFWZEY5VFlvS0NyZVV4OWgyUlFtUk4xUnIiLCJtYWMiOiI4ZWRkOTUyYzM2NzI3MzE5NWRhNWE3MmIxY2Y5MTQwZGE2NTdhOGVkNDI2ZDFkNzc5ODU2NjgxM2Q4ZjhkNDU4IiwidGFnIjoiIn0%3D"
//   );

//   const isValid =
//     guestDetailsArray && allGuestDetailsAdd
//       ? guestDetailsArray.every((pax, index) => {
//         if (!pax.name || !pax.email || !pax.phone) {
//           console.error(
//             `Error: Missing fields in object at index ${index}`
//           );
//           return false;
//         }
//         return true;
//       })
//       : guestDetailsArray.length > 0
//         ? true
//         : false;

//   if (!isValid) {
//     return showErrorToast("Please add the travelers details.");
//   }

//   const raw1 = JSON.stringify({
//     booking_source: "website",
//     agent_id: userDetails?.AgentId,
//     vendor_id: userDetails?.id,
//     customer_id: userDetails?.CustomerId,
//     tour_start: bookingData?.tourDate,
//     tour_duration: singlePackageData?.totalDay?.toString(),
//     package_id: singlePackageData?.packageId,
//     is_customizable: customized ? true : false,
//     tour_end: calculateToDatePlus(
//       bookingData?.tourDate,
//       singlePackageData?.totalDays
//     ),
//     total_discount_amount: calculationsOfPackages.discountAmount,
//     sub_total: calculationsOfPackages.priceAfterDiscount,
//     grand_total: calculationsOfPackages.totalAmount,
//     total_tax_amount: calculationsOfPackages.taxAmount,
//     tax_type: "exclusive",
//     date: currentDateSingle,
//     pay_with_agent_credit: false,
//     pay_with_advance: false,
//     remaining_credit_balance: 300,
//     tax_rate: bookingValues?.taxSlab,
//     remaining_advance_balance: 0,
//     itineraryData: hotelDayByDay,
//     booking_paxes: guestDetailsArray,
//     ...bookingData,
//     vehicleDetails: vehicleDetails,
//     balance_amount: calculationsOfPackages.totalAmount,
//     paid_amount: 0,
//     package_name: singlePackageData.packageName,
//     vehicle_name:
//       vehicleDetails && vehicleDetails.length > 0
//         ? vehicleDetails[0]?.Vehicle
//         : "",
//   });

//   const raw2 = JSON.stringify({
//     // customer_id: "",
//     booking_source: "website",
//     agent_id: userDetails?.AgentId,
//     tour_start: bookingData?.tourDate,
//     customer_id: userDetails?.CustomerId,
//     vendor_id: userDetails?.id,
//     tour_duration: singlePackageData?.totalDays?.toString(),
//     package_id: singlePackageData?.packageId,
//     is_customizable: customized ? true : false,
//     tour_end: calculateToDatePlus(
//       bookingData?.tourDate,
//       singlePackageData?.totalDays
//     ),
//     total_discount_amount: calculationsOfPackages.discountAmount,
//     sub_total: calculationsOfPackages.priceAfterDiscount,
//     grand_total: calculationsOfPackages.totalAmount,
//     total_tax_amount: calculationsOfPackages.taxAmount,
//     tax_type: "exclusive",
//     date: currentDateSingle,
//     pay_with_agent_credit: false,
//     tax_rate: bookingValues?.taxSlab,
//     pay_with_advance: false,
//     remaining_credit_balance: 300,
//     remaining_advance_balance: 0,
//     // itineraryData: hotelDayByDay,
//     booking_paxes: guestDetailsArray,
//     ...bookingData,

//     balance_amount: calculationsOfPackages.totalAmount,
//     paid_amount: 0,
//     package_name: singlePackageData.packageName,
//     vehicle_name:
//       vehicleDetails && vehicleDetails.length > 0
//         ? vehicleDetails[0]?.Vehicle
//         : "",
//   });

//   // console.log(JSON.parse(raw1));

//   // return

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: customized ? raw1 : raw2,
//     redirect: "follow",
//   };

//   fetch(
//     `${process.env.REACT_APP_APP_URL}/api/v1/online/package-booking/checkout`,
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((data) => {
//       if (data.success) {
//         setLoader(false);
//         // setAtomAccessToken(data?.atomTokenId);
//         // setTicketData(data?.package);

//         // console.log("ppp");

//         const checkoutUrl = `${process.env.REACT_APP_APP_URL}/api/v1/get/package/data/checkout/process/${data.razorpayOrderId}/${data.package_data.id}/?thankyoupageurl=https://www.mountainmysteries.com/thank-you/package/`;
//         // navigate(checkoutUrl);
//         window.location.href = checkoutUrl;
//       } else {
//         setLoader(false);
//         showErrorToast(data.message ? data.message : "Something Went Wrong");
//       }
//     })
//     .catch((error) => {
//       setLoader(false);
//       // console.log("error", error);
//     });
// };
// const processPayment = async (bookingData) => {
//   try {
//     setPaymentGatewayLoader(true);
//     const headers = {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_BASE_API_KEY,
//       Cookie: process.env.REACT_APP_COOKIE,
//     };

//     const body = JSON.stringify({
//       order_amount: bookingData?.grandTotal,
//       order_number: bookingData?.bookingNo,
//       order_id: bookingData?.id,
//       email: bookingData?.customerDetails?.email,
//       phone: bookingData?.customerDetails?.phone,
//       callbackUrl: "http://localhost:3000/thankyou",
//     });

//     const response = await fetch(
//       `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/process/${selectedPaymentGateway?.gatewayName}/payment`,
//       {
//         method: "POST",
//         headers: headers,
//         body: body,
//         redirect: "follow",
//       }
//     );

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const result = await response.json();
//     setAtomAccessToken(result?.data);
//     setPaymentGatewayLoader(false);
//   } catch (error) {
//     console.error("Error processing payment:", error);
//     setPaymentGatewayLoader(false);
//   }
// };

// useEffect(() => {
//   if (selectedPaymentGateway) {
//     // https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js
//     // https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js
//     const scriptUrl = "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";
//     // const scriptUrl = selectedPaymentGateway?.testMode ?
//     //     "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js" :
//     //     "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";

//     const existingScript = document.querySelector(`script[src="${scriptUrl}"]`);

//     if (!existingScript) {
//       const script = document.createElement("script");
//       script.src = scriptUrl;
//       script.async = true;
//       script.onload = () => {
//         console.log("Atom Payment Gateway script loaded");
//       };
//       script.onerror = () => {
//         console.error("Failed to load Atom Payment Gateway script");
//       };
//       document.body.appendChild(script);
//       return () => {
//         document.body.removeChild(script);
//       };
//     } else if (existingScript.readyState === "complete") {
//       console.log("Atom Payment Gateway script already loaded");
//     }
//   }
// }, [selectedPaymentGateway]);

// useEffect(() => {
//   if (atomAccessToken && packageBookingData && typeof window.AtomPaynetz !== "undefined") {
//     const options = {
//       atomTokenId: atomAccessToken,
//       merchId: "599204",
//       custEmail: packageBookingData?.customerDetails?.email,
//       custMobile: packageBookingData?.customerDetails?.phone,
//       returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response`,
//     };
//     // console.log(options);
//     const atom = new window.AtomPaynetz(options, 'uat');
//     // console.log("AtomPaynetz initialized", atom);
//   } else {
//     console.warn("AtomPaynetz is not ready or required data is missing");
//   }
// }, [atomAccessToken, packageBookingData]);

// const handleMessage = async ({ data }) => {
//   if (data === "cancelTransaction") {
//     console.log("Payment cancelled!!");
//     try {
//       const headers = {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_BASE_API_KEY,
//         Cookie: process.env.REACT_APP_COOKIE,
//       };
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response`, {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify({
//           "encData": "",
//           "order_id": packageBookingData?.id,
//           "status": 'cancelled'
//         })
//       });
//       if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//       }
//       const result = await response.json();
//       navigate(`/package/cancel/${packageBookingData?.id}`);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   }
// };

// useEffect(() => {
//   window.addEventListener('message', handleMessage);
//   return () => {
//     window.removeEventListener('message', handleMessage);
//   };
// }, [packageBookingData]);
// {
//   calculationsOfPackages?.extraAdultNumber ? (
//     <div className="flex justify_content_between mt-10">
//       <p>
//         Extra Adult Cost{" "}
//         <small>
//           {" "}
//           (
//           {calculationsOfPackages?.ExtraAdultCost /
//             calculationsOfPackages?.extraAdultNumber}{" "}
//           X {calculationsOfPackages?.extraAdultNumber})
//         </small>
//       </p>
//       <span className="room_text-right alert_text">
//         {customizableRatesLoader === true ? (
//           <div style={{ width: "100px", filter: "blur(3px)" }}>
//             loader
//           </div>
//         ) : (
//           <>
//             {" "}
//             {formatIndianCurrency(
//               calculationsOfPackages?.extraAdultCost
//             )}
//           </>
//         )}
//       </span>
//     </div>
//   ) : null
// }
// {
//   calculationsOfPackages?.extraAdultNumber ? (
//     <div className="flex justify_content_between mt-10">
//       <p>
//         <b>Total Adult Cost</b>{" "}
//         <small>
//           {" "}
//           (
//           {calculationsOfPackages?.ExtraAdultCost /
//             calculationsOfPackages?.extraAdultNumber}{" "}
//           X {calculationsOfPackages?.extraAdultNumber})
//         </small>
//       </p>
//       <span className="room_text-right alert_text">
//         {customizableRatesLoader === true ? (
//           <div style={{ width: "100px", filter: "blur(3px)" }}>
//             loader
//           </div>
//         ) : (
//           <>
//             {" "}
//             {formatIndianCurrency(
//               calculationsOfPackages?.extraAdultCost +
//               calculationsOfPackages?.onlyAdultCost
//             )}
//           </>
//         )}
//       </span>
//     </div>
//   ) : null
// }

// {calculationsOfPackages?.extraAdultNumber > 0 && (
//   <div className="flex justify_content_between mt-10">
//     <p>
//       <b>Total Adult Cost hhh</b>
//     </p>
//     <span
//       className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
//         "filterBlur3"
//         }`}
//     >
//       <b>
//         {formatIndianCurrency(
//           calculationsOfPackages?.ExtraAdultCost +
//           calculationsOfPackages?.onlyAdultCost
//         )}
//       </b>

//     </span>
//   </div>
// )}
