import React, { useEffect } from "react";
import PageMetaData from "../../../CustomComponents/PageMetaData/PageMetaData";
import CustomerHeader from "../CustomerHeader/CustomerHeader";
import CustomerSideBar from "../CustomerSideBar/CustomerSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useState } from "react";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import Loader from "../../../CustomComponents/Loader/Loader";
import { formatIndianCurrency, textDateFormatter } from "../../../utils/utils";
import SeeBookingSlider from "../../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import CustomerPackages from "../../CustomerPackages/CustomerPackages";

function CustomerBooking() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialRole = queryParams.get("role") || "profile";
  const [activeTab, setActiveTab] = useState(initialRole);
  const [customerBooking, setCustomerBooking] = useState([]);
  const [singleBookingData, setSingleBookingData] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleChangeClick = async () => {
    setIsSliderVisible(true);
  };

  // const customerBookingsFetch = () => {
  //   setLoader(true);

  //   fetch(`${process.env.REACT_APP_APP_URL}/api/v1/customers/bookings`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result) {
  //         setLoader(false);
  //         // setCustomerBooking(result?.data?.data);
  //         const groupByCategory = result?.data?.data?.reduce(
  //           (group, booking) => {
  //             const { tour_start } = booking;
  //             group[tour_start] = group[tour_start] ?? [];
  //             group[tour_start].push(booking);
  //             return group;
  //           },
  //           {}
  //         );
  //         setLoader(false);

  //         if (groupByCategory) {
  //           setCustomerBooking(groupByCategory);
  //         } else {
  //           setCustomerBooking([]);
  //         }
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //       console.error(
  //         "There was an error fetching the customers booking.",
  //         error
  //       );
  //     });
  // };

  const customerBookingsFetch = async () => {
    setLoader(true);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        `Bearer ${userToken}`,
      Cookie: process.env.REACT_APP_COOKIE,
    };

    const body = JSON.stringify({
      booking_type: "accommodation",
      account_type: "customer",
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/bookings/fetch/list`, {
        method: "POST",
        headers,
        body,
        redirect: "follow",
      });

      const result = await response.json();
      setCustomerBooking(result?.data);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    customerBookingsFetch();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    const userType = localStorage.getItem("userType");

    if (userType === "agent") {
      navigate("/agent-account");
    }
  }, [navigate]);
  const userToken = localStorage.getItem("token");

  const [customerDataa, setCustomerData] = useState(null);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      setFormData(userDetails);
    }
  }, []);

  useEffect(() => {
    const role = queryParams.get("role") || "profile";
    setActiveTab(role);
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const profileFilter = (tab) => {
    setActiveTab(tab);
  };

  const fetchCustomerDetails = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCustomerData(data.data);
          setFormData({
            name: data.data.name || "",
            phone: data.data.phone || "0",
            email: data.data.email || "",
          });
          localStorage.setItem(
            "userDetails",
            JSON.stringify(data.data)
          );
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  // const fetchSidebarDetails = (bookingId) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );
  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/v1/agents/single-booking/${bookingId}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.success) {
  //         handleChangeClick();
  //         setSingleBookingData(result?.data);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  const fetchSidebarDetails = async (bookingId) => {
    try {
      const myHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          `Bearer ${userToken}`,
        Cookie: process.env.REACT_APP_COOKIE,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/bookings/fetch/single/${bookingId}`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            booking_type: "accommodation",
          }),
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        handleChangeClick();
        setSingleBookingData(result?.data);
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
      throw error; // Optional: rethrow the error if further handling is required
    }
  };

  const [selectedButton, setSelectedButton] = useState("accommodation");

  return (
    <div id="Listingholidays">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <CustomerHeader />
      <div className="sidebar_main_div">
        <CustomerSideBar />
        <section className="w-full p-sm">
          <div id="common_background_profile m_20">
            {selectedButton === "accommodation" ? (
              <>
                <div className="flex justify_content_between align_items_center flex_wrap">
                  <h4 className="hide_heading">Hotel Bookings</h4>
                  <div className="agent_dashboard_bookings">
                    <div
                      onClick={() => setSelectedButton("accommodation")}
                      className={`agent_button ${selectedButton === "accommodation" ? "button-selected" : ""
                        }`}
                    >
                      Hotel Bookings
                    </div>
                    <div
                      onClick={() => setSelectedButton("package")}
                      className={`agent_button ${selectedButton === "package" ? "button-selected" : ""
                        }`}
                    >
                      Tour Bookings
                    </div>
                  </div>
                </div>
                <div className="common_background mt-10">
                  <div className="profile_content bookings">
                    {loader && <Loader size="4em" color="#016932" />}
                    <div >
                      {/* <h6 className="mt-10">{textDateFormatter(item)}</h6> */}
                      {customerBooking?.length > 0 &&
                        customerBooking.map((booking) => (
                          <div
                            key={booking?.unique_booking_id}
                            className="booking_details"
                          >
                            <div className="flex flex_gap_15 align_items_center">
                              <div className="vector_img">
                                <svg
                                  width="20"
                                  height="18"
                                  viewBox="0 0 20 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15 16H17V8H11V16H13V10H15V16ZM1 16V1C1 0.734784 1.10536 0.48043 1.29289 0.292893C1.48043 0.105357 1.73478 0 2 0H16C16.2652 0 16.5196 0.105357 16.7071 0.292893C16.8946 0.48043 17 0.734784 17 1V6H19V16H20V18H0V16H1ZM5 8V10H7V8H5ZM5 12V14H7V12H5ZM5 4V6H7V4H5Z"
                                    fill="#888888"
                                  />
                                </svg>
                              </div>
                              <div>
                                {/* <p>#{booking?.bookingNo}</p> */}
                                <button
                                  className="view_btn"
                                  onClick={() =>
                                    fetchSidebarDetails(
                                      booking?.id
                                    )
                                  }
                                >
                                  See Details
                                </button>
                              </div>
                            </div>
                            <div>
                              <small>Booking No.</small>
                              <p>{booking?.bookingNo}</p>
                            </div>
                            <div>
                              <small>Email</small>
                              <p>{booking?.customerDetails?.email}
                              </p>
                            </div>
                            <div>
                              <small>Phone No.</small>
                              <p>{booking?.customerDetails?.phone ? booking?.customerDetails?.phone : 'N/A'}</p>
                            </div>
                            <div>
                              <small>Grand Total</small>
                              <p>
                                {formatIndianCurrency(
                                  booking?.grandTotal
                                )}
                              </p>
                            </div>
                            <div>
                              <small>Status</small>
                              <p>{booking?.status}</p>
                            </div>
                            {/* <div>
                              <small>Total Amount</small>
                              <p>
                                {formatIndianCurrency(
                                  booking?.grand_total
                                )}
                              </p>
                            </div>
                            <div>
                              <small>Check-In</small>
                              <p>
                                {textDateFormatter(booking?.tour_start)}
                              </p>
                            </div>
                            <div>
                              <small>Check-Out</small>
                              <p>
                                {textDateFormatter(booking?.tour_end)}
                              </p>
                            </div>
                            <div>
                              <span className="change_btn text-capitalize">
                                {booking?.booking_status}
                              </span>
                            </div> */}
                          </div>
                        ))}
                    </div>
                    {isSliderVisible && singleBookingData && (
                      <SeeBookingSlider
                        singleBookingData={singleBookingData}
                        onClose={handleCloseSlider}
                        setIsSliderVisible={setIsSliderVisible}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <CustomerPackages
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                />
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default CustomerBooking;
