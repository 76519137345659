import React, { useState, useEffect } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import ChooseUs from "../../CustomComponents/ChooseUs/ChooseUs";
import SendItinerary from "../../CustomComponents/SendItinerary/SendItinerary";
import CustomSlider from "../../CustomComponents/CustomSlider/CustomSlider";
import { DisabledTextButton } from "../../CustomComponents/Buttons/Buttons";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import { Link, useParams } from "react-router-dom";
import carImg from "../../assets/images/car.png";
import OverviewContent from "../CustomTabHeader/OverviewContent";
import ItineraryContent from "../CustomTabHeader/ItineraryContent";
import AccommodationContent from "../CustomTabHeader/AccommodationContent";
import InclusionExclusionContent from "../CustomTabHeader/InclusionExclusionContent";

import TabHeader from "../CustomTabHeader/TabHeader";
import SinglePackageHeader from "../SinglePackageHeader/SinglePackageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Cancellation from "../CustomTabHeader/Cancellation";
import PointRemember from "../CustomTabHeader/PointRemember";
import PackageDetails from "../ButtonToDownload/PackageDetails";
import Gallery from "../CustomTabHeader/Gallery";
import {
  calculateLOS,
  calculateToDatePlus,
  currentDateSingle,
  formatIndianCurrency,
  scrollToTop,
} from "../../utils/utils";
import CustomizableAccommodation from "../CustomTabHeader/CustomizableAccommodation";
import TabHeaderCustomizable from "../CustomTabHeader/TabHeaderCustomizable";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../PageMetaData/PageMetaData";
import OgMetaTitles from "../PageMetaData/OgMetaTitles";
import moment from "moment";
import NewSendEnquiryPopup from "../NewSendEnquiryPopup/NewSendEnquiryPopup";

function SinglePackage() {
  // const {id} = useParams("packageId")
  const [date, setDate] = useState({
    setDay: "",
    setMonth: "",
    setYear: "",
  });
  const { packageHandle } = useParams();
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [showRatePopup, setshowRatePopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedAddOns, setSelectedAddOns] = useState([]);

  const [selectedHotelId, setSelectedHotelId] = useState(
    JSON.parse(localStorage.getItem("selected")) || null
  );
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [ratesOfPackages, setRatesOfPackages] = useState(null);
  const [selectedHotelPrice, setSelectedHotelPrice] = useState(null);
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [singlePackageData, setSinglePackageData] = useState({});
  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);
  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);
  const [allPackageHotels, setAllPackageHotels] = useState([]);
  const [packageRates, setPackageRates] = useState([]);
  const [markupData, setMarkupData] = useState([]);
  const [promotions, setPromotions] = useState([]);
  // const [selectedDiscount, setSelectedDiscount] = useState({});

  const [selectedDiscount, setSelectedDiscount] = useState([]);
  const [selectedAutomaticDiscount, setSelectedAutomaticDiscount] = useState(
    []
  );

  const userType = localStorage.getItem("userType");
  const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));
  const userToken = localStorage.getItem("token");
  const [commission, setCommission] = useState({});
  const [packageId, setPackageId] = useState(null);
  // const [roomsDetails, setRoomsDetails] = useState({});

  // markup fetch api
  const fetchMarkup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const findObject = result?.data?.find(
            (mark) => mark?.markupCustomer === "both"
          );
          if (findObject) {
            setMarkupData(findObject);
          } else {
            setMarkupData(null);
          }
        } else {
          setMarkupData("No data found");
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchMarkup();
  }, []);

  const handleSelectedAddOns = (addon) => {
    setSelectedAddOns((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.id === addon.id
      );
      if (isSelected) {
        return prevSelected.filter((selected) => selected.id !== addon.id);
      } else {
        return [...prevSelected, addon];
      }
    });
  };

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };

  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };

  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const storedData = JSON.parse(localStorage.getItem("selected"));

  const agentDetails = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (storedData) {
      setData(selectedHotelId);
    }
  }, [localStorage]);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const [activeTab, setActiveTab] = useState("package-information");

  const renderContent = () => {
    switch (activeTab) {
      case "package-information":
        return (
          <OverviewContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-itinerary":
        return (
          <ItineraryContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-hotels":
        return (
          <AccommodationContent
            loader={loader}
            singlePackageData={singlePackageData}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setSelectedMealPlan={setSelectedMealPlan}
            selectedMealPlan={selectedMealPlan}
            allPackageHotels={allPackageHotels}
            packageRates={packageRates}
            ratesOfPackages={ratesOfPackages}
            // roomsDetails={roomsDetails}
            // setRoomsDetails={setRoomsDetails}
          />
        );
      case "package-destinations":
        return (
          <InclusionExclusionContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-cancellation":
        return (
          <Cancellation singlePackageData={singlePackageData} loader={loader} />
        );
      case "package-remember":
        return (
          <PointRemember
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-gallery":
        return (
          <Gallery singlePackageData={singlePackageData} loader={loader} />
        );
      default:
        return null;
    }
  };

  const groupByCategory = (accommodations) => {
    return accommodations?.reduce((acc, accommodation) => {
      const category = accommodation.hotelcategory;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(accommodation);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (packageRates.length > 0) {
      setSelectedMealPlan(packageRates[0]?.mealPlan);
    }
  }, [packageRates.length]);

  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };

  const postQueryData = () => {};

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  const commissionAmount = commission?.commissionAmount;
  const commissionType = commission?.commissionType;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      departureDay: params.get("checkinDay"),
      departureMonth: params.get("checkinMonth"),
      departureYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      date: params.get("date"),
      day: params.get("day"),
      month: params.get("month"),
      year: params.get("year"),
      packageId: params.get("packageId"),
      packageHandle: packageHandle,
    };

    setDate({
      setDay: data.day,
      setMonth: data.month,
      setYear: data.year,
    });
    setPackageId(data.packageId);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch/single/${packageHandle}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoader(false);
        setSinglePackageData(data?.data);
        // setSelectedCategory(
        //   data?.data?.packageHotels[0]?.accommodationCategory
        // );
        // if (data?.data?.packageItinerary[0]?.accommodation.length === 0) {
        //   showErrorToast("Accommodations are not added in this package.")
        //   return
        // }
        setSelectedCategory(
          data?.data?.hotelCategory[0]
          // data?.data?.packageItinerary[0]?.accommodation[0]?.hotelcategory
        );

        const allAccommodations = data?.data?.packageItinerary.flatMap(
          (item) => item.accommodation
        );

        if (allAccommodations) {
          const uniqueAccommodations = Array.from(
            new Map(
              allAccommodations?.map((hotel) => [hotel?.accommodationId, hotel])
            ).values()
          );
          setAllPackageHotels(uniqueAccommodations);
        }

        setPackageRates(data?.data?.packageRates);
        scrollToTop();
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );

    const checkInDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.day).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
    });

    setGuest(data.guest);
    setChildren(data.children);
    setRooms(data.room);
    setChildAge(childAges);

    if (data.day && data.month && data.year) {
      setStartDate(checkInDate);
    } else {
      setStartDate(currentDateSingle);
    }
    // setStartDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);

    fetchPromotions({
      checkInDateFromBooking: checkInDate,
      packageIdFromBooking: Number(data?.packageId),
    });
  }, [location.search]);

  // /holidays/fetch/rate

  // const getRatesFromApi = async (
  //   packageId,
  //   pax,
  //   selectedCategory,
  //   selectedMealPlan
  // ) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
  //   myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

  //   const formdata = new FormData();
  //   formdata.append("package_id", packageId);
  //   formdata.append(
  //     "mealPlan",
  //     selectedMealPlan && selectedMealPlan.length > 0
  //       ? selectedMealPlan[0]
  //       : "ma"
  //   );
  //   formdata.append("no_pax", pax == 1 ? 2 : pax);
  //   formdata.append("hotelCategory", selectedCategory);
  //   // formdata.append("children", bookingData?.children);
  //   // Append childAge values dynamically
  //   if (bookingData.children > 0) {
  //     for (let i = 1; i <= bookingData?.children; i++) {
  //       formdata.append(`childAge[${i - 1}]`, bookingData?.childAge[i - 1]);
  //     }
  //   }

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch/rate`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {

  //       console.log(result);
  //       setRatesOfPackages(result?.data);
  //     })
  //     .catch((error) => console.error(error));
  // };

  const totalAddonsCostWithoutCommission = selectedAddOns?.reduce(
    (total, addon) => {
      if (addon.priceWithMarkup) {
        if (addon.perPerson && guest) {
          return total + Number(addon.priceWithMarkup) * Number(guest);
        } else {
          return total + Number(addon.priceWithMarkup);
        }
      }
    },
    0
  );

  const totalAddonsCost = selectedAddOns?.reduce((total, addon) => {
    if (addon.perPerson && guest) {
      return total + Number(addon.finalAddonPrice) * Number(guest);
    } else {
      return total + Number(addon.finalAddonPrice);
    }
  }, 0);

  const getRatesFromApi = async (
    packageId,
    pax,
    selectedCategory,
    selectedMealPlan
  ) => {
    // console.log(packageId,
    //   pax,
    //   selectedCategory,
    //   selectedMealPlan);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const formdata = new FormData();
    formdata.append("package_id", packageId);
    formdata.append("no_pax", pax == 1 ? 2 : pax);
    formdata.append("hotelCategory", selectedCategory);
    formdata.append("mealPlan", selectedMealPlan ? selectedMealPlan : "na");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch/rate`,
        requestOptions
      );
      const result = await response.json();
      setRatesOfPackages(result?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log(singlePackageData?.packageId,
  //   bookingData?.guest,
  //   selectedCategory,
  //   selectedMealPlan);

  useEffect(() => {
    if (
      singlePackageData?.packageId &&
      bookingData?.guest &&
      selectedCategory &&
      selectedMealPlan
    ) {
      getRatesFromApi(
        singlePackageData?.packageId,
        bookingData?.guest,
        selectedCategory,
        selectedMealPlan
      );
    }
  }, [
    singlePackageData,
    bookingData?.guest,
    selectedCategory,
    selectedMealPlan,
  ]);

  const childWithAgeLessThanSix = bookingData?.childAge?.filter(
    (age) => age <= 6
  )?.length;

  // reusable fn
  function calculateFinalPrice(basePrice, markupData, commissionData) {
    let finalPrice = 0;
    let priceAfterMarkup = 0;

    if (!basePrice || typeof basePrice !== "number" || basePrice <= 0) {
      return;
    }

    finalPrice = basePrice;

    // Apply Markup
    if (markupData?.markupType && markupData?.amount) {
      if (markupData.markupType === "percentage") {
        finalPrice += (basePrice * markupData.amount) / 100;
      } else if (markupData.markupType === "fixed") {
        finalPrice += Number(markupData.amount);
      }
    }
    priceAfterMarkup = finalPrice;

    // Apply Commission
    // if (commissionData?.commissionType && commissionData?.commissionAmount) {
    //   if (commissionData.commissionType === "percentage") {
    //     finalPrice -= (finalPrice * commissionData.commissionAmount) / 100;
    //   } else if (commissionData.commissionType === "fixed") {
    //     finalPrice -= Number(commissionData.commissionAmount);
    //   }
    // }

    return { finalPrice, priceAfterMarkup };
  }

  // Usage Example
  // const basePrice = 100;
  // const markupData = { markupType: "percentage", amount: 10 }; // 10% markup
  // const commissionData = { commissionType: "fixed", commissionAmount: 15 }; // $15 commission

  function calculateTotalAmount(
    basePrice = 0,
    selectedAutomaticDiscount,
    selectedDiscount,
    // discount = 0,
    // discountType = "fixed",
    guest = 2,
    ratesOfPackages,
    childAgeArray,
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    volvoAddonPrice,
    markupData,
    commissionAmount,
    commissionType
  ) {
    const childWithAgeGreaterThanSix = childAgeArray.filter(
      (age) => age > 6
    )?.length;
    const childWithAgeLessThanSix = childAgeArray.filter(
      (age) => age <= 6
    )?.length;

    const perPaxCount =
      Number(bookingData.guest) +
      childAgeArray?.length +
      ratesOfPackages?.ExtraAdultCount;

    guest = Number(guest);

    // discount = Number(discount);

    let startingPrice = basePrice;
    let markupAmount;
    let commissionAmt;

    if (markupData?.markupType) {
      if (markupData?.markupType === "percentage") {
        markupAmount = (basePrice * markupData?.amount) / 100;
      } else {
        markupAmount = Number(markupData?.amount);
      }
    }

    let onlyAdultCost = 0;

    if (markupAmount && basePrice) {
      basePrice = Number(basePrice) + markupAmount;
      // onlyAdultCost = Number(basePrice) + markupAmount;
    }

    const preCommissionPrice = basePrice;

    // if (commissionAmount && commissionType) {
    //   if (commissionType === "percentage") {
    //     commissionAmt =
    //       (Number(basePrice) * Number(commissionAmount)) / 100;
    //   } else if (commissionType === "fixed") {
    //     commissionAmt = Number(commissionAmount);
    //   }
    // }

    // if (commissionAmt) {
    //   basePrice -= Number(commissionAmt);
    // }

    const postCommissionPrice = basePrice;

    const agentSavings = preCommissionPrice - postCommissionPrice;

    onlyAdultCost = basePrice * guest;
    basePrice = basePrice * guest;

    let adjustedPackagePrice = startingPrice;

    let adultCosts = 0;
    let extraAdultSavings = 0;

    if (ratesOfPackages?.ExtraAdultCount) {
      adultCosts =
        Number(ratesOfPackages?.ExtraAdultCount) *
        Number(ratesOfPackages?.ExtraAdult);

      // const preExtraAdultPrice = adultCosts;
      let extraAdultMarkuoAmount;
      let extraAdultCommissionAmount;

      if (markupData?.markupType) {
        if (markupData?.markupType === "percentage") {
          extraAdultMarkuoAmount = (adultCosts * markupData?.amount) / 100;
        } else {
          extraAdultMarkuoAmount = Number(markupData?.amount);
        }
      }

      if (adultCosts && extraAdultMarkuoAmount) {
        adultCosts += extraAdultMarkuoAmount;
      }

      const preExtraAdultPostMarkup = adultCosts;

      // if (commissionAmount && commissionType) {
      //   if (commissionType === "percentage") {
      //     extraAdultCommissionAmount =
      //       (Number(adultCosts) * Number(commissionAmount)) / 100;
      //   } else if (commissionType === "fixed") {
      //     extraAdultCommissionAmount = Number(commissionAmount);
      //   }
      // }

      // if (adultCosts && extraAdultCommissionAmount) {
      //   adultCosts -= extraAdultCommissionAmount
      // }

      basePrice = basePrice + adultCosts;
      const postExtraAdultPrice = adultCosts;

      extraAdultSavings = preExtraAdultPostMarkup - postExtraAdultPrice;
    }

    let childCosts = 0;
    let childCostWithBed = 0;
    let childCostWithOutBed = 0;
    let childSavingsWithBed = 0;
    let childSavingsWithoutBed = 0;

    if (childAgeArray?.length > 0) {
      childAgeArray.forEach((age) => {
        if (Number(age) >= 6) {
          // childCostWithBed += Number(calculateFinalPrice(ratesOfPackages?.ChildWithBedCost, markupData, commission));
          const result = calculateFinalPrice(
            ratesOfPackages?.ChildWithBedCost,
            markupData,
            commission
          );

          childCostWithBed += Number(result?.finalPrice);
          // childSavingsWithBed += Number(result?.priceAfterMarkup - result?.finalPrice);
        } else {
          const result = calculateFinalPrice(
            ratesOfPackages?.ChildWithOutBedCost,
            markupData,
            commission
          );

          if (volvoAddonPrice !== undefined) {
            const volvoCost =
              +volvoAddonPrice / Number(childWithAgeLessThanSix);
            childCostWithOutBed +=
              Number(result?.finalPrice) + result?.volvoCost;
            // childSavingsWithoutBed += Number(result?.priceAfterMarkup - result?.finalPrice);
          } else {
            childCostWithOutBed += Number(result?.finalPrice);
            // childSavingsWithoutBed += Number(result?.priceAfterMarkup - result?.finalPrice);
          }
        }
      });
    }

    let totalAgentSavings =
      agentSavings * guest +
      extraAdultSavings +
      childSavingsWithBed +
      childSavingsWithoutBed;

    // if (commissionAmount && commissionType && totalAddonsCostWithoutCommission && totalAddonsCost) {
    //   totalAgentSavings += (totalAddonsCostWithoutCommission - totalAddonsCost)
    // }

    let totalChildCostPrice = childCostWithBed + childCostWithOutBed;

    if (totalChildCostPrice) {
      basePrice += totalChildCostPrice;
    }

    let discountAmount;

    const calculateAutomaticDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedAutomaticDiscount &&
        selectedAutomaticDiscount?.length > 0 &&
        selectedAutomaticDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount =
              (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };

    const calculateDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedDiscount &&
        selectedDiscount?.length > 0 &&
        selectedDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount =
              (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };
    const totalBookingAutomaticDiscountAmount =
      calculateAutomaticDiscountedAmount(basePrice);
    const totalBookingDiscountAmount = calculateDiscountedAmount(
      Number(basePrice) -
        Number(
          totalBookingAutomaticDiscountAmount
            ? totalBookingAutomaticDiscountAmount
            : 0
        )
    );

    discountAmount =
      Number(totalBookingAutomaticDiscountAmount) +
      Number(totalBookingDiscountAmount);
    // if (discountType === "percentage") {
    //   discountAmount = (basePrice * discount) / 100;
    // } else {
    //   discountAmount = discount;
    // }

    const priceAfterDiscount = basePrice - discountAmount;

    let calculatedCommissionAmount;

    if (commissionAmount && commissionType) {
      if (commission.commissionType === "percentage") {
        calculatedCommissionAmount =
          (priceAfterDiscount * commission.commissionAmount) / 100;
      } else if (commission.commissionType === "fixed") {
        calculatedCommissionAmount = commission.commissionAmount;
      }
    }

    let subtotal;
    if (calculatedCommissionAmount) {
      subtotal = priceAfterDiscount - calculatedCommissionAmount;
    } else {
      subtotal = priceAfterDiscount;
    }

    if (totalAddonsCost) {
      subtotal += totalAddonsCost;
    }

    const gst = subtotal * 0.05;

    const totalAmount = subtotal + gst;

    const discountPercentage = (discountAmount / basePrice) * 100;

    return {
      basePrice: basePrice.toFixed(2),
      discount: discountAmount.toFixed(2),
      priceAfterDiscount: priceAfterDiscount.toFixed(2),
      gst: gst.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      discountPercentage: discountPercentage,
      // discountType,
      taxAmount: gst.toFixed(2),
      ExtraAdultCost: adultCosts,
      childCosts,
      childCostWithBed,
      childCostWithOutBed,
      totalChildCostPrice,
      adultNumber: guest,
      onlyAdultCost: onlyAdultCost,
      extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      extraAdultCost: adultCosts,
      childNumber: childAgeArray?.length,

      perPaxCount,
      perPaxCost: (totalAmount / perPaxCount).toFixed(2),
      calculatedAgentAmount: commissionAmt * guest,
      calculatedCommissionAmount,

      agentSavings,
      extraAdultSavings,
      childSavingsWithBed,
      childSavingsWithoutBed,
      subtotal,
    };
  }

  // const handleDiscountChange = (item) => {
  //   if (selectedDiscount?.id === item?.id) {
  //     setSelectedDiscount(null);
  //   } else {
  //     setSelectedDiscount(item);
  //   }
  // };
  const handleDiscountChange = (item) => {
    setSelectedDiscount([item]);
  };

  // useEffect(() => {
  //   if (promotions.length > 0) {
  //     const automaticDiscount = promotions.find(
  //       (promotions) => promotions.promotionType === "automatic"
  //     );
  //     if (automaticDiscount) {
  //       setSelectedDiscount(automaticDiscount);
  //     }
  //   }
  // }, [promotions]);

  const volvoAddonPrice = Array.isArray(singlePackageData?.packageAddons)
    ? singlePackageData.packageAddons
        .filter(
          (detail) =>
            detail.detail.includes("Volvo") && detail.addonType === "ticket"
        )
        .map((detail) => {
          let calculatedAddonPrice = !detail.perPerson
            ? detail.price
            : Number(detail.price) * Number(childWithAgeLessThanSix);

          if (markupData) {
            const markup = markupData;
            if (markup.markupType === "percentage") {
              calculatedAddonPrice +=
                (calculatedAddonPrice * parseFloat(markup.amount)) / 100;
            } else if (markup.markupType === "fixed") {
              calculatedAddonPrice += parseFloat(markup.amount);
            }
          }
          // if (commissionAmount && commissionType) {
          //   if (commissionType === "percentage") {
          //     calculatedAddonPrice -=
          //       (Number(calculatedAddonPrice) * Number(commissionAmount)) / 100;
          //   } else if (commissionType === "fixed") {
          //     calculatedAddonPrice -= Number(commissionAmount);
          //   }
          // }
          return calculatedAddonPrice;
        })[0]
    : null;

  const calculationsOfPackages = calculateTotalAmount(
    ratesOfPackages?.Price,
    // selectedDiscount?.promotionAmount,
    // selectedDiscount?.promotionMode,
    selectedAutomaticDiscount,
    selectedDiscount,
    // agentDetails?.agent_category_discount
    //   ? agentDetails?.agent_category_discount
    //   : 0,
    // agentDetails?.agent_discount_type
    //   ? agentDetails?.agent_discount_type
    //   : "percentage",
    ratesOfPackages?.NoPax,
    ratesOfPackages,
    bookingData?.childAge ? bookingData?.childAge : [],
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    volvoAddonPrice,
    markupData,
    commissionAmount,
    commissionType
  );

  const navigateToCheckOutPackage = () => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    navigate(
      `/checkout-package/${bookingData?.packageHandle}?location=${bookingData?.location}&guest=${bookingData?.guest}&children=${bookingData?.children}${childAgeParams}&checkinDay=${date.setDay}&checkinMonth=${date.setMonth}&checkinYear=${date.setYear}`,
      {
        state: {
          packageData: singlePackageData,
          bookingData: bookingData,
          selectedCategory: selectedCategory,
          calculationsOfPackages: calculationsOfPackages,
          selectedAddOns: selectedAddOns,
          customized: false,
          ratesOfPackages,
          markupData,
          selectedMealPlan: selectedMealPlan,
          allPackageHotels: allPackageHotels,
          commission: commission,
          // roomsDetails: roomsDetails,
          totalAddonsCost: totalAddonsCost,
          totalAddonsCostWithoutCommission: totalAddonsCostWithoutCommission,
          selectedAutomaticDiscount: selectedAutomaticDiscount,
          selectedDiscount: selectedDiscount,
        },
      }
    );
  };

  const navigateToCustomizePackage = () => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    navigate(
      `/customize-package/${bookingData?.packagehandle}?packagehandle=${
        bookingData?.packagehandle
      }&location=${bookingData?.location}&guest=${
        bookingData?.guest
      }&children=${
        bookingData?.children ? bookingData?.children : 0
      }${childAgeParams}&checkinDay=${date.setDay}&checkinMonth=${
        date.setMonth
      }&checkinYear=${date.setYear}`,
      {
        state: {
          packageData: singlePackageData,
          bookingData: bookingData,
          selectedCategory: selectedCategory,
          calculationsOfPackages: calculationsOfPackages,
        },
      }
    );
  };

  const under6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) < 6
  );
  const over6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) >= 6
  );

  const checkDates = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return currentDate >= start && currentDate <= end;
  };

  const checkBookingDates = (
    checkinBookingDate,
    checkoutBookingDate,
    discountStartDate,
    discountEndDate
  ) => {
    const checkin = new Date(checkinBookingDate);
    const checkout = new Date(checkoutBookingDate);
    const discountStart = new Date(discountStartDate);
    const discountEnd = new Date(discountEndDate);

    return checkin <= discountEnd && checkout >= discountStart;
  };

  const isPromotionApplicable = (userType, userId, promotionApplicable) => {
    if (
      Array.isArray(promotionApplicable) &&
      promotionApplicable.length === 0
    ) {
      return true;
    }

    if (promotionApplicable) {
      return promotionApplicable?.some((applicable) => {
        return (
          applicable?.applicableTo === userType &&
          applicable?.applicableIds?.includes(userId?.toString())
        );
      });
    }
  };

  const isBlackoutDate = (blackoutDates, checkDate) => {
    const normalizedCheckDate = new Date(checkDate).toLocaleDateString("en-CA");
    return blackoutDates.some((blackout) => {
      const blackoutDate = new Date(blackout.date).toISOString().split("T")[0];
      return blackoutDate === normalizedCheckDate;
    });
  };

  const isPromotionApplicableForEntity = (
    promotionEntities,
    currentEntityType,
    currentEntityId
  ) => {
    return promotionEntities.some((entity) => {
      return (
        entity.entityType === currentEntityType &&
        entity?.entityIds?.some((id) => Number(id) === Number(currentEntityId))
      );
    });
  };

  const evaluateConditions = (conditions, validity) => {
    const evaluateCondition = (condition, dateToCheck) => {
      const { conditionType, conditionCondition, conditionValue } = condition;
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(startDate.getDate() + 1);
      let inputDate = new Date(dateToCheck);
      let currentDate = new Date();
      let comparisonValue;
      let valueToCompare;

      const los = calculateLOS(startDate, endDate);

      if (conditionType === "booking_date") {
        comparisonValue = moment(currentDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");
      } else if (conditionType === "LOS") {
        comparisonValue = los;
        valueToCompare = conditionValue;
      } else {
        comparisonValue = moment(inputDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");
      }

      switch (conditionCondition) {
        case "=":
          return comparisonValue === valueToCompare;
        case ">":
          return comparisonValue > valueToCompare;
        case "!=":
          return comparisonValue != valueToCompare;
        case ">=":
          return comparisonValue >= valueToCompare;
        case "<":
          return comparisonValue < valueToCompare;
        case "<=":
          return comparisonValue <= valueToCompare;
        default:
          return false;
      }
    };

    let result = conditions.length > 0 ? true : false;

    for (const condition of conditions) {
      const conditionResult = evaluateCondition(condition, validity);

      if (condition.logicalCondition === "OR") {
        result = result || conditionResult;
      } else {
        result = result && conditionResult;
      }
    }
    return result;
  };

  const fetchPromotions = async ({
    checkInDateFromBooking,
    packageIdFromBooking,
  }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/promotions`,
        requestOptions
      );
      const result = await response.json();
      setPromotions(result?.data);

      const applicableAutomaticPromotion = result?.data
        ?.filter((item) => {
          const isAutomatic = item.promotionType === "automatic";

          const isValidDate = checkDates(item.startDate, item.endDate);

          const isBookingWithinDiscount = checkBookingDates(
            checkInDateFromBooking,
            checkInDateFromBooking,
            item.startDate,
            item.endDate
          );

          const isUserEligible = isPromotionApplicable(
            userType === "customer"
              ? "customers"
              : userType === "agent"
              ? "agents"
              : "",
            userDetails?.id,
            item.promotionApplicable
          );

          const blackoutDates = item.promotionBlackOutDate || [];

          const isBlackout = isBlackoutDate(blackoutDates, startDate);

          const isApplicableForEntity = isPromotionApplicableForEntity(
            item.promotionEntity,
            "package",
            packageIdFromBooking
          );

          const isConditionsValid = evaluateConditions(
            item.promotionConditions,
            startDate
          );

          return (
            isAutomatic &&
            isValidDate &&
            isBookingWithinDiscount &&
            isUserEligible &&
            !isBlackout &&
            isApplicableForEntity &&
            isConditionsValid
          );
        })
        ?.sort((a, b) => b.stackable - a.stackable);

      let selectedPromotions = [];

      if (applicableAutomaticPromotion.length > 0) {
        const firstPromotion = applicableAutomaticPromotion[0];
        selectedPromotions.push(firstPromotion);

        if (applicableAutomaticPromotion.length > 1) {
          const secondPromotion = applicableAutomaticPromotion[1];
          if (firstPromotion.stackable && secondPromotion.stackable) {
            selectedPromotions.push(secondPromotion);
          }
        }
      }

      setSelectedAutomaticDiscount(selectedPromotions);
    } catch (error) {
      console.error("Error fetching promotions:", error);
    }
  };

  const fetchCommissions = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/commission/package/${packageId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        if (data?.data?.commissionType != "none") {
          setCommission(data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (packageId && userType === "agent") {
      fetchCommissions();
    }
  }, [packageId, userType]);

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <Header />

      <PageMetaData
        title={`${
          singlePackageData?.packageName &&
          singlePackageData?.packageName !== "" &&
          singlePackageData?.packageName !== undefined &&
          singlePackageData?.packageName
        } - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />

      <OgMetaTitles
        title={`${
          singlePackageData?.packageName &&
          singlePackageData?.packageName !== "" &&
          singlePackageData?.packageName !== undefined &&
          singlePackageData?.packageName
        } - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/package/${singlePackageData?.packagehandle}?location=&guest=2&children=0&packagehandle=${singlePackageData?.packagehandle}#package-information`}
      />
      {/* <SingleSearchForm /> */}
      {/* <PageLinks /> */}

      <section>
        <div className="container">
          {/* <PackageDetails packageData={singlePackageData} /> */}
          <SinglePackageHeader
            allPackageHotels={allPackageHotels}
            selectedCategory={selectedCategory}
            packageData={singlePackageData}
          />
          <main className="justify_content_center flex  packages_flex_wrap">
            <div className="reservation-content">
              <div
                className="m_0 package-update-profile-tab-form profile_tab_form"
                style={{ padding: "0" }}
              >
                <div className="package-update-content">
                  {/* {singlePackageData?.canCustomise ? (
                    <TabHeaderCustomizable
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : ( */}
                  <TabHeader
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {/* )} */}

                  <div className="package-update-content-containers">
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>

            <aside id="Price-Break">
              <div className="common_background common_background_parent_sticky">
                {/* <div>
                  <label>Select Meal Plan</label>
                  <select
                    className="w-full"
                    name="guide_required"
                  value={formData.guide_required}
                  onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div> */}

                {singlePackageData &&
                  singlePackageData?.packageRates &&
                  singlePackageData?.packageRates.length > 0 && (
                    <div>
                      <label>Select Meal Plan</label>
                      <select
                        className="w-full"
                        name="meal_plan"
                        value={selectedMealPlan}
                        onChange={(e) => setSelectedMealPlan(e.target.value)}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {Array.from(
                          new Set(
                            singlePackageData?.packageRates.map(
                              (rate) => rate.mealPlan
                            )
                          )
                        ).map((mealPlan) => (
                          <option key={mealPlan} value={mealPlan}>
                            {mealPlan.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                <div className="flex justify_content_between align_items_center">
                  <h4 style={{ fontSize: "14px", padding: "10px 0px" }}>
                    Fare Summary
                  </h4>

                  <div onClick={togglePaymentDetails}>
                    <button
                      className={`${
                        activePayment === "priceSummary" ? "" : ""
                      }`}
                      onClick={handleButtonClick}
                    >
                      <small className="link_btn">
                        {activePayment === "priceSummary"
                          ? "View Breakup"
                          : "Minimize Breakup"}{" "}
                      </small>
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          transition: "transform 0.3s",
                          transform: paymentDetails
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <path
                          d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                          stroke="#222222"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  {/* {activePayment === "priceSummary" && ( */}
                  <>
                    {activePayment !== "priceSummary" && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Adult Cost{" "}
                            <small>
                              (
                              {/* {commission && Object.keys(commission).length > 0 && (
                                <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                  {(calculationsOfPackages?.onlyAdultCost /
                                    calculationsOfPackages?.adultNumber) +
                                    calculationsOfPackages?.agentSavings}
                                  {" "}
                                </span>
                              )} */}
                              {calculationsOfPackages?.onlyAdultCost /
                                calculationsOfPackages?.adultNumber}{" "}
                              X {calculationsOfPackages?.adultNumber})
                            </small>
                          </p>
                          <span
                            className={`room_text-right ${
                              Number(calculationsOfPackages?.onlyAdultCost) <=
                                0 && "filterBlur3"
                            }`}
                          >
                            {formatIndianCurrency(
                              calculationsOfPackages?.onlyAdultCost
                            )}
                          </span>
                        </div>

                        {/* {commission && Object.keys(commission).length > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Your Amount{" "}

                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                calculationsOfPackages?.calculatedAgentAmount
                              )}
                            </span>
                          </div>
                        )} */}

                        {calculationsOfPackages?.extraAdultNumber > 0 &&
                        calculationsOfPackages?.ExtraAdultCost ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Extra Adult Cost{" "}
                              <small>
                                (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {(calculationsOfPackages?.ExtraAdultCost /
                                      calculationsOfPackages?.extraAdultNumber) +
                                      calculationsOfPackages?.extraAdultSavings}
                                    {" "}
                                  </span>
                                )} */}
                                {calculationsOfPackages?.ExtraAdultCost /
                                  calculationsOfPackages?.extraAdultNumber}{" "}
                                X {calculationsOfPackages?.extraAdultNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.ExtraAdultCost
                              )}
                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        ) : null}

                        {under6AgeChildren.length > 0 &&
                        calculationsOfPackages?.childNumber > 0 &&
                        calculationsOfPackages?.childCostWithOutBed ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`< 6 yrs`}){" "}
                              <small>
                                ̀ (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {(calculationsOfPackages?.childCostWithOutBed /
                                      under6AgeChildren.length) +
                                      calculationsOfPackages?.childSavingsWithoutBed}
                                    {" "}
                                  </span>
                                )} */}
                                {calculationsOfPackages?.childCostWithOutBed /
                                  under6AgeChildren.length}{" "}
                                X {under6AgeChildren.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(
                                  calculationsOfPackages?.childCostWithOutBed
                                ) <= 0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCostWithOutBed
                              )}
                            </span>
                          </div>
                        ) : null}

                        {over6AgeChildren.length > 0 &&
                        calculationsOfPackages?.childNumber > 0 &&
                        calculationsOfPackages?.childCostWithBed ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`> 6 yrs`}){" "}
                              <small>
                                ̀ (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {(calculationsOfPackages?.childCostWithBed /
                                      under6AgeChildren.length) +
                                      calculationsOfPackages?.childSavingsWithBed}
                                    {" "}
                                  </span>
                                )} */}
                                {calculationsOfPackages?.childCostWithBed /
                                  over6AgeChildren.length}{" "}
                                X {over6AgeChildren.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${
                                Number(
                                  calculationsOfPackages?.childCostWithBed
                                ) <= 0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCostWithBed
                              )}
                            </span>
                          </div>
                        ) : null}
                      </>
                    )}

                    <div className="flex justify_content_between mt-10">
                      <p>
                        <b>Total Package Cost</b>
                      </p>
                      <span
                        className={`room_text-right alert_text ${
                          Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                        }`}
                      >
                        <b>
                          {" "}
                          {formatIndianCurrency(
                            calculationsOfPackages?.basePrice
                          )}
                        </b>
                      </span>
                    </div>

                    {activePayment !== "priceSummary" && (
                      <>
                        {(selectedAutomaticDiscount.length > 0 ||
                          selectedDiscount.length > 0) && (
                          <div className="mt-10">
                            <p>
                              <b>
                                Package Discount
                                {selectedAutomaticDiscount?.length > 1
                                  ? "s"
                                  : ""}
                              </b>
                            </p>
                            {selectedAutomaticDiscount?.map(
                              (discount, index) => (
                                <div
                                  className="flex justify_content_between mt-2"
                                  key={discount.id}
                                >
                                  <span className="discount-name">
                                    {discount.promotionName}
                                  </span>
                                  <span className="room_text-right green">
                                    {discount.promotionMode === "percentage"
                                      ? `${+discount.promotionAmount}%`
                                      : `${formatIndianCurrency(
                                          discount.promotionAmount
                                        )}`}
                                  </span>
                                </div>
                              )
                            )}

                            {selectedDiscount &&
                              selectedDiscount?.length > 0 &&
                              selectedDiscount?.map((discount, index) => (
                                <div
                                  className="flex justify_content_between mt-2"
                                  key={discount.id}
                                >
                                  <span className="discount-name">
                                    {discount.promotionName}
                                  </span>
                                  <span className="room_text-right green">
                                    {discount.promotionMode === "percentage"
                                      ? `${+discount.promotionAmount}%`
                                      : `${formatIndianCurrency(
                                          discount.promotionAmount
                                        )}`}
                                  </span>
                                </div>
                              ))}
                          </div>
                        )}

                        {calculationsOfPackages?.discount > 0 && (
                          <div className="flex justify_content_between mt-10 border-bottom-one ">
                            <p>Package Cost (After Disc.)</p>
                            <span
                              className={`room_text-right ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.priceAfterDiscount
                              )}
                            </span>
                          </div>
                        )}

                        {commission && Object.keys(commission).length > 0 && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Commission{" "}
                                <small>
                                  ({" "}
                                  {commission.commissionType === "fixed" ? (
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        commission?.commissionAmount
                                      )}
                                    </span>
                                  ) : (
                                    <span className="room_text-right">
                                      {commission?.commissionAmount}%
                                    </span>
                                  )}
                                  )
                                </small>
                              </p>

                              {formatIndianCurrency(
                                calculationsOfPackages?.calculatedCommissionAmount
                              )}
                            </div>
                          </>
                        )}

                        {selectedAddOns && selectedAddOns.length > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>Addons Price</p>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                              }}
                            >
                              {commission &&
                                Object.keys(commission).length > 0 && (
                                  <small
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {formatIndianCurrency(
                                      totalAddonsCostWithoutCommission
                                    )}
                                  </small>
                                )}
                              <b>{formatIndianCurrency(totalAddonsCost)}</b>
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <p>Subtotal </p>

                          {formatIndianCurrency(
                            calculationsOfPackages?.subtotal
                          )}
                        </div>
                      </>
                    )}

                    {/* <div className="flex justify_content_between mt-10">
                      <p>
                        <b> Markup </b>
                      </p>
                      <span className="room_text-right">
                        {markupData?.amount}
                      </span>
                    </div> */}

                    {/* {calculationsOfPackages?.discount > 0 && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Discount(
                            {calculationsOfPackages?.discountType ===
                              "percentage"
                              ? `${calculationsOfPackages?.discountPercentage.toFixed()}%`
                              : formatIndianCurrency(
                                calculationsOfPackages?.discountAmount
                              )}
                            )
                          </p>
                          <span
                            className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                              0 && "filterBlur3"
                              }`}
                          >
                            {formatIndianCurrency(
                              calculationsOfPackages?.discountAmount
                            )}
                          </span>
                        </div>
                      </>
                    )} */}

                    <div className="flex justify_content_between mt-10 border-bottom-one ">
                      <p>GST(5%) </p>
                      <span
                        className={`room_text-right  ${
                          Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                        }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.taxAmount
                        )}
                      </span>
                    </div>

                    {/* {commission && Object.keys(commission).length > 0 && (
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Your Savings
                        </p>
                        <span className="room_text-right">
                          {formatIndianCurrency(
                            calculationsOfPackages?.totalAgentSavings
                          )}
                        </span>
                      </div>
                    )} */}

                    <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>Total Amount to be paid</b>
                      </h6>
                      <h6>
                        <span
                          className={` ${
                            Number(calculationsOfPackages?.totalAmount) <= 0 &&
                            "filterBlur3"
                          }`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                          }}
                        >
                          {/* {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.calculatedCommissionAmount && (
                            <small style={{ color: 'red', textDecoration: 'line-through' }}>
                              {formatIndianCurrency(
                                Number(calculationsOfPackages?.calculatedCommissionAmount) + Number(calculationsOfPackages?.totalAmount)
                              )}
                            </small>
                          )} */}
                          <b>
                            {formatIndianCurrency(
                              calculationsOfPackages?.totalAmount
                            )}
                          </b>
                        </span>
                      </h6>
                    </div>

                    {/* <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>
                          Per Pax Cost{" "}
                          <small>({calculationsOfPackages?.perPaxCount})</small>
                        </b>
                      </h6>
                      <h6>
                        <b>
                          {" "}
                          {formatIndianCurrency(
                            calculationsOfPackages?.perPaxCost
                          )}
                        </b>
                      </h6>
                    </div>{" "} */}
                  </>
                  {/* )} */}
                </div>

                {promotions.length > 0 && (
                  <>
                    {promotions.some(
                      (item) =>
                        item.promotionType === "coupon" &&
                        (() => {
                          const isValidDate = checkDates(
                            item.startDate,
                            item.endDate
                          );
                          const isBookingWithinDiscount = checkBookingDates(
                            startDate,
                            startDate,
                            item.startDate,
                            item.endDate
                          );
                          const isUserEligible = isPromotionApplicable(
                            userType === "customer"
                              ? "customers"
                              : userType === "agent"
                              ? "agents"
                              : "",
                            userDetails?.id,
                            item.promotionApplicable
                          );
                          const blackoutDates =
                            item.promotionBlackOutDate || [];
                          const isBlackout = isBlackoutDate(
                            blackoutDates,
                            startDate
                          );
                          const isApplicableForEntity =
                            isPromotionApplicableForEntity(
                              item.promotionEntity,
                              "package",
                              singlePackageData?.packageId
                            );
                          const isConditionsValid = evaluateConditions(
                            item.promotionConditions,
                            startDate
                          );

                          return (
                            isValidDate &&
                            isBookingWithinDiscount &&
                            isUserEligible &&
                            !isBlackout &&
                            isApplicableForEntity &&
                            isConditionsValid
                          );
                        })()
                    ) && (
                      <div
                        className="common_background package_price_breakup_discount"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="flex justify_content_between cursor_pointer">
                          <div className="flex justify_content_between w-100">
                            <div>
                              <h5 className="text-left">Discounts</h5>
                            </div>
                            {selectedDiscount && (
                              <button
                                className="view_btn"
                                onClick={() => setSelectedDiscount([])}
                              >
                                clear
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          {promotions?.map((item, index) => {
                            const isCouponCode =
                              item?.promotionType === "coupon" ? true : false;
                            const isValidDate = checkDates(
                              item.startDate,
                              item.endDate
                            );
                            const isBookingWithinDiscount = checkBookingDates(
                              startDate,
                              startDate,
                              item.startDate,
                              item.endDate
                            );
                            const isUserEligible = isPromotionApplicable(
                              userType === "customer"
                                ? "customers"
                                : userType === "agent"
                                ? "agents"
                                : "",
                              userDetails?.id,
                              item.promotionApplicable
                            );
                            const blackoutDates =
                              item.promotionBlackOutDate || [];
                            const isBlackout = isBlackoutDate(
                              blackoutDates,
                              startDate
                            );
                            const isApplicableForEntity =
                              isPromotionApplicableForEntity(
                                item.promotionEntity,
                                "package",
                                singlePackageData?.packageId
                              );

                            const isConditionsValid = evaluateConditions(
                              item.promotionConditions,
                              startDate
                            );

                            return (
                              isCouponCode &&
                              isValidDate &&
                              isBookingWithinDiscount &&
                              isUserEligible &&
                              !isBlackout &&
                              isApplicableForEntity &&
                              isConditionsValid && (
                                <div
                                  key={index}
                                  className="flex discountSingleDiv justify_content_between w-100"
                                >
                                  <div className="flex">
                                    <label
                                      style={{
                                        textTransform: "capitalize",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      htmlFor={item?.promotionName}
                                    >
                                      {" "}
                                      <input
                                        // checked={
                                        //   selectedDiscount?.id === item?.id
                                        // }
                                        // onChange={() =>
                                        //   handleDiscountChange(item)
                                        // }
                                        // type="radio"
                                        // id={item?.promotionName}

                                        checked={selectedDiscount?.some(
                                          (discount) => discount.id === item.id
                                        )}
                                        onChange={() =>
                                          handleDiscountChange(item)
                                        }
                                        type="radio"
                                        id={item.promotionName}
                                      />
                                      <div>
                                        <div>
                                          {item?.promotionName?.replace(
                                            /-/g,
                                            " "
                                          )}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                  <div>
                                    {item?.promotionMode === "fixed" ? (
                                      formatIndianCurrency(
                                        item?.promotionAmount
                                      )
                                    ) : (
                                      <>{item?.promotionAmount}%</>
                                    )}
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="buttons flex_direction_col">
                  {/* <Link to={"/checkout-package"}> */}
                  {Number(calculationsOfPackages?.totalAmount) > 0 ? (
                    <>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          onClick={navigateToCustomizePackage}
                          className="common_btn"
                        >
                          Customize & Book
                        </button>
                      )} */}
                      <button
                        style={{ marginTop: "10px", borderRadius: "15px" }}
                        onClick={navigateToCheckOutPackage}
                        className="primary_btn"
                      >
                        Book Now
                      </button>
                    </>
                  ) : (
                    <>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          onClick={navigateToCustomizePackage}
                          className="common_btn_disabled"
                        >
                          Customize
                        </button>
                      )} */}
                      {/* <button
                        onClick={navigateToCheckOutPackage}
                        className="common_btn_disabled"
                        disabled
                      >
                        Book Now
                      </button> */}
                    </>
                  )}
                  {/* </Link> */}
                  <button
                    onClick={(e) => handleshowRatePopup(e)}
                    className="secondary_btn mt-10 border-radius"
                  >
                    Get Quote
                  </button>

                  {/* {singlePackageData?.packageAddons &&
                    singlePackageData?.packageAddons.length > 0 && (
                      <div className="mt-20">
                        {singlePackageData.packageAddons.some(
                          (item) => !item.detail.includes("Volvo")
                        ) && <h5 className="mb-10">Addons</h5>}
                        {singlePackageData.packageAddons
                          .filter((item) => !item.detail.includes("Volvo"))
                          .map((item, index) => (
                            <div
                              key={index}
                              className="flex justify_content_between w-100 mt-10 align_items_start"
                            >
                              <div
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                <div
                                  style={{ cursor: "pointer", width: "100%" }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.detail}{" "}
                                    {formatIndianCurrency(item.price)}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="flex justify_content_end"
                                style={{
                                  width: "60%",
                                }}
                              >
                                <button
                                  style={{
                                    fontSize: "10px",
                                  }}
                                  onClick={() => handleSelectedAddOns(item)}
                                >
                                  {selectedAddOns.some(
                                    (addon) => addon.id === item.id
                                  ) ? (
                                    <div style={{ color: "red" }}>
                                      <svg
                                        fill="red"
                                        width="20px"
                                        height="14px"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <defs></defs>
                                        <path
                                          id="cancel"
                                          className="cls-1"
                                          d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                          transform="translate(-924 -96)"
                                        />
                                      </svg>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        background: "#016937",
                                        color: "#fff",
                                        borderRadius: "4px",
                                        padding: "5px 3px 5px 7px",
                                      }}
                                    >
                                      {" "}
                                      Add
                                      <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#ffffff">
                                        <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                                    </div>
                                  )}
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )} */}

                  {singlePackageData?.packageAddons &&
                    singlePackageData?.packageAddons.length > 0 && (
                      <div className="mt-20">
                        <div
                          className="flex align_items_center justify_content_between"
                          onClick={toggleAccordion}
                        >
                          <h5>Addons</h5>
                          <svg
                            width="25px"
                            height="25px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              cursor: "pointer",
                              transform: isExpanded
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                          >
                            <path
                              d="M7 10L12 15L17 10"
                              stroke="#016937"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          className="overflow-width"
                          style={{
                            display: isExpanded ? "block" : "none",
                          }}
                        >
                          {singlePackageData.packageAddons
                            .filter(
                              (item) =>
                                !item.detail.includes("Volvo") &&
                                item.addonType !== "ticket"
                            )
                            .map((item, index) => {
                              let finalAddonPrice = item?.price;
                              let priceWithMarkup = item?.price;

                              if (markupData) {
                                const markup = markupData;
                                if (markup.markupType === "percentage") {
                                  finalAddonPrice +=
                                    (item.price * parseFloat(markup.amount)) /
                                    100;
                                } else if (markup.markupType === "fixed") {
                                  finalAddonPrice += parseFloat(markup.amount);
                                }
                              }
                              priceWithMarkup = finalAddonPrice;

                              if (
                                commission?.commissionAmount &&
                                commission?.commissionType
                              ) {
                                if (
                                  commission?.commissionType === "percentage"
                                ) {
                                  finalAddonPrice -=
                                    (Number(finalAddonPrice) *
                                      Number(commission?.commissionAmount)) /
                                    100;
                                } else if (
                                  commission?.commissionType === "fixed"
                                ) {
                                  finalAddonPrice = Number(
                                    commission?.commissionAmount
                                  );
                                }
                              }

                              return (
                                <div
                                  key={index}
                                  className="flex justify_content_between w-100 mt-10 align_items_start"
                                  style={{ padding: "7px 5px 0 0" }}
                                >
                                  <div
                                    style={{ cursor: "pointer", width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {item.detail}{" "}
                                      {commission?.commissionAmount &&
                                        commission?.commissionType && (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "red",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {formatIndianCurrency(
                                              priceWithMarkup
                                            )}{" "}
                                            {item.perPerson ? `* ${guest}` : ""}
                                          </span>
                                        )}
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "#222222",
                                        }}
                                      >
                                        {formatIndianCurrency(finalAddonPrice)}{" "}
                                        {item.perPerson ? `* ${guest}` : ""}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex justify_content_end"
                                    style={{ width: "60%" }}
                                  >
                                    <button
                                      style={{ fontSize: "10px" }}
                                      onClick={() =>
                                        handleSelectedAddOns({
                                          ...item,
                                          finalAddonPrice,
                                          priceWithMarkup,
                                        })
                                      }
                                    >
                                      {selectedAddOns.some(
                                        (addon) => addon.id === item.id
                                      ) ? (
                                        <div style={{ color: "red" }}>
                                          <svg
                                            fill="red"
                                            width="20px"
                                            height="14px"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              id="cancel"
                                              className="cls-1"
                                              d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                              transform="translate(-924 -96)"
                                            />
                                          </svg>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            background: "#016937",
                                            color: "#fff",
                                            borderRadius: "4px",
                                            padding: "5px 3px 5px 7px",
                                            display: "flex",
                                          }}
                                        >
                                          Add
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="16px"
                                            viewBox="0 -960 960 960"
                                            width="16px"
                                            fill="#ffffff"
                                          >
                                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                          </svg>
                                        </div>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <OurQuery />
              <ChooseUs />
              <SendItinerary singlePackageData={singlePackageData} />
            </aside>

            {mobilePriceBreakup && (
              <>
                <div className="flex justify_content_between total_amount align_items_center">
                  <div>
                    <h6 className="font_12">Total Amount to be paid </h6>
                    <h6>
                      <span
                        className={` ${
                          Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                        }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.totalAmount
                        )}{" "}
                        <svg
                          onClick={() => setPriceBreakupPopup(true)}
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0,0,256,256"
                          width="20px"
                          height="20px"
                          fillRule="nonzero"
                        >
                          <g
                            fill="#ffffff"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            stroke-miterlimit="10"
                            stroke-dasharray=""
                            stroke-dashoffset="0"
                            font-family="none"
                            font-weight="none"
                            font-size="none"
                            text-anchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(8,8)">
                              <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </h6>{" "}
                  </div>
                  <div className="flex flex_gap_10 align_items_center">
                    <div>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          style={{
                            background: "#fff",
                            color: "#016937",
                            padding: "7px",
                            fontSize: "10px",
                            borderRadius: "4px",
                          }}
                          onClick={navigateToCustomizePackage}
                        >
                          <b> Customize</b>
                        </button>
                      )} */}
                    </div>
                    <div>
                      {" "}
                      <button
                        style={{ background: "white", color: "#016937" }}
                        onClick={navigateToCheckOutPackage}
                        className="primary_btn fit-width"
                      >
                        <b> Book Now</b>
                      </button>
                    </div>
                  </div>
                </div>
                {priceBreakupPopup && (
                  <div className="bg_back_drop_overlay">
                    <div
                      className="common_background common_background_parent_sticky"
                      id="PriceBreak"
                    >
                      <div className="flex justify_content_end p_b_10 ">
                        <svg
                          onClick={() => setPriceBreakupPopup(false)}
                          width="14"
                          height="13"
                          viewBox="0 0 14 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 12.5L13 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                          <path
                            d="M13 12.5L1 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                        </svg>
                      </div>

                      {singlePackageData &&
                        singlePackageData?.packageRates &&
                        singlePackageData?.packageRates.length > 0 && (
                          <div>
                            <label>Select Meal Plan</label>
                            <select
                              className="w-full"
                              name="meal_plan"
                              value={selectedMealPlan}
                              onChange={(e) =>
                                setSelectedMealPlan(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Select an option
                              </option>
                              {Array.from(
                                new Set(
                                  singlePackageData?.packageRates.map(
                                    (rate) => rate.mealPlan
                                  )
                                )
                              ).map((mealPlan) => (
                                <option key={mealPlan} value={mealPlan}>
                                  {mealPlan.toUpperCase()}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      <div className="flex justify_content_between align_items_center">
                        <h4 style={{ fontSize: "14px" }}>Fare Summary</h4>
                        <div onClick={togglePaymentDetails}>
                          <button
                            className={`${
                              activePayment === "priceSummary" ? "" : ""
                            }`}
                            onClick={handleButtonClick}
                          >
                            <small className="link_btn">
                              {activePayment === "priceSummary"
                                ? "View Breakup"
                                : "Minimize Breakup"}{" "}
                            </small>
                            <svg
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                transition: "transform 0.3s",
                                transform: paymentDetails
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                            >
                              <path
                                d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                stroke="#222222"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        {/* {activePayment === "priceSummary" && ( */}
                        <>
                          {activePayment !== "priceSummary" && (
                            <>
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Adult Cost{" "}
                                  <small>
                                    {" "}
                                    (
                                    {calculationsOfPackages?.onlyAdultCost /
                                      calculationsOfPackages?.adultNumber}{" "}
                                    X {calculationsOfPackages?.adultNumber})
                                  </small>
                                </p>
                                <span
                                  className={`room_text-right ${
                                    Number(
                                      calculationsOfPackages?.onlyAdultCost
                                    ) <= 0 && "filterBlur3"
                                  }`}
                                >
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.onlyAdultCost
                                  )}
                                </span>
                              </div>

                              {calculationsOfPackages?.extraAdultNumber > 0 &&
                              calculationsOfPackages?.ExtraAdultCost ? (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Extra Adult Cost{" "}
                                    <small>
                                      {" "}
                                      (
                                      {calculationsOfPackages?.ExtraAdultCost /
                                        calculationsOfPackages?.extraAdultNumber}{" "}
                                      X{" "}
                                      {calculationsOfPackages?.extraAdultNumber}
                                      )
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${
                                      Number(
                                        calculationsOfPackages?.totalAmount
                                      ) <= 0 && "filterBlur3"
                                    }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.ExtraAdultCost
                                    )}
                                    {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                  </span>
                                </div>
                              ) : null}

                              {/* packageRate */}
                              {/* {commission &&
                                Object.keys(commission).length > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p>
                                      Your Amount{" "}
                                      <small
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {commission.commissionType}
                                      </small>
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        calculateTotalAmount?.packageRate
                                      )}
                                    </span>
                                  </div>
                                )} */}

                              {/* {calculationsOfPackages?.extraAdultNumber > 0 && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    <b>Total Adult Cost</b>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(
                                      calculationsOfPackages?.totalAmount
                                    ) <= 0 && "filterBlur3"
                                      }`}
                                  >
                                    <b>
                                      {formatIndianCurrency(
                                        calculationsOfPackages?.ExtraAdultCost +
                                        calculationsOfPackages?.onlyAdultCost
                                      )}
                                    </b>

                                  </span>
                                </div>
                              )} */}

                              {/* {calculationsOfPackages?.childNumber > 0 && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost{" "}
                                    <small>
                                      {" "}
                                      (
                                      {calculationsOfPackages?.childCosts /
                                        calculationsOfPackages?.childNumber}{" "}
                                      X {calculationsOfPackages?.childNumber})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(
                                      calculationsOfPackages?.childCosts
                                    ) <= 0 && "filterBlur3"
                                      }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.childCosts
                                    )}
                                  </span>
                                </div>
                              )} */}

                              {under6AgeChildren.length > 0 &&
                              calculationsOfPackages?.childNumber > 0 &&
                              calculationsOfPackages?.childCostWithOutBed ? (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost ({`< 6 yrs`}){" "}
                                    <small>
                                      ̀ (
                                      {calculationsOfPackages?.childCostWithOutBed /
                                        under6AgeChildren.length}{" "}
                                      X {under6AgeChildren.length})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${
                                      Number(
                                        calculationsOfPackages?.childCostWithOutBed
                                      ) <= 0 && "filterBlur3"
                                    }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.childCostWithOutBed
                                    )}
                                  </span>
                                </div>
                              ) : null}

                              {over6AgeChildren.length > 0 &&
                              calculationsOfPackages?.childNumber > 0 &&
                              calculationsOfPackages?.childCostWithBed ? (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost ({`> 6 yrs`}){" "}
                                    <small>
                                      ̀ (
                                      {calculationsOfPackages?.childCostWithBed /
                                        over6AgeChildren.length}{" "}
                                      X {over6AgeChildren.length})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${
                                      Number(
                                        calculationsOfPackages?.childCostWithBed
                                      ) <= 0 && "filterBlur3"
                                    }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.childCostWithBed
                                    )}
                                  </span>
                                </div>
                              ) : null}
                            </>
                          )}

                          <div className="flex justify_content_between mt-10">
                            <p>
                              <b>Total Package Cost</b>
                            </p>
                            <span
                              className={`room_text-right alert_text ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              <b>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.basePrice
                                )}
                              </b>
                            </span>
                          </div>

                          {activePayment !== "priceSummary" && (
                            <>
                              {(selectedAutomaticDiscount.length > 0 ||
                                selectedDiscount.length > 0) && (
                                <div className="mt-10">
                                  <p>
                                    <b>
                                      Package Discount
                                      {selectedAutomaticDiscount?.length > 1
                                        ? "s"
                                        : ""}
                                    </b>
                                  </p>
                                  {selectedAutomaticDiscount?.map(
                                    (discount, index) => (
                                      <div
                                        className="flex justify_content_between mt-2"
                                        key={discount.id}
                                      >
                                        <span className="discount-name">
                                          {discount.promotionName}
                                        </span>
                                        <span className="room_text-right green">
                                          {discount.promotionMode ===
                                          "percentage"
                                            ? `${+discount.promotionAmount}%`
                                            : `${formatIndianCurrency(
                                                discount.promotionAmount
                                              )}`}
                                        </span>
                                      </div>
                                    )
                                  )}

                                  {selectedDiscount &&
                                    selectedDiscount?.length > 0 &&
                                    selectedDiscount?.map((discount, index) => (
                                      <div
                                        className="flex justify_content_between mt-2"
                                        key={discount.id}
                                      >
                                        <span className="discount-name">
                                          {discount.promotionName}
                                        </span>
                                        <span className="room_text-right green">
                                          {discount.promotionMode ===
                                          "percentage"
                                            ? `${+discount.promotionAmount}%`
                                            : `${formatIndianCurrency(
                                                discount.promotionAmount
                                              )}`}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              )}

                              {calculationsOfPackages?.discount > 0 && (
                                <div className="flex justify_content_between mt-10 border-bottom-one ">
                                  <p>Package Cost (After Disc.)</p>
                                  <span
                                    className={`room_text-right ${
                                      Number(
                                        calculationsOfPackages?.totalAmount
                                      ) <= 0 && "filterBlur3"
                                    }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.priceAfterDiscount
                                    )}
                                  </span>
                                </div>
                              )}

                              {commission &&
                                Object.keys(commission).length > 0 && (
                                  <>
                                    <div className="flex justify_content_between mt-10">
                                      <p>
                                        Commission{" "}
                                        <small>
                                          ({" "}
                                          {commission.commissionType ===
                                          "fixed" ? (
                                            <span className="room_text-right">
                                              {formatIndianCurrency(
                                                commission?.commissionAmount
                                              )}
                                            </span>
                                          ) : (
                                            <span className="room_text-right">
                                              {commission?.commissionAmount}%
                                            </span>
                                          )}
                                          )
                                        </small>
                                      </p>

                                      {formatIndianCurrency(
                                        calculationsOfPackages?.calculatedCommissionAmount
                                      )}
                                    </div>
                                  </>
                                )}

                              {selectedAddOns && selectedAddOns.length > 0 && (
                                <div className="flex justify_content_between mt-10">
                                  <p>Addons Price</p>
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "end",
                                    }}
                                  >
                                    {commission &&
                                      Object.keys(commission).length > 0 && (
                                        <small
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {formatIndianCurrency(
                                            totalAddonsCostWithoutCommission
                                          )}
                                        </small>
                                      )}
                                    <b>
                                      {formatIndianCurrency(totalAddonsCost)}
                                    </b>
                                  </span>
                                </div>
                              )}

                              <div className="flex justify_content_between mt-10">
                                <p>Subtotal </p>

                                {formatIndianCurrency(
                                  calculationsOfPackages?.subtotal
                                )}
                              </div>
                            </>
                          )}

                          {/* {calculationsOfPackages?.discount > 0 && (
                            <>
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Discount (
                                  {calculationsOfPackages?.discountPercentage}
                                  %)
                                </p>
                                <span
                                  className={`room_text-right ${Number(
                                    calculationsOfPackages?.totalAmount
                                  ) <= 0 && "filterBlur3"
                                    }`}
                                >
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.discountAmount
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                          
                          {calculationsOfPackages?.discount > 0 && (
                            <div className="flex justify_content_between mt-10 border-bottom-one ">
                              <p>
                                Package Cost (After Disc.)
                              </p>
                              <span
                                className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                                  }`}
                              >
                                {formatIndianCurrency(
                                  calculationsOfPackages?.priceAfterDiscount
                                )}
                              </span>
                            </div>
                          )} */}

                          <div className="flex justify_content_between mt-10 border-bottom-one ">
                            <p>GST(5%) </p>
                            <span
                              className={`room_text-right  ${
                                Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                              }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.taxAmount
                              )}
                            </span>
                          </div>

                          {/* {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.calculatedCommissionAmount && (
                            <>
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Your Savings
                                </p>
                                <span className="room_text-right">
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.calculatedCommissionAmount
                                  )}
                                </span>
                              </div>
                            </>
                          )} */}

                          <div className="flex justify_content_between mt-10">
                            <h6>
                              <b>Total Amount to be paid</b>
                            </h6>
                            <h6>
                              <span
                                className={` ${
                                  Number(calculationsOfPackages?.totalAmount) <=
                                    0 && "filterBlur3"
                                }`}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "end",
                                }}
                              >
                                {/* {commission && Object.keys(commission).length > 0 && calculationsOfPackages?.calculatedCommissionAmount && (
                                  <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {formatIndianCurrency(
                                      Number(calculationsOfPackages?.calculatedCommissionAmount) + Number(calculationsOfPackages?.totalAmount)
                                    )}
                                  </small>
                                )} */}
                                <b>
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.totalAmount
                                  )}
                                </b>
                              </span>
                            </h6>
                          </div>
                          {/* <div className="flex justify_content_between mt-10">
      <h6>
        <b>
          Per Pax Cost{" "}
          <small>({calculationsOfPackages?.perPaxCount})</small>
        </b>
      </h6>
      <h6>
        <b>
          {" "}
          {formatIndianCurrency(
            calculationsOfPackages?.perPaxCost
          )}
        </b>
      </h6>
    </div>{" "} */}
                        </>
                        {/* )} */}
                      </div>

                      <div className="buttons flex_direction_col">
                        {/* <Link to={"/checkout-package"}> */}
                        {Number(calculationsOfPackages?.totalAmount) > 0 ? (
                          <>
                            {/* {singlePackageData?.canCustomise && (
                              <button
                                onClick={navigateToCustomizePackage}
                                className="common_btn sm-btn"
                              >
                                Customize & Book
                              </button>
                            )} */}
                          </>
                        ) : (
                          <>
                            {singlePackageData?.canCustomise && (
                              <button
                                onClick={navigateToCustomizePackage}
                                className="common_btn_disabled"
                              >
                                Customize
                              </button>
                            )}
                          </>
                        )}
                        {/* </Link> */}
                        <button
                          onClick={(e) => handleshowRatePopup(e)}
                          className="secondary_btn mt-10 border-radius"
                        >
                          Get Quote
                        </button>
                      </div>

                      {singlePackageData?.packageAddons &&
                        singlePackageData?.packageAddons.length > 0 && (
                          <div className="mt-20">
                            <div
                              className="flex align_items_center justify_content_between p_b_10"
                              onClick={toggleAccordion}
                            >
                              <h5>Addons</h5>
                              <svg
                                width="25px"
                                height="25px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  cursor: "pointer",
                                  transform: isExpanded
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                  transition: "transform 0.3s ease",
                                }}
                              >
                                <path
                                  d="M7 10L12 15L17 10"
                                  stroke="#016937"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div
                              className="overflow-width"
                              style={{
                                display: isExpanded ? "block" : "none",
                              }}
                            >
                              {singlePackageData.packageAddons
                                .filter(
                                  (item) =>
                                    !item.detail.includes("Volvo") &&
                                    item.addonType !== "ticket"
                                )
                                .map((item, index) => {
                                  let finalAddonPrice = item?.price;
                                  let priceWithMarkup = item?.price;

                                  if (markupData) {
                                    const markup = markupData;
                                    if (markup.markupType === "percentage") {
                                      finalAddonPrice +=
                                        (item.price *
                                          parseFloat(markup.amount)) /
                                        100;
                                    } else if (markup.markupType === "fixed") {
                                      finalAddonPrice += parseFloat(
                                        markup.amount
                                      );
                                    }
                                  }
                                  priceWithMarkup = finalAddonPrice;

                                  if (
                                    commission?.commissionAmount &&
                                    commission?.commissionType
                                  ) {
                                    if (
                                      commission?.commissionType ===
                                      "percentage"
                                    ) {
                                      finalAddonPrice -=
                                        (Number(finalAddonPrice) *
                                          Number(
                                            commission?.commissionAmount
                                          )) /
                                        100;
                                    } else if (
                                      commission?.commissionType === "fixed"
                                    ) {
                                      finalAddonPrice = Number(
                                        commission?.commissionAmount
                                      );
                                    }
                                  }

                                  return (
                                    <div
                                      key={index}
                                      className="flex justify_content_between w-100 mt-10 align_items_start"
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontSize: "15px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {item.detail}{" "}
                                          {commission?.commissionAmount &&
                                            commission?.commissionType && (
                                              <span
                                                style={{
                                                  fontSize: "12px",
                                                  color: "red",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {formatIndianCurrency(
                                                  priceWithMarkup
                                                )}{" "}
                                                {item.perPerson
                                                  ? `* ${guest}`
                                                  : ""}
                                              </span>
                                            )}
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "#222222",
                                            }}
                                          >
                                            {formatIndianCurrency(
                                              finalAddonPrice
                                            )}{" "}
                                            {item.perPerson ? `* ${guest}` : ""}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="flex justify_content_end"
                                        style={{ width: "60%" }}
                                      >
                                        <button
                                          style={{ fontSize: "10px" }}
                                          onClick={() =>
                                            handleSelectedAddOns({
                                              ...item,
                                              finalAddonPrice,
                                              priceWithMarkup,
                                            })
                                          }
                                        >
                                          {selectedAddOns.some(
                                            (addon) => addon.id === item.id
                                          ) ? (
                                            <div style={{ color: "red" }}>
                                              <svg
                                                fill="red"
                                                width="20px"
                                                height="14px"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  id="cancel"
                                                  className="cls-1"
                                                  d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                                  transform="translate(-924 -96)"
                                                />
                                              </svg>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                background: "#016937",
                                                color: "#fff",
                                                borderRadius: "4px",
                                                padding: "5px 3px 5px 7px",
                                                display: "flex",
                                              }}
                                            >
                                              Add
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="16px"
                                                viewBox="0 -960 960 960"
                                                width="16px"
                                                fill="#ffffff"
                                              >
                                                <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                              </svg>
                                            </div>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}

                      {/* {promotions.length > 0 && (
                        <>
                          {promotions.some((item) => {
                            const isValidDate = checkDates(item.startDate, item.endDate);
                            const isBookingWithinDiscount = checkBookingDates(
                              startDate,
                              startDate,
                              item.startDate,
                              item.endDate
                            );
                            const isUserEligible = isPromotionApplicable(
                              userType === "customer"
                                ? "customers"
                                : userType === "agent"
                                  ? "agents"
                                  : "",
                              userDetails?.id,
                              item.promotionApplicable
                            );
                            const blackoutDates = item.promotionBlackOutDate || [];
                            const isBlackout = isBlackoutDate(blackoutDates, startDate);
                            const isApplicableForEntity = isPromotionApplicableForEntity(
                              item.promotionEntity,
                              "package",
                              singlePackageData?.packageId
                            );
                            const isConditionsValid = evaluateConditions(
                              item.promotionConditions,
                              startDate
                            );

                            return (
                              isValidDate &&
                              isBookingWithinDiscount &&
                              isUserEligible &&
                              !isBlackout &&
                              isApplicableForEntity &&
                              isConditionsValid
                            );
                          }) && (
                              <div
                                className="common_background"
                                style={{ marginTop: "10px" }}
                              >
                                <div className="flex justify_content_between cursor_pointer">
                                  <div className="flex justify_content_between w-100">
                                    <div>
                                      <h5 className="text-left">Discounts</h5>
                                    </div>
                                    {selectedDiscount && (
                                      <button
                                        className="view_btn"
                                        onClick={() => setSelectedDiscount(null)}
                                      >
                                        clear
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {promotions
                                    ?.filter(item => item.promotionType === "coupon")
                                    ?.map((item, index) => {
                                      const isCouponCode = item?.method === "code";

                                      const isValidDate = checkDates(item.startDate, item.endDate);
                                      const isBookingWithinDiscount = checkBookingDates(
                                        startDate,
                                        startDate,
                                        item.startDate,
                                        item.endDate
                                      );
                                      const isUserEligible = isPromotionApplicable(
                                        userType === "customer"
                                          ? "customers"
                                          : userType === "agent"
                                            ? "agents"
                                            : "",
                                        userDetails?.id,
                                        item.promotionApplicable
                                      );
                                      const blackoutDates = item.promotionBlackOutDate || [];
                                      const isBlackout = isBlackoutDate(blackoutDates, startDate);
                                      const isApplicableForEntity = isPromotionApplicableForEntity(
                                        item.promotionEntity,
                                        "package",
                                        singlePackageData?.packageId
                                      );
                                      const isConditionsValid = evaluateConditions(
                                        item.promotionConditions,
                                        startDate
                                      );

                                      return (
                                        isValidDate &&
                                        isBookingWithinDiscount &&
                                        isUserEligible &&
                                        !isBlackout &&
                                        isApplicableForEntity &&
                                        isConditionsValid && (
                                          <div
                                            key={index}
                                            className="flex discountSingleDiv justify_content_between w-100"
                                          >
                                            <div className="flex">
                                              <label
                                                style={{
                                                  textTransform: "capitalize",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                                htmlFor={item?.promotionName}
                                              >
                                                <input
                                                  checked={selectedDiscount?.id === item?.id}
                                                  onChange={() => handleDiscountChange(item)}
                                                  type="radio"
                                                  id={item?.promotionName}
                                                />
                                                <div>
                                                  <div>
                                                    {item?.promotionName?.replace(/-/g, " ")}
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                            <div>
                                              {item?.promotionMode === "fixed" ? (
                                                formatIndianCurrency(item?.promotionAmount)
                                              ) : (
                                                <>{item?.promotionAmount}%</>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                        </>
                      )} */}

                      {promotions.length > 0 && (
                        <>
                          {promotions.some(
                            (item) =>
                              item.promotionType === "coupon" &&
                              (() => {
                                const isValidDate = checkDates(
                                  item.startDate,
                                  item.endDate
                                );
                                const isBookingWithinDiscount =
                                  checkBookingDates(
                                    startDate,
                                    startDate,
                                    item.startDate,
                                    item.endDate
                                  );
                                const isUserEligible = isPromotionApplicable(
                                  userType === "customer"
                                    ? "customers"
                                    : userType === "agent"
                                    ? "agents"
                                    : "",
                                  userDetails?.id,
                                  item.promotionApplicable
                                );
                                const blackoutDates =
                                  item.promotionBlackOutDate || [];
                                const isBlackout = isBlackoutDate(
                                  blackoutDates,
                                  startDate
                                );
                                const isApplicableForEntity =
                                  isPromotionApplicableForEntity(
                                    item.promotionEntity,
                                    "package",
                                    singlePackageData?.packageId
                                  );
                                const isConditionsValid = evaluateConditions(
                                  item.promotionConditions,
                                  startDate
                                );

                                return (
                                  isValidDate &&
                                  isBookingWithinDiscount &&
                                  isUserEligible &&
                                  !isBlackout &&
                                  isApplicableForEntity &&
                                  isConditionsValid
                                );
                              })()
                          ) && (
                            <div
                              className="common_background"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="flex justify_content_between cursor_pointer">
                                <div className="flex justify_content_between w-100">
                                  <div>
                                    <h5 className="text-left">Discounts</h5>
                                  </div>
                                  {selectedDiscount && (
                                    <button
                                      className="view_btn"
                                      onClick={() => setSelectedDiscount(null)}
                                    >
                                      clear
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div>
                                {promotions
                                  .filter(
                                    (item) => item.promotionType === "coupon"
                                  )
                                  .map((item, index) => {
                                    const isValidDate = checkDates(
                                      item.startDate,
                                      item.endDate
                                    );
                                    const isBookingWithinDiscount =
                                      checkBookingDates(
                                        startDate,
                                        startDate,
                                        item.startDate,
                                        item.endDate
                                      );
                                    const isUserEligible =
                                      isPromotionApplicable(
                                        userType === "customer"
                                          ? "customers"
                                          : userType === "agent"
                                          ? "agents"
                                          : "",
                                        userDetails?.id,
                                        item.promotionApplicable
                                      );
                                    const blackoutDates =
                                      item.promotionBlackOutDate || [];
                                    const isBlackout = isBlackoutDate(
                                      blackoutDates,
                                      startDate
                                    );
                                    const isApplicableForEntity =
                                      isPromotionApplicableForEntity(
                                        item.promotionEntity,
                                        "package",
                                        singlePackageData?.packageId
                                      );
                                    const isConditionsValid =
                                      evaluateConditions(
                                        item.promotionConditions,
                                        startDate
                                      );

                                    return (
                                      isValidDate &&
                                      isBookingWithinDiscount &&
                                      isUserEligible &&
                                      !isBlackout &&
                                      isApplicableForEntity &&
                                      isConditionsValid && (
                                        <div
                                          key={index}
                                          className="flex discountSingleDiv justify_content_between w-100"
                                        >
                                          <div className="flex">
                                            <label
                                              style={{
                                                textTransform: "capitalize",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              htmlFor={item?.promotionName}
                                            >
                                              <input
                                                checked={
                                                  selectedDiscount?.id ===
                                                  item?.id
                                                }
                                                onChange={() =>
                                                  handleDiscountChange(item)
                                                }
                                                type="radio"
                                                id={item?.promotionName}
                                              />
                                              <div>
                                                <div>
                                                  {item?.promotionName?.replace(
                                                    /-/g,
                                                    " "
                                                  )}
                                                </div>
                                              </div>
                                            </label>
                                          </div>
                                          <div>
                                            {item?.promotionMode === "fixed" ? (
                                              formatIndianCurrency(
                                                item?.promotionAmount
                                              )
                                            ) : (
                                              <>{item?.promotionAmount}%</>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                <aside>
                  <OurQuery />
                  <ChooseUs className="mt-20" />
                  <SendItinerary singlePackageData={singlePackageData} />
                </aside>
              </>
            )}
          </main>
        </div>
      </section>
      <Footer />

      {/* {showRatePopup && (
        <SendEnquiryPopup
          package_id={singlePackageData?.packageId}
          setshowRatePopup={setshowRatePopup}
          typeDetail="package"
        />
      )} */}

      {showRatePopup && (
        <NewSendEnquiryPopup
          package_id={singlePackageData?.packageId}
          setshowRatePopup={setshowRatePopup}
          typeDetail="package"
        />
      )}
    </>
  );
}

export default SinglePackage;

// if (childAgeArray?.length > 0) {
//   childAgeArray.forEach((age) => {
//     if (Number(age) >= 6) {
//       childCostWithBed += Number(calculateFinalPrice(ratesOfPackages?.ChildWithBedCost, markupData, commission));
//     } else {
//       if (volvoAddonPrice !== undefined) {
//         childCostWithOutBed +=
//           Number(calculateFinalPrice(ratesOfPackages?.ChildWithOutBedCost, markupData, commission)) + (+volvoAddonPrice / Number(childWithAgeLessThanSix));
//       } else {
//         childCostWithOutBed += Number(calculateFinalPrice(ratesOfPackages?.ChildWithOutBedCost, markupData, commission));
//       }
//     }
//   });
// }
