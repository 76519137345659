import React, { useState } from "react";
import { NavLink, Link, useParams, useLocation } from "react-router-dom";
import "../SideBar/SideBar.css";
import { useEffect } from "react";

const SideBar = ({ allPropertiesPage }) => {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  const handleOpenDropdown = () => {
    setDropdown((prevState) => !prevState);
  };

  // useEffect(() => {
  //   const isActive =
  //     location.pathname.startsWith("/partner/rates/") ||
  //     location.pathname.startsWith("/partner/inventory/");
  //   setDropdown(isActive);
  // }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.startsWith("/partner/rates/") ||
      location.pathname.startsWith("/partner/inventory/")
    ) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  }, []);

  const { id } = useParams();
  // console.log(id);

  return (
    <>
      {allPropertiesPage ? (
        <section className="sidebar">
          <div className="close_popup"></div>
          <Link className="sidebar_btn">My Properties</Link>
        </section>
      ) : (
        <section className="sidebar">
          <div className="close_popup">
            {/* <svg
                            width="10"
                            height="10"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                            <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                        </svg> */}
          </div>
          <NavLink
            className="sidebar_btn flex align_items_center"
            to={`/partner/overview/${id}`}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H8V2H2V16ZM10 16H16V9H10V16ZM10 7H16V2H10V7Z"
                fill="#1C1B1F"
              />
            </svg>
            Dashboard
          </NavLink>
          <NavLink
            className="sidebar_btn flex align_items_center flex_gap_5"
            to={`/vendor-account/${id}`}
          >
            <svg
              className="m_0"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.04551 6.54502C6.14832 6.54502 5.38027 6.22557 4.74137 5.58666C4.10246 4.94775 3.78301 4.17971 3.78301 3.28252C3.78301 2.38533 4.10246 1.61729 4.74137 0.978379C5.38027 0.339473 6.14832 0.0200195 7.04551 0.0200195C7.94269 0.0200195 8.71074 0.339473 9.34965 0.978379C9.98855 1.61729 10.308 2.38533 10.308 3.28252C10.308 4.17971 9.98855 4.94775 9.34965 5.58666C8.71074 6.22557 7.94269 6.54502 7.04551 6.54502ZM0.520508 11.4388V10.7863C0.520508 10.3241 0.639453 9.89928 0.877344 9.51186C1.11523 9.12443 1.43129 8.82877 1.82551 8.62486C2.66832 8.20346 3.52473 7.8874 4.39473 7.6767C5.26473 7.466 6.14832 7.36064 7.04551 7.36064C7.94269 7.36064 8.82629 7.466 9.69629 7.6767C10.5663 7.8874 11.4227 8.20346 12.2655 8.62486C12.6597 8.82877 12.9758 9.12443 13.2137 9.51186C13.4516 9.89928 13.5705 10.3241 13.5705 10.7863V11.4388C13.5705 11.8874 13.4108 12.2714 13.0913 12.5908C12.7719 12.9103 12.3879 13.07 11.9393 13.07H2.15176C1.70316 13.07 1.31914 12.9103 0.999687 12.5908C0.680234 12.2714 0.520508 11.8874 0.520508 11.4388ZM2.15176 11.4388H11.9393V10.7863C11.9393 10.6367 11.9019 10.5008 11.8271 10.3785C11.7523 10.2561 11.6538 10.161 11.5314 10.093C10.7974 9.72596 10.0565 9.45068 9.30887 9.26717C8.56121 9.08365 7.80676 8.99189 7.04551 8.99189C6.28426 8.99189 5.5298 9.08365 4.78215 9.26717C4.03449 9.45068 3.29363 9.72596 2.55957 10.093C2.43723 10.161 2.33867 10.2561 2.26391 10.3785C2.18914 10.5008 2.15176 10.6367 2.15176 10.7863V11.4388ZM7.04551 4.91377C7.4941 4.91377 7.87812 4.75404 8.19758 4.43459C8.51703 4.11514 8.67676 3.73111 8.67676 3.28252C8.67676 2.83393 8.51703 2.4499 8.19758 2.13045C7.87812 1.811 7.4941 1.65127 7.04551 1.65127C6.59691 1.65127 6.21289 1.811 5.89344 2.13045C5.57398 2.4499 5.41426 2.83393 5.41426 3.28252C5.41426 3.73111 5.57398 4.11514 5.89344 4.43459C6.21289 4.75404 6.59691 4.91377 7.04551 4.91377Z"
                fill="#000"
              />
            </svg>
            My Account
          </NavLink>
          <NavLink
            className="sidebar_btn flex align_items_center "
            to={`/partner/all-rooms/${id}`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4521_3526"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4521_3526)">
                <path
                  d="M7 19H6L5.35 17H4V11.975C4 11.425 4.19583 10.9583 4.5875 10.575C4.97917 10.1917 5.45 10 6 10V7C6 6.45 6.19583 5.97917 6.5875 5.5875C6.97917 5.19583 7.45 5 8 5H16C16.55 5 17.0208 5.19583 17.4125 5.5875C17.8042 5.97917 18 6.45 18 7V10C18.55 10 19.0208 10.1958 19.4125 10.5875C19.8042 10.9792 20 11.45 20 12V17H18.65L18 19H17L16.35 17H7.65L7 19ZM13 10H16V7H13V10ZM8 10H11V7H8V10ZM6 15H18V12H6V15Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Rooms
          </NavLink>

          {/* <NavLink
            className="sidebar_btn flex align_items_center"
            to={`/partner/all-room-rates/${id}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4521_3532"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4521_3532)">
                <path
                  d="M13.725 21L7 14V12H10.5C11.3833 12 12.1458 11.7125 12.7875 11.1375C13.4292 10.5625 13.8167 9.85 13.95 9H6V7H13.65C13.3667 6.41667 12.9458 5.9375 12.3875 5.5625C11.8292 5.1875 11.2 5 10.5 5H6V3H18V5H14.75C14.9833 5.28333 15.1917 5.59167 15.375 5.925C15.5583 6.25833 15.7 6.61667 15.8 7H18V9H15.975C15.8417 10.4167 15.2583 11.6042 14.225 12.5625C13.1917 13.5208 11.95 14 10.5 14H9.775L16.5 21H13.725Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Rate Plans
          </NavLink> */}
          <NavLink
            className="sidebar_btn flex align_items_center"
            to={`/partner/bookings/${id}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4518_3506"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4518_3506)">
                <path
                  d="M11.0667 15.105L14.1556 11.9775C14.3333 11.7975 14.5481 11.7075 14.8 11.7075C15.0519 11.7075 15.2667 11.7975 15.4444 11.9775C15.6222 12.1575 15.7111 12.375 15.7111 12.63C15.7111 12.885 15.6222 13.1025 15.4444 13.2825L11.6889 17.085C11.5111 17.265 11.3037 17.355 11.0667 17.355C10.8296 17.355 10.6222 17.265 10.4444 17.085L8.55556 15.1725C8.37778 14.9925 8.28889 14.775 8.28889 14.52C8.28889 14.265 8.37778 14.0475 8.55556 13.8675C8.73333 13.6875 8.94815 13.5975 9.2 13.5975C9.45185 13.5975 9.66667 13.6875 9.84444 13.8675L11.0667 15.105ZM5.77778 21C5.28889 21 4.87037 20.8237 4.52222 20.4713C4.17407 20.1187 4 19.695 4 19.2V6.6C4 6.105 4.17407 5.68125 4.52222 5.32875C4.87037 4.97625 5.28889 4.8 5.77778 4.8H6.66667V3.9C6.66667 3.645 6.75185 3.43125 6.92222 3.25875C7.09259 3.08625 7.3037 3 7.55556 3C7.80741 3 8.01852 3.08625 8.18889 3.25875C8.35926 3.43125 8.44444 3.645 8.44444 3.9V4.8H15.5556V3.9C15.5556 3.645 15.6407 3.43125 15.8111 3.25875C15.9815 3.08625 16.1926 3 16.4444 3C16.6963 3 16.9074 3.08625 17.0778 3.25875C17.2481 3.43125 17.3333 3.645 17.3333 3.9V4.8H18.2222C18.7111 4.8 19.1296 4.97625 19.4778 5.32875C19.8259 5.68125 20 6.105 20 6.6V19.2C20 19.695 19.8259 20.1187 19.4778 20.4713C19.1296 20.8237 18.7111 21 18.2222 21H5.77778ZM5.77778 19.2H18.2222V10.2H5.77778V19.2ZM5.77778 8.4H18.2222V6.6H5.77778V8.4Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Bookings
          </NavLink>
          <Link
            className="sidebar_btn flex align_items_center"
            onClick={() => handleOpenDropdown()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4518_3502"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4518_3502)">
                <path
                  d="M7.95 15.15H16.05V15.825C16.05 16.02 16.1138 16.1812 16.2413 16.3088C16.3688 16.4362 16.53 16.5 16.725 16.5C16.92 16.5 17.0813 16.4362 17.2087 16.3088C17.3363 16.1812 17.4 16.02 17.4 15.825V12.765C17.4 12.315 17.2762 11.9137 17.0288 11.5612C16.7812 11.2087 16.455 10.95 16.05 10.785V9.3C16.05 8.805 15.8737 8.38125 15.5212 8.02875C15.1687 7.67625 14.745 7.5 14.25 7.5H9.75C9.255 7.5 8.83125 7.67625 8.47875 8.02875C8.12625 8.38125 7.95 8.805 7.95 9.3V10.785C7.545 10.95 7.21875 11.2087 6.97125 11.5612C6.72375 11.9137 6.6 12.315 6.6 12.765V15.825C6.6 16.02 6.66375 16.1812 6.79125 16.3088C6.91875 16.4362 7.08 16.5 7.275 16.5C7.47 16.5 7.63125 16.4362 7.75875 16.3088C7.88625 16.1812 7.95 16.02 7.95 15.825V15.15ZM7.95 13.8V12.765C7.95 12.555 8.025 12.375 8.175 12.225C8.325 12.075 8.505 12 8.715 12H15.285C15.495 12 15.675 12.075 15.825 12.225C15.975 12.375 16.05 12.555 16.05 12.765V13.8H7.95ZM9.3 10.65V8.85H14.7V10.65H9.3ZM4.8 21C4.305 21 3.88125 20.8237 3.52875 20.4713C3.17625 20.1187 3 19.695 3 19.2V4.8C3 4.305 3.17625 3.88125 3.52875 3.52875C3.88125 3.17625 4.305 3 4.8 3H19.2C19.695 3 20.1187 3.17625 20.4713 3.52875C20.8237 3.88125 21 4.305 21 4.8V19.2C21 19.695 20.8237 20.1187 20.4713 20.4713C20.1187 20.8237 19.695 21 19.2 21H4.8ZM4.8 19.2H19.2V4.8H4.8V19.2Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Rates & Inventory{" "}
            <svg
              style={{ marginLeft: "10px" }}
              width="10"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={dropdown ? "rotate-icon" : ""}
            >
              <path
                d="M11 0.5L6 5.5L1 0.5"
                stroke="#333333"
                strokeWidth="1.2"
              />
            </svg>
          </Link>
          {dropdown && (
            <div>
              <NavLink
                className="sidebar_btn pl-50"
                to={`/partner/rates/${id}`}
              >
                Rates
              </NavLink>
              <NavLink
                className="sidebar_btn pl-50"
                to={`/partner/inventory/${id}`}
              >
                Inventory
              </NavLink>
            </div>
          )}
          <NavLink
            className="sidebar_btn flex align_items_center "
            to={`/partner/promotion/${id}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4518_3510"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4518_3510)">
                <path
                  d="M7.50006 11C6.53339 11 5.70839 10.6583 5.02506 9.975C4.34173 9.29167 4.00006 8.46667 4.00006 7.5C4.00006 6.53333 4.34173 5.70833 5.02506 5.025C5.70839 4.34167 6.53339 4 7.50006 4C8.46673 4 9.29173 4.34167 9.97506 5.025C10.6584 5.70833 11.0001 6.53333 11.0001 7.5C11.0001 8.46667 10.6584 9.29167 9.97506 9.975C9.29173 10.6583 8.46673 11 7.50006 11ZM7.50006 9C7.91673 9 8.27089 8.85417 8.56256 8.5625C8.85423 8.27083 9.00006 7.91667 9.00006 7.5C9.00006 7.08333 8.85423 6.72917 8.56256 6.4375C8.27089 6.14583 7.91673 6 7.50006 6C7.08339 6 6.72923 6.14583 6.43756 6.4375C6.14589 6.72917 6.00006 7.08333 6.00006 7.5C6.00006 7.91667 6.14589 8.27083 6.43756 8.5625C6.72923 8.85417 7.08339 9 7.50006 9ZM16.5001 20C15.5334 20 14.7084 19.6583 14.0251 18.975C13.3417 18.2917 13.0001 17.4667 13.0001 16.5C13.0001 15.5333 13.3417 14.7083 14.0251 14.025C14.7084 13.3417 15.5334 13 16.5001 13C17.4667 13 18.2917 13.3417 18.9751 14.025C19.6584 14.7083 20.0001 15.5333 20.0001 16.5C20.0001 17.4667 19.6584 18.2917 18.9751 18.975C18.2917 19.6583 17.4667 20 16.5001 20ZM16.5001 18C16.9167 18 17.2709 17.8542 17.5626 17.5625C17.8542 17.2708 18.0001 16.9167 18.0001 16.5C18.0001 16.0833 17.8542 15.7292 17.5626 15.4375C17.2709 15.1458 16.9167 15 16.5001 15C16.0834 15 15.7292 15.1458 15.4376 15.4375C15.1459 15.7292 15.0001 16.0833 15.0001 16.5C15.0001 16.9167 15.1459 17.2708 15.4376 17.5625C15.7292 17.8542 16.0834 18 16.5001 18ZM4.70006 19.3C4.51673 19.1167 4.42506 18.8833 4.42506 18.6C4.42506 18.3167 4.51673 18.0833 4.70006 17.9L17.9001 4.7C18.0834 4.51667 18.3167 4.425 18.6001 4.425C18.8834 4.425 19.1167 4.51667 19.3001 4.7C19.4834 4.88333 19.5751 5.11667 19.5751 5.4C19.5751 5.68333 19.4834 5.91667 19.3001 6.1L6.10006 19.3C5.91673 19.4833 5.68339 19.575 5.40006 19.575C5.11673 19.575 4.88339 19.4833 4.70006 19.3Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Promotions
          </NavLink>
          <NavLink
            className="sidebar_btn flex align_items_center"
            to={`/partner/rules/${id}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_4518_3519"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_4518_3519)">
                <path
                  d="M4 21V19H16V21H4ZM9.65 16.15L4 10.5L6.1 8.35L11.8 14L9.65 16.15ZM16 9.8L10.35 4.1L12.5 2L18.15 7.65L16 9.8ZM20.6 20L7.55 6.95L8.95 5.55L22 18.6L20.6 20Z"
                  fill="#1C1B1F"
                />
              </g>
            </svg>
            Rules
          </NavLink>
        </section>
      )}
    </>
  );
};

export default SideBar;
