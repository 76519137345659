import React from "react";
import Slider from "react-slick";
import { destinationImages } from "../../constants";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import imgNotFound from "../../assets/images/single-image.png";

function AllDestinationsSlider({ footerDestinations, heading }) {
  var settings = {
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "40px",
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2.5,
          arrows: false,
        },
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="common_bg">
      <div className="container">
        <div className="common_padding">
          <div className="flex justify_content_between align_items_end">
            <div></div>
            <h3>{heading}</h3>
            <div>
              <Link
                className="view-all-btn fit-width p_0"
                onClick={() =>
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  })
                }
                to="/all-destinations"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
        <div className="images-flex">
          <Slider {...settings}>
            {footerDestinations?.map((items, index) => {
              return (
                <Link
                  onClick={() =>
                    scroll.scrollToTop({
                      duration: 500,
                      smooth: "easeInOutQuad",
                    })
                  }
                  to={`/destination/${items.name.toLowerCase()}`}
                  className="destination_link"
                  key={index}
                >

                  {items.images && items.images.length > 0 ?
                    <img src={items.images[0]} alt={items.alt} />
                    : <img src={imgNotFound} alt='Image not found' />
                  }

                  <div className="destination_name">
                    <h6>{items.name}</h6>
                  </div>
                </Link>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default AllDestinationsSlider;
