import React from "react";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useLocation } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter } from "../../../utils/utils";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Loader from "../../../CustomComponents/Loader/Loader";
import AddressAutocomplete from "../../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import SeeBookingSlider from "../../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import AgentHeader from "../AgentHeader/AgentHeader";
import { getDecryptedData } from "../../../utils/crypto";
import MultipleImageUploadComponent from "../../../CustomComponents/ImageUpload/MultipleImageUploadComponent";
import UploadFileComponent from "../../../CustomComponents/ImageUpload/UploadFileComponent";

function AgentAccount() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const role = query.get("role");
  const [loader, setLoader] = useState(false);
  const [allBookings, setAllBookings] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResult, setUploadResult] = useState("");
  const BearerToken = getDecryptedData("userToken");
  const userToken = getDecryptedData("userToken");

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    handleSubmitCertificate(file, type);
  };

  const slug = window.location.pathname.split("/");
  // console.log(role ,"slug")

  const [activeTab, setActiveTab] = useState("Profile");
  useEffect(() => {
    if (role === "bookings") {
      setActiveTab("Bookings");
    } else {
      setActiveTab("Profile");
    }
  }, []);
  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const handleChangeClick = async () => {
    setIsSliderVisible(true);
  };
  const [formData, setFormData] = useState({
    company_name: "",
    phone: "",
    email: "",
    gst_number: "",
    country: "",
    state: "",
    city: "",
    address: "",
    agent_category: "",
    pan: "",
    agent_category_discount: "",
    agent_discount_type: "",
    tan: "",
    tac: "",
    zipcode: "",
  });

  const [agentDetails, setAgentDetails] = useState(null);
  const [agentUpdateData, setAgentUpdateData] = useState(null);
  const agent_id = agentDetails?.AgentId;

  const account_id = agentDetails?.AccountId;
  const status = agentUpdateData?.data?.status;

  //fetch categories
  const [categoryDetail, setCategoryDetail] = useState(null);
  const [singleBookingData, setSingleBookingData] = useState(null);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();

  // console.log("formData", formData);

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));
  // console.log(userDetails);

  const [agentAccountDetails, setAgentAccountDetails] = useState({});
  // console.log(agentAccountDetails);

  const fetchAccountDetails = (id) => {
    setLoader(true);
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/credit-balance/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setAgentAccountDetails(result.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => console.error("Error fetching single rate:", error));
  };

  useEffect(() => {
    if (userDetails?.AgentId) {
      fetchAccountDetails(userDetails?.AgentId);
    }
  }, [userDetails?.AgentId]);
  // console.log(userDetails);

  const handleAddressData = (data) => {
    // console.log(data, "data");
    setAddressData(data);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // console.log({ ...formData, ...addressData });
  //   const valid = validate();
  //   setTimeout(() => {
  //     setFormErrors({});
  //   }, 1500);
  //   if (!valid) {
  //     showErrorToast("Please fill the above details.");
  //     return;
  //   }

  //   const raw = JSON.stringify({ ...formData, ...addressData });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/agent/update/${agent_id}`,
  //     requestOptions
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setAgentUpdateData(data);
  //       localStorage.setItem("isVerified", data?.data?.status || "false"); // Ensure proper saving
  //       showSuccessToast("Profile Saved Successfully");
  //       fetchAgentDetails();
  //       // navigate("/");
  //       // window.location.href = "/";
  //     })
  //     .catch((error) =>
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       )
  //     );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      showErrorToast("Please fill the above details.");
      return;
    }
    setFormErrors({});
    setTimeout(() => setFormErrors({}), 1500);

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/update`;

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${BearerToken}`);
    headers.append("Cookie", process.env.REACT_APP_COOKIE);

    const payload = JSON.stringify({ ...formData, ...addressData });

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: payload,
        redirect: "follow",
      });

      const result = await response.json();
      if (result?.success) {
        showSuccessToast(result.message || "Updated Successfully");
        setAgentUpdateData(result);
        localStorage.setItem("isVerified", result?.data?.status || "false");
        fetchAgentDetails();
      } else {
        showErrorToast(result.message || "Login Failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      showErrorToast("An error occurred while updating your profile.");
    }
  };

  const profileFilter = (tab) => {
    setActiveTab(tab);
  };

  const fetchAgentDetails = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/user-profile`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BearerToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setAgentDetails(data?.data);

        localStorage.setItem("userDetails", JSON.stringify(data?.data));

        localStorage.setItem("role", "agent");

        setAddressData({
          country: data?.data?.country || "",
          state: data?.data?.state || "",
          city: data?.data?.city || "",
          address: data?.data?.address || "",
          latitude: data?.data?.latitude || "",
          longitude: data?.data?.longitude || "",
          placeId: data?.data?.placeId || "",
        });
        setFormData({
          company_name: data?.data?.name || "",
          name: data?.data?.name || "",
          phone: data?.data?.phone || "",
          email: data?.data?.email || "",
          gst_number: data?.data?.additional_details[0]?.company_gst || "",
          country: data?.data?.country || "",
          state: data?.data?.state || "",
          city: data?.data?.city || "",
          address: data?.data?.address || "",
          agent_category_discount: +data?.data?.agent_category_discount || "",
          agent_discount_type: data?.data.agent_discount_type || "",
          agent_category: data?.data.AgentCategoryName || "",
          pan: data?.data.additional_details[0]?.company_pan || "",
          tan: data?.data.additional_details[0]?.company_tan || "",
          tac: data?.data.tac || "",
          zipcode: data?.data.zipcode || "",
        });

        if (data.success) {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  useEffect(() => {
    const agentData = localStorage.getItem("role");
    // console.log(agentData);
    if (agentData === "agent") {
      fetchAgentDetails();
      // fetchAgentsBookings();

      // fetchSidebarDetails();
    } else {
      fetchAgentDetails();
      // fetchAgentsBookings();
    }
    // fetchAgentsBookings();
  }, [localStorage.getItem("role")]);

  const fetchCategoryDetail = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/agents/category-list/${account_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assuming the API returns JSON
      })
      .then((result) => {
        setCategoryDetail(result);
      })
      .catch((error) => {
        setError(error);
        console.error(
          "There was an error fetching the category details:",
          error
        );
      });
  };

  const fetchAgentsBookings = () => {
    setLoader(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/bookings`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assuming the API returns JSON
      })
      .then((result) => {
        if (result.success) {
          const groupByCategory = result?.data?.data?.reduce(
            (group, booking) => {
              const { tour_start } = booking;
              group[tour_start] = group[tour_start] ?? [];
              group[tour_start].push(booking);
              return group;
            },
            {}
          );
          setLoader(false);

          setAllBookings(groupByCategory);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError(error);
        console.error(
          "There was an error fetching the category details:",
          error
        );
      });
  };

  const handleSubmitCertificate = (file, type) => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("collection", type);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: formData,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/agents/agent-doc-upload/${agent_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          setUploadResult(result);
          fetchAgentDetails();
          toast.success("File uploaded successfully");
        } else {
          toast.error(
            result.message ? result.message : "Error uploading file."
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setUploadResult("Error uploading file.");
      });
  };

  const panImageData = agentDetails?.imageUrl?.find(
    (image) => image.collection === "PAN"
  );

  const gstImageData = agentDetails?.imageUrl?.find(
    (image) => image.collection === "gst_number"
  );
  // console.log(panImageData);

  const deleteImageHandler = async (mediaId) => {
    // console.log(mediaId);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const raw = JSON.stringify({ mediaId });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/agents/delete-image`,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        toast.success("Image deleted successfully");
        fetchAgentDetails();
      } else {
        toast.error(result.message ? result.message : "Error deleting image.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [editData, setEditData] = useState(false);
  const handleOnClick = () => {
    setEditData(!editData);
  };

  const [formErrors, setFormErrors] = useState({});

  // console.log("formData", formData);

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    // if (currentStep === 1) {
    if (!formData.name.trim()) {
      errors.name = "required";
    }
    if (!formData.email.trim()) {
      errors.email = "required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "is required";
    }
    // if (!formData.trim.trim()) {
    //   errors.address = "is required";
    // }
    // } else if (currentStep === 2) {
    if (!formData.gst_number) {
      errors.gst_number = "required";
    }
    if (!formData.pan) {
      errors.pan = "required";
    }
    // if (!formData.tan) {
    //   errors.tan = "required";
    // }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const agent_selected_id = JSON.parse(localStorage.getItem("userDetails"));

  // console.log("setFormErrors", formErrors);
  return (
    <div id="Listingholidays">
      {/* <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      /> */}
      <AgentHeader showSelectProperty={true} vendorProperty={false} />
      <div className="sidebar_main_div">
        <AgentSideBar />
        <section className="w-full p-sm">
          <div id="common_background_profile m_20">
            <h4 className=" mb-10 ">My Account</h4>
            {loader ? (
              <Loader size="4em" color="#016937" />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  {editData ? (
                    <>
                      <div className="flex-div">
                        <div className="w-half-div">
                          <div className="common_background">
                            <h4>General Details</h4>
                            <div className="profile_content Profile mt-10">
                              <div onSubmit={handleSubmit}>
                                {currentStep === 1 && (
                                  <div className="input-flex">
                                    <div>
                                      <label htmlFor="company_name">Name</label>
                                      <input
                                        style={
                                          formErrors.name && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                          }
                                        }
                                        className="w-full"
                                        onChange={handleInputChange}
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                      />
                                    </div>
                                    <div>
                                      <label htmlFor="phone">Phone</label>
                                      <input
                                        style={
                                          formErrors.phone && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                          }
                                        }
                                        className="w-full"
                                        onChange={handleInputChange}
                                        type="text"
                                        placeholder="Phone"
                                        name="phone"
                                        value={formData.phone}
                                      />
                                    </div>
                                    <div>
                                      <label htmlFor="email">Email</label>
                                      <input
                                        style={
                                          formErrors.email && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                          }
                                        }
                                        className="w-full"
                                        disabled={true}
                                        onChange={handleInputChange}
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="common_background mt-10">
                            <h4>Address</h4>
                            <div className="profile_content Profile mt-10">
                              <div onSubmit={handleSubmit}>
                                <label htmlFor="address">Address</label>
                                <AddressAutocomplete
                                  formdata={formData}
                                  style={
                                    formErrors.address && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  onAddressChange={handleAddressData}
                                />
                              </div>
                              <div>
                                <label htmlFor="email">Zip Code</label>
                                <input
                                  style={
                                    formErrors.email && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  className="w-full"
                                  // disabled={true}
                                  onChange={handleInputChange}
                                  type="number"
                                  placeholder="Zip Code"
                                  name="zipcode"
                                  value={formData.zipcode}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="common_background mt-10">
                            <h4>Documentation</h4>
                            <div className="profile_content Profile">
                              <div onSubmit={handleSubmit}>
                                <div className="input-flex">
                                  <div className="edit-rooms-div-wrapper-container  mt-10">
                                    <label htmlFor="discount mb-10">
                                      Gst Certificate
                                    </label>

                                    {gstImageData?.url ? (
                                    <>
                                      <div className="featureImages_uploadFile_img_container">
                                        <img src={gstImageData.url} alt="pan" />
                                        <div
                                          onClick={() =>
                                            deleteImageHandler(gstImageData?.id)
                                          }
                                          className="featureImages_uploadFile_delete"
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 12.5L13 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                            <path
                                              d="M13 12.5L1 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ position: "relative" }}>
                                        <div className="featureImages_uplaodFile_displaytext_container">
                                          <p className="featured_image_text">
                                            Add Files
                                          </p>
                                          <p className="featured_image_text_dragdrop">
                                            or drop JPG, PNG images here
                                          </p>
                                          <input
                                            accept="image/*"
                                            type="file"
                                            id="select-image"
                                            className="featureImages_upload_file_input"
                                            onChange={(e) =>
                                              handleFileChange(e, "gst_number")
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  </div>

                                  <div className="edit-rooms-div-wrapper-container  mt-10">
                                    <label htmlFor="discount mb-10">
                                      PAN Certificate
                                    </label>
                                    {panImageData?.url ? (
                                      <>
                                        <div className="featureImages_uploadFile_img_container">
                                          <img
                                            src={panImageData.url}
                                            alt="pan"
                                          />
                                          <button
                                            type="button"
                                            onClick={() =>
                                              deleteImageHandler(
                                                panImageData?.id
                                              )
                                            }
                                            className="featureImages_uploadFile_delete"
                                          >
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 14 13"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 12.5L13 0.5"
                                                stroke="#AAAAAA"
                                                strokeWidth="1.2"
                                              />
                                              <path
                                                d="M13 12.5L1 0.5"
                                                stroke="#AAAAAA"
                                                strokeWidth="1.2"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div style={{ position: "relative" }}>
                                          <div className="featureImages_uplaodFile_displaytext_container">
                                            <p className="featured_image_text">
                                              Add Files
                                            </p>
                                            <p className="featured_image_text_dragdrop">
                                              or drop JPG, PNG images here
                                            </p>
                                            <div>
                                              <input
                                                accept="image/*"
                                                type="file"
                                                id="select-image"
                                                className="featureImages_upload_file_input"
                                                onChange={(e) =>
                                                  handleFileChange(e, "PAN")
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="button mt-10">
                            <button
                              className="primary_btn search-btn-width sm-btn"
                              type="submit"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                        <div className="w-half-div">
                          <div className="common_background">
                            <h4>Business Information</h4>
                            <div className="profile_content Profile mt-10">
                              <div onSubmit={handleSubmit}>
                                <div className="input-flex">
                                  <div>
                                    <label htmlFor="gst_number">GST No.</label>
                                    <input
                                      className="w-full"
                                      onChange={handleInputChange}
                                      type="text"
                                      placeholder="gst_number No."
                                      name="gst_number"
                                      value={formData.gst_number}
                                      style={
                                        formErrors.gst_number && {
                                          backgroundColor: "#ff000040",
                                          border: "1px solid red",
                                        }
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="pan">PAN No.</label>
                                    <input
                                      className="w-full"
                                      style={
                                        formErrors.pan && {
                                          backgroundColor: "#ff000040",
                                          border: "1px solid red",
                                        }
                                      }
                                      onChange={handleInputChange}
                                      type="text"
                                      placeholder="PAN No."
                                      name="pan"
                                      value={formData.pan}
                                    />
                                  </div>
                                </div>
                                <div className="input-flex">
                                  {/* <div>
                                  <label htmlFor="discount">TAN Number</label>
                                  <input
                                    style={
                                      formErrors.tan && {
                                        backgroundColor: "#ff000040",
                                        border: "1px solid red",
                                      }
                                    }
                                    className="w-full"
                                    // disabled={true}
                                    onChange={handleInputChange}
                                    // type="number"
                                    placeholder="TAN Number"
                                    name="tan"
                                    value={formData.tan}
                                  />
                                </div> */}
                                  <div>
                                    <label htmlFor="discount">TAC %</label>
                                    <input
                                      className="w-full"
                                      // disabled={true}
                                      onChange={handleInputChange}
                                      // type="number"
                                      placeholder="TAC %"
                                      name="tac"
                                      value={formData.tac}
                                      disabled
                                    />
                                  </div>
                                </div>
                                {/* <div className="input-flex">
                                <div>
                                  <label htmlFor="discount">
                                    Agent Category Discount
                                  </label>
                                  <input
                                    className="w-full"
                                    disabled={true}
                                    onChange={handleInputChange}
                                    // type="number"
                                    placeholder="Agent Category Discount"
                                    name="Agent Category Discount"
                                    value={`${
                                      formData.agent_category_discount
                                    } ${
                                      formData.agent_discount_type ===
                                        "percentage" && "%"
                                    }`}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="agent">
                                    Agent Category Name
                                  </label>
                                  <input
                                    className="w-full"
                                    disabled={true}
                                    onChange={handleInputChange}
                                    type="text"
                                    placeholder="Agent Category"
                                    name="agent_category"
                                    value={formData.agent_category}
                                  />
                                </div>
                              </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="button mt-10">
                            <button
                              className="primary_btn sm-btn-block"
                              type="submit"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex-div">
                        <div className="w-half-div">
                          <div className="common_background">
                            <h4>General Details</h4>
                            <div className="profile_content Profile">
                              <div onSubmit={handleSubmit}>
                                <div className="input-flex">
                                  <div className="mt-10">
                                    <p className="py-5">Name</p>
                                    <p className="py-5">Email</p>
                                    <p className="py-5">Phone</p>
                                  </div>
                                  <div className="mt-10">
                                    <p className="py-5">
                                      {formData?.company_name}
                                    </p>
                                    <p className="py-5">{formData.email}</p>
                                    <p className="py-5">{formData.phone}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="common_background mt-10">
                            <h4>Address</h4>
                            <div className="profile_content Profile">
                              <div onSubmit={handleSubmit}>
                                <div className="input-flex">
                                  <div className="mt-10">
                                    <p className="py-5">Country</p>
                                    <p className="py-5">City</p>
                                    <p className="py-5">State</p>
                                    <p className="py-5">Address</p>
                                    <p className="py-5">Zip Code</p>
                                  </div>
                                  <div className="mt-10">
                                    <p className="py-5">
                                      {addressData?.country}
                                    </p>
                                    <p className="py-5">{addressData?.city}</p>
                                    <p className="py-5">{addressData?.state}</p>
                                    <p className="py-5">
                                      {addressData?.address}
                                    </p>
                                    <p className="py-5">
                                      {formData?.zipcode
                                        ? formData?.zipcode
                                        : "NA"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="common_background mt-10">
                          <h4>Documentation</h4>
                          <div className="profile_content Profile">
                            <div onSubmit={handleSubmit}>
                              <div className="input-flex">
                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                  <label htmlFor="discount mb-10">
                                    Gst Certificate
                                  </label>

                                  {gstImageData?.url ? (
                                    <>
                                      <div className="featureImages_uploadFile_img_container">
                                        <img src={gstImageData.url} alt="pan" />
                                        <div
                                          onClick={() =>
                                            deleteImageHandler(gstImageData?.id)
                                          }
                                          className="featureImages_uploadFile_delete"
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 12.5L13 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                            <path
                                              d="M13 12.5L1 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ position: "relative" }}>
                                        <div className="featureImages_uplaodFile_displaytext_container">
                                          <p className="featured_image_text">
                                            Add Files
                                          </p>
                                          <p className="featured_image_text_dragdrop">
                                            or drop JPG, PNG images here
                                          </p>
                                          <input
                                            accept="image/*"
                                            type="file"
                                            id="select-image"
                                            className="featureImages_upload_file_input"
                                            onChange={(e) =>
                                              handleFileChange(e, "gst_number")
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>

                                <div className="edit-rooms-div-wrapper-container  mt-10">
                                  <label htmlFor="discount mb-10">
                                    PAN Certificate
                                  </label>
                                  {panImageData?.url ? (
                                    <>
                                      <div className="featureImages_uploadFile_img_container">
                                        <img src={panImageData.url} alt="pan" />
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deleteImageHandler(panImageData?.id)
                                          }
                                          className="featureImages_uploadFile_delete"
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 14 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 12.5L13 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                            <path
                                              d="M13 12.5L1 0.5"
                                              stroke="#AAAAAA"
                                              strokeWidth="1.2"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ position: "relative" }}>
                                        <div className="featureImages_uplaodFile_displaytext_container">
                                          <p className="featured_image_text">
                                            Add Files
                                          </p>
                                          <p className="featured_image_text_dragdrop">
                                            or drop JPG, PNG images here
                                          </p>
                                          <div>
                                            <input
                                              accept="image/*"
                                              type="file"
                                              id="select-image"
                                              className="featureImages_upload_file_input"
                                              onChange={(e) =>
                                                handleFileChange(e, "PAN")
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                          <div className="common_background mt-10">
                            <h4>Image Upload </h4>
                            <div className="profile_content Profile">
                              <MultipleImageUploadComponent
                                modelType={"agents"}
                                modelId={agent_selected_id?.id}
                                urlType={"website/agent/file/fetch"}
                                deleteType={"website/agent/file/delete"}
                                uploadType={"website/agent/file/upload"}  
                                file_type_agent={"agent-logo"}
                                tokenType={"agent"}
                                showSingleImage={true}
                              />
                            </div>
                          </div>

                          <div className="button mt-10">
                            <button
                              className="primary_btn search-btn-width sm-btn"
                              onClick={handleOnClick}
                              type="button"
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                        <div className="w-half-div">
                          <div className="common_background">
                            <h4>Business Information</h4>
                            <div className="profile_content Profile">
                              <div onSubmit={handleSubmit}>
                                <div className="input-flex">
                                  <div className="mt-10">
                                    <p className="py-5">GST No.</p>
                                    <p className="py-5">PAN No.</p>
                                    {/* <p className="py-5">TAN Number</p> */}
                                    <p className="py-5">TAN No.</p>
                                    <p className="py-5">TAC %</p>

                                    {/* 
                                  <p className="py-5">Agent Category Name</p>
                                  <p className="py-5">
                                    Agent Category Discount
                                  </p> */}
                                  </div>
                                  <div className="mt-10">
                                    <p className="py-5">
                                      {formData?.gst_number
                                        ? formData?.gst_number
                                        : "-"}
                                    </p>
                                    <p className="py-5">
                                      {formData?.pan ? formData?.pan : "-"}{" "}
                                    </p>
                                    <p className="py-5">
                                      {formData?.tan ? formData?.tan : "-"}{" "}
                                    </p>
                                    {/* <p className="py-5">{formData?.TAN_Number ? formData?.TAN_Number : "-"} </p> */}
                                    <p className="py-5">
                                      {formData?.tac ? formData?.tac : "-"}
                                    </p>
                                    <p className="py-5">
                                      {/* {formData?.agent_category ? formData?.agent_category : "-"}  */}
                                    </p>
                                    <p className="py-5">
                                      {/* {formData?.agent_category_discount ? formData?.agent_category_discount : "-"}  */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="button mt-10">
                            <button
                              className="primary_btn sm-btn-block"
                              onClick={handleOnClick}
                              type="button"
                            >
                              Edit
                            </button>
                          </div>
                          {/* <div className="common_background mt-10">
                          <h4>Property Address</h4>
                          <div className="profile_content Profile">
                            <div onSubmit={handleSubmit}>
                              <div className="input-flex">
                                <div className="mt-10">
                                  <p className="py-5">Country</p>
                                  <p className="py-5">City</p>
                                  <p className="py-5">State</p>
                                  <p className="py-5">Pin Code</p>
                                  <p className="py-5">Address</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </>
                  )}
                </form>

                <div className="common_background mt-10 agentDocument">
                  <h4>Document Upload</h4>
                  <div className="profile_content Profile">
                    <UploadFileComponent
                      upload_collection="documents"
                      uploadType={"website/agent/file/upload"}
                      fetchType={"website/agent/file/fetch"}
                      deleteType={"website/agent/file/delete"}
                      modelType="agents"
                      modelId={agent_selected_id?.id}
                      fileType="pdf"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default AgentAccount;
