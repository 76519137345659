import React, { useEffect, useRef, useState } from "react";
import { formatIndianCurrency, textDateFormatter } from "../../utils/utils";
import "./PackageDetails.css";
import imgNotFound from "../../assets/images/single-image.png";
import NoDataFound from "../NoDataFound/NoDataFound";

function ItnerarySideBar({
  onClose,
  setIsSliderVisible,
  singleBookingData: singleSliderBookingData,
  singlePackageData: packageData,
  setIsItineraryDetailsVisible,
  isItineraryDetailsVisible,
  allPackageHotels,
  selectedCategory
}) {
  const bookingDetailsPopupRef = useRef(null);
  const headerRef = useRef(null);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const togglePolicy = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    setIsSliderVisible(true);
    document.body.style.overflow = "hidden";
    const handleClickOutside = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      setIsSliderVisible(false);
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsSliderVisible]);

  const handleScroll = () => {
    if (bookingDetailsPopupRef.current.scrollTop > 50) {
      headerRef.current.classList.add("is-fixed");
    } else {
      headerRef.current.classList.remove("is-fixed");
    }
  };

  // function checkDestinationCount(destination, data) {
  //   if (!destination) return
  //   const destinationLowerCase = destination?.toLowerCase();
  //   const count = data?.filter(item => item.endDestination?.toLowerCase() === destinationLowerCase)?.length;
  //   return count;
  // }

  function checkDestinationCount(destination, data) {
    if (!destination) return;

    // Create a new array excluding the last item
    const filteredData = data?.slice(0, -1);
    const destinationLowerCase = destination?.toLowerCase();

    // Check the count of matching destinations in the filtered array
    const count = filteredData?.filter(item => item.endDestination?.toLowerCase() === destinationLowerCase)?.length;
    return count;
  }

  const groupByCategory = (accommodations) => {
    return (
      accommodations &&
      accommodations?.reduce((acc, accommodation) => {
        const category = accommodation.hotelcategory;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(accommodation);
        return acc;
      }, {})
    );
  };

  // const destinationCount = checkDestinationCount("shimla", packageData?.packageItinerary);
  // console.log(destinationCount);


  //   useEffect(() => {
  //     const sliderContainer = bookingDetailsPopupRef.current;
  //     sliderContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       sliderContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  const [activeTab, setActiveTab] = useState("package-information");

  const noOfRooms = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_of_rooms);
    },
    0
  );

  const noOfChild = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_child);
    },
    0
  );

  const noOfAdults = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_adult);
    },
    0
  );

  const extraAdult = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.extra_adult);
    },
    0
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="bg-backdrop">
        <div
          id="booking_details_slider_container_new"
          ref={bookingDetailsPopupRef}
        >
          <div id="">
            <div className="close_svg border-bottom py-5">
              <svg
                style={{ marginLeft: "20px" }}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                onClick={() => setIsItineraryDetailsVisible(false)}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 13L13 1" stroke="#000" strokeWidth="1.2" />
                <path d="M13 13L1 1" stroke="#000a" strokeWidth="1.2" />
              </svg>
            </div>

            <div className="package-name-duration-top day-wise-details-container">
              <h5>{packageData?.packageName}</h5>

              <p>
                Validity :{" "}
                {new Date(packageData?.validFrom).toLocaleDateString()} -{" "}
                {new Date(packageData?.validTo).toLocaleDateString()}
              </p>

              <p>
                Duration : {packageData?.totalDays} D /{" "}
                {packageData?.totalNights} N{" "}
              </p>
            </div>

            {/* destination images */}

            <div
              className="day-wise-details-container p_20"
              style={{ marginBottom: "20px" }}
            >
              <h5 className="day-wise-details-headings">Destinations</h5>
              <hr className="lineDownload" />
              <div
                style={{ display: "flex", gap: "15px", paddingBottom: "30px" }}
              >
                {packageData?.packageDestination?.map((destination, index) => (
                  <div className="w-full" key={index}>
                    {destination.image ? (
                      <img
                        src={destination?.image}
                        alt={destination?.title}
                        height={100}
                        width={200}
                      />
                    ) : (
                      <img
                        src={imgNotFound}
                        alt="image not found"
                        height="100px"
                        width="200px"
                      />
                    )}

                    <div style={{ textAlign: "center" }}>
                      {destination?.name}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {/* ({packageData?.totalNights} N) */}
                      ({checkDestinationCount(destination?.name, packageData?.packageItinerary) + 'N'})
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* DAY WISE DETAILS */}

            <div className="day-wise-details-container p_20 p_top_0">
              <h5 className="day-wise-details-headings">DAY WISE DETAILS</h5>
              <hr className="line" />
              <div>
                {packageData?.packageItinerary?.map((day, index) => (
                  <div
                    key={index}
                    className="package-name-duration-top mt-10 text-left"
                    style={{ padding: "10px" }}
                  >
                    <div className="flex align_items_center justify_content_between">
                      <h4 className="text-left">Day {day?.day}</h4>

                      <div>
                        Your Stay in{" "}
                        <span style={{ fontWeight: "600", color: "#016937" }}>
                          {day?.accommodation[0]?.name}
                        </span>
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: day?.additionalDetails?.short_description,
                      }}
                      className="text-left py-5"
                    ></p>
                    <div className="flex justify_content_between align_items_center">
                      <p>
                        <b>Start :</b> {day?.startDestination}{" "}
                      </p>
                      <p>
                        <b>End :</b> {day?.endDestination}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Hotel View */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Hotels</h5>
              <hr className="line" />
              <div className="flex flex_wrap justify_content_between py-10">
                {/* {packageData &&
                  packageData?.packageItinerary?.length > 0 &&
                  packageData?.packageItinerary.map((hotel, index) => (
                    <div style={{ width: "48%", marginBottom: '30px' }}>
                      <img src={hotel?.accommodation[0]?.featuredImage} />
                      <h4 style={{ fontSize: "14px", fontWeight: "normal" }}>{hotel?.accommodation[0]?.name} <small>({hotel?.accommodation[0]?.starRating} Stars)</small></h4>
                    </div>
                  ))} */}

                {groupByCategory(allPackageHotels)[
                  selectedCategory
                ]?.map((itemData, itemIndex) => {
                  return (
                    <div style={{ width: "24%", marginBottom: '30px' }}>
                      <img src={itemData?.featuredImage ? itemData?.featuredImage : imgNotFound} />
                      <h4 style={{ fontSize: "14px", fontWeight: "normal" }}>{itemData?.name} <small>({itemData?.starRating} Stars)</small></h4>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Hotel Categories */}
            <div className="day-wise-details-container p_20 p_top_0">
              <h5 className="day-wise-details-headings">Hotel Category</h5>
              <hr className="line" />
              <ul className="">
                <li className="text-capitalize">{selectedCategory ? selectedCategory : ""}</li>
                {/* {packageData &&
                  packageData?.packageItinerary?.length > 0 &&
                  packageData?.packageItinerary[0] && (
                    // <></>
                    <li className="text-capitalize">
                      {
                        packageData?.packageItinerary[0]?.accommodation[0]
                          ?.hotelcategory
                      }
                    </li>
                  )} */}
              </ul>
            </div>

            {/* Star Ratings */}
            {/* <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Star Ratings</h5>
              <hr className="line" />
              <ul className="">
                {packageData &&
                  packageData?.packageItinerary.length > 0 &&
                  packageData?.packageItinerary.map((stars, index) => (
                    <li key={index}>
                      {stars.accommodation[0]?.starRating} Star
                    </li>
                  ))}
              </ul>
            </div> */}

            {/* Policies */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Policies</h5>
              <hr className="line" />
              {packageData?.packagePolicies?.map((policy, index) => (
                <div key={index} className="policy-item mt-10">
                  <div className="policy-header" style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => togglePolicy(index)}>
                    <h4 style={{ fontSize: "16px" }}>{policy?.name}</h4>
                    <button
                      className="view-more-btn"
                    >
                      {/* {expandedIndex === index ? "Hide" : "View More"} */}
                      <svg
                        style={{
                          transform:
                            expandedIndex === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 18 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 1.38002C0 1.64002 0.1 1.89002 0.29 2.09002L7.23 9.03002C7.7 9.50002 8.33 9.76002 9 9.76002C9.67 9.76002 10.3 9.50002 10.77 9.02002L17.71 2.05002C18.1 1.66002 18.1 1.02002 17.71 0.630024C17.32 0.240024 16.69 0.240024 16.3 0.630024L9.36 7.60002C9.23 7.73002 9.08 7.75002 9.01 7.75002C8.93 7.75002 8.78 7.73002 8.66 7.60002L1.72 0.660024C1.33 0.270024 0.7 0.270024 0.31 0.660024C0.11 0.860024 0.02 1.11002 0.02 1.37002L0 1.38002Z"
                          fill="#5C5C5C"
                        />
                      </svg>
                    </button>
                  </div>
                  {expandedIndex === index && (
                    <div
                      className="policy-content"
                      dangerouslySetInnerHTML={{ __html: policy?.content }}
                    ></div>
                  )}
                </div>
              ))}
            </div>

            {/* Contact */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">For More Details Contact :</h5>
              <hr className="line" />
              <div className="mt-10 flex justify_content_between">
                <h4 style={{ fontSize: "15px" }}> Email: <span style={{ fontSize: "14px", fontWeight: "normal" }}> info@mountainmysteries.com</span></h4>
                <h4 style={{ fontSize: "15px" }}> Phone: <span style={{ fontSize: "14px", fontWeight: "normal" }}> +91-98050-21966</span></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItnerarySideBar;
