import React, { useEffect, useState } from "react";
import "./PointsRemember.css";
import Skeleton from "react-loading-skeleton";

const InclusionExclusionContent = ({ singlePackageData, loader }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (singlePackageData?.packagePolicies) {
      const firstInclusionIndex = singlePackageData?.packagePolicies?.findIndex(
        (item) => item.type === "inclusion"
      );
      if (firstInclusionIndex !== -1) {
        setExpandedIndex(firstInclusionIndex);
      }
    }
  }, [singlePackageData]);

  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="package-update-card" key={index}>
                  <div className="content-consider">
                    <div>
                      <Skeleton width={100} />
                      <div className="agent">
                        <div className="singlePackageTabShowBullets">
                          <ul>
                            <li>
                              <Skeleton width={720} />
                            </li>
                            <li>
                              <Skeleton width={400} />
                            </li>
                            <li>
                              <Skeleton width={300} />
                            </li>
                            <li>
                              <Skeleton width={200} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card p_0">
            {/* Content for accommodation */}
            {singlePackageData?.packagePolicies?.find(
              (item) => item.type === "inclusion"
            ) ? (
              <div className="content-consider p_0">
                {singlePackageData?.packagePolicies?.map((item, index) => (
                  <div key={index}>
                    {item.type === "inclusion" && (
                      <div>
                        <div
                          className="agent m_0 w-full cursor_pointer p_10"
                          onClick={() => toggleAccordion(index)}
                        >
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              Inclusions
                            </span>
                          </div>
                          <svg
                            style={{
                              transform:
                                expandedIndex === index
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                            width="10"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 1.38002C0 1.64002 0.1 1.89002 0.29 2.09002L7.23 9.03002C7.7 9.50002 8.33 9.76002 9 9.76002C9.67 9.76002 10.3 9.50002 10.77 9.02002L17.71 2.05002C18.1 1.66002 18.1 1.02002 17.71 0.630024C17.32 0.240024 16.69 0.240024 16.3 0.630024L9.36 7.60002C9.23 7.73002 9.08 7.75002 9.01 7.75002C8.93 7.75002 8.78 7.73002 8.66 7.60002L1.72 0.660024C1.33 0.270024 0.7 0.270024 0.31 0.660024C0.11 0.860024 0.02 1.11002 0.02 1.37002L0 1.38002Z"
                              fill="#5C5C5C"
                            />
                          </svg>
                        </div>
                        {expandedIndex === index && (
                          <div
                            className="singlePackageTabShowBullets p_10 p_top_0"
                            dangerouslySetInnerHTML={{
                              __html: item.content,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center" style={{padding: '5px 0'}}>No Data Found</div>
            )}
          </div>
          <div className="package-update-card p_0">
            {/* Content for accommodation */}
            {singlePackageData?.packagePolicies?.find(
              (item) => item.type === "exclusion"
            ) ? (
              <div className="content-consider p_0">
                {singlePackageData?.packagePolicies?.map((item, index) => (
                  <div key={index}>
                    {item.type === "exclusion" && (
                      <div>
                        <div
                          className="agent m_0 w-full cursor_pointer p_10"
                          onClick={() => toggleAccordion(index)}
                        >
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              Exclusion
                            </span>
                          </div>
                          <svg
                            style={{
                              transform:
                                expandedIndex === index
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                            width="10"
                            height="10"
                            viewBox="0 0 18 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 1.38002C0 1.64002 0.1 1.89002 0.29 2.09002L7.23 9.03002C7.7 9.50002 8.33 9.76002 9 9.76002C9.67 9.76002 10.3 9.50002 10.77 9.02002L17.71 2.05002C18.1 1.66002 18.1 1.02002 17.71 0.630024C17.32 0.240024 16.69 0.240024 16.3 0.630024L9.36 7.60002C9.23 7.73002 9.08 7.75002 9.01 7.75002C8.93 7.75002 8.78 7.73002 8.66 7.60002L1.72 0.660024C1.33 0.270024 0.7 0.270024 0.31 0.660024C0.11 0.860024 0.02 1.11002 0.02 1.37002L0 1.38002Z"
                              fill="#5C5C5C"
                            />
                          </svg>
                        </div>
                        {expandedIndex === index && (
                          <div
                            className="singlePackageTabShowBullets p_10 p_top_0"
                            dangerouslySetInnerHTML={{
                              __html: item.content,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center" style={{padding: '5px 0'}}>No Data Found</div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InclusionExclusionContent;
