import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import unmeteredGif from "../../assets/images/unmetered-gif.gif";
import { animateScroll as scroll } from "react-scroll";
import "./Footer.css";
import mtgi from "../../assets/images/mtgi.png";
import adtoi from "../../assets/images/adtoi.png";
import india from "../../assets/images/destination-india.png";
import lato from "../../assets/images/lato.png";

function Footer() {
  const [userType, setUserType] = useState("");
  const [loader, setLoader] = useState(false);

  const userToken = localStorage.getItem("token");
  // console.log(userToken, "token");

  const [footerDestinations, setFooterDestinations] = useState([]);
  useEffect(() => {
    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const { id: propertyId } = useParams();

  const location = useLocation();

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    const formData = new FormData();
    formData.append("limit", 10);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setFooterDestinations(result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {/* <div className="bg-dark py-15">
        <div className="container">
          <div className="flex justify_content_between align_items_center">
            <div className="flex align_items_center flex_gap_10">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40 20C40 30.2613 32.2742 38.7173 22.3215 39.8667C21.5614 39.9547 20.7854 40 20.0013 40C8.9526 40 0 31.0453 0 20C0 8.95467 8.9526 0 20.0013 0C31.0501 0 39.584 8.544 39.984 19.2347C39.9947 19.488 40 19.744 40 20Z"
                  fill="#EB442D"
                />
                <path
                  d="M40 20C40 30.2613 32.2742 38.7173 22.3215 39.8667L20.0013 40L7.12048 27.4374L32.0395 11.776L39.984 19.2347C39.9947 19.488 40 19.744 40 20Z"
                  fill="#D13C2C"
                />
                <path
                  d="M7.12048 12.528V27.4374L15.0383 19.576L7.12048 12.528Z"
                  fill="white"
                />
                <path
                  d="M22.7935 20.0053L32.0395 11.7733H7.96053L17.2065 20.0053C18.7986 21.4213 21.1988 21.4213 22.7935 20.0053Z"
                  fill="white"
                />
                <path
                  d="M23.5376 20.8426C22.5295 21.7413 21.2628 22.1893 20.0013 22.1893C18.7399 22.1893 17.4705 21.7413 16.4624 20.8426L15.8784 20.3226L7.9152 28.2266H32.0848L24.1216 20.3226L23.5376 20.8426Z"
                  fill="white"
                />
                <path
                  d="M24.9617 19.576L32.8795 27.4373V12.528L24.9617 19.576Z"
                  fill="white"
                />
              </svg>
              <div>
                <h4 className="text-left">Stay up to Date</h4>
                <p className="font_12 text-black">
                  Subscribe our newsletter and receive the latest travel news &
                  offers
                </p>
              </div>
            </div>
            <div className="flex align_items_center">
              <input
                type="text"
                placeholder="Your@email.com"
                className="suscribe"
              />
              <button className="suscribe_btn">Suscribe</button>
            </div>
          </div>
        </div>
      </div> */}

      <footer>
        <div className="bg-dark">
          {" "}
          <div className="container">
            <div className="footer-top">
              <div>
                <h2 className="text-white">
                  <b>24/7*</b>
                </h2>
                <span className="text-white">Support</span>
              </div>
              <div>
                <h2 className="text-white">
                  <b>150000+</b>
                </h2>
                <span className="text-white">Happy Clients</span>
              </div>
              <div className="border_0">
                <h2 className="text-white">
                  <b>22+</b>
                </h2>
                <span className="text-white">Years of Experience</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-20">
          <div className="footer_links flex justify_content_between mt-10">
            <div className="Footer_direct_link">
              <h6>Our Products</h6>
              <Link
                onClick={() =>
                  scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })
                }
                to="/all-hotels"
              >
                Hotels
              </Link>
              <Link
                onClick={() =>
                  scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })
                }
                to="/all-packages"
              >
                Holidays
              </Link>
              <Link
                onClick={() =>
                  scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })
                }
                to="/all-destinations"
              >
                Destinations
              </Link>
              <Link
                onClick={() =>
                  scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })
                }
                to="/all-activities"
              >
                Activities
              </Link>
              <Link
                onClick={() =>
                  scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })
                }
                to="/themes"
              >
                Themes
              </Link>
              {/* <Link onClick={() => scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })} to="/activities">Activities</Link> */}
            </div>
            <div className="Footer_direct_link">
              <h6> Popular Destinations</h6>
              <div className="flex justify_content_between flex_wrap">
                <div>
                  {footerDestinations &&
                    footerDestinations.length > 0 &&
                    footerDestinations.slice(0, 5)?.map((data, index) => (
                      // <>
                      <div key={index}>
                        <Link
                          onClick={() =>
                            scroll.scrollToTop({
                              duration: 500,
                              smooth: "easeInOutQuad",
                            })
                          }
                          className="single-des-link"
                          key={index}
                          to={`/destination/${data.name.toLowerCase()}`}
                        >
                          {data.name}
                        </Link>
                      </div>
                      // </>
                    ))}{" "}
                </div>
              </div>
              {/* <Link onClick={() => scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })} to="/activities">Activities</Link> */}
            </div>
            <div className="Footer_direct_link">
              <h6>Destinations</h6>
              <div className="flex justify_content_between flex_wrap">
                <div>
                  {footerDestinations.slice(5, 10)?.map((data, index) => (
                    <div key={index}>
                      <Link
                        onClick={() =>
                          scroll.scrollToTop({
                            duration: 500,
                            smooth: "easeInOutQuad",
                          })
                        }
                        className="single-des-link"
                        to={`/destination/${data.name.toLowerCase()}`}
                      >
                        {data.name}
                      </Link>
                    </div>
                  ))}{" "}
                </div>
              </div>
              {/* <Link onClick={() => scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })} to="/activities">Activities</Link> */}
            </div>

            <div className="Footer_direct_link">
              <h6>company</h6>
              <Link
                to="/about"
                onClick={() =>
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  })
                }
              >
                About Us
              </Link>
              <Link
                to="/contact-us"
                onClick={() =>
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  })
                }
              >
                Contact Us
              </Link>

              {/* <Link to="">Contact us</Link> */}
              {/* <Link to="">Customer Reviews</Link> */}
              {/* <Link to="">FAQs</Link> */}
              {/* <Link to="">Careers</Link> */}
              <Link to="/health-safety">Health & Safety</Link>
              {/* <Link to="/login/vendor">Vendor Login</Link> */}

              {/* <Link to="">Customer Support</Link> */}
            </div>
            {/* <div className="Footer_direct_link">
              <h6>useful links</h6>

              {!userToken && (
                <>
                  <Link
                to="/login/vendor"
                onClick={() =>
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  })
                }
              >
                Vendor Login
              </Link>
              <Link
                to="/login/agent"
                onClick={() =>
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  })
                }
              >
                Agent Login
              </Link>
              </>
              )}
            
              {userType === "customer" && (
                <Link to="/customer-booking?role=bookings">My Booking</Link>
              )}
              {userType === "customer" && (
                <Link to="/customer-profile?role=profile">My Account</Link>
              )}
              {userType === "agent" && (
                <Link to="/agent-account?role=bookings">My Booking</Link>
              )}
              {userType === "agent" && (
                <Link to="/agent-account?role=profile">My Account</Link>
              )}
              {userType === "vendor" && (
                <Link to={`/partner/bookings/1`}>My Booking</Link>
              )}
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-copyright">
              <div className="flex flex_gap_10 align_items_center">
                <span>Follow Us :</span>
                <div className="flex flex_gap_3 align_items_center">
                  <Link
                    to="https://www.facebook.com/mountainmysteries.in"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12.5" cy="13" r="12" stroke="#009A50" />
                      <path
                        d="M13.75 13.9375H15.3125L15.9375 11.4375H13.75V10.1875C13.75 9.54375 13.75 8.9375 15 8.9375H15.9375V6.8375C15.7337 6.81063 14.9644 6.75 14.1519 6.75C12.455 6.75 11.25 7.78562 11.25 9.6875V11.4375H9.375V13.9375H11.25V19.25H13.75V13.9375Z"
                        fill="#009A50"
                      />
                    </svg>
                  </Link>
                  <Link
                    to="https://www.instagram.com/mountainmysteries/"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12.5" cy="13" r="12" stroke="#009A50" />
                      <path
                        d="M9.875 6.75H15.125C17.125 6.75 18.75 8.375 18.75 10.375V15.625C18.75 16.5864 18.3681 17.5084 17.6883 18.1883C17.0084 18.8681 16.0864 19.25 15.125 19.25H9.875C7.875 19.25 6.25 17.625 6.25 15.625V10.375C6.25 9.41359 6.63192 8.49156 7.31174 7.81174C7.99156 7.13192 8.91359 6.75 9.875 6.75ZM9.75 8C9.15326 8 8.58097 8.23705 8.15901 8.65901C7.73705 9.08097 7.5 9.65326 7.5 10.25V15.75C7.5 16.9937 8.50625 18 9.75 18H15.25C15.8467 18 16.419 17.7629 16.841 17.341C17.2629 16.919 17.5 16.3467 17.5 15.75V10.25C17.5 9.00625 16.4937 8 15.25 8H9.75ZM15.7812 8.9375C15.9885 8.9375 16.1872 9.01981 16.3337 9.16632C16.4802 9.31284 16.5625 9.51155 16.5625 9.71875C16.5625 9.92595 16.4802 10.1247 16.3337 10.2712C16.1872 10.4177 15.9885 10.5 15.7812 10.5C15.574 10.5 15.3753 10.4177 15.2288 10.2712C15.0823 10.1247 15 9.92595 15 9.71875C15 9.51155 15.0823 9.31284 15.2288 9.16632C15.3753 9.01981 15.574 8.9375 15.7812 8.9375ZM12.5 9.875C13.3288 9.875 14.1237 10.2042 14.7097 10.7903C15.2958 11.3763 15.625 12.1712 15.625 13C15.625 13.8288 15.2958 14.6237 14.7097 15.2097C14.1237 15.7958 13.3288 16.125 12.5 16.125C11.6712 16.125 10.8763 15.7958 10.2903 15.2097C9.70424 14.6237 9.375 13.8288 9.375 13C9.375 12.1712 9.70424 11.3763 10.2903 10.7903C10.8763 10.2042 11.6712 9.875 12.5 9.875ZM12.5 11.125C12.0027 11.125 11.5258 11.3225 11.1742 11.6742C10.8225 12.0258 10.625 12.5027 10.625 13C10.625 13.4973 10.8225 13.9742 11.1742 14.3258C11.5258 14.6775 12.0027 14.875 12.5 14.875C12.9973 14.875 13.4742 14.6775 13.8258 14.3258C14.1775 13.9742 14.375 13.4973 14.375 13C14.375 12.5027 14.1775 12.0258 13.8258 11.6742C13.4742 11.3225 12.9973 11.125 12.5 11.125Z"
                        fill="#009A50"
                      />
                    </svg>
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCLCvoKy9UnH6f8nvax44R8w"
                    target="_blank"
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12.5" cy="13" r="12" stroke="#009A50" />
                      <path
                        d="M17.2544 9.87187C17.2069 9.68562 17.0719 9.545 16.9175 9.50187C16.6438 9.425 15.3131 9.25 12.5006 9.25C9.68813 9.25 8.35813 9.425 8.0825 9.50187C7.93 9.54437 7.795 9.685 7.74688 9.87187C7.67875 10.1369 7.50062 11.2469 7.50062 13C7.50062 14.7525 7.67875 15.8625 7.74688 16.1287C7.79437 16.3144 7.92938 16.455 8.08313 16.4975C8.35812 16.575 9.6875 16.75 12.5 16.75C15.3125 16.75 16.6431 16.575 16.9181 16.4981C17.0706 16.4556 17.2056 16.315 17.2538 16.1281C17.3225 15.8631 17.5 14.75 17.5 13C17.5 11.25 17.3225 10.1375 17.2544 9.87187ZM18.4644 9.56125C18.75 10.675 18.75 13 18.75 13C18.75 13 18.75 15.325 18.4644 16.4388C18.3056 17.0544 17.8412 17.5387 17.2531 17.7025C16.1856 18 12.5 18 12.5 18C12.5 18 8.81687 18 7.74688 17.7025C7.15625 17.5363 6.6925 17.0525 6.53563 16.4388C6.25 15.325 6.25 13 6.25 13C6.25 13 6.25 10.675 6.53563 9.56125C6.69438 8.94563 7.15875 8.46125 7.74688 8.2975C8.8175 8 12.5006 8 12.5006 8C12.5006 8 16.1856 8 17.2538 8.2975C17.8438 8.46375 18.3081 8.9475 18.465 9.56125M11.2506 15.1875V10.8125L15.0006 13L11.2506 15.1875Z"
                        fill="#009A50"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="flex align_items_center">
                <span>Member of :</span>
                <div className="flex flex_gap_5">
                  <img
                    src={mtgi}
                    alt=""
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "scale-down",
                    }}
                  />
                  <img
                    src={lato}
                    alt=""
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "scale-down",
                    }}
                  />
                  <img
                    src={adtoi}
                    alt=""
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "scale-down",
                    }}
                  />
                  <img
                    src={india}
                    alt=""
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "scale-down",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-copyright">
              <small className="descript font_11">
                <b>Disclaimer : </b> All travel packages and services listed on
                this website are subject to availability and may change without
                prior notice. Prices, inclusions, and itineraries are not
                guaranteed until full payment is confirmed. The agency is not
                liable for any delays, cancellations, or issues caused by
                third-party providers or unforeseen events. We recommend
                travelers review all terms and conditions before booking. By
                using this site, you agree to comply with the policies stated.
              </small>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-bottom">
            <div className="footer-copyright">
              <span className="copy">
                © Copyright 2025. All Right Reserved.
              </span>

              <div className="term">
                <Link to="/term-conditions">Terms & Conditions </Link>
                <Link to="/cancellation-policy">| Cancellation Policy |</Link>
                <Link to="/disclaimer">Disclaimer |</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="unmetered-gif">
                <p> Powered By</p>
                <Link to="https://unmeteredtechnologies.com/">
                  <img
                    src={unmeteredGif}
                    alt="unmetered-gif"
                    style={{ height: "auto", width: "75px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
