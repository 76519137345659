import React, { useEffect, useState } from "react";
import Header from "../../CustomComponents/Header/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Loader from "../../CustomComponents/Loader/Loader";
import Footer from "../../CustomComponents/Footer/Footer";
import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import Slider from "react-slick";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../CustomComponents/SkeletonLoader/SkeletonLoader";
import imgNotFound from "../../assets/images/single-image.png";
import AllHotelsSearchForm from "../../CustomComponents/AllHotelsSearchForm/AllHotelsSearchForm";
import { currentDateSingle, formatIndianCurrency } from "../../utils/utils";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
import BreadcrumbSchema from "../../CustomComponents/Schema/BreadcrumbSchema";

import { animateScroll as scroll } from "react-scroll";

const Destinations = () => {
  const { destination } = useParams();
  // console.log(destination, "destination");
  const location = useLocation();
  const [bookingData, setBookingData] = useState({});
  const [hotelData, setHotelData] = useState({});
  const navigate = useNavigate();
  const [destinationImagesArray, setDestinationsImagesArray] = useState([]);

  const [allDestinations, setAllDestinations] = useState([]);
  const [allDestinationArray, setAllDestinationArray] = useState([]);

  // console.log(destinationImagesArray);

  const [loader, setLoader] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    hotelData &&
    hotelData &&
    hotelData.length > 0 &&
    hotelData.slice(startIndex, endIndex);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const currentDate = currentDateSingle;

    const data = {
      // hotelID: params.get("hotelID"),
      location: destination,
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      checkinDay: new Date(currentDate).getDate(),
      checkinMonth: new Date(currentDate).getMonth() + 1,
      checkinYear: new Date(currentDate).getFullYear(),
      checkoutDay: new Date(currentDate).getDate() + 1,
      checkoutMonth: new Date(currentDate).getMonth() + 1,
      checkoutYear: new Date(currentDate).getFullYear(),
      radio: params.get("radio"),
      childAge: childAges, // Assign the array of child ages
    };
    // console.log(hotelData, "data hotel")
    setBookingData(data);
    postBookingData(data);
  }, [location.search, destination]);
  // console.log(items, "  data hotel")

  // console.log(bookingData, "bookingData");

  const postBookingData = async (data) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("location", destination);
    formdata.append("limit", 8);

    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
      fetchDestinations(destination);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // showErrorToast(error.message);
    } finally {
      // setLoader(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const handleViewRoomsClickHandler = async (item) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    try {
      await navigate(
        `/single-hotel/${item?.hotel_slug}?hotelId=${item?.hotel_id}&location=${bookingData.location}&room=1&adult=1&children=0&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`,
        { state: { item: item } }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoader(false);
    } finally {
      // setLoader(false);
    }
  };

  const fetchDestinations = async (destination) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("token")
    // );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );

      const result = await response.json();

      if (result.success) {
        setAllDestinations(result?.data);
        setAllDestinationArray(result?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };
  // console.log(allDestinations, "allDestinations");

  const [searchInputValue, setSearchInputValue] = useState(null);

  useEffect(() => {
    const filterData = allDestinationArray.filter((item) =>
      item.name.toLowerCase().includes(searchInputValue.toLowerCase())
    );
    // console.log(filterData, "filterData");

    if (!searchInputValue || searchInputValue === "") {
      setAllDestinationArray(allDestinationArray);
    } else {
      setAllDestinations(filterData);
    }
  }, [searchInputValue]);

  useEffect(() => {
    fetchDestinations();
  }, []);

  // console.log("searchInputValue", searchInputValue);

  return (
    <div id="singleDestination">
      <PageMetaData
        title={`Best Places to Visit in Himachal Pradesh - Mountain Mysteries`}
        description="Looking for the best places to visit in Himachal Pradesh? From peaceful hill stations to breathtaking mountain views, uncover the beauty and charm of this magical destination"
        keywords="places to visit in Himachal Pradesh"
      />
      <OgMetaTitles
        title={`Best Places to Visit in Himachal Pradesh - Mountain Mysteries`}
        type="Looking for the best places to visit in Himachal Pradesh? From peaceful hill stations to breathtaking mountain views, uncover the beauty and charm of this magical destination"
        url={`https://www.mountainmysteries.com/all-destinations`}
      />
      <BreadcrumbSchema />
      <Header />
      {/* <AllHotelsSearchForm searchType="single_destination" /> */}

      {loader ? (
        <>
          <div className="container">
            <div>
              <div className="mt-20">
                <Skeleton width={300} height={30} />
              </div>
              <div className="destinationsCard mt-10 evenly-align">
                {Array.from({ length: 9 }).map((_, index) => (
                  <div
                    className="destination_link destination-image"
                    key={index}
                  >
                    <Skeleton height={280} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="properties_section ">
            <div className="container">
              <div>
                <div className="flex justify_content_between align_items_center flex_wrap mt-20">
                  <h3
                    style={{ textTransform: "capitalize" }}
                    className="text-left"
                  >
                    All Destinations {bookingData.location}
                  </h3>
                  <div className="relative search-input">
                    <input
                      type="text"
                      placeholder="search destination.."
                      className="searchInput"
                      value={searchInputValue}
                      onChange={(e) => setSearchInputValue(e.target.value)}
                    />
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.34975 11.1503 3 8.25 3C5.34975 3 3 5.34975 3 8.25C3 11.1503 5.34975 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                        fill="#888888"
                      />
                    </svg>
                  </div>
                </div>
                <div className="destinationsCard mt-10 pb_30">
                  {allDestinations?.length > 0 ? (
                    allDestinations?.map((item, index) => {
                      return (
                        <Link
                          // onClick={scroll.scrollToTop({
                          //   duration: 500,
                          //   smooth: "easeInOutQuad",
                          // })}
                          // to={`/destination/${item.name.toLowerCase()}`}
                          to={`/destination/${
                            item.name ? String(item.name).toLowerCase() : ""
                          }`}
                          className="destination_link destination-image"
                          key={index}
                        >
                          {item.images && item.images.length > 0 ? (
                            <img src={item.images[0]} alt={item.name} />
                          ) : (
                            <img src={imgNotFound} alt={item.name} />
                          )}
                          <div className="destination_name">
                            <h6>{item.name}</h6>
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </div>
  );
};

export default Destinations;
