import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import logoimg from "../../assets/images/Mountain-mystries-logo.png";
import { accordionItems } from "../../constants";
import { RegisterWork } from "../../constants";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./LoginVendors.css";
import NewFooter from "../../CustomComponents/NewFooter/NewFooter";
import { type } from "@testing-library/user-event/dist/type";
import Footer from "../../CustomComponents/Footer/Footer";
import { Features } from "../../constants";
import { Packages } from "../../constants";
import AccountDetailDropdown from "../../CustomComponents/Login/AccountDetailDropdown";
import LoginBtn from "../../CustomComponents/Login/LoginBtn";
import NoIndexMeta from "../../utils/NoIndexMeta";
import { saveEncryptedData } from "../../utils/crypto";

function LoginVendors({ onClose }) {
  const [openPopup, setOpenPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRegister, setShowPasswordRegister] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialRole = queryParams.get("role") || "agent";
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsRegister, setFormErrorsRegister] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    // if (currentStep === 1) {
    if (!formData?.email.trim()) {
      errors.email = "required";
    }
    if (!formData?.password.trim()) {
      errors.password = "required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const validateRegister = () => {
    const errors = {};
    if (!createFormData?.name.trim()) {
      errors.name = "required";
    }
    if (!createFormData?.email.trim()) {
      errors.email = "required";
    }
    if (!createFormData?.password.trim()) {
      errors.password = "required";
    }
    if (!createFormData?.confirmPassword.trim()) {
      errors.confirmPassword = "required";
    }
    if (!createFormData.phone.trim()) {
      errors.phone = "is required";
    }
    setFormErrorsRegister(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }

    return Object.keys(errors).length === 0;
  };

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const showCreateAccount = () => {
    setIsCreatingAccount(true);
    setResetForm(false);
  };

  const showResetForm = () => {
    setResetForm(true);
    setIsCreatingAccount(false);
  };

  // loginbuttons
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check if token is present in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    window.location.reload();
    navigate("/");
    localStorage.clear();
    showSuccessToast("Logged Out Successful");
    // alert("test")
    // setLoader(true);
    // const token = localStorage.getItem("token");
    // const myHeaders = new Headers();

    // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // try {
    //   const response = await fetch(
    //     "https://extranet.travcatalyst.com/api/v1/customers/logout",
    //     requestOptions
    //   );
    //   const result = await response.json();
    //   console.log(result);

    //   showSuccessToast("Logged Out Successful")
    //   localStorage.clear()
    //   setIsLoggedIn(false);
    //   // navigate("/login")
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoader(false);
    // }
  };

  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    // console.log(storedUserDetails);
    if (storedUserDetails) {
      const userDetails = JSON.parse(storedUserDetails);
      // console.log(userDetails);

      setName(userDetails.vendor_contact_name);
    }

    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  const switchForm = (form) => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setIsCreatingAccount(false);
    setResetForm(false);
  };

  const [createFormData, setCreateFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChangeFormCreate = (e) => {
    const { name, value } = e.target;
    setCreateFormData({ ...createFormData, [name]: value });
  };

  const userToken = localStorage.getItem("token");
  //   console.log(userToken, "tokkkk");
  const [agentDetails, setAgentData] = useState();

  // console.log(formData);
  const handleClosePopup = (e) => {
    onClose();
  };

  const getUserProfile2 = (token) => {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        Authorization: "Bearer " + token,
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/website/supplier/user-profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          localStorage.setItem("userDetails", JSON.stringify(result?.vendor));
          navigate("/vendor/property/list");
          showSuccessToast("Login Successful");
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  const getUserProfile = (token) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/supplier/user-profile`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          localStorage.setItem("userDetails", JSON.stringify(data?.data));
          navigate("/vendor/property/list");
          // showSuccessToast("Login Successful");
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  // useEffect(() => {
  //     getUserProfile();
  // }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  // Vendor Register

  const handleVendorSubmit2 = async (event) => {
    event.preventDefault();
    validateRegister();

    setTimeout(() => {
      setFormErrorsRegister({});
    }, 1000);
    // validate();

    // Add password match validation
    if (createFormData.password !== createFormData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }
    // Add password length validation
    if (
      createFormData.password.length < 8 &&
      createFormData.confirmPassword.length < 8
    ) {
      showErrorToast("Password must be at least 8 characters.");
      return;
    }

    // Prepare headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    // Prepare the request body
    const raw = JSON.stringify({
      name: createFormData?.name,
      email: createFormData?.email,
      password: createFormData?.password,
      c_password: createFormData?.confirmPassword,
      phone: createFormData?.phone, // Assuming a static phone number for demonstration
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/vendor/sign-up`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // console.log("Vendor Registration API response:", result);

      if (result.success) {
        showSuccessToast(result.message || "Vendor Registration Successful");
        localStorage.setItem("vendorData", JSON.stringify(result.vendor));
        // Redirect or perform any other actions needed post-registration
      } else {
        showErrorToast(result.message || "Registration Failed");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      showErrorToast("Error during registration");
    }
  };

  const handleVendorRegister = async (e) => {
    e.preventDefault();
    validateRegister();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const raw = JSON.stringify({
      type: "vendor",
      name: createFormData?.name,
      email: createFormData?.email,
      callbackUrl: "https://mountainmysteries.com",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/register`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        showSuccessToast(result.message || "Vendor Registration Successful");
        localStorage.setItem("vendorData", JSON.stringify(result.vendor));
        setCreateFormData({
          name: "",
          email: "",
        });
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast("Error registering Agent");
      console.error("Error registering user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVendorLogin2 = (e) => {
    e.preventDefault();
    // validateRegister();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1000);
    if (!valid) {
      showErrorToast("Please fill the required details");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: formData.email,
      password: formData.password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "vendor");
        if (result.success) {
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("userName", result.data.name);
          localStorage.setItem("userType", "vendor");
          getUserProfile(result.data.token);

          // navigate(`/vendor/property/list/${result.data.id}`);
          //   showSuccessToast(result.message);
        } else {
          showErrorToast(result.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleVendorLogin = async (e) => {
    e.preventDefault();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const raw = JSON.stringify({
      type: "vendor",
      email: formData?.email,
      password: formData?.password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/login`,
        requestOptions
      );
      const result = await response.json();
      if (result?.success) {
        if (result.data.accountStatus === "InActive") {
          showErrorToast(
            "Your account is currently inactive. Please reach out to the Mountain Mysteries team to reactivate it."
          );
        } else {
          showSuccessToast(result.message || "Login Successfully!");
          localStorage.setItem("token", result.data.token);
          saveEncryptedData("userToken", result.data.token);
          localStorage.setItem("userName", result.data.email);
          localStorage.setItem("userType", "vendor");
          getUserProfile(result.data.token);
        }
      } else {
        showErrorToast(result.message || "Login Failed");
      }
      if (!result?.success) {
        showErrorToast(result.message || "Login Failed");
      }
    } catch (error) {
      showErrorToast("Login Failed");
      console.error("Error Login agent:", error);
    } finally {
      setLoading(false);
    }
  };

  // const resetPasswordHandler = async (event) => {
  //   event.preventDefault();

  //   // Prepare headers
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );

  //   // Prepare the request body
  //   const raw = JSON.stringify({
  //     email: formData?.email,
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_APP_URL}/api/v1/agent/send-reset-link`,
  //       requestOptions
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const result = await response.json();

  //     if (result.success) {
  //       showSuccessToast(result.message || "Please Check Your Email.");
  //       setOpenPopup(false);
  //     } else {
  //       showErrorToast(result.message || "Password Reset Failed");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //     showErrorToast("Password Reset Failed");
  //   }
  // };

  // const resetPasswordHandler = async (e) => {
  //   e.preventDefault();
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
  //   myHeaders.append(
  //     "Cookie",
  //     process.env.REACT_APP_COOKIE
  //   );

  //   const raw = JSON.stringify({
  //     type: "vendor",
  //     email: formData?.email,
  //     callbackUrl: "http://localhost:3000/reset-password",
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/send-password-reset-link`,
  //       requestOptions
  //     );
  //     const result = await response.json();
  //     console.log(result);
  //     if (result.success) {
  //       showSuccessToast(result.message || "Please Check Your Email.");
  //       setOpenPopup(false);
  //     } else {
  //       showErrorToast(result.message || "Password Reset Failed");
  //     }
  //   } catch (error) {
  //     console.error("Error sending reset password link:", error);
  //   }
  // };

  // useEffect(() => {
  //   resetPasswordHandler();
  // }, []);

  const resetPasswordHandler = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);

    const raw = JSON.stringify({
      email: formData?.email,
      type: "vendor",
      callbackUrl: "https://www.mountainmysteries.com/reset-password/vendor",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accounts/send-password-reset-link`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        showSuccessToast(result.message || "Please Check Your Email.");
        setOpenPopup(false);
      } else {
        showErrorToast(result.message || "Password Reset Failed");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      showErrorToast("Password Reset Failed");
    }
  };

  return (
    <>
      <NoIndexMeta />
      <div className="LoginVender_topHeader">
        <div className="container">
          <div className="LoginVendor_head flex_align_center justify_content_between">
            <div className="LoginVendor_logo">
              <Link to="/">
                <img className="logo" src={logoimg} alt="" />
              </Link>
            </div>

            <div className="flex_align_center  cursor_pointer flex_gap_10">
              <div>
                {isLoggedIn ? (
                  <div className="Main-header-btn">
                    <AccountDetailDropdown
                      name={name}
                      userType={userType}
                      handleLogout={handleLogout}
                    />
                  </div>
                ) : (
                  <div className="Mainregister-btn Main-header-btn">
                    <LoginBtn dontShowCustomerDetail={true} />
                  </div>
                )}
              </div>
              <div className="support_btn">
                <span>
                  <svg
                    style={{ color: "white" }}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2485_129"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="17"
                    >
                      <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2485_129)">
                      <path
                        d="M8 15.8335V14.5002H12.6667V13.8335H10V8.50016H12.6667V7.8335C12.6667 6.54461 12.2111 5.44461 11.3 4.5335C10.3889 3.62239 9.28889 3.16683 8 3.16683C6.71111 3.16683 5.61111 3.62239 4.7 4.5335C3.78889 5.44461 3.33333 6.54461 3.33333 7.8335V8.50016H6V13.8335H3.33333C2.96667 13.8335 2.65278 13.7029 2.39167 13.4418C2.13056 13.1807 2 12.8668 2 12.5002V7.8335C2 7.01127 2.15833 6.23627 2.475 5.5085C2.79167 4.78072 3.22222 4.14461 3.76667 3.60016C4.31111 3.05572 4.94722 2.62516 5.675 2.3085C6.40278 1.99183 7.17778 1.8335 8 1.8335C8.82222 1.8335 9.59722 1.99183 10.325 2.3085C11.0528 2.62516 11.6889 3.05572 12.2333 3.60016C12.7778 4.14461 13.2083 4.78072 13.525 5.5085C13.8417 6.23627 14 7.01127 14 7.8335V14.5002C14 14.8668 13.8694 15.1807 13.6083 15.4418C13.3472 15.7029 13.0333 15.8335 12.6667 15.8335H8ZM3.33333 12.5002H4.66667V9.8335H3.33333V12.5002ZM11.3333 12.5002H12.6667V9.8335H11.3333V12.5002Z"
                        fill="#333333"
                      />
                    </g>
                  </svg>
                </span>
                Support
                <div className="supportDetails">
                  <Link className="flex" to="tel:+919805021966">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_992"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2263_992)">
                          <path
                            d="M14.9625 16.25C13.4 16.25 11.8563 15.9094 10.3313 15.2281C8.80625 14.5469 7.41875 13.5813 6.16875 12.3313C4.91875 11.0813 3.95312 9.69375 3.27188 8.16875C2.59063 6.64375 2.25 5.1 2.25 3.5375C2.25 3.3125 2.325 3.125 2.475 2.975C2.625 2.825 2.8125 2.75 3.0375 2.75H6.075C6.25 2.75 6.40625 2.80937 6.54375 2.92812C6.68125 3.04688 6.7625 3.1875 6.7875 3.35L7.275 5.975C7.3 6.175 7.29375 6.34375 7.25625 6.48125C7.21875 6.61875 7.15 6.7375 7.05 6.8375L5.23125 8.675C5.48125 9.1375 5.77812 9.58437 6.12187 10.0156C6.46562 10.4469 6.84375 10.8625 7.25625 11.2625C7.64375 11.65 8.05 12.0094 8.475 12.3406C8.9 12.6719 9.35 12.975 9.825 13.25L11.5875 11.4875C11.7 11.375 11.8469 11.2906 12.0281 11.2344C12.2094 11.1781 12.3875 11.1625 12.5625 11.1875L15.15 11.7125C15.325 11.7625 15.4688 11.8531 15.5812 11.9844C15.6937 12.1156 15.75 12.2625 15.75 12.425V15.4625C15.75 15.6875 15.675 15.875 15.525 16.025C15.375 16.175 15.1875 16.25 14.9625 16.25ZM4.51875 7.25L5.75625 6.0125L5.4375 4.25H3.76875C3.83125 4.7625 3.91875 5.26875 4.03125 5.76875C4.14375 6.26875 4.30625 6.7625 4.51875 7.25ZM11.2313 13.9625C11.7188 14.175 12.2156 14.3438 12.7219 14.4688C13.2281 14.5938 13.7375 14.675 14.25 14.7125V13.0625L12.4875 12.7063L11.2313 13.9625Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>
                      <b style={{ fontSize: "12px" }}>Call Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>+91-98050-21966</span>
                    </span>
                  </Link>
                  <Link className="flex" to="mailto:info@mountainmysteries.com">
                    <span className="supportSvg">
                      <svg
                        className="m_0"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2263_999"
                          style={{
                            maskType: "alpha",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0",
                            width: "18",
                            height: "19",
                          }}
                        >
                          <rect
                            x="0.333374"
                            y="0.5"
                            width="18"
                            height="18"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_2263_999)">
                          <path
                            d="M3.33337 15.5C2.92087 15.5 2.56775 15.3531 2.274 15.0594C1.98025 14.7656 1.83337 14.4125 1.83337 14V5C1.83337 4.5875 1.98025 4.23438 2.274 3.94063C2.56775 3.64688 2.92087 3.5 3.33337 3.5H15.3334C15.7459 3.5 16.099 3.64688 16.3927 3.94063C16.6865 4.23438 16.8334 4.5875 16.8334 5V14C16.8334 14.4125 16.6865 14.7656 16.3927 15.0594C16.099 15.3531 15.7459 15.5 15.3334 15.5H3.33337ZM9.33337 10.25L3.33337 6.5V14H15.3334V6.5L9.33337 10.25ZM9.33337 8.75L15.3334 5H3.33337L9.33337 8.75ZM3.33337 6.5V5V14V6.5Z"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </span>

                    <span>
                      <b style={{ fontSize: "12px" }}>Mail Support</b>
                      <br />
                      <span style={{ color: "#016937" }}>
                        {" "}
                        info@mountainmysteries.com
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            {/* <form onSubmit={handleVendorLogin} >
                            <div className="LoginVendor_form flex flex_gap_10">
                                <div className="LoginVendor_form_cont">
                                    <input name="email"
                                        value={formData.email}
                                        onChange={handleInputChange} placeholder="Email" type="email" />
                                </div>
                                <div style={{ alignItems: "flex-end" }} className="LoginVendor_form_cont flex flex_direction_col">
                                    <input name="password"
                                        value={formData.password}
                                        onChange={handleInputChange} placeholder="Password" type="password" />
                                    
                                </div>
                                <div className="LoginVendor_form_cont">
                                    <button type="submit" className="LoginVendor_form_submit red_new_btn">Sign In</button>
                                </div>

                            </div>
                        </form> */}
          </div>
        </div>
      </div>

      {/* Banner */}
      <section className="LoginVendor_banner">
        <div className="container">
          <div className="LoginVendor_banner_main LoginVendor_padding">
            <div className="LoginVendor_banner_content">
              <h3>
                Boost Your Property's Visibility – List for Free on Mountain
                Mysteries!
              </h3>
              <p>
                List your property for free on Mountain Mysteries and reach
                thousands of eager travelers! It’s quick, easy, and the perfect
                way to showcase your unique stay to a world of adventure
                seekers.
              </p>

              <ScrollLink
                to="vendorRegister"
                smooth="easeInOutQuad"
                duration={500}
                className="LoginVendor_banner_getStarted red_new_btn hoverCursor"
              >
                Get Started Now - <small>It's Free!</small>
              </ScrollLink>
            </div>
            <div className="LoginVendor_banner_form">
              <form onSubmit={handleVendorLogin}>
                <div className="LoginVendor_form flex flex_direction_col">
                  <h2>Sign In</h2>
                  <div className="LoginVendor_form_cont">
                    <label htmlFor="">Email</label>
                    <input
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      type="email"
                      style={
                        formErrors.email && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                    />
                  </div>
                  <div
                    className="LoginVendor_form_cont"
                    style={{ position: "relative" }}
                  >
                    <label htmlFor="">Password</label>
                    <input
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      style={
                        formErrors.password && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                      type={
                        showPassword ? (type = "text") : (type = "password")
                      }
                    />
                    {showPassword ? (
                      <svg
                        onClick={() => setShowPassword(false)}
                        style={{
                          position: "absolute",
                          top: "33px",
                          right: "5px",
                          cursor: "pointer",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2 2L22 22"
                          stroke="#888888"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                          stroke="#888888"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                          stroke="#888888"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => setShowPassword(true)}
                        style={{
                          position: "absolute",
                          top: "34px",
                          right: "5px",
                          cursor: "pointer",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 14 10"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_298)">
                          <path
                            d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                            fill="#888888"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_1_298">
                            <rect width="14" height="10" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </div>
                </div>
                <div className="LoginVendor_form_cont LoginVendor_form_btn">
                  <button
                    type="submit"
                    className="LoginVendor_form_submit red_new_btn_black"
                  >
                    Sign In For Vendors
                  </button>
                  <Link onClick={() => setOpenPopup(true)}>
                    Forgot Password ?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* How It Work */}
      <section
        className="LoginVendor_padding"
        style={{ background: "#F5F5F5" }}
      >
        <div className="container">
          <h3 className="LoginVendor_heading">How It Works?</h3>
          <div className="work-flow">
            {RegisterWork.map((item, index) => {
              const className =
                (index + 1) % 2 === 0 ? "heading-6-even" : "heading-6-odd";
              return (
                <div className="main-circle" key={index}>
                  <div className="circle">
                    <div className="text-center">
                      {" "}
                      {item.svg}
                      <span className="block mt-5" style={{ width: "100px" }}>
                        {item.heading}
                      </span>
                    </div>
                  </div>
                  <h6 className={`${className} heading-6 `}> {item.Step}</h6>
                </div>
              );
            })}
            <div className="fixed-line"></div>
          </div>
        </div>
      </section>
      {/* Register Form */}
      <section id="vendorRegister" className="LoginVendor_shade">
        <div className="LoginVendor_regMain">
          <div className="LoginVendor_registrationRight">
            <div className="LoginVendor_registerMain flex Register_Agent_Vendor_Form">
              <form onSubmit={handleVendorRegister} action="">
                <div className="LoginVendor_registerForm flex">
                  <h2 className="LoginVendor_heading Register_heading">
                    Its Free. Register Now
                  </h2>
                  {/* <div className="w-full LoginVendor_registerForm_entry">
                     <div className="">
                      <label htmlFor="" className="text-black">
                        Phone Number
                      </label>
                      <input
                        className="w-100"
                        onChange={handleInputChangeFormCreate}
                        name="phone"
                        value={createFormData?.phone}
                        type="number"
                        style={
                          formErrorsRegister.phone
                            ? {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            : {}
                        }
                      />
                    </div>
                  </div> */}
                  <div className="w-100">
                    <div className="">
                      <label htmlFor="" className="text-black">
                        Full Name
                      </label>
                      <input
                        className="w-100"
                        onChange={handleInputChangeFormCreate}
                        name="name"
                        value={createFormData?.name}
                        type="text"
                        style={
                          formErrorsRegister.name
                            ? {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            : {}
                        }
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    {/* <div className="" style={{ position: "relative" }}>
                      <label htmlFor="" className="text-black">
                        Password
                      </label>
                      <input
                        className="w-100"
                        onChange={handleInputChangeFormCreate}
                        name="password"
                        value={createFormData?.password}
                        style={
                          formErrorsRegister.password
                            ? {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            : {}
                        }
                        type={
                          showPasswordRegister
                            ? (type = "text")
                            : (type = "password")
                        }
                      />

                      {showPasswordRegister ? (
                        <svg
                          onClick={() => setShowPasswordRegister(false)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M2 2L22 22"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          onClick={() => setShowPasswordRegister(true)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 14 10"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_298)">
                            <path
                              d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                              fill="#888888"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_298">
                              <rect width="14" height="10" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </div>
                    <div className="" style={{ position: "relative" }}>
                      <label htmlFor="" className="text-black">
                        Confirm Password
                      </label>
                      <input
                        className="w-100"
                        style={
                          formErrorsRegister.confirmPassword
                            ? {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            : {}
                        }
                        onChange={handleInputChangeFormCreate}
                        name="confirmPassword"
                        value={createFormData?.confirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                      />

                      {showConfirmPassword ? (
                        <svg
                          onClick={() => setConfirmPassword(false)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M2 2L22 22"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                            stroke="#888888"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          onClick={() => setConfirmPassword(true)}
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 14 10"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_298)">
                            <path
                              d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                              fill="#888888"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_298">
                              <rect width="14" height="10" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </div> */}
                    <div className="">
                      <label htmlFor="" className="text-black">
                        Email Id
                      </label>
                      <input
                        className="w-100"
                        onChange={handleInputChangeFormCreate}
                        name="email"
                        value={createFormData?.email}
                        type="email"
                        style={
                          formErrorsRegister.email
                            ? {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            : {}
                        }
                      />
                    </div>
                  </div>
                  <div className="LoginVendor_registerForm_btn w-full">
                    <button type="submit" className="red_new_btn_black">
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="flex-packages">
            {Packages.map((item, index) => {
              return (
                <div className="LoginVendor_wokingCard" key={index}>
                  <div className="LoginVendor_wokingCard_content">
                    <img
                      src={item.image}
                      alt="package-icon"
                      style={{
                        height: "40px",
                        width: "40px",
                        objectFit: "scale-down",
                      }}
                    />
                    <h5 className="mt-5 font_12 text-center">{item.heading}</h5>
                    <small className="text-center">{item.text}</small>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Features */}
      <section
        className="LoginVendor_padding"
        style={{ background: "#F5F5F5" }}
      >
        <div className="container">
          <h3 className="LoginVendor_heading">Features At a Glance</h3>
          <div className="LoginVendor_featuresMain flex_align_center">
            {Features.map((content, index) => (
              <div key={index} className="LoginVendor_featuresCard">
                <div className="LoginVendor_featuresCard_icon flex mb-10">
                  <img src={content.img} alt="" />
                </div>
                <div className="LoginVendor_featuresCard_content">
                  <h4>{content.head}</h4>
                  <small className="block">{content.description}</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section className="LoginVendor_padding LoginVendor_shade">
        <div className="container">
          <h3 className="LoginVendor_heading">FAQs</h3>
          {accordionItems.map((item, index) => (
            <div className="accordion mb-20" key={index}>
              <div className="accordion-item" key={index}>
                <div
                  className="flex justify_content_between p_10 cursor_pointer accordion_icon align_items_center"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="accordion-item-header">{item.header}</div>
                  <svg
                    className={`accordion-icon ${
                      activeIndex === index ? "rotated" : ""
                    }`}
                    width="10"
                    height="10"
                    viewBox="0 0 9 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.25 0.75L4.75 4.25L1.25 0.75" stroke="#000" />
                  </svg>
                </div>
                <div
                  className={`accordion-item-body ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div className="accordion-item-body-content">
                    {item.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />

      {openPopup && (
        <div className="popup_backdrop">
          <div className="popup_section">
            <form onSubmit={resetPasswordHandler} className="registration-form">
              {/* <form onSubmit={}> */}
              <div className="flex justify_content_between align_items_center">
                <h4 className="text-left">
                  <b>Reset Your Password</b>
                </h4>
                <svg
                  onClick={() => setOpenPopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>
              <div className="LoginVendor_form_cont mt-10">
                <label htmlFor="">Email</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  required
                />
              </div>
              <button
                type="submit"
                className="LoginVendor_form_submit red_new_btn_black"
                // onClick={resetPasswordHandler}
              >
                Send{" "}
              </button>
            </form>
          </div>
        </div>
      )}

      {/* old form */}
      {/* <section style={{ background: "#fff" }}>
                <div>
                    <div className="customer_form" id="vendor_login">
                        <div
                            onClick={handleClosePopup}
                            className="closeBtn" >
                        </div>
                        <img src={loginImg} alt="Logo" className="login-img" />
                        <div>
                            <div className="login_filter_btn">
                                <button
                                    className="filter_form_btn"
                                >
                                    Vendor
                                </button>
                            </div>

                            <div
                                className="login-form-customer"
                            >
                                {isCreatingAccount ? (
                                    <form>
                                        <div>
                                            <label htmlFor="name">Your Name</label>
                                            <input className="w-full"
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-flex">
                                            <div>
                                                <label htmlFor="phone">Phone</label>
                                                <input className="w-full"
                                                    type="text"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="email">Email Address</label>
                                                <input className="w-full"
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="input-flex">
                                            <div>
                                                <label htmlFor="password">Password</label>
                                                <input className="w-full"
                                                    type="password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <input className="w-full"
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="button">
                                            <button type="button" className="common_btn m_0_auto">
                                                {resetForm ? "CONTINUE" : "CONTINUE"}
                                            </button>
                                        </div>
                                        <div className="text-center mt-10">
                                            <small>
                                                Already have an account?{" "}
                                                <button
                                                    className=""
                                                    style={{ color: "#016937" }}
                                                    onClick={() => setIsCreatingAccount(false)}
                                                >
                                                    Log in
                                                </button>
                                            </small>
                                        </div>
                                    </form>
                                ) : resetForm ? (
                                    <form>
                                        <div>
                                            <label htmlFor="email">Email Address</label>
                                            <input className="w-full" type="email" placeholder="Enter Email ID" />
                                        </div>
                                        <div className="button">
                                            <button className="common_btn m_0_auto">CONTINUE</button>
                                        </div>
                                        <div className="text-center mt-10">
                                            <small>
                                                Already have an account?{" "}
                                                <button
                                                    className=""
                                                    onClick={showCreateAccount}
                                                    style={{ color: "#016937" }}
                                                >
                                                    Create account
                                                </button>
                                            </small>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={handleVendorLogin}>
                                        <div>
                                            <label htmlFor="email">Email Address</label>
                                            <input
                                                className="w-full"
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="Enter Email ID"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password">Password</label>
                                            <input
                                                className="w-full"
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                placeholder="Enter Password"
                                                required
                                            />
                                        </div>
                                        <div className="button">
                                            <button type="submit" className="common_btn m_0_auto">
                                                CONTINUE
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {openPopup && (
        <div className="popup_backdrop">
          <div className="popup_section">
            <form onSubmit={resetPasswordHandler}>
              {/* <form onSubmit={}> */}
              <div className="flex justify_content_between align_items_center">
                <h4 className="text-left">
                  <b>Reset Your Password</b>
                </h4>
                <svg
                  onClick={() => setOpenPopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>
              <div className="LoginVendor_form_cont mt-10">
                <label htmlFor="">Email</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  required
                />
              </div>
              <button
                type="submit"
                className="LoginVendor_form_submit red_new_btn_black"
                // onClick={resetPasswordHandler}
              >
                Send{" "}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginVendors;
