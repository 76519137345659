import React, { useEffect, useState, useRef } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import InventoryUpdate from "../../CustomComponents/InventoryUpdate/InventoryUpdate";
import { currentDateSingle, textDateFormatter } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import Flatpickr from "react-flatpickr";
import Loader from "../../CustomComponents/Loader/Loader";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import { showSuccessToast } from "../../assets/toastUtils";

const Inventory = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [loader, setLoader] = useState(false);

  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [startDate, setStartDate] = useState(currentDateSingle);
  // unblocked
  const [unblockedPopup, setUnblockedPopup] = useState(false);
  const handleUnblocked = () => {
    setUnblockedPopup(true);
  };
  const vendorToken = localStorage.getItem("token");

  const handleUpdateDate = (id) => {
    setSelectedId(id);
    setUpdateDate(true);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { id } = useParams();

  // const InventoryDetails = (id, startDate) => {
  //   setLoader(true);

  //   // if (!startDate) {
  //   //   toast.error("Start date is not defined.");
  //   //   return;
  //   // }
  //   const myHeaders = new Headers();
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-inventory/${id}/list?filterDate=${startDate}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log(result);

  //       if (result.success) {
  //         setInventoryData(result?.inventory);
  //         setLoader(false);
  //       } else {
  //         setLoader(false);
  //       }
  //       // console.log(result);
  //     })
  //     .catch((error) => setLoader(false));
  // };

  const InventoryDetails = async (id, startDate) => {
    setLoader(true);
    try {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${vendorToken}`
      );
      headers.append(
        "Cookie", process.env.REACT_APP_COOKIE
      );

      const formData = new FormData();
      formData.append("filterDate", startDate);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/inventory/fetch/${id}`,
        requestOptions
      );

      const result = await response.json();
      if (result.success) {
        setInventoryData(result?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching room rates:", error);
    }
  };

  useEffect(() => {
    if (id && startDate) {
      InventoryDetails(id, startDate);
    }
  }, [id, startDate]);

  // const handleDateChange = (e) => {
  //  setDate
  // };

  // const handleDateChange = (date) => {
  //   if (date.length > 0) {
  //     // Convert the date to the local time zone and format it
  //     const selectedDate = date[0];
  //     const zonedDate = utcToZonedTime(selectedDate, 'America/New_York'); // Replace with your time zone
  //     const formattedDate = format(zonedDate, 'yyyy-MM-dd'); // Format date as YYYY-MM-DD
  //     setStartDate(formattedDate);
  //   }
  // };

  const handleOnUpdate = () => {
    setUpdatePopup(true);
  };

  const handleClosePopup = () => {
    setUpdatePopup(false);
  };

  const handleCloseUpdateDate = () => {
    setUpdateDate(false);
  };

  const fetchUpdateDate = async () => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${vendorToken}`
    );
    headers.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );


    const body = JSON.stringify({
      type: "block",
      "from-date": fromDate,
      "to-date": toDate,
    });

    const requestOptions = {
      method: "POST",
      headers,
      body,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/inventory/block/${id}/${selectedId}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setUpdateDate(false);
        showSuccessToast(result.message);
        InventoryDetails(id, startDate);
        setFromDate("");
        setToDate("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUnblockedDate = async () => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${vendorToken}`
    );
    headers.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );

    const body = JSON.stringify({
      type: "unblock",
      "from-date": fromDate,
      "to-date": toDate,
    });

    const requestOptions = {
      method: "POST",
      headers,
      body,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/inventory/block/${id}/${selectedId}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setUpdateDate(false);
        setUnblockedPopup(false);
        showSuccessToast(result.message);
        InventoryDetails(id, startDate);
        setFromDate("");
        setToDate("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const dateInputRef = useRef(null);

  const handleFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />

        <section className="w-full table-data">
          <div className="flex justify_content_between align_items_center">
            <h4>Inventory</h4>
            <div className="flex flex_gap_10">
              {/* <Flatpickr
                className="dateBtn_inventory m_0 fit-width"
                value={startDate}
                onChange={handleDateChange}
                options={{ dateFormat: "Y-m-d" }} // Set the date format
              /> */}

              <input
                type="date"
                className="dateBtn_inventory m_0 fit-width"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                ref={dateInputRef}
              ></input>
              {/* <button className="secondary_btn fit-width">
                {textDateFormatter(currentDateSingle)}
              </button> */}
              <button
                className="primary_btn fit-width"
                onClick={handleOnUpdate}
              >
                BULK UPDATE
              </button>
            </div>
          </div>
          {loader ? (
            <Loader size="4em" color="#016937" />
          ) : (
            <div className="overflow_x_scroll_y_hidden">
              {inventoryData?.length > 0 ? (<><table className="inventory_table">
                <tr>
                  <th>Room Category</th>
                  {inventoryData?.[0]?.inventory?.map((data, index) => {
                    // return <th key={index}>{textDateFormatter(data?.date)}</th>;
                    return <th key={index}>{data?.date}</th>;
                  })}
                  {/* {item?.inventory?.map((data) => {
                      return <th>{textDateFormatter(data?.date)}</th>;
                    })} */}
                </tr>
                {inventoryData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <div className="flex ">
                            <div>{item?.name} </div>
                            <div>
                              <svg
                                onClick={() => handleUpdateDate(item?.roomId)}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                                width="20px"
                                height="20px"
                              >
                                <path d="M 16 3 C 12.15625 3 9 6.15625 9 10 L 9 13 L 6 13 L 6 29 L 26 29 L 26 13 L 23 13 L 23 10 C 23 6.15625 19.84375 3 16 3 Z M 16 5 C 18.753906 5 21 7.246094 21 10 L 21 13 L 11 13 L 11 10 C 11 7.246094 13.246094 5 16 5 Z M 8 15 L 24 15 L 24 27 L 8 27 Z" />
                              </svg>
                            </div>
                          </div>
                        </td>
                        {item?.inventory?.map((data, index) => {
                          return (
                            <td key={index}>
                              <span
                                onClick={() =>
                                  data.blocked === true
                                    ? [
                                      handleUnblocked(),
                                      setToDate(data.date),
                                      setFromDate(data.date),
                                      setSelectedId(item?.roomId),
                                    ]
                                    : null
                                }
                                style={
                                  data.blocked === true
                                    ? {
                                      backgroundColor: "red",
                                      cursor: "pointer",
                                    }
                                    : {}
                                }
                              >
                                {data?.total_rooms}

                              </span>
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </table></>) : (<><NoDataFound /></>)}
            </div>
          )}
        </section>
      </div>

      {updatePopup && (
        <InventoryUpdate
          onClose={handleClosePopup}
          inventoryData={inventoryData}
          startDate={startDate}
          InventoryDetails={InventoryDetails}
        />
      )}
      {updateDate && (
        <div className="popup_backdrop">
          <div className="popup_section">
            <div className="agent">
              <h4>Block Inventory</h4>
              <svg
                onClick={handleCloseUpdateDate}
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
              </svg>
            </div>
            <div>
              <div>
                <label htmlFor="">Start Date</label>
                <input
                  className="w-full"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="mt-10">
                  End Date
                </label>
                <input
                  className="w-full"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <button
                className="primary_btn mt-10"
                type="button"
                onClick={() => fetchUpdateDate(selectedId)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {unblockedPopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div>
              <div className="flex justify_content_between">
                <h4>Are you sure to unblock the inventory.</h4>
                <svg
                  onClick={() => setUnblockedPopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <button
                className="primary_btn mt-10"
                type="button"
                onClick={() => fetchUnblockedDate(selectedId)}
              >
                Unblocked
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Inventory;

// const fetchUpdateDate = () => {
//   const myHeaders = new Headers();
//   myHeaders.append("key", process.env.REACT_APP_API_KEY);
//   myHeaders.append("Content-Type", "application/json");

//   const raw = JSON.stringify({
//     room_categories: [selectedId],
//     accommodation_id: id,
//     from_date: fromDate,
//     to_date: toDate,
//   });

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   fetch(
//     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-inventory/block`,
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((result) => {
//       if (result.success) {
//         setUpdateDate(false);
//         toast.success(result.message);
//         // console.log(result, "result");
//         InventoryDetails(id, startDate);
//         setFromDate("");
//         setToDate("");
//       }
//     })
//     .catch((error) => console.error(error));
// };
// const fetchUnblockedDate = () => {
//   const myHeaders = new Headers();
//   myHeaders.append("key", process.env.REACT_APP_API_KEY);
//   myHeaders.append("Content-Type", "application/json");

//   const raw = JSON.stringify({
//     room_categories: [selectedId],
//     accommodation_id: id,
//     from_date: fromDate,
//     to_date: toDate,
//   });

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   fetch(
//     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-inventory/unblock`,
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((result) => {
//       if (result.success) {
//         setUnblockedPopup(false);
//         toast.success(result.message);
//         InventoryDetails(id, startDate);
//         setFromDate("");
//         setToDate("");
//       }
//     })
//     .catch((error) => console.error(error));
// };