import React, { useEffect, useState } from "react";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";
import TabBar from "../../../CustomComponents/Property/TabBar/TabBar";
import "../PropertyDetails/PropertyDetails.css";
import BasicInformation from "../../../CustomComponents/Property/BasicInformation/BasicInformation";
import Locations from "../../../CustomComponents/Property/Locations/Locations";
import AllAmenities from "../../../CustomComponents/Property/AllAmenities/AllAmenities";
import Rooms from "../../../CustomComponents/Property/Rooms/Rooms";
import PhotosVideos from "../../../CustomComponents/Property/PhotosVideos/PhotosVideos";
import Policies from "../../../CustomComponents/Property/Policies/Policies";
import MyProperties from "../../../CustomComponents/Property/MyProperties/MyProperties";
import Header from "../../../CustomComponents/Header/Header";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";

function AddPropertyVendor({ formData }) {
  const [propertyDetails, setPropertyDetails] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const [vendorDetails, setVendorDetails] = useState([]);

  const vendorToken = localStorage.getItem("token");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const dataFromParent = (data) => {
    setActiveTab(data);
  };
  const navigate = useNavigate();

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const handleChangeAddressData = (data) => {
    // console.log(data, "setAddressData");
    setAddressData(data);
  };

  // const [propertyData, setPropertyData] = useState({
  //   property_name: "",
  //   property_type: "",
  //   mobile_number: "",
  //   email: "",
  //   start_rating: "",
  //   property_built: "",
  //   built_year: "",
  //   no_of_rooms: "",
  //   resturant: "",
  //   no_of_floors: "",
  //   channel_manager: false,
  //   currency: "",
  //   vcc_currency: "",
  //   time_zone: "",
  //   check_in: "",
  //   check_out: "",
  //   description: "",
  //   display_name: "",
  //   room_type: "",
  //   total_rooms: "",
  //   room_view: "",
  //   bed_type: "",
  //   room_size: "",
  //   room_size_units: "",
  //   room_description: "",
  //   policy_description: "",
  //   terms_description: "",
  //   base_adult: "",
  //   min_los: "",
  //   max_los: "",
  //   max_adult: "",
  //   max_child: "",
  //   max_occupancy: "",
  //   price_per_night: {
  //     ep: "",
  //     map: "",
  //     cp: "",
  //     ap: "",
  //   },
  //   extra_adult_cost: {
  //     ep: "",
  //     map: "",
  //     cp: "",
  //     ap: "",
  //   },
  //   child_with_bed_cost: {
  //     ep: "",
  //     map: "",
  //     cp: "",
  //     ap: "",
  //   },
  //   child_without_bed_cost: {
  //     ep: "",
  //     map: "",
  //     cp: "",
  //     ap: "",
  //   },
  // });

  const [propertyData, setPropertyData] = useState({
    name: "",
    type: "",
    supplier_id: "",
    email: "",
    phone: "",
    // city: addressData?.city,
    featured: "",
    online: "",
    // state: addressData?.state,
    // country: addressData?.country,
    // address: addressData?.address,
    rating: "",
    category: "",
    content: "",
    status: "",
    // latitude_information: addressData?.latitude_information,
    // longitude_information: addressData?.longitude_information,
    property_amenity_amenities: "",
    check_in_time_information: "",
    check_out_time_information: "",
  });

  // console.log(formData, "address")


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({ ...propertyData, [name]: value });
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setActiveTab(activeTab + 1);
  };
  // console.log(addressData);

  const [vendorId, setVendorId] = useState({});

  useEffect(() => {
    const vendor_id = localStorage.getItem("userDetails");
    if (vendor_id) {
      const userDetails = JSON.parse(vendor_id);
      // console.log(userDetails);
      setVendorId(userDetails.id);
    }
  });
  // console.log(vendorId);

  const fetchPropertyDetails = (e) => {
    e.preventDefault();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }
    // console.log("form submit");

    // Check if propertyData and addressData are defined
    if (!propertyData || !addressData) {
      console.error("propertyData or addressData is undefined");
      return;
    }

    // Log the actual data being used
    // console.log("propertyData:", propertyData);
    // console.log("addressData:", addressData);

    // Create the payload
    const raw = JSON.stringify({
      name: propertyData?.property_name || "N/A",
      email: propertyData?.email || "N/A",
      phone: propertyData?.mobile_number || "N/A",
      city: addressData?.city || "N/A",
      state: addressData?.state || "N/A",
      country: addressData?.country || "N/A",
      details: propertyData?.description || "N/A",
      address: addressData?.address || "N/A",
      check_in: "2024-08-10 14:00:00",
      check_out: "2024-08-15 11:00:00",
      status: "enable",
      show_online: 1,
      type: "hotel",
      category: "luxury",
      star_rating: propertyData?.start_rating,
      selected_amenities: "1,2,3",
    });

    // console.log("Raw payload:", raw);

    const requestOptions = {
      method: "POST",
      body: raw,
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/accommodation-create/${vendorId}`,
      requestOptions
    )
      .then((response) => {
        return response.text().then((text) => {
          if (!response.ok) {
            throw new Error("Network response was not ok: " + text);
          }
          return text;
        });
      })
      .then((text) => {
        try {
          const data = JSON.parse(text);
          if (data.success) {
            setPropertyDetails(data);
            showSuccessToast(data.success.message || "Property Added");
            navigate("/vendor/property/list", {
              state: { propertyData, addressData },
            });
          } else {
            // console.log("Error fetching data", data);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error, text);
        }
      })
      .catch((error) => {
        console.error("Error fetching details:", error);
      });
  };

  const getUserProfile = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/supplier/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${vendorToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setVendorDetails(data?.data)
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  const createAccommodation = async (e) => {
    e.preventDefault();

    if (!propertyData || !addressData) {
      console.error("propertyData or addressData is undefined");
      return;
    }

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${vendorToken}`
    );
    headers.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );

    const formData = new FormData();
    formData.append("name", propertyData?.name);
    formData.append("type", propertyData?.type);
    formData.append("supplier_id", vendorDetails?.id);
    formData.append("email", propertyData?.email);
    formData.append("phone", propertyData?.phone);
    formData.append("city", addressData?.city);
    formData.append("featured", propertyData?.featured);
    formData.append("online", propertyData?.online);
    formData.append("state", addressData?.state);
    formData.append("country", addressData?.country);
    formData.append("address", addressData?.address);
    formData.append("rating", propertyData?.rating);
    formData.append("category", propertyData?.category);
    formData.append("content", propertyData?.content);
    formData.append("status", propertyData?.status);
    formData.append("latitude_information", addressData?.latitude);
    formData.append("longitude_information", addressData?.longitude);
    formData.append(
      "property_amenity_amenities", propertyData.property_amenity_amenities
    );

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/crud/create`,
        requestOptions
      );

      const result = await response.json();
      if (result.success) {
        showSuccessToast(result?.message);
        setPropertyData({
          name: "",
          type: "",
          supplier_id: "",
          email: "",
          phone: "",
          featured: "",
          online: "",
          rating: "",
          category: "",
          content: "",
          status: "",
          property_amenity_amenities: "",
          check_in_time_information: "",
          check_out_time_information: "",
        });
        setAddressData({
          country: "",
          state: "",
          city: "",
          address: "",
          latitude: "",
          longitude: "",
          placeId: "",
        })

        navigate("/vendor/property/list");
      } else {
        showErrorToast(result.message)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const validate = () => {
    const errors = {};
    if (!formData?.property_name.trim()) {
      errors.property_name = "required";
    }
    if (!formData?.start_rating.trim()) {
      errors.start_rating = "required";
    }
    if (!formData?.check_in.trim()) {
      errors.check_in = "is required";
    }
    if (!formData?.check_out.trim()) {
      errors.check_out = "required";
    }
    if (!formData?.description.trim()) {
      errors.description = "required";
    }
    if (!formData?.mobile_number.trim()) {
      errors.mobile_number = "is required";
    }
    if (!formData?.email.trim()) {
      errors.email = "required";
    }
    if (!formData?.mobile_number.trim()) {
      errors.mobile_number = "is required";
    }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <VendorHeader />
      <div className="sidebar_main_div">
        {/* <SideBar allPropertiesPage={true} /> */}
        {/* <MyProperties/> */}
        <div className="tabBar w-full">
          {/* <TabBar dataFromParent={dataFromParent} activeTab={activeTab} /> */}
          {activeTab === 1 && (
            <div>
              <BasicInformation
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                propertyData={propertyData}
                handleInputChange={handleInputChange}
                handleNextClick={handleNextClick}
                addressData={addressData}
                handleChangeAddressData={handleChangeAddressData}
                onSubmitForm={createAccommodation}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            </div>
          )}
          {/* {activeTab === 2 && <div><Locations handleNextClick={handleNextClick} onDataPass={handleAddressDataFromChild} onSubmitForm={fetchPropertyDetails} /></div>} */}
          {/* {activeTab === 3 && <div><AllAmenities showTitle={true} onSubmitForm={fetchPropertyDetails} /></div>} */}
          {activeTab === 4 && (
            <div>
              <Rooms
                activeTab={activeTab}
                propertyData={propertyData}
                handleInputChange={handleInputChange}
                handleNextClick={handleNextClick}
              />
            </div>
          )}
          {/* {activeTab === 5 && <div><PhotosVideos /></div>} */}
          {/* {activeTab === 6 && <div><Policies activeTab={activeTab} propertyData={propertyData} handleInputChange={handleInputChange} handleNextClick={handleNextClick} /></div>} */}
        </div>
      </div>
    </>
  );
}

export default AddPropertyVendor;
