import React, { useState, useEffect } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import ListingBanner from "../../CustomComponents/ListingBanner/ListingBanner";
import FilterHoliday from "../../CustomComponents/FilterSection/FilterHoliday";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import PackageSearchForm from "../../CustomComponents/PackageSearchForm/PackageSearchForm";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import AllPackagesSearchForm from "../../CustomComponents/AllPackagesSearchForm/AllPackagesSearchForm";
import CustomListingCardActivities from "../../CustomComponents/CustomListingCardActivities/CustomListingCardActivities";
import ListingActivitiesSearchForm from "../../CustomComponents/ListingActivitiesSearchForm/ListingActivitiesSearchForm";
import FilterActivities from "../../CustomComponents/FilterSection/FilterActivities";
import imgNotFound from "../../assets/images/single-image.png";
import { scrollToTop } from "../../utils/utils";
import SendEnquiryPopup from "../../CustomComponents/SendEnquiryPopup/SendEnquiryPopup";
import NewSendEnquiryPopup from "../../CustomComponents/NewSendEnquiryPopup/NewSendEnquiryPopup";

function ListingActivities() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [showSendEnquiryPopup, setShowSendEnquiryPopup] = useState(false);
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const [filterLoader, setFilterLoader] = useState(false);
  const location = useLocation();
  const [activitiesData, setActivitiesData] = useState([]);
  const [sortedFilter, setSortedFilter] = useState({
    show: "Popular",
    value: "polpular",
  });

  useEffect(() => {
    // console.log(sortedFilter?.value);
    let dummyData = [...activitiesData];
    if (sortedFilter?.value === "l2hByp") {
      dummyData.sort((a, b) => a.activity_price - b.activity_price);
      setActivitiesData(dummyData);
    }

    if (sortedFilter?.value === "h2lByp") {
      dummyData.sort((a, b) => b.activity_price - a.activity_price);
      setActivitiesData(dummyData);
    }
  }, [sortedFilter?.value]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      date: params.get("date"),
      year: params.get("year"),
      month: params.get("month"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchPackageData(data);
  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };
  // console.log(packageData, "packageData");

  const startIndex = (currentPage - 1) * itemsPerPage;

  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    activitiesData &&
    activitiesData &&
    activitiesData.length > 0 &&
    activitiesData.slice(startIndex, endIndex);

  const fetchPackageData = async (data, filters) => {
    setFilterLoader(true);

    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "key",
      process.env.REACT_APP_BASE_API_KEY
    );
    const formdata = new FormData();
    formdata.append("location", data.location);
    // formdata.append("limit", 8);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL
        }/api/v1/activities/fetch?price_range=${filters?.budget ? filters?.budget : ""
        }`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);
      setActivitiesData(result.data);
    } catch (error) {
      setActivitiesData([]);
      setLoader(false);
      console.error(error);
    } finally {
      setFilterLoader(false);
      scrollToTop();
    }
  };

  const sendDataToParent = (data) => {
    // console.log(data);
    fetchPackageData(bookingData, data);
  };

  return (
    <>
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <Header />
      <ListingActivitiesSearchForm />
      {/* <ListingBanner heading={`${bookingData.location}`} /> */}
      <div className="container" id="hotelsData">
        <main>
          {loader ? (
            <div className="filters">
              <div className="agent border-bottom-one">
                <div>
                  <Skeleton width={100} />
                </div>
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox ">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
            </div>
          ) : (
            <FilterActivities
              sendDataToParent={sendDataToParent}
              showDestinations={true}
            />
          )}

          <div
            className="listing-right-container"
            style={{ paddingTop: "0px" }}
          >
            <div className="flex align_items_center flex_wrap justify_content_between">
              <h3 style={{ textTransform: "capitalize" }} className="text-left">
                {bookingData.location ? (
                  <span>{`Showing Activities in ${bookingData.location}`}</span>
                ) : (
                  <span> Showing All Activities</span>
                )}

                {/* {bookingData.location} */}
              </h3>
              <div className="sorted_div">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_5040_6776"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="14"
                    height="14"
                  >
                    <rect width="14" height="14" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_5040_6776)">
                    <path
                      d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>
                Sort by:{" "}
                <span className="cursor_pointer">
                  <b>{sortedFilter?.show}</b>
                </span>
                <div className="sorted_selected">
                  <div
                    onClick={() =>
                      setSortedFilter({
                        value: "l2hByp",
                        show: "Price - Low to High",
                      })
                    }
                    className="cursor_pointer p-5"
                  >
                    {" "}
                    <span>Price - Low to High</span>
                  </div>
                  <div
                    className="cursor_pointer p-5"
                    onClick={() =>
                      setSortedFilter({
                        value: "h2lByp",
                        show: "Price - high to Low",
                      })
                    }
                  >
                    {" "}
                    <span>Price - High to Low</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards-flex">
              {loader || filterLoader ? (
                Array.from({ length: 9 }).map((items, index) => (
                  <div
                    key={index}
                    className="card-listing"
                    style={{
                      background: "none",
                      borderRadius: "8px",
                      marginBottom: "0px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      padding: "0px",
                    }}
                  >
                    <div>
                      <Skeleton
                        style={{ borderRadius: "8px 8px 0px 0px" }}
                        height={150}
                      />
                    </div>
                    <div className="card_content" style={{ marginTop: "0px" }}>
                      <h6>
                        <Skeleton width="60%" />
                      </h6>
                      <h6>
                        <Skeleton width="40%" />
                      </h6>
                      <h6>
                        <Skeleton width="20%" />
                      </h6>
                      <h6>
                        <Skeleton width="24%" />
                      </h6>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {activitiesData?.length === 0 ? (
                    <NoDataFound />
                  ) : (
                    <>
                      {displayedData?.map((data, index) => (
                        <CustomListingCardActivities
                          key={index}
                          imgSrc={
                            data.featuredImage
                              ? data.featuredImage
                              : imgNotFound
                          }
                          title={data.activityName}
                          packageHub={data.packageHub}
                          duration={1}
                          nightDuration={data.totalNights}
                          price={data.startingPrice}
                          bookingLink={data.bookingLink}
                          detailsLink={data.detailsLink}
                          packagehandle={data.packagehandle}
                          canBookable={data?.canBookable}
                          description={data?.content}
                          id={data?.activityId}
                          slug={data?.activityHandle}
                          setShowSendEnquiryPopup={setShowSendEnquiryPopup}
                        />
                      ))}
                      {/* {showSendEnquiryPopup && (
                        <SendEnquiryPopup
                          package_id={""}
                          setshowRatePopup={setShowSendEnquiryPopup}
                          typeDetail="activity"
                        />
                      )} */}
                      {showSendEnquiryPopup && (
                        <NewSendEnquiryPopup
                          package_id={""}
                          setshowRatePopup={setShowSendEnquiryPopup}
                          typeDetail="activity"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="communication_pagination">
              <CustomPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={activitiesData.length}
                onPageChange={handlePageChange}
                clearSelection={clearSelection}
              />
            </div>
          </div>
        </main>
      </div>
      {/* <DestinationLinks /> */}
      <Footer />
    </>
  );
}

export default ListingActivities;
