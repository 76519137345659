import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";

const ImageList = ({ imageData, fetchMediaRefetch, multiple = false,deleteUrl }) => {
    // Convert the data object into an array of values
    const images = Object.values(imageData);
    const vendorToken = localStorage.getItem("token");

    //   const [
    //     deleteData,
    //     { isLoading, isSuccess: isSuccessDelete, isError, error },
    //   ] = useDeleteMediaMutation();


    const deleteData = async (mediaId) => {
        console.log("mediaId",mediaId)
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${vendorToken}`);
        headers.append("Cookie", process.env.REACT_APP_COOKIE);

        const body = JSON.stringify({
            mediaId,
        });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/${deleteUrl}`,
                {
                    method: "DELETE",
                    headers,
                    body,
                    redirect: "follow",
                }
            );
            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message)
            } else {
                showErrorToast(result.message)
            }

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const deleteMediaHandler = async (e, mediaId) => {
        e.preventDefault();
        try {
            await deleteData(mediaId);
        } catch (err) {
            console.error("Delete failed: ", err);
        }
    };

    //   useEffect(() => {
    //     if (isSuccessDelete) {
    //       fetchMediaRefetch();
    //       toast.success("Deleted successfully");
    //     } else if (isError) {
    //     }
    //   }, [isSuccessDelete, isError, error]);

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                maxHeight: "300px",
                overflowY: "auto",
            }}
        >
            {multiple ? (
                <>
                    {" "}
                    {images.length > 0 ? (
                        images.map((image) => (
                            <div
                                key={image.uuid}
                                style={{ textAlign: "center", position: "relative" }}
                            >
                                <img
                                    src={image.original_url}
                                    alt={image.name}
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "8px",
                                    }}
                                />

                                <button
                                    style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        color: "red",
                                        border: "none",
                                        cursor: "pointer",
                                    }}
                                    type="button"
                                    onClick={(e) => deleteMediaHandler(e, image.uuid)}
                                >
                                    X
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No Images Added</p>
                    )}
                </>
            ) : (
                <div className="agentLogoUpload"
                    key={imageData}
                    style={{ textAlign: "center", position: "relative" }}
                >
                    <img
                        src={imageData}
                        alt={"image.name"}
                        style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                        }}
                    />

                    {/* <button
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "red",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => deleteMediaHandler("image.uuid")}
        >
          X
        </button> */}
                </div>
            )}
        </div>
    );
};

export default ImageList;