import React, { useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";

const ManageRatesPlans = () => {
    const [ratePlan, setRatePlan] = useState([]);
    const [deletePopup, setDeletePopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const { id: propertyId } = useParams();
    const [selectedId, setSelectedId] = useState();
    const [roomDetails, setRoomDetails] = useState({});
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const vendorToken = localStorage.getItem("token");
    const { id, roomId } = useParams();
    const [editPopup, setEditPopup] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const validate = () => {
        const errors = {};
        if (!addRates?.name.trim()) {
            errors.name = "required";
        }
        if (!addRates?.type.trim()) {
            errors.type = "required";
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const [addRates, setAddRates] = useState({
        accommodation_id: "",
        room_id: "",
        name: "",
        type: "",
        status: "",
        tax_included: false,
    });

    const [editRates, setEditRates] = useState({
        accommodation_id: "",
        room_id: "",
        name: "",
        type: "",
        status: "",
        tax_included: false,
    });

    const handleInputChange = (e, isEdit = false) => {
        const { name, value } = e.target;
        if (isEdit) {
            setEditRates((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setAddRates((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const openAddPopup = (id) => {
        setSelectedId(id);
        setAddPopup(true);
    };

    const closeAddPopup = () => {
        setAddPopup(false);
    };

    const openDeletePopup = (id) => {
        setSelectedId(id);
        setDeletePopup(true);
    };

    const closeDeletePopup = () => {
        setDeletePopup(false);
    };

    const createPlan = async (e) => {
        e.preventDefault();
        const valid = validate();
        setTimeout(() => {
            setFormErrors({});
        }, 1000);
        if (!valid) {
            showErrorToast("Please fill the required details");
            return;
        }

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append(
            "Authorization",
            `Bearer ${vendorToken}`
        );
        headers.append(
            "Cookie", process.env.REACT_APP_COOKIE
        );

        const formData = new FormData();
        formData.append("accommodation_id", id);
        formData.append("room_id", roomId);
        formData.append("name", addRates.name);
        formData.append("type", addRates.type);
        formData.append("status", addRates.status);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/rate-plans/create`,
                {
                    method: "POST",
                    headers,
                    body: formData,
                    redirect: "follow",
                }
            );

            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message);
                setAddPopup(false);
                fetchRatePlan(id, roomId)
            } else {
                showErrorToast(result.message)
            }
            console.log(result);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const fetchRatePlan = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append(
            "Authorization",
            `Bearer ${vendorToken}`
        );
        myHeaders.append(
            "Cookie", process.env.REACT_APP_COOKIE
        );
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/rate-plans/${id}/list/${roomId}`,
                requestOptions
            );
            const result = await response.json();
            setRatePlan(result?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (id, roomId) {
            fetchRatePlan(propertyId);
        }
    }, [id, roomId]);

    const deleteRatePlan = async () => {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${vendorToken}`
        };

        const body = JSON.stringify({
            ids: [selectedId]
        });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/rate-plans/delete`,
                {
                    method: "DELETE",
                    headers,
                    body,
                    redirect: "follow"
                }
            );
            const result = await response.json();
            console.log(result);
            setDeletePopup(false);
            fetchRatePlan(id, roomId);
            showSuccessToast(result.message);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // Get Room api
    const getRooms = (id) => {
        const requestOptions = {
            // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
            headers: {
                "Content-Type": "application/json",
                key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            method: "GET",
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rooms/${id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log("result", result);

                if (result.success) {
                    setRoomDetails(result?.rooms);
                } else {
                    setRoomDetails([]);
                }
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        if (propertyId) {
            getRooms(propertyId);
        }
    }, [propertyId]);

    const [singleRate, setSingleRate] = useState({});

    const fetchSingleRate = async (planId) => {
        try {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append(
                "Authorization",
                `Bearer ${vendorToken}`
            );
            headers.append(
                "Cookie", process.env.REACT_APP_COOKIE
            );

            const requestOptions = {
                method: "GET",
                headers: headers,
                redirect: "follow",
            };

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/rate-plans/edit/${planId}`,
                requestOptions
            );
            const result = await response.json();
            if (result.success) {
                setSingleRate(result.data);
                setEditRates({
                    name: result.data.name,
                    type: result.data.type,
                    status: result.data.status ? "1" : "0",
                });
                setEditPopup(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleEditDetail = (id, item) => {
        // console.log(id, item);
        setSelectedId(id);
        setEditItem(item); // Set the item to be edited
        setEditPopup(true); // Open the edit popup
    };

    const openEditPopup = (item) => {
        setSelectedId(item.id);
        fetchSingleRate(item.id);
    };

    const closeEditPopup = () => {
        setEditPopup(false);
        setEditItem(null); // Clear the item when closing the popup
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append(
                "Authorization",
                `Bearer ${vendorToken}`
            );
            headers.append(
                "Cookie", process.env.REACT_APP_COOKIE
            );

            const formData = new FormData();
            formData.append("name", editRates?.name);
            formData.append("type", editRates?.type);
            formData.append("status", editRates?.status);

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/rate-plans/update/${selectedId}`,
                {
                    method: "POST",
                    headers,
                    body: formData,
                    redirect: "follow",
                }
            );

            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message);
                closeEditPopup();
                fetchRatePlan(id, roomId);
            } else {
                showErrorToast(result.message)
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="w-full table-data">
            <div className="flex justify_content_between align_items_center">
                <h4>Room Rate</h4>
                <div className="flex flex_gap_10">
                    <button
                        onClick={() => openAddPopup()}
                        className="primary_btn fit-width"
                    >
                        Add Rate Plans
                    </button>
                </div>
            </div>
            {loader ? (
                <Loader size="4em" color="#016937" />
            ) : (
                <div className="overflow_x_scroll_y_hidden">
                    {ratePlan?.length > 0 ? (
                        <>
                            {" "}
                            <table className="inventory_table">
                                <tr style={{ borderBottom: "1px solid #cccccc96" }}>
                                    <th> NAME</th>
                                    <th> PLAN</th>
                                    <th>STATUS</th>
                                </tr>
                                {ratePlan?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td> {item?.name}</td>
                                            <td>{item?.type}</td>
                                            <td> {item?.status === true ? 'Active' : 'Inactive'}</td>
                                            <td>
                                                <div className="flex justify_content_end flex_gap_10">
                                                    <span onClick={() => openDeletePopup(item?.id)}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 32 32"
                                                            width="20px"
                                                            height="20px"
                                                        >
                                                            <path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z" />
                                                        </svg>
                                                    </span>

                                                    <span
                                                        onClick={() => {
                                                            fetchSingleRate(item?.id);
                                                            handleEditDetail(item?.id, item);
                                                        }}
                                                    >
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <mask
                                                                id="mask0_2797_7470"
                                                                style={{ maskType: "alpha" }}
                                                                maskUnits="userSpaceOnUse"
                                                                x="0"
                                                                y="0"
                                                                width="20"
                                                                height="20"
                                                            >
                                                                <rect width="20" height="20" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_2797_7470)">
                                                                <path
                                                                    d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                                                                    fill="#333333"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </>
                    ) : (
                        <>
                            <NoDataFound />
                        </>
                    )}
                </div>
            )}
            {addPopup && (
                <div className="popup_backdrop">
                    <div className="popup_section p_20">
                        <div>
                            <div className="flex justify_content_between">
                                <h4>Add Rate Plans</h4>
                                <svg
                                    onClick={() => closeAddPopup(false)}
                                    width="14"
                                    height="13"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                    <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                </svg>
                            </div>

                            <form onSubmit={createPlan} action="">
                                <div>
                                    <div className="mt-10">
                                        <label htmlFor="">Name</label>
                                        <input
                                            className="w-100"
                                            name="name"
                                            value={addRates?.name}
                                            onChange={handleInputChange}
                                            type="text"
                                            style={
                                                formErrors.name && {
                                                    backgroundColor: "#ff000040",
                                                    border: "1px solid red",
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <label htmlFor="">Status</label>
                                        <select
                                            className="py-5 w-100 border rounded mt-5"
                                            name="status"
                                            value={addRates?.status}
                                            onChange={handleInputChange}
                                            style={
                                                formErrors.status && {
                                                    backgroundColor: "#ff000040",
                                                    border: "1px solid red",
                                                }
                                            }
                                        >
                                            <option value="">Select Option</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                            {/* {roomDetails.map((room, index) => (
                                                <option key={index} value={room.id}>
                                                    {room.name}
                                                </option>
                                            ))} */}
                                        </select>
                                    </div>
                                    <div className="mt-5">
                                        <label htmlFor="">Type</label>
                                        <select
                                            className="py-5 w-100 border rounded mt-5"
                                            name="type"
                                            value={addRates?.type}
                                            style={
                                                formErrors.type && {
                                                    backgroundColor: "#ff000040",
                                                    border: "1px solid red",
                                                }
                                            }
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="ep">EP</option>
                                            <option value="cp">CP</option>
                                            <option value="map">MAP</option>
                                            <option value="ap">AP</option>
                                        </select>
                                    </div>
                                    {/* <div className="mt-5">
                                        <label htmlFor="">Tax Included</label>
                                        <div className="flex border-radius border radio-btn mt-5">
                                            <input
                                                type="radio"
                                                id="tax_included_yes"
                                                name="tax_included"
                                                value="true"
                                                checked={addRates?.tax_included === "true"}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor="tax_included_yes" className="m_0">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex border-radius border radio-btn mt-5">
                                            <input
                                                type="radio"
                                                id="tax_included_no"
                                                name="tax_included"
                                                value="false"
                                                checked={addRates?.tax_included === "false"}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor="tax_included_no" className="m_0">
                                                No
                                            </label>
                                        </div>
                                    </div> */}

                                    <button className="primary_btn mt-10" type="submit">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {editPopup && (
                <div className="popup_backdrop">
                    <div className="popup_section p_20">
                        <div className={`modal ${editPopup ? "show" : ""}`}>
                            <div className="flex justify_content_between">
                                <h4>Edit Room Rates</h4>
                                <svg
                                    onClick={closeEditPopup}
                                    width="14"
                                    height="13"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                    <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                </svg>
                            </div>

                            <form onSubmit={handleUpdate}>
                                <div>
                                    <div>
                                        <label htmlFor="">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={editRates.name}
                                            onChange={(e) => handleInputChange(e, true)}
                                            placeholder="Name"
                                            className="p-5 w-100 border rounded mt-5"
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <label htmlFor="">Type</label>
                                        <select
                                            type="text"
                                            name="type"
                                            value={editRates.type}
                                            onChange={(e) => handleInputChange(e, true)}
                                            placeholder="Type"
                                            className="py-5 w-100 border rounded mt-5"
                                        >
                                            <option value="ep">EP</option>
                                            <option value="cp">CP</option>
                                            <option value="map">MAP</option>
                                            <option value="ap">AP</option>
                                        </select>
                                    </div>
                                    <div className="mt-5">
                                        <label htmlFor="">Status</label>
                                        <select
                                            name="status"
                                            value={editRates.status}
                                            onChange={(e) => handleInputChange(e, true)}
                                            className="py-5 w-100 border rounded mt-5"
                                        >
                                            <option value="">Select Option</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                    {/* <div>
                                        <label htmlFor="">Tax Included</label>

                                        <div className="flex border-radius border radio-btn">
                                            <input
                                                type="radio"
                                                id="tax_included_yes"
                                                name="tax_included"
                                                value={editRates.tax_included}
                                                onChange={(e) =>
                                                    setEditRates({
                                                        ...editRates,
                                                        tax_included: true,
                                                    })
                                                }
                                                checked={editRates?.tax_included === true}
                                            />
                                            <label htmlFor="tax_included_yes" className="m_0">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="flex border-radius border radio-btn">
                                            <input
                                                type="radio"
                                                id="tax_included_no"
                                                name="tax_included"
                                                value={editRates.tax_included}
                                                onChange={(e) =>
                                                    setEditRates({
                                                        ...editRates,
                                                        tax_included: false,
                                                    })
                                                }
                                                checked={editRates?.tax_included === false}
                                            />
                                            <label htmlFor="tax_included_no" className="m_0">
                                                No
                                            </label>
                                        </div>
                                    </div> */}
                                    <button className="primary_btn mt-10" type="submit">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {deletePopup && (
                <div className="popup_backdrop">
                    <div className="popup_section p_20">
                        <div>
                            <div className="flex justify_content_between">
                                <h4>Are you sure to Delete.</h4>
                                <svg
                                    onClick={() => closeDeletePopup(false)}
                                    width="14"
                                    height="13"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                    <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                                </svg>
                            </div>

                            <button
                                className="primary_btn mt-10"
                                type="button"
                                onClick={() => deleteRatePlan()}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ManageRatesPlans;