import React, { useState } from "react";
import { showSuccessToast } from "../../assets/toastUtils";
import styles from "./NewGetQuoteDownloadPopup.module.scss";
import locationIcon from "../../assets/images/location_icon.png";
import destinationIcon from "../../assets/images/experienc_icon.png";
import securityIcon from "../../assets/images/security_icon.png";
import customerIcon from "../../assets/images/customer_icon.png";


const NewGetQuoteDownloadPopup = ({
    package_id,
    setshowRatePopup,
    handleDownload,
    packageData,
}) => {
    // const [showRatePopup, setShowRatePopup] = useState(false);

    const [formData, setFormData] = useState({
        company_name: "",
        name: "",
        phone: "",
        email: "",
        querry_detail: "",
        country: "",
        state: "",
        city: "",
        address: "",
        travel_date: "",
        leadSubject: "Download Itinerary",
        start_date: "",
        end_date: "",
        source_type: "",
        type: "package",
        adult: "",
        children: "",
        reference_name_information: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowRatePopup = (e) => {
        e.stopPropagation();
        setshowRatePopup(false);
    };

    const postDataHandle = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();

        myHeaders.append("Accept", "application/json");
        myHeaders.append("key", process.env.REACT_APP_API_KEY);

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            leadName: formData?.company_name,

            leadPhone: formData?.phone,

            leadSubject: formData?.subject,

            leadEmail: formData?.email,

            package_id: package_id,

            leadDetail: formData?.querry_detail,

            leadSource: "Website",

            query_company: formData?.name,

            query_type: "agent",

            travel_date: formData?.travel_date,

            leadSubject: "Download Itinerary",
        });

        const requestOptions = {
            method: "POST",

            headers: myHeaders,

            body: raw,

            redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_APP_URL}/api/v1/send-query`, requestOptions)
            .then((response) => response.json())

            .then((result) => {
                if (result.success) {
                    setshowRatePopup(false);
                    handleDownload();
                }
            })

            .catch((error) => console.error(error));
    };
console.log(packageData?.hotelCategory);
    const sendQuery = async (e) => {
        e.preventDefault();
        try {
            const myHeaders = {
                Accept: "application/json",
                "Content-Type": "application/json",
                key: process.env.REACT_APP_BASE_API_KEY,
                Cookie: process.env.REACT_APP_COOKIE,
            };

            const formattedDestination = packageData?.packageDestination?.map(
                (destination) => destination.destinationId
            );

            console.log(formattedDestination);

            const body = JSON.stringify({
                name: formData?.name,
                email: formData?.email,
                content: formData?.querry_detail,
                source: "website",
                source_type: formData?.source_type,
                type: "package",

                start_date_information: formData.start_date,
                end_date_information: formData.end_date,

                destination_start_information:
                    packageData?.packageItinerary[0]?.startDestination,
                destination_end_information:
                    packageData?.packageItinerary[0]?.endDestination,

                adults_information: formData.adult,
                children_information: formData.children,

                hotel_category_information: packageData?.hotelCategory,
                room_category_information: ["budget"],
                all_destinations_information: formattedDestination,
                reference_name_information: formData?.name,
            });

            // const response = await fetch(
            //     `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
            //     {
            //         method: "POST",
            //         headers: myHeaders,
            //         body: body,
            //         redirect: "follow",
            //     }
            // );

            // const result = await response.json();
            // if (result.success) {
            //     showSuccessToast(result.message);
            //     setshowRatePopup(false);
            // }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="bg-backdrop">
            {" "}
            <div className={styles.new_popup_flex}>
                <div className={styles.new_popup_colored}>
                    <div className="flex align_items_center h-full">
                        <div>
                            <h4
                                style={{
                                    fontSize: "25px",
                                    fontWeight: "600",
                                    color: "#333",
                                    textAlign: "left",
                                }}
                            >
                                Over 22+ Years of Experience
                            </h4>
                            <div className={styles.choose_us}>
                                <div className={styles.highlight}>
                                    <img src={locationIcon} alt="location-icon" />
                                    <span>All in one platform</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={destinationIcon} alt="destination-icon" />
                                    <span>Expertise in Mountain Destinations</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={securityIcon} alt="security-icon" />
                                    <span> Security</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={customerIcon} alt="customer-icon" />
                                    <span>24x7 Customer Support</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form
                    onSubmit={sendQuery}
                    className={styles.new_popup_form}
                >
                    <h4 style={{ fontSize: "18px", fontWeight: "600", color: "#BB735D" }}>
                        Download Itinerary
                    </h4>
                    <div
                        className="dashboard_checkin_modal_close_btn"
                        onClick={handleShowRatePopup}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                        >
                            <path
                                d="M1 12.7464L13 0.851318"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                            <path
                                d="M13 12.7463L0.999999 0.851296"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "20px",
                        }}
                    >
                        <div>
                            <label htmlFor="name">Name:</label>
                            <input
                                required
                                className="w-full"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Your Name"
                                style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                }}
                            />
                        </div>


                        {/* <input
            required
            className="w-full"
            type="text"
            name="company_name"
            value={formData.company_name}
            onChange={handleInputChange}
            placeholder="Company Name"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          /> */}

                        <div className="input-flex">
                            <div>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder="Phone"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="source_type">Are you Agent:</label>
                            <select
                                className="w-full"
                                name="source_type"
                                value={formData.source_type}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>
                                    Select an option
                                </option>
                                <option value="agent">Yes</option>
                                <option value="customer">No</option>
                            </select>
                        </div>
                        <div className="input-flex">
                            <div>
                                <label htmlFor="source_type">Start Date:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="date"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleInputChange}
                                    placeholder="Start Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="source_type">End Date:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="date"
                                    name="end_date"
                                    value={formData.end_date}
                                    onChange={handleInputChange}
                                    placeholder="End Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="input-flex">
                            <div>
                                <label htmlFor="source_type">Adult:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="number"
                                    name="adult"
                                    value={formData.adult}
                                    onChange={handleInputChange}
                                    placeholder="Adult"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="source_type">Children:</label>
                                <input
                                    required
                                    className="w-full"
                                    type="number"
                                    name="children"
                                    value={formData.children}
                                    onChange={handleInputChange}
                                    placeholder="Children"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                            </div>
                        </div>

                        {/* <div className="input-flex">
            <div>
              <label htmlFor="source_type">Are you Agent:</label>
              <select
                className="w-full"
                name="source_type"
                value={formData.source_type}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="agent">Yes</option>
                <option value="customer">No</option>
              </select>
            </div>
          </div> */}

                        <textarea
                            className="w-full"
                            required
                            type="text-area"
                            name="querry_detail"
                            value={formData.querry_detail}
                            onChange={handleInputChange}
                            placeholder="Querry Detail"
                            style={{
                                padding: "10px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                height: "100px",
                                overflow: "scroll",
                            }}
                        />
                        {/* <div className="search-btn-width"> */}
                        <button type="submit" className="common_btn">
                            Download Itinerary
                        </button>
                        {/* </div> */}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewGetQuoteDownloadPopup;