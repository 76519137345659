import React from "react";

function OurQuery() {
    return (
        <div className="common_background question">
            <h4 className="text-left"><b>Got a Question? </b></h4>
            <p>Our Destination expert will be happy to help you resolve your queries for
                this tour.</p>
            <h5>
                <a href="tel:+919805021966">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.23 15.26L16.69 14.97C16.3914 14.9349 16.0886 14.968 15.8046 15.0667C15.5206 15.1654 15.2626 15.3273 15.05 15.54L13.21 17.38C10.3712 15.9362 8.06382 13.6288 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.69335 4.29219 8.45923 3.84225 8.09228 3.51586C7.72532 3.18947 7.25115 3.00943 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
                            fill="#016937" />
                    </svg>
                    +91-98050-21966
                </a>
            </h5>
        </div>
    )
}

export default OurQuery;