import React, { useEffect, useRef, useState } from "react";
import {
  formatIndianCurrency,
  textDateFormatter,
  textDateFormatterNoAddInIteration,
} from "../../utils/utils";
import moment from "moment";

function SeeBookingSlider({
  onClose,
  setIsSliderVisible,
  singleBookingData: singleSliderBookingData,
}) {
  // console.log("singleSliderBookingData", singleSliderBookingData);
  const bookingDetailsPopupRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    setIsSliderVisible(true);
    document.body.style.overflow = "hidden";
    const handleClickOutside = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      setIsSliderVisible(false);
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsSliderVisible]);

  const handleScroll = () => {
    if (bookingDetailsPopupRef.current.scrollTop > 50) {
      headerRef.current.classList.add("is-fixed");
    } else {
      headerRef.current.classList.remove("is-fixed");
    }
  };

  useEffect(() => {
    const sliderContainer = bookingDetailsPopupRef.current;
    sliderContainer.addEventListener("scroll", handleScroll);

    return () => {
      sliderContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeTab, setActiveTab] = useState("package-information");

  const noOfRooms = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_of_rooms);
    },
    0
  );

  const noOfChild = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_child);
    },
    0
  );

  const noOfAdults = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_adult);
    },
    0
  );

  const extraAdult = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.extra_adult);
    },
    0
  );

  const handleClose = () => {
    onClose();
  };

  console.log(singleSliderBookingData);

  return (
    <>
      <div className="bg-backdrop">
        <div
          id="booking_details_slider_container_new"
          ref={bookingDetailsPopupRef}
        >
          <div className="popup" id="popup">
            <div
              className="d_flex booking_detail_popup_head fixed_header"
              ref={headerRef}
              style={{ flexDirection: "column" }}
            >
              <div className="flex justify_content_between">
                <div onClick={handleClose} className="close_popup">
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 12.5L13 0.5"
                      stroke="#AAAAAA"
                      strokeWidth="1.2"
                    />
                    <path
                      d="M13 12.5L1 0.5"
                      stroke="#AAAAAA"
                      strokeWidth="1.2"
                    />
                  </svg>
                </div>
                <div className="d_flex booking_id">
                  <p style={{ marginRight: "6px" }}>Booking No:</p>
                  <b>#{singleSliderBookingData?.bookingNo}</b>
                </div>
              </div>
            </div>
            <div>
              {/* <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  Property Name
                </h6>
                <p className="p_20">
                  {singleSliderBookingData?.property?.name}
                </p>
              </div> */}
              <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  OverView
                </h6>
                <div className="flex justify_content_between p_20 p_top_0 flex_wrap">
                  <div>
                    <div className="mt-10">
                      <h6>Guest Details</h6>
                      <p>{singleSliderBookingData?.customerDetails?.name}</p>
                    </div>
                    <div className="mt-10">
                      <h6>Booking Date</h6>
                      <p>
                        {moment(singleSliderBookingData?.createdAt).format(
                          "MMM Do YYYY"
                        )}
                        {/* {textDateFormatterNoAddInIteration(
                          singleSliderBookingData?.created_at
                        )} */}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>State</h6>
                      <p>
                        {singleSliderBookingData?.customerDetails?.state ? singleSliderBookingData?.customerDetails?.state : 'N/A'}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Booking Type</h6>
                      <p>{singleSliderBookingData?.bookingType}</p>
                    </div>
                  </div>
                  <div>
                    <div className="mt-10">
                      <h6>Phone </h6>
                      <p>{singleSliderBookingData?.customerDetails?.phone ? singleSliderBookingData?.customerDetails?.phone : 'N/A'}</p>
                    </div>
                    <div className="mt-10">
                      <h6>Address</h6>
                      <p>
                        {singleSliderBookingData?.customerDetails?.address ? singleSliderBookingData?.customerDetails?.address : 'N/A'}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>City</h6>
                      <p>
                        {singleSliderBookingData?.customerDetails?.city ? singleSliderBookingData?.customerDetails?.city : 'N/A'}
                      </p>
                    </div>
                    {/* <div className="mt-10">
                      <h6>Booking Source</h6>
                      <p>website</p>
                    </div> */}
                  </div>
                  <div>
                    <div className="mt-10">
                      <h6>Email</h6>
                      <p>{singleSliderBookingData?.customerDetails?.email ? singleSliderBookingData?.customerDetails?.email : 'N/A'}</p>
                    </div>
                    <div className="mt-10">
                      <h6>Country</h6>
                      <p>
                        {singleSliderBookingData?.customerDetails?.country ? singleSliderBookingData?.customerDetails?.country : 'N/A'}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Status</h6>
                      <p>{singleSliderBookingData?.status ? singleSliderBookingData?.status : 'N/A'}</p>
                    </div>
                    {/* <div className="mt-10">
                      <h6>Booking Status</h6>
                      <p className="alert_text text-capitalize">
                        {singleSliderBookingData?.booking_status}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  Room & Plan
                </h6>
                {singleSliderBookingData?.items?.map((items) => {
                  return (
                    <div className="flex justify_content_between p_20 p_top_0 flex_wrap">
                      <div>
                        <div className="mt-10">
                          <p>{`${items?.no_of_rooms} X ${items?.room_type_name}`}</p>
                        </div>
                      </div>
                      <div>
                        <div className="mt-10">
                          <p>{items?.room_plan}</p>
                        </div>
                      </div>
                      <div>
                        <div className="mt-10">
                          <p> {formatIndianCurrency(items?.room_rate)}/night</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}
              <div>
                <h6 className="bg text-white" style={{ padding: "5px 20px" }}>
                  Price Breakup
                </h6>
                <div className="p_20 p_top_0">
                  <div className="flex justify_content_between flex_wrap">
                    <div className="mt-10">
                      <h6>Subtotal</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.total
                        )}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Discount Amt.</h6>
                      <p>
                        {/* {formatIndianCurrency(
                          singleSliderBookingData?.total_discount_amount
                        )} */}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6 className="">Tax Amount</h6>
                      <p className="">
                        {formatIndianCurrency(
                          singleSliderBookingData?.tax_details[0]?.amount
                        )}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Due Amount</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.dueAmount
                        )}
                      </p>
                    </div>
                    <div className="mt-10">
                      <h6>Grand Total</h6>
                      <p>
                        {formatIndianCurrency(
                          singleSliderBookingData?.grandTotal
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeeBookingSlider;
