import React, { useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";

const SendEnquiryPopup = ({ package_id, setshowRatePopup, typeDetail}) => {
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  // const [formData, setFormData] = useState({
  //   company_name: "",
  //   name: "",
  //   phone: "",
  //   email: "",
  //   querry_detail: "",
  //   country: "",
  //   state: "",
  //   city: "",
  //   address: "",
  //   travel_date: "",
  //   type: "",
  //   adult: "",
  //   time: "",
  // });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    travel_date: "",
    source: "website",
    adult: "",
    time: "",
    content: "",
    source_type: "",
    guide_required: "",
    type: typeDetail ? typeDetail : "package",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleShowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(false);
  };

  const postDataHandle = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the required details");
      return;
    }

    setTimeout(() => {
      setFormErrors({});
    }, 1000);

    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      leadName: formData?.company_name,

      leadPhone: formData?.phone,

      leadSubject: formData?.subject,

      leadEmail: formData?.email,

      package_id: package_id,

      leadDetail: formData?.querry_detail,

      leadSource: "Website",

      query_company: formData?.name,

      query_type: "agent",

      travel_date: formData?.travel_date,

      leadSubject: "Enquiry",

      type: typeDetail ? typeDetail : "",

      adult: formData?.adult,

      time: formData?.time,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/send-query`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // console.log(result);
          showSuccessToast("Enquiry sent successfully");

          setTimeout(() => {
            window.location.reload();
          }, 200);

          setShowRatePopup(false);
        }
      })

      .catch((error) => console.error(error));
  };

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    // if (currentStep === 1) {
    if (!formData.name.trim()) {
      errors.name = "required";
    }
    if (!formData.company_name.trim()) {
      errors.company_name = "required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "is required";
    }
    if (!formData.querry_detail) {
      errors.querry_detail = "required";
    }
    if (!formData.email) {
      errors.email = "required";
    }
    if (!formData.travel_date) {
      errors.travel_date = "required";
    }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "key",
      process.env.REACT_APP_BASE_API_KEY
    );
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const rawData = {
      name: formData?.name,
      email: formData?.email,
      phone_information: formData?.phone,
      travel_date_information: formData?.travel_date,
      guide_required_information: formData?.guide_required,
      content: formData?.content,
      source: formData?.source,
      source_type: formData?.source_type,
      type: formData?.type,
    };

    if (typeDetail === "activity") {
      rawData.adult_information = formData?.adult;
      rawData.time_information = formData?.time;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(rawData),
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (result.success) {
        showSuccessToast(result.message || "Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          travel_date: "",
          adult: "",
          time: "",
          source: "website",
          content: "",
          source_type: "",
        });
        setshowRatePopup(false)
      } else {
        showErrorToast(
          result.message || "An error occurred. Please try again."
        );
        setFormData({
          name: "",
          email: "",
          phone: "",
          travel_date: "",
          adult: "",
          time: "",
          source: "website",
          content: "",
          source_type: "",
        });
      }
    } catch (error) {
      showErrorToast("An error occurred. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="bg-backdrop">
      <form
        onSubmit={handleSubmit}
        className="dashboard_checkin_modal_container"
        id="send_query_popup"
      >
        <h4 style={{ fontSize: "18px", fontWeight: "600", color: "#BB735D" }}>
          Our expert will get in touch with you shortly
        </h4>
        <div
          className="dashboard_checkin_modal_close_btn"
          onClick={handleShowRatePopup}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M1 12.7464L13 0.851318"
              stroke="#AAAAAA"
              strokeWidth="1.2"
            />
            <path
              d="M13 12.7463L0.999999 0.851296"
              stroke="#AAAAAA"
              strokeWidth="1.2"
            />
          </svg>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <div>
            <label htmlFor="name">Name:</label>
            <input
              // required
              className="w-full"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                ...(formErrors.name && {
                  backgroundColor: "#ff000040",
                  border: "1px solid red",
                }),
              }}
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              className="w-full"
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                ...(formErrors.email && {
                  backgroundColor: "#ff000040",
                  border: "1px solid red",
                }),
              }}
            />
          </div>

          <div className="flex flex_gap_10">
            <div className={typeDetail === "activity" ? "activity_inputs_query_forms" : "w-full"}>
              <label htmlFor="phone">Phone:</label>
              <input
                className="w-full"
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone"
                style={{
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  ...(formErrors.phone && {
                    backgroundColor: "#ff000040",
                    border: "1px solid red",
                  }),
                }}
              />
            </div>
            {typeDetail === "activity" && (
              <div className="activity_inputs_query_forms">
                <label htmlFor="guide_required">Do you need Guide:</label>
                <select
                  style={{
                    padding: '10px',
                    ...(formErrors.guide_required && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }),
                  }}
                  className="w-full"
                  name="guide_required"
                  value={formData.guide_required}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            )}
          </div>

          <div className="flex flex_gap_10">
            <div className="activity_inputs_query_forms">
              <label htmlFor="source_type">Travel Date:</label>
              <input
                className="w-full"
                type="date"
                name="travel_date"
                value={formData.travel_date}
                onChange={handleInputChange}
                placeholder="Travel Date"
                style={{
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  ...(formErrors.travel_date && {
                    backgroundColor: "#ff000040",
                    border: "1px solid red",
                  }),
                }}
              />
            </div>
            <div className="activity_inputs_query_forms">
              <label htmlFor="source_type">Are you Agent:</label>
              <select
                style={{
                  padding: '10px',
                  ...(formErrors.source_type && {
                    backgroundColor: "#ff000040",
                    border: "1px solid red",
                  }),
                }}
                className="w-full"
                name="source_type"
                value={formData.source_type}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="agent">Yes</option>
                <option value="customer">No</option>
              </select>
            </div>
          </div>

          {typeDetail === "activity" && (
            <div className="flex flex_gap_10">
              <div className="activity_inputs_query_forms">
                <label htmlFor="source_type">Adult:</label>
                <input
                  className="w-full"
                  type="number"
                  name="adult"
                  value={formData.adult}
                  onChange={handleInputChange}
                  placeholder="Adult"
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    ...(formErrors.adult && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }),
                  }}
                />
              </div>
              <div className="activity_inputs_query_forms">
                <label htmlFor="source_type">Time:</label>
                <input
                  className="w-full"
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  placeholder="Time"
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    ...(formErrors.time && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }),
                  }}
                />
              </div>
            </div>
          )}
          <div>
            <label htmlFor="content">Query Detail:</label>
            <textarea
              className="w-full"
              type="text-area"
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              placeholder="Querry Detail"
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                ...(formErrors.content && {
                  backgroundColor: "#ff000040",
                  border: "1px solid red",
                }),
              }}
            />
          </div>
          <div className="search-btn-width">
            <button type="submit" className="common_btn">
              SEND
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendEnquiryPopup;
