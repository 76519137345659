import React, { useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useState } from "react";
import { textDateFormatter } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { showErrorToast } from "../../assets/toastUtils";
import { toast } from "react-toastify";

const InventoryUpdate = ({ onClose, inventoryData, InventoryDetails, startDate }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // console.log(fromDate);
  const vendorToken = localStorage.getItem("token");

  const { id: propertyId } = useParams();

  const [resultArray, setResultArray] = useState([]);
  // console.log(resultArray);

  const handleClosePopup = (e) => {
    onClose();
  };

  useEffect(() => {
    if (inventoryData) {
      const inventoryNewData = inventoryData.map((data) => {
        return {
          room_id: data.roomId,
          inventory: "",
        };
      });
      setResultArray(inventoryNewData);
    }
  }, [inventoryData]);

  // console.log(resultArray);

  const onChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const newArray = [...resultArray];
    newArray[index][name] = value;
    setResultArray(newArray);
  };

  const data = {
    1: {
      room_name: "1",
      inventory: "2",
    },
    2: {
      room_name: "2",
      inventory: "2",
    },
    3: {
      room_name: "3",
      inventory: "2",
    },
    4: {
      room_name: "4",
      inventory: "2",
    },
  };

  // const handleOnUpdate = () => {

  //   if (fromDate === "" || toDate === "") {
  //     showErrorToast("Please select start date and end date");
  //     return;
  //   }

  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);
  //   myHeaders.append("token", localStorage.getItem("token"));

  //   const finalResultArray = resultArray.filter((rate) => {
  //     return rate.inventory !== "";
  //   })

  //   const raw = JSON.stringify({
  //     "from-date": fromDate,
  //     "to-date": toDate,
  //     rooms: finalResultArray,
  //   });

  //   const requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-inventory/${propertyId}/bulk-update`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log(result);
  //       if (result.success) {
  //         onClose();
  //         toast.success(result.message);
  //         InventoryDetails(propertyId, startDate);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  const handleOnUpdate = async () => {
    const headers = {
      Accept: "application/json", "Content-Type": "application/json",
      Authorization:
        `Bearer ${vendorToken}`,
      Cookie: process.env.REACT_APP_BASE_URL,
    };

    const finalResultArray = resultArray.filter((rate) => {
      return rate.inventory !== "";
    })

    const body = JSON.stringify({
      "from-date": fromDate,
      "to-date": toDate,
      rooms: finalResultArray,
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/inventory/update/${propertyId}`,
        {
          method: "POST",
          headers: headers,
          body: body,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      if (result.success) {
        onClose();
        toast.success(result.message);
        InventoryDetails(propertyId, startDate);
      }
    } catch (error) {
      console.error("Error updating inventory:", error);
    }
  };

  return (
    <div className="popup_backdrop">
      <div className="inventory_update">
        <div className="flex justify_content_between">
          <h4>Inventory Bulk Update</h4>
          <div className="close_popup">
            <svg
              onClick={handleClosePopup}
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
              <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
            </svg>
          </div>
        </div>
        <div>
          <form action="" className="mt-10">
            <div className="input-flex">
              <div>
                <label>From Date</label>
                {/* <Flatpickr className="m_0 border w-full"
                                    color="#ffffff"
                                    value={fromDate}
                                    onChange={([date]) => setFromDate(date)}
                                    min={new Date()}
                                />
                                 */}
                <input
                  className="w-full"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div>
                <label>To Date</label>
                <input
                  className="w-full"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
          </form>
          {resultArray?.map((item, index) => {
            return (
              <div className="border-bottom py-5">
                <div className="w-55">
                  <div className="flex align_items_center justify_content_between">
                    <span>{inventoryData[index].name}</span>
                    <input
                      type="number"
                      name="inventory"
                      style={{ width: "50px" }}
                      value={item?.inventory}
                      onChange={(e) => onChangeHandler(e, index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <button
            className="primary_btn fit-width mt-10"
            type="submit"
            onClick={handleOnUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryUpdate;
