import React, { useEffect } from "react";
import { showErrorToast } from "../../assets/toastUtils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NTTData = ({
  mainData,
  selectedPaymentGateway,
  setPaymentGatewayLoader,
}) => {
  const [atomAccessToken, setAtomAccessToken] = useState(null);

  // mainData should be an this format

  // const mainData = [{
  //   order_amount= grand total,
  //  order_number= booking number,
  //   order_id= booking id,
  // email= customer email,
  // phone=customer phone number,
  // callBackUrl = return url

  //   custEmail = customer email,
  // custMobile= customer phone,
  // returnUrl = return url,
  //  }]

  //   mainData ^
  const navigate = useNavigate();
  // const [data, setData] = useState({
  //     order_amount: "",
  //     order_number: "",
  //     order_id: "",
  //     email: "",
  //     phone: "",
  //     callbackUrl: "",
  //     custEmail: "",
  //     custMobile: "",
  //     returnUrl: "",
  //     selectedPaymentGateway:""
  // });

  // Update state when `mainData` changes
  useEffect(() => {
    if (mainData[0]?.apiStartValue) {
      processPayment(mainData);
    }
  }, [mainData[0]?.apiStartValue === true]);

  // setData(mainData)

  // Payment Gateway
  const [paymentGateways, setPaymentGateways] = useState([]);

  // Use the below commented code to get the payment gateway

  //   const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);
  //   const fetchPaymentGateway = async () => {
  //     try {
  //       const headers = {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         key: process.env.REACT_APP_BASE_API_KEY,
  //       };
  //       const response = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/fetch/active`,
  //         {
  //           method: "GET",
  //           headers: headers,
  //           redirect: "follow",
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const result = await response.json();
  //       setPaymentGateways(result.data);

  //       const firstActiveGateway = result.data.find(
  //         (gateway) => gateway.gatewayName === "NTTData"
  //       );
  //       if (firstActiveGateway) {
  //         setSelectedPaymentGateway(firstActiveGateway);
  //       } else {
  //         showErrorToast("Failed to process payment!!!");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchPaymentGateway();
  //   }, [mainData]);

  // Use the above commented code to get the payment gateway

  //   const handlePaymentGatewayChange = (item) => {
  //     setSelectedPaymentGateway(item);
  //   };

  const processPayment = async (data) => {
    try {
      setPaymentGatewayLoader(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
        Cookie: process.env.REACT_APP_COOKIE,
      };

      const body = JSON.stringify({
        order_amount: data[0]?.order_amount,
        order_number: data[0]?.order_amount,
        order_id: data[0]?.order_id,
        email: data[0]?.email,
        phone: data[0]?.phone,
        // callbackUrl: mainData[0]?.callbackUrl,
        callbackUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${data[0]?.order_id}`,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/process/${selectedPaymentGateway?.gatewayName}/payment`,
        {
          method: "POST",
          headers: headers,
          body: body,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setAtomAccessToken(result?.data);
      setPaymentGatewayLoader(false);
    } catch (error) {
      console.error("Error processing payment:", error);
      setPaymentGatewayLoader(false);
    }
  };

  // New NTT UseEffect
  useEffect(() => {
    if (selectedPaymentGateway) {
      const scriptUrl = selectedPaymentGateway?.testMode
        ? "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js"
        : "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";

      const existingScript = document.querySelector(
        `script[src="${scriptUrl}"]`
      );

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => console.log("Atom Payment Gateway script loaded");
        script.onerror = () => console.error("Failed to load Atom script");
        document.body.appendChild(script);

        // Clean up script on unmount
        return () => {
          document.body.removeChild(script);
        };
      } else if (existingScript.readyState === "complete") {
        console.log("Atom Payment Gateway script already loaded");
      }
    }
  }, [selectedPaymentGateway]);

  useEffect(() => {
    if (
      atomAccessToken &&
      mainData.length > 0 &&
      typeof window.AtomPaynetz !== "undefined"
    ) {
      const options = {
        merchId: process.env.REACT_APP_ATOM_MERCHANT_ID, // Use your merchant ID
        atomTokenId: atomAccessToken,
        custEmail: mainData[0]?.custEmail,
        custMobile: mainData[0]?.custMobile,
        // returnUrl: mainData[0]?.returnUrl
        returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${mainData[0].order_id}`,
      };

      const atom = new window.AtomPaynetz(options, "uat");

      const handlePaymentClick = (e) => {
        e.preventDefault(); // Prevent default action

        try {
          atom.invoke((status) => {
            console.log("Payment Status:", status);

            if (status === "success") {
              // Navigate to ThankYou component on succ ess
              //   navigate("/thank-you", {
              //     state: { bookingId: hotelBookingData?.id },
              //   });
              console.log("Success");
            } else if (status === "failure" || status === "cancel") {
              // Handle failure or cancellation
              console.log("Payment failed. Please try again.");
              //   navigate("/cancel", {
              //     state: { bookingId: hotelBookingData?.id },
              //   });
            } else {
              console.log("Unknown payment status. Please contact support.");
            }
          });
        } catch (err) {
          console.error("Error initiating payment", err);
          console.log("Failed to initiate payment. Please try again.");
        }
      };

      const paymentButton = document.querySelector("#paymentButton");
      if (paymentButton) {
        paymentButton.addEventListener("click", handlePaymentClick);
      }

      // Clean up event listener on unmount
      return () => {
        if (paymentButton) {
          paymentButton.removeEventListener("click", handlePaymentClick);
        }
      };
    } else {
      console.warn("AtomPaynetz is not ready or required data is missing");
    }
  }, [atomAccessToken, mainData, selectedPaymentGateway, navigate]);

  const handleMessage = async ({ data }) => {
    if (data === "cancelTransaction") {
      console.log("Payment cancelled!!");

      try {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          key: process.env.REACT_APP_BASE_API_KEY,
          Cookie: process.env.REACT_APP_COOKIE,
        };
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway}/response/${mainData[0]?.order_id}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              status: "cancelled",
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        navigate(
          `/thank-you?paymentGateway=${selectedPaymentGateway}&bookingId=${mainData[0]?.order_id}&status=cancelled`
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [mainData]);

  return <div></div>;
};

export default NTTData;
