import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatIndianCurrency } from "../../utils/utils";

function CustomViewRooms({
  rooms,
  bookingData,
  handleBackToList,
  onClose,
  updateHotelNameForSelectedDay,
  selectedHotel,
  setCustomizableRatesLoader,
}) {
  const selectedHotelPrice = 1000;

  // console.log("selectedHotel", selectedHotel);

  // console.log(rooms, "roomdata");
  const [pricePopUpId, setPricePopUpId] = useState(false);
  const [isPopupVisibleId, setIsPopupVisibleId] = useState(null);
  const openPopup = (id) => {
    setIsPopupVisibleId(id);
  };

  const closePopup = (id) => {
    setIsPopupVisibleId(id);
    setCustomizableRatesLoader(false);
  };

  const openPriceDetails = (id) => {
    setPricePopUpId(id);
  };
  const closePriceDetails = (id) => {
    setPricePopUpId(id);
  };

  const navigate = useNavigate();

  const handleSelectRoomClickHandler = async (item, hotelData) => {
    // console.log("handleSelectRoomClickHandler", item, hotelData);

    updateHotelNameForSelectedDay(selectedHotel, item);

    localStorage.setItem("selectedRoomRates", JSON.stringify(item));
    localStorage.setItem("selectedHotel", JSON.stringify(selectedHotel));

    onClose();
    // window.location.reload()
    // handleClose()
    // let hasChildAgeError = false;
    // const childAge = bookingData?.childAge;
    // const sanitizedChildAges = childAge
    //     .map((age) => (age !== "" ? age : null))
    //     .filter((age) => age !== null);

    // const childAgeParams = sanitizedChildAges
    //     .map((age, index) => {
    //         if (age > 12) {
    //             alert("Child age must be between 0 and 12");
    //             hasChildAgeError = true;
    //             return;
    //         }
    //         return `&childAge[${index + 1}]=${age}`;
    //     })
    //     .join("");

    // if (hasChildAgeError) {
    //     alert("Child age must be between 0 and 12");
    //     return;
    // }

    // try {
    //     await navigate(
    //         `/checkout-hotel/#test`,
    //         // `/hotels-checkout?hotelId=${bookingData?.hotelId}&location=${bookingData.location}&room=${bookingData.room}&adult=${bookingData.adult}&children=${bookingData.children}${childAgeParams}&checkinDay=${bookingData.checkinDay}&checkinMonth=${bookingData.checkinMonth}&checkinYear=${bookingData.checkinYear}&checkoutDay=${bookingData.checkoutDay}&checkoutMonth=${bookingData.checkoutMonth}&checkoutYear=${bookingData.checkoutYear}&hotel_name=${item.hotel_name}&hotel_address=${item.hotel_address}`,
    //         {
    //             state: {
    //                 item: item,
    //                 hotelData: rooms,
    //                 ratesData: hotelData,
    //                 bookingData: bookingData,
    //             },
    //         }
    //     );
    // } catch (error) {
    //     console.error("Error fetching data:", error);
    //     // setLoader(false);
    // } finally {
    //     // setLoader(false);
    // }
  };

  // console.log(rooms?.room, "roororo");
  return (
    <>
      <section className="SingleRoomCard-Main" style={{ padding: "20px" }}>
        <div
          onClick={handleBackToList}
          className="back_handle_wrap"
          style={{
            display: "flex",
            marginBottom: "13px",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 1024 1024"
            className="icon"
          >
            <path
              fill="#000000"
              d="M224 480h640a32 32 0 110 64H224a32 32 0 010-64z"
            />
            <path
              fill="#000000"
              d="M237.248 512l265.408 265.344a32 32 0 01-45.312 45.312l-288-288a32 32 0 010-45.312l288-288a32 32 0 1145.312 45.312L237.248 512z"
            />
          </svg>
          <p style={{ fontSize: "14px", lineHeight: "13px" }}>
            Back to hotel listing
          </p>
        </div>
        {rooms &&
          rooms?.rooms &&
          rooms?.rooms?.length > 0 &&
          rooms?.rooms?.map((cards) => {
            return (
              <>
                <div
                  className="single-room-card slider-singer-room-card"
                  style={{ height: "fit-content" }}
                >
                  <div className="card_head">
                    <div className="agent">
                      <h4>
                        {cards.hotel_city} {"  "}
                        {/* <b> */}
                        {cards?.room_name}
                        {/* </b> */}
                        <small style={{ textTransform: "capitalize" }}>
                          ({cards.room_view})
                        </small>
                      </h4>
                      <div className="flex flex_gap_10">
                        <span
                          className="room_plan"
                          style={{
                            fontSize: "13px",
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 18 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.666992 11.8333V6.83329C0.666992 6.45829 0.743381 6.11802 0.896159 5.81246C1.04894 5.5069 1.25033 5.23607 1.50033 4.99996V2.66663C1.50033 1.97218 1.74338 1.3819 2.22949 0.895793C2.7156 0.409682 3.30588 0.166626 4.00033 0.166626H7.33366C7.6531 0.166626 7.95171 0.225654 8.22949 0.343709C8.50727 0.461765 8.76421 0.624959 9.00033 0.833293C9.23644 0.624959 9.49338 0.461765 9.77116 0.343709C10.0489 0.225654 10.3475 0.166626 10.667 0.166626H14.0003C14.6948 0.166626 15.285 0.409682 15.7712 0.895793C16.2573 1.3819 16.5003 1.97218 16.5003 2.66663V4.99996C16.7503 5.23607 16.9517 5.5069 17.1045 5.81246C17.2573 6.11802 17.3337 6.45829 17.3337 6.83329V11.8333H15.667V10.1666H2.33366V11.8333H0.666992ZM9.83366 4.33329H14.8337V2.66663C14.8337 2.43052 14.7538 2.2326 14.5941 2.07288C14.4344 1.91315 14.2364 1.83329 14.0003 1.83329H10.667C10.4309 1.83329 10.233 1.91315 10.0732 2.07288C9.91352 2.2326 9.83366 2.43052 9.83366 2.66663V4.33329ZM3.16699 4.33329H8.16699V2.66663C8.16699 2.43052 8.08713 2.2326 7.92741 2.07288C7.76769 1.91315 7.56977 1.83329 7.33366 1.83329H4.00033C3.76421 1.83329 3.5663 1.91315 3.40658 2.07288C3.24685 2.2326 3.16699 2.43052 3.16699 2.66663V4.33329ZM2.33366 8.49996H15.667V6.83329C15.667 6.59718 15.5871 6.39927 15.4274 6.23954C15.2677 6.07982 15.0698 5.99996 14.8337 5.99996H3.16699C2.93088 5.99996 2.73296 6.07982 2.57324 6.23954C2.41352 6.39927 2.33366 6.59718 2.33366 6.83329V8.49996Z"
                              fill="#333333"
                            />
                          </svg>
                          <span style={{ textTransform: "capitalize" }}>
                            {cards?.room_bed}{" "}
                          </span>
                        </span>
                        <span
                          className="room_plan"
                          style={{
                            fontSize: "13px",
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.667 11.1667H14.8337V7.00004H13.167V9.50004H10.667V11.1667ZM3.16699 7.00004H4.83366V4.50004H7.33366V2.83337H3.16699V7.00004ZM2.33366 13.6667C1.87533 13.6667 1.48296 13.5035 1.15658 13.1771C0.830187 12.8507 0.666992 12.4584 0.666992 12V2.00004C0.666992 1.54171 0.830187 1.14935 1.15658 0.822957C1.48296 0.496568 1.87533 0.333374 2.33366 0.333374H15.667C16.1253 0.333374 16.5177 0.496568 16.8441 0.822957C17.1705 1.14935 17.3337 1.54171 17.3337 2.00004V12C17.3337 12.4584 17.1705 12.8507 16.8441 13.1771C16.5177 13.5035 16.1253 13.6667 15.667 13.6667H2.33366ZM2.33366 12H15.667V2.00004H2.33366V12Z"
                              fill="#333333"
                            />
                          </svg>{" "}
                          {cards?.room_area} {cards?.room_area_unit}{" "}
                        </span>
                        <span
                          className="room_plan"
                          style={{
                            fontSize: "13px",
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_3149_3904"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="20"
                              height="20"
                            >
                              <rect width="20" height="20" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3149_3904)">
                              <path
                                d="M0.958984 16.6667L3.83398 10.9167C3.97287 10.6389 4.17079 10.4167 4.42773 10.25C4.68468 10.0834 4.97287 10 5.29232 10C5.62565 10 5.93121 10.0868 6.20898 10.2605C6.48676 10.4341 6.68815 10.6806 6.81315 11L7.37565 12.375C7.40343 12.4584 7.46593 12.4966 7.56315 12.4896C7.66037 12.4827 7.72287 12.4375 7.75065 12.3542L9.54232 6.37504C9.73676 5.70837 10.1083 5.17365 10.6569 4.77087C11.2055 4.3681 11.827 4.16671 12.5215 4.16671C13.202 4.16671 13.8097 4.36462 14.3444 4.76046C14.8791 5.15629 15.2507 5.67365 15.459 6.31254L19.0632 16.6667H17.2923L13.8757 6.83337C13.7645 6.51393 13.5909 6.2674 13.3548 6.09379C13.1187 5.92018 12.8409 5.83337 12.5215 5.83337C12.202 5.83337 11.9208 5.92365 11.6777 6.10421C11.4347 6.28476 11.2576 6.53476 11.1465 6.85421L9.35482 12.8334C9.22982 13.2223 9.00412 13.5417 8.67773 13.7917C8.35135 14.0417 7.97982 14.1667 7.56315 14.1667C7.18815 14.1667 6.84093 14.066 6.52148 13.8646C6.20204 13.6632 5.97287 13.382 5.83398 13.0209L5.27148 11.6459L2.81315 16.6667H0.958984ZM5.00065 8.33337C4.30621 8.33337 3.71593 8.08685 3.22982 7.59379C2.74371 7.10074 2.50065 6.51393 2.50065 5.83337C2.50065 5.13893 2.74371 4.54865 3.22982 4.06254C3.71593 3.57643 4.30621 3.33337 5.00065 3.33337C5.6951 3.33337 6.28537 3.57643 6.77148 4.06254C7.2576 4.54865 7.50065 5.13893 7.50065 5.83337C7.50065 6.51393 7.2576 7.10074 6.77148 7.59379C6.28537 8.08685 5.6951 8.33337 5.00065 8.33337ZM5.00065 6.66671C5.23676 6.66671 5.43468 6.58685 5.5944 6.42712C5.75412 6.2674 5.83398 6.06948 5.83398 5.83337C5.83398 5.59726 5.75412 5.39935 5.5944 5.23962C5.43468 5.0799 5.23676 5.00004 5.00065 5.00004C4.76454 5.00004 4.56662 5.0799 4.4069 5.23962C4.24718 5.39935 4.16732 5.59726 4.16732 5.83337C4.16732 6.06948 4.24718 6.2674 4.4069 6.42712C4.56662 6.58685 4.76454 6.66671 5.00065 6.66671Z"
                                fill="#333333"
                              />
                            </g>
                          </svg>
                          <span style={{ textTransform: "capitalize" }}>
                            {cards?.room_view}
                          </span>
                        </span>
                        <button
                          onClick={() => openPopup(cards?.room_id)}
                          className="details_btn"
                          style={{
                            fontSize: "13px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.33301 10.3334H7.66634V6.33337H6.33301V10.3334ZM6.99967 5.00004C7.18856 5.00004 7.34701 4.93604 7.47501 4.80804C7.60301 4.68004 7.66679 4.52182 7.66634 4.33337C7.66634 4.14448 7.60234 3.98604 7.47434 3.85804C7.34634 3.73004 7.18812 3.66626 6.99967 3.66671C6.81079 3.66671 6.65234 3.73071 6.52434 3.85871C6.39634 3.98671 6.33256 4.14493 6.33301 4.33337C6.33301 4.52226 6.39701 4.68071 6.52501 4.80871C6.65301 4.93671 6.81123 5.00048 6.99967 5.00004ZM6.99967 13.6667C6.07745 13.6667 5.21079 13.4916 4.39967 13.1414C3.58856 12.7912 2.88301 12.3163 2.28301 11.7167C1.68301 11.1167 1.20812 10.4112 0.858341 9.60004C0.508563 8.78893 0.333452 7.92226 0.333008 7.00004C0.333008 6.07782 0.508119 5.21115 0.858341 4.40004C1.20856 3.58893 1.68345 2.88337 2.28301 2.28337C2.88301 1.68337 3.58856 1.20849 4.39967 0.858707C5.21079 0.50893 6.07745 0.333818 6.99967 0.333374C7.9219 0.333374 8.78856 0.508485 9.59967 0.858707C10.4108 1.20893 11.1163 1.68382 11.7163 2.28337C12.3163 2.88337 12.7915 3.58893 13.1417 4.40004C13.4919 5.21115 13.6668 6.07782 13.6663 7.00004C13.6663 7.92226 13.4912 8.78893 13.141 9.60004C12.7908 10.4112 12.3159 11.1167 11.7163 11.7167C11.1163 12.3167 10.4108 12.7918 9.59967 13.142C8.78856 13.4923 7.9219 13.6672 6.99967 13.6667ZM6.99967 12.3334C8.48856 12.3334 9.74967 11.8167 10.783 10.7834C11.8163 9.75004 12.333 8.48893 12.333 7.00004C12.333 5.51115 11.8163 4.25004 10.783 3.21671C9.74967 2.18337 8.48856 1.66671 6.99967 1.66671C5.51079 1.66671 4.24967 2.18337 3.21634 3.21671C2.18301 4.25004 1.66634 5.51115 1.66634 7.00004C1.66634 8.48893 2.18301 9.75004 3.21634 10.7834C4.24967 11.8167 5.51079 12.3334 6.99967 12.3334Z"
                              fill="#016937"
                              onClick={() => closePopup(null)}
                            />
                          </svg>
                          More Details
                        </button>
                        {isPopupVisibleId === cards?.room_id && (
                          <div className="popup_backdrop">
                            <div className="popup_section">
                              <div className="agent">
                                <div>
                                  <h6>
                                    <b>{cards?.room_name}</b>
                                  </h6>
                                </div>
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  onClick={closePopup}
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 13L13 1"
                                    stroke="#AAAAAA"
                                    strokeWidth="1.2"
                                  />
                                  <path
                                    d="M13 13L0.999999 1"
                                    stroke="#AAAAAA"
                                    strokeWidth="1.2"
                                  />
                                </svg>
                              </div>
                              <hr />
                              <div>
                                <h6>
                                  <b>Room Amenities</b>
                                </h6>
                                <div className="agent">
                                  <div>
                                    <li>Kids Area</li>
                                    <li>Balcony Rooms</li>
                                    <li>Dining</li>
                                  </div>
                                  <div>
                                    <li>Banquet Hall</li>
                                    <li>Fitness Center</li>
                                    <li>Parking</li>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="single_room_data_">
                    <div
                      className="rooms_content"
                      style={{ width: "100%", height: "fit-content" }}
                    >
                      {cards?.rates?.map((items) => {
                        const rateDifference =
                          Number(items.rate_per_night) - selectedHotelPrice;
                        const priceDifferenceText =
                          rateDifference > 0
                            ? `+${rateDifference}`
                            : rateDifference < 0
                            ? `${rateDifference}`
                            : "Same price";

                        return (
                          <div className="single_room_details">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              <p>
                                {items.meal_plan_name}{" "}
                                <small>({items.meal_plan})</small>
                              </p>
                              <button
                                type="button"
                                onClick={() =>
                                  openPriceDetails(items.rate_plan_id)
                                }
                                className="view_btn"
                              >
                                View Price Details
                              </button>

                              {pricePopUpId === items.rate_plan_id && (
                                <div className="popup_backdrop">
                                  <div className="popup_section">
                                    <div className="agent">
                                      <div>
                                        <h6>
                                          <b>{cards.room_name}</b>
                                        </h6>
                                        <p>
                                          {items.meal_plan_name
                                            ? items.meal_plan_name
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        onClick={() => closePriceDetails(null)}
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L13 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                        <path
                                          d="M13 13L0.999999 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                      </svg>
                                    </div>
                                    <hr />
                                    <div>
                                      <div className="agent">
                                        <p>
                                          Room Rate <small> (Per Night)</small>
                                        </p>
                                        <h6>₹ {items.rate_per_night}</h6>
                                      </div>
                                      <div className="agent">
                                        <p>Discount</p>
                                        <h6>₹ 0</h6>
                                      </div>
                                      <div className="agent">
                                        <p>
                                          Total Amount
                                          <small> (Taxes Extra) </small>
                                        </p>
                                        <h6>₹ {items.rate_per_night}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="single_room_price">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "end",
                                }}
                              >
                                <h4>
                                  {/* <h6> {priceDifferenceText}</h6> */}
                                  {formatIndianCurrency(
                                    items.rate_per_night
                                  )}{" "}
                                  <smalll>+ GST</smalll>
                                </h4>
                                <div>
                                  {items.status === "AVAILABLE" ? (
                                    <>
                                      {items.add_rooms &&
                                      Number(items.required_rooms) >=
                                        Number(bookingData.room) ? (
                                        // <CustomTooltip
                                        //   preferredPosition="below"
                                        //   content={<div>{"Please Increase Rooms"}</div>}
                                        // >
                                        <button
                                          className="primary_btn"
                                          style={{
                                            cursor: "not-allowed",
                                            background: "grey",
                                            width: "120px",
                                          }}
                                          disabled
                                        >
                                          PLEASE ADD ROOM
                                        </button>
                                      ) : (
                                        // </CustomTooltip>
                                        <button
                                          onClick={() =>
                                            handleSelectRoomClickHandler(
                                              items,
                                              selectedHotel
                                            )
                                          }
                                          style={{
                                            width: "120px",
                                          }}
                                          className="primary_btn"
                                        >
                                          Select Rooms
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {items.add_rooms &&
                                      Number(items.required_rooms) <=
                                        Number(rooms) ? (
                                        // <CustomTooltip
                                        //   preferredPosition="below"
                                        //   content={<div>{"Please Increase Rooms"}</div>}
                                        // >
                                        <button
                                          className="primary_btn"
                                          style={{
                                            cursor: "not-allowed",
                                            background: "grey",
                                            width: "120px",
                                          }}
                                          disabled
                                        >
                                          PLEASE ADD ROOM
                                        </button>
                                      ) : (
                                        // </CustomTooltip>
                                        // <button
                                        //     className="primary_btn"
                                        //     style={{
                                        //         cursor: "not-allowed",
                                        //         background: "grey",
                                        //         width:"60px"
                                        //     }}
                                        //     disabled
                                        // >
                                        //     Sold Out
                                        // </button>
                                        <button
                                          onClick={() =>
                                            handleSelectRoomClickHandler(
                                              items,
                                              cards
                                            )
                                          }
                                          // style={{
                                          //   width: "120px",}}
                                          className="primary_btn"
                                        >
                                          Select Rooms
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </section>
    </>
  );
}

export default CustomViewRooms;
