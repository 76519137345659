import React, { useState } from "react";
import Slider from "react-slick";
import { destinationImages } from "../../constants";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Skeleton from "react-loading-skeleton";
import imgNotFound from "../../assets/images/single-image.png";

function DestinationLinks({ footerDestinations, heading, loader }) {
  return (
    <section className="common_bg">
      <div className="container">
        <div className="common_padding">
          <h3>{heading}</h3>
        </div>
        <div className="des-flex">
          {loader ? (
            <>
              {Array.from({ length: 9 }).map((_, index) => (
                <Link
                  style={{ width: "30.33%" }}
                  className="destination_links"
                  key={`skeleton-${index}`} // Use index as key for skeleton
                >
                  <div>
                    <Skeleton height={60} />
                  </div>
                </Link>
              ))}
            </>
          ) : (
            <>
              {footerDestinations?.slice(0, 9).map((items) => (
                <Link
                  onClick={() =>
                    scroll.scrollToTop({
                      duration: 500,
                      smooth: "easeInOutQuad",
                    })
                  }
                  to={`/destination/${items.name.toLowerCase()}`}
                  className="destination-card"
                  key={items.id || `destination-${items.name}`} // Use a unique key for each destination item
                >
                  <img src={items?.featuredImage ? items.featuredImage : imgNotFound} alt={items.alt} />
                  <div>
                    <h6>{items?.name}</h6>
                    <small>
                      Budget Hotels in {items?.name}, 4 Star Hotels in{" "}
                      {items?.name}, 5 Star Hotels in {items?.name}
                    </small>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default DestinationLinks;
