import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VendorHeader from "../VendorHeader/VendorHeader";
import SideBar from "../SideBar/SideBar";
import TabBar from "../TabBar/TabBar";
import BasicInformation from "../BasicInformation/BasicInformation";
import AddressAutocomplete from "../../CustomGooglePlaceAPi/AddressAutocomplete";
import { toast } from "react-toastify";
import "./EditProperty.css";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import {
  AccommodationCategoryOptions,
  AccommodationStarRatingOptions,
  AccommodationTypeOptions,
} from "../../../constants";
import MultipleImageUploadComponent from "../../ImageUpload/MultipleImageUploadComponent";
import UploadFileComponent from "../../ImageUpload/UploadFileComponent";

function EditProperty() {
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPropertyLogo, setFetchedPropertyLogo] = useState([]);
  const vendorToken = localStorage.getItem("token");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);
  const [vendorDetails, setVendorDetails] = useState([]);
  const token = localStorage.getItem("token");

  const getUserProfile = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/supplier/user-profile`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${vendorToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setVendorDetails(data?.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  const handleFileUploadChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedFile(file);
    setLogoUrl(fileUrl);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  // try
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyData, addressData } = location.state || {};
  const [propertyDetails, setPropertyDetails] = useState(propertyData || {});
  const [addressDetails, setAddressDetails] = useState(addressData || {});

  const deleteFetchedImages = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/delete-image`,

      {
        method: "DELETE",
        body: JSON.stringify({
          mediaId: id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          //   showSuccessToast(data.message);
          showSuccessToast("Image deleted successfully");
          fetchPropertyData();
        } else {
          //   showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const addPropertyImages = (event) => {
    event.preventDefault();
    // setLoader(true);
    const myHeaders = new Headers();
    const formData = new FormData();
    formData.append("media_type", "property");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    formData.append("associated_id", id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("image[]", selectedFiles[i]);
    }
    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/add-hotel-image/${id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "multipart/form-data": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // fetchPropertyImages(id);
          fetchPropertyData();
          setSelectedFiles([]);
          //   setLoader(false);
          //   showSuccessToast(data.message);
        } else if (data.error) {
          //   setLoader(false);
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          //   showErrorToast(firstErrorMessage);
        } else {
          //   setLoader(false);
          //   showErrorToast(data.message);
        }
      });
  };

  const [vendorId, setVendorId] = useState({});

  useEffect(() => {
    const vendor_id = localStorage.getItem("userDetails");
    if (vendor_id) {
      const userDetails = JSON.parse(vendor_id);
      // console.log(userDetails);
      setVendorId(userDetails.id);
    }
  });

  // const fetchPropertyData = () => {
  //   const requestOptions = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-accommodation/${id}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json()) // Assuming the response is in JSON format
  //     .then((result) => {
  //       setPropertyDetails(result.hotels);
  //       setAddressDetails({
  //         address: result.hotels.address,
  //         latitude: "",
  //         longitude: "",
  //         country: result.hotels.country,
  //         state: result.hotels.state,
  //         city: result.hotels.city,
  //       });
  //       // console.log(result, "result");
  //     })
  //     .catch((error) => console.error("Error fetching property data:", error));
  // };

  const fetchPropertyData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${vendorToken}`);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/crud/edit/${id}`,
        requestOptions
      );
      const result = await response.json();

      if (response.ok) {
        setPropertyDetails({
          ...result.data,
          featured: result.data.featured ? "1" : "0",
          online: result.data.online ? "1" : "0",
        });
        setAddressDetails({
          address: result.data.address,
          latitude: "",
          longitude: "",
          country: result.data.country,
          state: result.data.state,
          city: result.data.city,
        });
      } else {
        throw new Error(result.message || "Something went wrong");
      }
    } catch (err) {
      console.error("Error fetching property data:", err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPropertyData();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyDetails({ ...propertyDetails, [name]: value });
  };

  const handleAddressChange = (data) => {
    setAddressDetails(data);
  };

  const handleSave = (e) => {
    e.preventDefault();

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify({
        ...propertyDetails,
      }),
      redirect: "follow",
    };

    fetch(
      `https://extranet.travcatalyst.com/api1/vendor/accommodation-updatee/${id}/${vendorId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        navigate("/vendor/property/list");
      })
      .catch((error) => console.error("Error updating property data:", error));
  };

  const updateAccommodation = async (e) => {
    e.preventDefault();

    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${vendorToken}`);
    headers.append("Cookie", process.env.REACT_APP_COOKIE);

    const formData = new FormData();
    formData.append("name", propertyDetails?.name);
    formData.append("type", propertyDetails?.type);
    formData.append("supplier_id", propertyDetails?.supplierId);
    formData.append("email", propertyDetails?.email);
    formData.append("phone", propertyDetails?.phone);
    formData.append("city", addressDetails?.city);
    formData.append("featured", propertyDetails?.featured);
    formData.append("online", propertyDetails?.online);
    formData.append("state", addressDetails?.state);
    formData.append("country", addressDetails?.country);
    formData.append("address", addressDetails?.address);
    formData.append("rating", propertyDetails?.starRating);
    formData.append("category", propertyDetails?.category);
    formData.append("content", propertyDetails?.content);
    formData.append("status", propertyDetails?.status);
    formData.append("latitude_information", addressDetails?.latitude);
    formData.append("longitude_information", addressDetails?.longitude);
    formData.append(
      "property_amenity_amenities",
      propertyDetails.property_amenity_amenities
    );

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/crud/update/${id}`,
        requestOptions
      );

      const result = await response.json();
      if (result.success) {
        showSuccessToast(result?.message);
        navigate("/vendor/property/list");
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <VendorHeader />
      <section>
        <div className="basic-info-container">
          <div className="flex_align_center flex_gap_20">
            <span onClick={() => window.history.back()} className="back_btn">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                  fill="#333333"
                />
              </svg>
            </span>
            <div>
              <h3 className="text-left">Edit Property Details</h3>
              <p>Please fill the details of your property.</p>
            </div>
          </div>
          {/* <div>
            <h3 className="text-left">Edit Property Details</h3>
            <p>Please fill the details of your property.</p>
          </div> */}
          <form>
            <div className="bg-white-div">
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="property_name">
                    Property Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Property Name"
                    onChange={handleInputChange}
                    name="name"
                    value={propertyDetails.name || ""}
                    required
                  />
                </div>

                <div className="w-half">
                  <label htmlFor="starRating">
                    Star Rating <span className="required">*</span>
                  </label>
                  <select
                    name="starRating"
                    value={propertyDetails.starRating || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select</option>
                    {AccommodationStarRatingOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label} Star
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-half">
                  <label htmlFor="rating">
                    Type <span className="required">*</span>
                  </label>
                  <select
                    name="type"
                    id="type"
                    value={propertyDetails.type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {AccommodationTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="rating">
                    Featured ddd <span className="required">*</span>
                  </label>
                  <select
                    name="featured"
                    id="type"
                    value={propertyDetails.featured}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>

                <div className="w-half">
                  <label htmlFor="online">
                    Online <span className="required">*</span>
                  </label>
                  <select
                    name="online"
                    id="online"
                    value={propertyDetails.online}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>

                <div className="w-half">
                  <label htmlFor="rating">
                    Category <span className="required">*</span>
                  </label>
                  <select
                    name="category"
                    id="type"
                    value={propertyDetails.category}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {AccommodationCategoryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-half">
                  <label htmlFor="rating">
                    Status <span className="required">*</span>
                  </label>
                  <select
                    name="status"
                    id="type"
                    value={propertyDetails.status}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="active">Active</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>
              </div>

              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="check_in">Check-in Time</label>
                  <select
                    name="check_in"
                    value={propertyDetails.check_in || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="06:00 am">06:00 am</option>
                    <option value="06:30 am">06:30 am</option>
                    <option value="07:00 am">07:00 am</option>
                    <option value="07:30 am">07:30 am</option>
                    <option value="08:00 am">08:00 am</option>
                    <option value="08:30 am">08:30 am</option>
                    <option value="09:00 am">09:00 am</option>
                    <option value="09:30 am">09:30 am</option>
                    <option value="10:00 am">10:00 am</option>
                    <option value="10:30 am">10:30 am</option>
                    <option value="11:00 am">11:00 am</option>
                    <option value="11:30 am">11:30 am</option>
                    <option value="12:00 pm (noon)">12:00 pm (noon)</option>
                    <option value="12:30 pm">12:30 pm</option>
                    <option value="01:00 pm">01:00 pm</option>
                    <option value="01:30 pm">01:30 pm</option>
                    <option value="02:00 pm">02:00 pm</option>
                    <option value="02:30 pm">02:30 pm</option>
                    <option value="03:00 pm">03:00 pm</option>
                    <option value="03:30 pm">03:30 pm</option>
                    <option value="04:00 pm">04:00 pm</option>
                  </select>
                </div>
                <div className="w-half">
                  <label htmlFor="check_out">Check-out Before</label>
                  <select
                    name="check_out"
                    value={propertyDetails.check_out || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="06:00 am">06:00 am</option>
                    <option value="06:30 am">06:30 am</option>
                    <option value="07:00 am">07:00 am</option>
                    <option value="07:30 am">07:30 am</option>
                    <option value="08:00 am">08:00 am</option>
                    <option value="08:30 am">08:30 am</option>
                    <option value="09:00 am">09:00 am</option>
                    <option value="09:30 am">09:30 am</option>
                    <option value="10:00 am">10:00 am</option>
                    <option value="10:30 am">10:30 am</option>
                    <option value="11:00 am">11:00 am</option>
                    <option value="11:30 am">11:30 am</option>
                    <option value="12:00 pm (noon)">12:00 pm (noon)</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="content">Description</label>
                <textarea
                  rows={9}
                  name="content"
                  onChange={handleInputChange}
                  value={propertyDetails?.content || ""}
                  className="w-full"
                  placeholder="Type here..."
                  cols={6}
                  c
                ></textarea>
              </div>
            </div>
            <div className="bg-white-div mt-10">
              <h4>Contact Details</h4>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="mobile_number">
                    Mobile Number <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Contact Number"
                    onChange={handleInputChange}
                    name="phone"
                    value={propertyDetails?.phone || ""}
                    required
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="email">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleInputChange}
                    name="email"
                    value={propertyDetails?.email || ""}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="bg-white-div mt-10">
              <h4 className="mb-10">Address Details</h4>
              <div>
                <AddressAutocomplete
                  formdata={addressDetails}
                  onAddressChange={handleAddressChange}
                  addressDetails={addressDetails}
                />
              </div>
            </div>
            <div className="bg-white-div mt-10">
              <div className="edit-rooms-form-wrapper-container  mt-10">
                <h4 className="mb-10">Property Images</h4>

                {/* <div className="propertyImagesContainer">
                  {propertyDetails?.media?.map((item, index) => (
                    <div key={index} className="propertyImagesContainerDiv">
                      <img
                        src={item?.original_url}
                        alt={`Property Image ${index}`}
                      />
                      <button
                        type="button"
                        onClick={() => deleteFetchedImages(item.id)}
                        className="propertyImagesContainerDelete"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path d="M1 6.94752L7 1" stroke="black" />
                          <path
                            d="M7 6.94775L1 1.00023"
                            stroke="black"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>

                <div>
                  <div className="flex flex_gap_10 flex_wrap">
                    <>
                      {fetchedImages.map((item, index) => (
                        <div key={index}>
                          <div className="flex flex_gap_10 justify-content-between">
                            <div>
                              <img
                                src={item.src}
                                alt={`Property Image ${index}`}
                                height="80px"
                                width="100px"
                              />
                            </div>
                            <div className="featureImages_btnContainer">
                              <button
                                type="button"
                                onClick={() => deleteFetchedImages(item.id)}
                                className="featureImages_removeLogobtn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <path d="M1 6.94752L7 1" stroke="black" />
                                  <path
                                    d="M7 6.94775L1 1.00023"
                                    stroke="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  </div>
                  <div style={{ position: "relative" }}>
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      multiple="multiple"
                      onChange={handleFileUploadChange}
                      className={`${selectedFiles.length === 0
                        ? "featureImages_upload_file_input"
                        : "featureImages_upload_file_input featureImages_addzindex"
                        }`}
                    />
                    <hr style={{ margin: "20px" }} />
                    {selectedFiles && selectedFiles.length > 0 ? (
                      <div
                        className="propertyImagesContainer"
                        style={{ marginTop: "10px" }}
                      >
                        {selectedFiles.map((file, index) => (
                          <div
                            key={index}
                            className="propertyImagesContainerDiv"
                          >
                            <div style={{ height: "100%" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                alt="error"
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>
                            <div className="featureImages_btnContainer">
                              <button
                                type="button"
                                style={{ display: "flex" }}
                                onClick={() =>
                                  setSelectedFiles((prev) =>
                                    prev.filter((item) => item !== file)
                                  )
                                }
                                className="propertyImagesContainerDelete"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <path d="M1 6.94752L7 1" stroke="black" />
                                  <path
                                    d="M7 6.94775L1 1.00023"
                                    stroke="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // </div>
                      <div className="featureImages_uplaodFile_displaytext_container">
                        <p className="featured_image_text">Add Files</p>
                        <p className="featured_image_text_dragdrop">
                          or drop JPG, PNG images here
                        </p>
                      </div>
                    )}
                    {selectedFiles && selectedFiles.length ? (
                      <button
                        type="button"
                        className="primary_btn search-btn-width"
                        style={{ marginTop: "20px" }}
                        onClick={(event) => addPropertyImages(event)}
                      >
                        Upload
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}

                <MultipleImageUploadComponent
                  modelType="accommodation"
                  modelId={id}
                  upload_collection="photos"
                  urlType="website/supplier/file/fetch"
                  uploadType={"website/supplier/file/upload"}
                  deleteType={"website/supplier/file/delete"}
                  // onFileUpload={handleFileUpload}
                  // setState={setIsPopupVisible}
                  // imageData={imageData}
                  fileTypeDisabled={true}
                />
              </div>
              <div className="edit-rooms-form-wrapper-container  mt-10">

                </div>
            </div>
            
            <div className="flex justify_content_end mt-10">
              <button
                type="submit"
                className="primary_btn"
                style={{ width: "70px" }}
                onClick={updateAccommodation}
              >
                Save
              </button>
            </div>
          </form>

          <div className="bg-white-div">
            <h4>Documents</h4>
          <UploadFileComponent
              uploadType={"website/supplier/file/upload"}
              fetchType={"website/supplier/file/fetch"}
              deleteType={"website/supplier/file/delete"}
              modelType={"accommodation"}
              modelId={id}
              upload_collection={"documents"}
               fileType="pdf"
            />

          </div>
        </div>
      </section>
    </>
  );
}

export default EditProperty;
