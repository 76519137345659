import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";

const CancelPage = ({status}) => {
  const [orderDetails, setOrderDetails] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchOrderId() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/new/check/payment/${id}/hotel`
        );
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error("Error fetching order ID:", error);
      }
    }
    fetchOrderId();
  }, []);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const paymentGateway = queryParams.get("paymentGateway");
  const bookingId = queryParams.get("bookingId");
  const statusFromUrl = queryParams.get("status");

  const handleCancelBooking = async()=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", `${process.env.REACT_APP_BASE_API_KEY}`);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);
    
    const raw = JSON.stringify({
      "status": "cancelled"
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      // /website/payment-gateway/payment/NTTData/response/184
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${paymentGateway}/response/${bookingId}`,requestOptions)
      const data = await response.json()
      if(data.success){
        console.log(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    if(statusFromUrl==="cancelled"){
      handleCancelBooking()
    }
  },[status])

  return (
    <section>
      {/* <Header /> */}
      <div className="container">
        <div className="row justify-content-center pb_30">
          <div className="col-md-8">
            <div className="thankyou_main mt-20">
              <div className="text-center">
                {/* <Image src="/icons/econew.svg" width={160} height={60} alt="Eco Logo"></Image> */}
                <h1 className="mt-30">Booking Cancellation!</h1>
                <div className="mt-20">
                  <svg
                    height="60px"
                    width="60px"
                    version="1.1"
                    id="Layer_1"
                    //   xmlns="http://www.w3.org/2000/svg"
                    //   xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 496 496"
                    //   xml:space="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />

                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        style={{ fill: "#B5092B" }}
                        d="M496,248c0,136.8-111.2,248-248,248S0,384.8,0,248S111.2,0,248,0S496,111.2,496,248z"
                      />{" "}
                      <path
                        style={{ fill: "#B5092B" }}
                        d="M248,0c136.8,0,248,111.2,248,248S384.8,496,248,496"
                      />{" "}
                      <path
                        style={{ fill: "#B5092B" }}
                        d="M72.8,72.8c96.8-96.8,253.6-96.8,350.4,0s96.8,253.6,0,350.4"
                      />{" "}
                      <g>
                        {" "}
                        <path
                          style={{ fill: "#fff" }}
                          d="M321.6,333.6c-3.2,0-5.6-0.8-8-3.2l-148-148c-4.8-4.8-4.8-12,0-16.8s12-4.8,16.8,0l148,148 c4.8,4.8,4.8,12,0,16.8C328,332.8,324.8,333.6,321.6,333.6z"
                        />{" "}
                        <path
                          style={{ fill: "#fff" }}
                          d="M174.4,333.6c-3.2,0-5.6-0.8-8-3.2c-4.8-4.8-4.8-12,0-16.8l148-148c4.8-4.8,12-4.8,16.8,0 s4.8,12,0,16.8l-148.8,148C180,332.8,176.8,333.6,174.4,333.6z"
                        />{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </div>
              </div>
              <p className="mt-20">
                {" "}
                <b>
                  {" "}
                  Dear{" "}
                  {orderDetails?.booking && orderDetails?.booking.booked_by
                    ? orderDetails?.booking.booked_by
                    : "Guest"}
                  ,
                </b>
                We regret to inform you that your booking has been canceled due
                to non-payment or other reasons. We understand that this may
                cause inconvenience and disappointment, and we sincerely
                apologize for any inconvenience caused.
              </p>
              <p>
                {" "}
                If you believe this cancellation is an error or if you have any
                concerns, please do not hesitate to reach out to our customer
                support team at Email:{" "}
                <a href="mailto:info@mountainmysteries.com">
                  <strong>info@mountainmysteries.com</strong>
                </a>{" "}
                or Phone:{" "}
                <a href="tel:+919805021966">
                  <strong>+91-9805021966</strong>
                </a>
                . We will be more than happy to assist you in resolving
                rebooking your stay .
              </p>
              <p>Here are the payment details for your reference:</p>
              <p>
                {" "}
                We appreciate your understanding and cooperation in this matter.
                We value your patronage and hope to have the opportunity to
                serve you in the future.
              </p>
              <p className="text-left">
                Thank you.
                <br />
                Team <strong>Mountain Mysteries</strong>
              </p>
              <div className="text-center mt-20">
                <Link to={"/"} className="animate_btn btn-6">
                  <span className="btn-lnk">Back to Home</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </section>
  );
};

export default CancelPage;
