import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer.jsx";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData.jsx";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles.jsx";
import CustomSearchFormTransport from "../../CustomComponents/SearchForm/CustomSearchFormActivities/CustomSearchFormTransport.jsx";
import Header from "../../CustomComponents/Header/Header.jsx";
import imgNotFound from "../../assets/images/single-image.png";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial.jsx";
import Highlights from "../../CustomComponents/Highlights/Highlights.jsx";
import Slider from "react-slick";
import { currentDateSingle } from "../../utils/utils.js";

function Transport() {
  const [footerDestinations, setFooterDestinations] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  console.log(currentDateSingle);

  const [date, setDate] = useState(new Date());
  useEffect(() => {
    // const isVerifiedToken = localStorage.getItem('isVerified');
    const AccountStatus = localStorage.getItem("AccountStatus");
    // console.log(AccountStatus, "AccountStatus")
    // const role = localStorage.getItem('role');

    // console.log(isVerifiedToken, "token");
    // console.log(role, "role");

    if (AccountStatus === "disable") {
      console.error("Error: Invalid token or undefined for agent role");
      // showErrorToast("First Fill all required ")
      navigate("/agent-account");
    }
  }, [navigate]);

  const fetchDestinations = async () => {
    setLoader(true);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("token")
    // );

    const formData = new FormData();

    formData.append("limit", 10);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );
      const result = await response.json();
      setFooterDestinations(result?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false); // Ensure loader is turned off
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const hubs = [
    {
      id: 1,
      name: "Ex Chandigarh",
      status: true,
    },
    {
      id: 2,
      name: "Ex Delhi",
      status: true,
    },
    {
      id: 3,
      name: "Ex Amritsar",
      status: true,
    },
  ];

  var settings = {
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "40px",
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2.5,
          arrows: false,
        },
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  const handleSubmitButtonClickHandlerActivity = async (startDestination) => {

    // if (e) {
    //     e.preventDefault();
    // }

    setLoader(true);

    try {
      await navigate(
        `/transport/listing?startdestination=${startDestination}&noOfDays=2&date=${new Date(date).getDate()}&month=${new Date(date).getMonth() + 1}&year=${new Date(date).getFullYear()}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div id="transport">
      {/* <PageMetaData
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        description="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        keywords="Hotels In Himachal Pradesh"
      />

      <OgMetaTitles
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        type="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        url="https://www.mountainmysteries.com/accommodation/"
      /> */}
      <Header />
      <CustomSearchFormTransport
        topRadios={["Hotel", "B&B", "Camping", "HomeStay"]}
      />

      <div className="container">
        <div className="common_padding">
          <div className="flex justify_content_center align_items_end">
            <h3>Transport Destination</h3>
          </div>
        </div>
        {/* {hubs &&
            hubs?.map((item, index) => (
              <div className="destination_link transport_hubs_card" key={index}>
                {item.images && item.images.length > 0 ? (
                  <img src={item.images[0]} alt={item.name} />
                ) : (
                  <img src={imgNotFound} alt="Image not found" />
                )}
                <div className="destination_name">
                  <h6>{item.name}</h6>
                </div>
              </div>
            ))} */}

        <div className="images-flex">
          <Slider {...settings}>
            {footerDestinations?.map((items, index) => {
              return (
                <div
                  // onClick={() =>
                  //   scroll.scrollToTop({
                  //     duration: 500,
                  //     smooth: "easeInOutQuad",
                  //   })
                  // }
                  // to={`/destination/${items.name.toLowerCase()}`}
                  className="destination_link"
                  key={index}
                  onClick={() => handleSubmitButtonClickHandlerActivity(items?.name)}
                >

                  {items.images && items.images.length > 0 ?
                    <img src={items.images[0]} alt={items.alt} />
                    : <img src={imgNotFound} alt='Image not found' />
                  }

                  <div className="destination_name">
                    <h6>{items.name}</h6>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <Testimonial />
      <Highlights />
      <Footer />
    </div>
  );
}

export default Transport;
