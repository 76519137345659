import React, { useEffect } from "react";
import PageMetaData from "../../../CustomComponents/PageMetaData/PageMetaData";
import CustomerHeader from "../CustomerHeader/CustomerHeader";
import CustomerSideBar from "../CustomerSideBar/CustomerSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useState } from "react";
import Loader from "../../../CustomComponents/Loader/Loader";

function CustomerProfile() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialRole = queryParams.get("role") || "profile";
  const [activeTab, setActiveTab] = useState(initialRole);
  const [loader, setLoader] = useState(true);
  const [edit, setEdit] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const handleEditBtn = () => {
    setEdit(!edit);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    address: "",
    gst_information: "",
    company_information: ""
  });

  const navigate = useNavigate();
  useEffect(() => {
    const userType = localStorage.getItem("userType");

    if (userType === "agent") {
      navigate("/agent-account");
    }
  }, [navigate]);
  const userToken = localStorage.getItem("token");

  const [customerDataa, setCustomerData] = useState(null);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      setFormData(userDetails);
    }
  }, []);

  useEffect(() => {
    const role = queryParams.get("role") || "profile";
    setActiveTab(role);
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const profileFilter = (tab) => {
    setActiveTab(tab);
  };

  // const fetchCustomerDetails = () => {
  //   fetch(`${process.env.REACT_APP_APP_URL}/api/v1/customers/user-profile`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         setCustomerData(data.data.customer);
  //         setLoader(false);
  //         setFormData({
  //           name: data.data.customer.Name || "",
  //           phone: data.data.customer.PhoneNumber || "0",
  //           email: data.data.customer.Email || "",
  //         });
  //         localStorage.setItem(
  //           "userDetails",
  //           JSON.stringify(data.data.customer)
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
  //     });
  // };

  const fetchCustomerDetails = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${userToken}`
    );
    const cookie = process.env.REACT_APP_COOKIE || '';
    myHeaders.append("Cookie", cookie);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/user-profile`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setCustomerData(result.data);
        setLoader(false);
        setFormData({
          name: result.data.name || "",
          phone: result.data.phone || "",
          email: result.data.email || "",
          city: result.data.city || "",
          state: result.data.state || "",
          country: result.data.country || "",
          zipcode: result.data.zipcode || "",
          address: result.data.address || "",
          gst_information: result.data.additional_details?.gst || "",
          company_information: result.data.additional_details?.company || "",
        });
        localStorage.setItem(
          "userDetails",
          JSON.stringify(result.data)
        );
      } else {
        showErrorToast(result.message || "User Profile not fetched successfully..")
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const cust_id = customerDataa?.AgentId;
    const valid = validate();
    setTimeout(() => {
      setFormErrors({})
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/customer/update/${cust_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      // .then((data) => {
      //   console.log(data, "datatta")
      //   setFormData({
      //     name: data.data.name || "",
      //     phone: data.data.phone || "0",
      //     email: data.data.email || "",
      //   });
      //   localStorage.setItem("userDetails", JSON.stringify(data.data));
      //   showSuccessToast("Profile Updated Successfully")
      //   navigate("/");
      // })
      // .catch((error) => {
      //   showErrorToast("Unable to update data");
      //   console.error(
      //     "There has been a problem with your fetch operation:",
      //     error
      //   );
      // });
      .then((data) => {
        setFormData({
          name: data.data.name || "",
          phone: data.data.phone || "",
          email: data.data.email || "",
        });
        localStorage.setItem("userDetails", JSON.stringify(data.data));
        showSuccessToast("Profile Updated Successfully");
        // navigate("/");
        window.location.href = "/";
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cust_id = customerDataa?.AgentId;
    const valid = validate();
    setTimeout(() => {
      setFormErrors({})
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const cookie = process.env.REACT_APP_COOKIE || '';
    if (cookie) {
      myHeaders.append("Cookie", cookie);
    }
    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/update`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        showSuccessToast(result.message || "Profile Updated Successfully");
        setEdit(true);

        // setFormData({
        //   name: result.data.name || "",
        //   phone: result.data.phone || "",
        //   email: result.data.email || "",
        //   city: result.data.city || "",
        //   state: result.data.state || "",
        //   country: result.data.country || "",
        //   zipcode: result.data.zipcode || "",
        //   address: result.data.address || "",
        //   gst_information: result.data.gst_information || "",
        //   company_information: result.data.company_information || "",
        // });
        localStorage.setItem("userDetails", JSON.stringify(result.data));
        navigate("/customer-profile");
        // window.location.href = "/";

      } else {
        const errorText = await response.text();
        console.error("Failed to update customer details:", errorText);
      }
    } catch (error) {
      console.error("Error updating customer details:", error);
    }
  };


  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "required";
    }
    if (!formData.email.trim()) {
      errors.email = "required";
    }
    if (!formData.phone.trim()) {
      errors.phone_number = "is required";
    }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <div id="Listingholidays">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <CustomerHeader />
      <div className="sidebar_main_div">
        <CustomerSideBar />
        <section className="w-full p-sm">
          <div id="common_background_profile m_20">
            <h4>My Profile</h4>{" "}
            <div className="common_background mt-10">
              {loader ? (
                <Loader size="4em" color="#016932" />
              ) : (
                <>
                  {edit ? (
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex_gap_10">
                        <h6>Your Name : </h6>
                        <span>{formData?.name}</span>
                      </div>
                      <div className="flex flex_gap_10 mt-10">
                        <h6>Phone Number : </h6>
                        <span>{formData?.phone}</span>
                      </div>
                      <div className="flex flex_gap_10 mt-10">
                        <h6>Email : </h6>
                        <span>{formData?.email}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>City : </h6>
                        <span>{formData?.city}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>State : </h6>
                        <span>{formData?.state}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>Country : </h6>
                        <span>{formData?.country}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>Zipcode : </h6>
                        <span>{formData?.zipcode}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>Address : </h6>
                        <span>{formData?.address}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>GST Information : </h6>
                        <span>{formData?.gst_information}</span>
                      </div>
                      <div className="flex flex_gap_10">
                        <h6>Company Information : </h6>
                        <span>{formData?.company_information}</span>
                      </div>
                      <div className="button">
                        <button
                          className="primary_btn search-btn-width mt-10"
                          onClick={handleEditBtn}
                        >
                          Edit
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="input-flex">
                        <div>
                          <label htmlFor="name">Your Name</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="name"
                            value={formData.name || ""}
                            style={
                              formErrors.name && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>

                        <div>
                          <label htmlFor="phone">Phone</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="phone"
                            value={formData.phone}
                            style={
                              formErrors.phone && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>

                        <div>
                          <label htmlFor="email">Email Address</label>
                          <input
                            className="w-full"
                            type="email"
                            onChange={handleInputChange}
                            name="email"
                            value={formData.email || ""}
                            style={
                              formErrors.email && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                      </div>
                      <div className="input-flex">
                        <div>
                          <label htmlFor="phone">Address</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="address"
                            value={formData.address}
                            style={
                              formErrors.adress && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="email">Country</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="country"
                            value={formData.country || ""}
                            style={
                              formErrors.country && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="state">State</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="state"
                            value={formData.state}
                            style={
                              formErrors.state && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="city">City</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="city"
                            value={formData.city}
                            style={
                              formErrors.city && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                      </div>
                      <div className="input-flex">
                        <div>
                          <label htmlFor="zipcode">Zipcode</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="zipcode"
                            value={formData.zipcode}
                            style={
                              formErrors.zipcode && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="gst_information">GST Information</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="gst_information"
                            value={formData.gst_information}
                            style={
                              formErrors.gst_information && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="company_information">Company Information</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="company_information"
                            value={formData.company_information || ""}
                            style={
                              formErrors.company_information && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                      </div>
                      <div className="button">
                        <button
                          className="primary_btn search-btn-width mt-10"
                          type="submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CustomerProfile;
