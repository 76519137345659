import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../SingleSearchForm/SingleSearchForm.css";
import Flatpickr from "react-flatpickr";
import MultiSelectSearch from "../../NewSearchSateInput/MultiSelectSearch.jsx";
import NewSearchSateInput from "../../NewSearchSateInput/NewSearchSateInput.jsx";
import { calculateToDatePlus } from "../../../utils/utils.js";
import NewSearchSateInputSecound from "../../NewSearchSateInput/NewSearchSateInputSecound";

function ListingTransportSearchForm({ includingDestinations, searchType, singleHotelPage, singleHotelData }) {
    const [modifyPkg, setModiyPkg] = useState(false);
    const [mobileHead, setMobileHead] = useState(false);
    const toggleModify = () => {
        setModiyPkg(!modifyPkg);
    };
    const [startDestination, setStartDestination] = useState("");
    const [endDestination, setEndDestination] = useState("");
    const [noOfDays, setNoOfDays] = useState(null);
    const [date, setDate] = useState(new Date());
    const [destinationToggle, setdestinationToggle] = useState(false);

    const getDataFromChild = (data, resultArray) => {
        setStartDestination(data);
        // setStateResultArray(resultArray)
        // console.log(resultArray);
    };

    const getDataFromChildForToCity = (data, resultArray, selectedType) => {
        setEndDestination(data);
        // setSelectedType(selectedType);
    };

    const [guestDetails, setIsguestDetails] = useState(false);
    const dropdownGuestDetails = () => {
        setIsguestDetails(!guestDetails);
    };

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [data, setData] = useState({ fromCity: "", toCity: "" });

    const showErrorToast = (message) => {
        alert(message);
    };

    const [bookingData, setBookingData] = useState({});
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const navigate = useNavigate();

    // Date alterations
    const [loader, setLoader] = useState(false);

    const dropdownRef = useRef(null);

    const popupsRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupsRef.current && !popupsRef.current.contains(event.target)) {
                setIsguestDetails(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleSubmitButtonClickHandler = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoader(true);

        try {
            await navigate(
                `/transport/listing?startdestination=${startDestination}&noOfDays=${noOfDays}&date=${new Date(date).getDate()}&month=${new Date(date).getMonth() + 1}&year=${new Date(date).getFullYear()}`,
                {
                    state: {
                        includingDestinations: selectedMultipleDestinations,
                    },
                },
                undefined,
                { shallow: false }
            );
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        } finally {
            setLoader(false);
        }
    };

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateDimensions = () => {
        // console.log("window.innerWidth", window.innerWidth);

        if (width > 640) {
            setMobileHead(false);
        } else {
            setMobileHead(true);
        }
    };

    useEffect(() => {
        // Call the function on initial render
        // updateDimensions();

        // Add event listener for window resize
        window.addEventListener("resize", updateDimensions);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener("resize", updateDimensions);
    }, [width]);

    const [selectedMultipleDestinations, setSelectedMultipleDestinations] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const data = {
            adult: params.get("adult"),
            date: params.get("date"),
            year: params.get("year"),
            month: params.get("month"),
            noOfDays: params.get("noOfDays"),
            startDestination: params.get("startdestination"),
            endDestination: params.get("enddestination"),
        };

        const travelDate = `${data.year}-${String(data.month).padStart(
            2,
            "0"
        )}-${String(data.date).padStart(2, "0")}`;
        setBookingData({ ...data, travelDate });
        setDate(travelDate);
        setNoOfDays(data?.noOfDays);
        setStartDestination(data?.startDestination);
        setEndDestination(data?.endDestination);

        if (includingDestinations?.length) {
            setSelectedMultipleDestinations(includingDestinations);
        }
    }, [location.search, includingDestinations]);

    const handleMultiDestinationsChange = (selected) => {
        setSelectedMultipleDestinations(selected);
    };

    return (
        <section className="checkout-hotels">
            <div className="test_form" id="singleSearch">
                <div className="container">
                    <form
                        action=""
                        className="SingleSearch-mainform test_form main-form"
                        id="main-form"
                        onSubmit={handleSubmitButtonClickHandler}
                    >
                        {/* {width} */}
                        {width <= 640 ? (
                            <>
                                <div className="SingleSearch-Resp flex_align_center justify_content_between">
                                    <div className="flex_align_center">
                                        <span onClick={() => window.history.back()}>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                                                    fill="#333333"
                                                />
                                            </svg>
                                        </span>
                                        <div className="SingleSearch-resp_content">
                                            <h4>
                                                <p>Enter destinations</p>
                                            </h4>
                                            <div>
                                                <span>{startDestination}</span>
                                                <span> | </span>
                                                <span>
                                                    {endDestination}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={toggleModify}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_2797_7470"
                                                style={{ maskType: "alpha" }}
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_2797_7470)">
                                                <path
                                                    d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                                                    fill="#333333"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                {modifyPkg && (
                                    <>
                                        <div>
                                            <label htmlFor="">From City/Destination</label>
                                            {/* <select
                                                className="w-full testform_input"
                                                name="hub"
                                                value={hub}
                                                onChange={(e) => setHub(e.target.value)}
                                            >
                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                {hubs && hubs.map((item, index) => (
                                                    <option value={item.name} key={index}>{item.name}</option>
                                                ))}
                                            </select> */}
                                            <NewSearchSateInput
                                                onClick={() => setdestinationToggle(true)}
                                                onDataChange={getDataFromChild}
                                                dropdownGuestDetails={dropdownGuestDetails}
                                                setIsguestDetails={setIsguestDetails}
                                                type="destination"
                                                className={'testform_input'}
                                            />
                                        </div>
                                        {/* <div className="">
                                            <label htmlFor="">To City/Destination</label>
                                            <NewSearchSateInputSecound
                                                onClick={() => setdestinationToggle(true)}
                                                onDataChange={getDataFromChildForToCity}
                                                dropdownGuestDetails={dropdownGuestDetails}
                                                setIsguestDetails={setIsguestDetails}
                                                type="toDestination"
                                                className={'testform_input'}
                                            />
                                        </div> */}
                                        {/* <div className="">
                                            <label htmlFor="">Including Destinations</label>
                                            <MultiSelectSearch
                                                type="transport"
                                                onClick={() => setdestinationToggle(true)}
                                                onMultiSelectionChange={handleMultiDestinationsChange}
                                                defaultSelected={selectedMultipleDestinations}
                                                className={'testform_input'}
                                            />
                                            
                                        </div> */}
                                        <div className="search_form_div">
                                            <label htmlFor="">No. of Days</label>
                                            <input
                                                type="number"
                                                value={noOfDays}
                                                onChange={(e) => setNoOfDays(e.target.value)}
                                                className="testform_input"
                                            />
                                        </div>
                                        <div className="search_form_div p_0 icon_button_only">
                                            <label>Departure Date</label>
                                            <Flatpickr
                                                className=" w_full m_0 testform_input"
                                                color="#ffffff"
                                                value={date}
                                                onChange={([date]) => setDate(date)}
                                                min={new Date()}
                                                // f
                                                options={{
                                                    minDate: "today",
                                                }}
                                            />
                                        </div>
                                        <div className="search-btn-width">
                                            <button className="common_btn" type="submit">
                                                SEARCH
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div>
                                    <label htmlFor="">From City/Destination</label>
                                    <NewSearchSateInput
                                        onClick={() => setdestinationToggle(true)}
                                        onDataChange={getDataFromChild}
                                        dropdownGuestDetails={dropdownGuestDetails}
                                        setIsguestDetails={setIsguestDetails}
                                        type="package"
                                        package_type="transport"
                                        className={'testform_input'}
                                    />
                                </div>
                                {/* <div className="" style={{ width: '195px' }}>
                                    <label htmlFor="">To City/Destination</label>
                                    <NewSearchSateInputSecound
                                        onClick={() => setdestinationToggle(true)}
                                        onDataChange={getDataFromChildForToCity}
                                        dropdownGuestDetails={dropdownGuestDetails}
                                        setIsguestDetails={setIsguestDetails}
                                        type="toDestination"
                                        className={'testform_input'}
                                    />
                                </div> */}
                                {/* <div className="search_form_div p_0 icon_button_only">
                                    <label htmlFor="">Including Destinations</label>
                                    <MultiSelectSearch
                                        type="transport"
                                        onClick={() => setdestinationToggle(true)}
                                        onMultiSelectionChange={handleMultiDestinationsChange}
                                        className={'testform_input'}
                                        defaultSelected={selectedMultipleDestinations}
                                    />
                                </div> */}
                                <div className="search_form_div">
                                    <label htmlFor="">No. of Days</label>
                                    <input
                                        type="number"
                                        value={noOfDays}
                                        onChange={(e) => setNoOfDays(e.target.value)}
                                        className="testform_input"
                                    />
                                </div>
                                <div className="search_form_div p_0 icon_button_only">
                                    <label>Departure Date</label>
                                    <Flatpickr
                                        className=" testform_input"
                                        color="#ffffff"
                                        value={date}
                                        onChange={([date]) => setDate(date)}
                                        min={new Date()}
                                        // f
                                        options={{
                                            minDate: "today",
                                        }}
                                    />
                                </div>
                                <div className="search-btn-width">
                                    <button className="common_btn" type="submit">
                                        SEARCH
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
                <input type="hidden" name="hotelId" value="{{ $hotelId }}" />
            </div>
        </section>
    );
}

export default ListingTransportSearchForm;
