import React, { useEffect, useState } from "react";
import "../BasicInformation/BasicInformation.css";
import Locations from "../Locations/Locations";
import AddressAutocomplete from "../../CustomGooglePlaceAPi/AddressAutocomplete";
import { Navigate, useNavigate } from "react-router-dom";
import { AccommodationCategoryOptions, AccommodationStarRatingOptions, AccommodationTypeOptions } from "../../../constants";

const BasicInformation = ({
  setActiveTab,
  activeTab,
  propertyData,
  handleInputChange,
  handleNextClick,
  addressData,
  handleChangeAddressData,
  onSubmitForm,
  setFormErrors,
  formErrors,
}) => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="basic-info-container">
        <div className="flex_align_center flex_gap_20">
          <span onClick={() => navigate(-1)} className="back_btn">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                fill="#333333"
              />
            </svg>
          </span>
          <div>
            <h3 className="text-left">Add Your Property</h3>
            <p>Please fill the details of your property.</p>
          </div>
        </div>
        <form onSubmit={onSubmitForm}>
          <div className="bg-white-div">
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Property Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Property Name"
                  onChange={handleInputChange}
                  name="name"
                  value={propertyData.name}
                  style={
                    formErrors.name && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                />
              </div>

              <div className="w-half">
                <label htmlFor="rating">
                  Rating <span className="required">*</span>
                </label>
                <select
                  name="rating"
                  id="rating"
                  value={propertyData.rating}
                  onChange={handleInputChange}
                  style={
                    formErrors.rating && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  {AccommodationStarRatingOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label} Star
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-half">
                <label htmlFor="rating">
                  Type <span className="required">*</span>
                </label>
                <select
                  name="type"
                  id="type"
                  value={propertyData.type}
                  onChange={handleInputChange}
                  style={
                    formErrors.type && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  {AccommodationTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="rating">
                  Featured <span className="required">*</span>
                </label>
                <select
                  name="featured"
                  id="type"
                  value={propertyData.featured}
                  onChange={handleInputChange}
                  style={
                    formErrors.featured && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="w-half">
                <label htmlFor="rating">
                  Online <span className="required">*</span>
                </label>
                <select
                  name="online"
                  id="type"
                  value={propertyData.online}
                  onChange={handleInputChange}
                  style={
                    formErrors.online && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="w-half">
                <label htmlFor="rating">
                  Category <span className="required">*</span>
                </label>
                <select
                  name="category"
                  id="type"
                  value={propertyData.category}
                  onChange={handleInputChange}
                  style={
                    formErrors.category && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  {AccommodationCategoryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-half">
                <label htmlFor="rating">
                  Status <span className="required">*</span>
                </label>
                <select
                  name="status"
                  id="type"
                  value={propertyData.status}
                  onChange={handleInputChange}
                  style={
                    formErrors.status && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="draft">Draft</option>
                </select>
              </div>
            </div>
            <div className="flex flex_gap_10 w-full">
              {/* <div className='w-half'>
                                <label htmlFor="">When was this property Built?</label>
                                <select name="property_built" id="" onChange={handleInputChange}>
                                    <option value="">Select</option>
                                    <option value="1800-1900">1800-1900</option>
                                    <option value="1900-2000">1900-2000</option>
                                    <option value="2000-2010">2000-2010</option>
                                    <option value="2010-2020">2010-2020</option>
                                    <option value="2020-2024">2020-2024</option>
                                </select>
                            </div> */}
            </div>
            {/* <div className='flex flex_gap_10 w-full'>
                            <div className='w-half'>
                                <label htmlFor="">Built Year</label>
                                <input type="number" onChange={handleInputChange} name='built_year' value={propertyData.built_year} />
                            </div>
                            <div className='w-half'>
                                <label htmlFor="">No. Of Rooms<span className='required'>*</span></label>
                                <input type="number" onChange={handleInputChange} name='no_of_rooms' value={propertyData.no_of_rooms} required />
                            </div>
                        </div> */}
            {/* <div className='flex flex_gap_10 w-full'>
                            <div className='w-half'>
                                <label htmlFor="">No. of Restaurant</label>
                                <input type="number" onChange={handleInputChange} name='resturant' value={propertyData.resturant} />
                            </div>
                            <div className='w-half'>
                                <label htmlFor="">No. Of Floors</label>
                                <input type="number" onChange={handleInputChange} name='no_of_floors' value={propertyData.no_of_floors} />
                            </div>
                        </div> */}
            {/* <div className='flex flex_gap_10 w-full'>
                            <div className='w-half'>
                                <label htmlFor="">Currency</label>
                                <input type="text" onChange={handleInputChange} name='currency' value={propertyData.currency} />
                            </div>
                            <div className='w-half'>
                                <label htmlFor="">VCC Currency</label>
                                <input type="text" onChange={handleInputChange} name='vcc_currency' value={propertyData.vcc_currency} />
                            </div>
                            <div className='w-half'>
                                <label htmlFor="">Timezone</label>
                                <input type="text" onChange={handleInputChange} name='time_zone' value={propertyData.time_zone} />
                            </div>
                        </div> */}
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">Check-in Time</label>
                <input name="check_in_time_information" value={propertyData.check_in_time_information}   onChange={handleInputChange} type="time"  id="" />
                {/* <select
                  name="check_in_time_information"
                  id=""
                  onChange={handleInputChange}
                  style={
                    formErrors.check_in_time_information && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  <option value="06:00 am">06:00 am</option>
                  <option value="06:30 am">06:30 am</option>
                  <option value="07:00 am">07:00 am</option>
                  <option value="07:30 am">07:30 am</option>
                  <option value="08:00 am">08:00 am</option>
                  <option value="08:30 am">08:30 am</option>
                  <option value="09:00 am">09:00 am</option>
                  <option value="09:30 am">09:30 am</option>
                  <option value="10:00 am">10:00 am</option>
                  <option value="10:30 am">10:30 am</option>
                  <option value="11:00 am">11:00 am</option>
                  <option value="11:30 am">11:30 am</option>
                  <option value="12:00 pm (noon)">12:00 pm (noon)</option>
                  <option value="12:30 pm">12:30 pm</option>
                  <option value="01:00 pm">01:00 pm</option>
                  <option value="01:30 pm">01:30 pm</option>
                  <option value="02:00 pm">02:00 pm</option>
                  <option value="02:30 pm">02:30 pm</option>
                  <option value="03:00 pm">03:00 pm</option>
                  <option value="03:30 pm">03:30 pm</option>
                  <option value="04:00 pm">04:00 pm</option>
                </select> */}
              </div>
              <div className="w-half">
                <label htmlFor="">Check-out Before</label>
                <input name="check_out_time_information" value={propertyData.check_out_time_information}  onChange={handleInputChange} type="time"  id="" />
                {/* <select
                  name="check_out_time_information"
                  id=""
                  onChange={handleInputChange}
                  style={
                    formErrors.check_out_time_information && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select</option>
                  <option value="06:00 am">06:00 am</option>
                  <option value="06:30 am">06:30 am</option>
                  <option value="07:00 am">07:00 am</option>
                  <option value="07:30 am">07:30 am</option>
                  <option value="08:00 am">08:00 am</option>
                  <option value="08:30 am">08:30 am</option>
                  <option value="09:00 am">09:00 am</option>
                  <option value="09:30 am">09:30 am</option>
                  <option value="10:00 am">10:00 am</option>
                  <option value="10:30 am">10:30 am</option>
                  <option value="11:00 am">11:00 am</option>
                  <option value="11:30 am">11:30 am</option>
                  <option value="12:00 pm (noon)">12:00 pm (noon)</option>
                </select> */}
              </div>
            </div>
            <div>
              <label htmlFor=""> Description</label>
              <textarea
                name="content"
                onChange={handleInputChange}
                value={propertyData.content}
                id=""
                className="w-full"
                placeholder="Type here..."
                style={
                  formErrors.content && {
                    backgroundColor: "#ff000040",
                    border: "1px solid red",
                  }
                }
              ></textarea>
            </div>
            {/* <div className='w-half'>
                            <label htmlFor="">  Do you work with Channel Manager?</label>
                            <div className="flex flex_gap_5 mt-10">
                                <div className='flex border-radius border radio-btn'><input type="radio" checked={propertyData?.channel_manager === "true"} name='channel_manager' value="true" onChange={handleInputChange} /> <label htmlFor="" className='m_0'>Yes</label></div>
                                <div className='flex border-radius border radio-btn'><input type="radio" checked={propertyData?.channel_manager === "false"} name='channel_manager' className='radio-btn' value="false" onChange={handleInputChange} /> <label htmlFor="" className='m_0'>No</label></div>
                            </div>
                        </div> */}
          </div>
          <div className="bg-white-div mt-10">
            <h4>Contact Details</h4>
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Mobile Number <span className="required">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Contact Number"
                  onChange={handleInputChange}
                  name="phone"
                  value={propertyData.phone}
                  style={
                    formErrors.phone && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                />
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  onChange={handleInputChange}
                  name="email"
                  value={propertyData.email}
                  style={
                    formErrors.email && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                />
              </div>
            </div>
            {/* <div className="flex justify_content_end mt-10">
                            <button type='submit' className="primary_btn" style={{ width: "70px" }}>Save</button>
                        </div> */}
          </div>

          <div className="bg-white-div mt-10">
            <h4 className="mb-10">Address Details</h4>
            <AddressAutocomplete
              formdata={addressData}
              onAddressChange={handleChangeAddressData}
            />
          </div>
          {/* <div className="flex flex_gap_10 mt-10">
                            <span>Latitude: 965423.0 </span>
                            <span>longitude: 8746532 </span>
                        </div> */}
          <div className="flex justify_content_end mt-10">
            <button
              type="submit"
              className="primary_btn"
              style={{ width: "70px" }}
            >
              Save
            </button>
          </div>
        </form>
        {/* <Locations /> */}
      </div>
    </section>
  );
};

export default BasicInformation;
