import React, { useState } from "react";
import styles from "./NewMultipleImageUploadComponent.module.scss";
 
const DocumentList = ({ documents }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
 
  const handleDocumentClick = (url) => {
    setSelectedDocument(url);
  };
 
  return (
    <div className={styles.documentListContainer}>
      <ul className={styles.documentList}>
        {Object.values(documents).map((doc) => (
          <li
            key={doc.uuid}
            className={styles.documentItem}
            onClick={() => handleDocumentClick(doc.original_url)}
          >
            <div className={styles.documentInfo}>
              <span className={styles.documentName}>{doc.name}</span>
              <span className={styles.documentSize}>
                {(doc.size / 1024).toFixed(2)} KB
              </span>
            </div>
          </li>
        ))}
      </ul>
 
      {selectedDocument && (
        <div className={styles.documentPreview}>
          <div
            className={styles.documentPreviewOverlay}
            onClick={() => setSelectedDocument(null)}
          />
          <iframe
            src={selectedDocument}
            title="Document Preview"
            className={styles.documentIframe}
          />
 
          <button
            onClick={() => setSelectedDocument(null)}
            className={styles.deleteBtn}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};
 
export default DocumentList;