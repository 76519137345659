import React, { useEffect, useRef, useState } from "react";
import "./SearchStateInput.css";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import BgBackdrop from "../BgBackDrop/BgBackDrop";

function SearchStateInput({
  onDataChange,
  onClick,
  dropdownGuestDetails,
  setIsguestDetails,
}) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const [SearchState, setSearchState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [resultArray, setResultArray] = useState([]);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setResultArray([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const fetchDestination = async (destination) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_API_KEY);
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("token")
  //   );

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_APP_URL}/api/v1/destinations/fetch`,
  //       requestOptions
  //     );

  //     const result = await response.json();

  //     if (result.success) {
  //       setSearchState(result?.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     SkeletonLoader(false);
  //   }
  // };

  const fetchDestination = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      if (result.success) {
        setSearchState(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      SkeletonLoader(false);
    }
  };

  useEffect(() => {
    fetchDestination();
  }, []);

  const handleOnStateChange = (e) => {
    setSelectedState(e.target.value);
    const { value } = e.target;
    const filteredArray = SearchState.filter((item) =>
      item?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setResultArray(filteredArray);
  };
  
  onDataChange(selectedState, resultArray);

  const handleKeyDown = (event) => {
    if (resultArray.length === 0) return;

    if (event.key === "ArrowDown") {
      setActiveIndex((prevIndex) =>
        prevIndex < resultArray.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "Enter" && activeIndex >= 0) {
      const selectedItem = resultArray[activeIndex];
      setSelectedState(selectedItem.name);
      // setSelectedType(selectedItem);
      setResultArray([]);
      setIsguestDetails(true);

      // setSelectedState(state?.name),
      // setResultArray([]),
      // setIsguestDetails(true),
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeIndex, resultArray]);

  return (
    <>
      <div className="search-location-input relative">
        <input
          onClick={onClick}
          className="search_form_input pac-target-input"
          placeholder="Search Places.."
          value={selectedState}
          onChange={handleOnStateChange}
          required
        />

        {resultArray?.length > 0 && (
          <div className="search_list" ref={dropdownRef}>
            {resultArray?.map((state, index) => (
              <div
                style={{
                  backgroundColor: index === activeIndex ? "#f0f0f0" : "",
                }}
                key={index}
                onClick={() => [
                  setSelectedState(state?.name),
                  setResultArray([]),
                  setIsguestDetails(true),
                ]}
              >
                <span>{state?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* {dimmerOpen && (
        <>
          <BgBackdrop />
        </>
      )} */}
    </>
  );
}

export default SearchStateInput;
