import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer.jsx";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData.jsx";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles.jsx";
import CustomSearchFormTransport from "../../CustomComponents/SearchForm/CustomSearchFormActivities/CustomSearchFormTransport.jsx";
import Header from "../../CustomComponents/Header/Header.jsx";
import imgNotFound from "../../assets/images/single-image.png";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial.jsx";
import Highlights from "../../CustomComponents/Highlights/Highlights.jsx";
import FilterActivities from "../../CustomComponents/FilterSection/FilterActivities.jsx";
import { formatIndianCurrency, scrollToTop } from "../../utils/utils.js";
import FilterTransport from "../../CustomComponents/FilterSection/FilterTransport.jsx";
import "./Transport.css";
import ListingTransportSearchForm from "../../CustomComponents/SearchForm/CustomSearchFormActivities/ListingTransportSearchForm.jsx";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination.jsx";

function ListingTransportItinerary() {
  const [footerDestinations, setFooterDestinations] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [packageData, setPackageData] = useState([]);

  const [startDestination, setStartDestination] = useState("");
  const [endDestination, setEndDestination] = useState("");
  const [noOfDays, setNoOfDays] = useState(null);
  const [date, setDate] = useState(new Date());

  const { includingDestinations } = location?.state || {};
  const [loader, setLoader] = useState(false);
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const data = {
      adult: params.get("adult"),
      date: params.get("date"),
      year: params.get("year"),
      month: params.get("month"),
      noOfDays: params.get("noOfDays"),
      startDestination: params.get("startdestination"),
      // endDestination: params.get("enddestination"),
    };

    const travelDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;
    setBookingData({ ...data, travelDate });
    fetchPackageData(data);

    setDate(travelDate);
    setNoOfDays(data?.noOfDays);
    setStartDestination(data?.startDestination);
    // setEndDestination(data?.endDestination);
  }, [location.search]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    packageData && packageData && packageData.length > 0
      ? packageData.slice(startIndex, endIndex)
      : [];
  const [packageRates, setPackageRates] = useState({});


  const fetchPackageData = async (data, filters) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    const formdata = new FormData();
    {
      data.startDestination &&
        formdata.append("location", data.startDestination ? data.startDestination : "Shimla");
    }
    formdata.append("type", 'transport')

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch?destination=${filters?.destinationType ? filters?.destinationType : ""
        }`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);
      setPackageData(result.data);

      // const rates = {};
      // for (const pkg of result.data) {
      //   const rateData = await getRatesFromApi(
      //     pkg.packageHub,
      //     pkg.packageDestination,
      //     pkg.totalDays,
      //     pkg.totalNights,
      //     "sedan"
      //   );
      //   rates[pkg.id] = rateData;
      // }
      // setPackageRates(rates);

      scrollToTop();
    } catch (error) {
      setPackageData([]);
      scrollToTop();
      setLoader(false);
      console.error(error);
    } finally {
      scrollToTop();
      setLoader(false);
    }
  };

  const getRatesFromApi = async (
    packageHub,
    packageDestination,
    totalDays,
    totalNights,
    selectedVehicle
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const destinationNames = packageDestination?.map((dest) => dest.name);

    const formdata = new FormData();
    formdata.append("hub", packageHub);
    destinationNames?.forEach((name) => {
      formdata.append("destinations[]", name);
    });
    formdata.append("days", totalDays);
    formdata.append("nights", totalNights);
    formdata.append("vehicle_type", selectedVehicle);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/transport-fixed-rates/fetch`,
        requestOptions
      );
      const result = await response.json();
      return result?.data[0];
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  const sendDataToParent = (data) => {
    if (data.destinationType.length > 0) {
      fetchPackageData(bookingData, data);
    }
    // console.log(data, filters);
    // console.log(data);
  };

  const navigateToSingleTransport = (item) => {
    navigate(
      `/transport/${item.packagehandle
      }?startdestination=${startDestination}&noOfDays=${noOfDays}&date=${new Date(
        date
      ).getDate()}&month=${new Date(date).getMonth() + 1}&year=${new Date(
        date
      ).getFullYear()}`,
      {
        state: {
          transportData: item,
          bookingData: bookingData,
          // activityPrice: displayedRate,
          // selectedAddOns: selectedAddOns,
          // totalAddonsCost: totalAddonsCost,
          // totalAddonsCostWithoutCommission: totalAddonsCostWithoutCommission,
          // markupData: markupData,
          // activityPriceWithMarkup, activityPriceWithMarkup,
        },
      }
    );
  };

  return (
    <div id="transport">
      {/* <PageMetaData
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        description="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        keywords="Hotels In Himachal Pradesh"
      />

      <OgMetaTitles
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        type="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        url="https://www.mountainmysteries.com/accommodation/"
      /> */}
      <Header />
      <ListingTransportSearchForm
        includingDestinations={includingDestinations}
      />
      <div className="common_bg common_padding">
        <div className="container ">
          {/* <FilterActivities
                        sendDataToParent={sendDataToParent}
                        showDestinations={true}
                    /> */}
          <main>
            <FilterTransport sendDataToParent={sendDataToParent} />
            <div className="listing-right-container">
              <div className="flex align_items_center flex_wrap justify_content_between">
                <h3
                  style={{ textTransform: "capitalize" }}
                  className="text-left mb-10"
                >
                  Showing All Transport
                </h3>
              </div>
              <div className="transport_listing_page flex flex_direction_col flex_gap_10">
                <div className="cards-flex">
                  {displayedData &&
                    displayedData.length > 0 &&
                    displayedData?.map((item, index) => (
                      <div
                        className=" card-listing"
                        style={{
                          background: "none",
                          borderRadius: "8px",
                          marginBottom: "0px",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          padding: "0px",
                        }}
                        key={index}
                      >
                        {item.featuredImage ? (
                          <img src={item.featuredImage} alt={item.packageName} />
                        ) : (
                          <img src={imgNotFound} alt="Image not found" />
                        )}
                        <div
                          className="card_content"
                          style={{ marginTop: "0px" }}
                        >
                          <h6>{item?.packageName}</h6>
                          <p className="m_0">{item.packageHub}</p>

                          <div className="flex justify_content_between align_items_center">
                            {/* <div>
                              <h4>
                                {packageRates[item.id]
                                  ? formatIndianCurrency(packageRates[item.id]?.rate)
                                  : "Fetching rates..."}
                              </h4>
                              <p
                                className="p_0 m_0"
                                style={{
                                  fontSize: "10px",
                                  //   textAlign: "right",
                                  color: "#000000",
                                }}
                              >
                                + GST (%)
                              </p>
                            </div> */}
                            <div className="flex align_items_center">
                              <button
                                onClick={() => navigateToSingleTransport(item)}
                                style={{ width: "auto", marginTop: "10px" }}
                                className="primary_btn w-auto"
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="communication_pagination">
                  <CustomPagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={packageData?.length}
                    onPageChange={handlePageChange}
                    clearSelection={clearSelection}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ListingTransportItinerary;
