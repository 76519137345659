import React, { useEffect, useState } from "react";
import "./ActivePropertySelectBox.css";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const ActivePropertySelectBox = () => {
  const [allProperties, setAllProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const vendorToken = localStorage.getItem("token");

  const navigate = useNavigate();
  const location = useLocation();

  const { id: propertyId } = useParams();

  // console.log(propertyId);

  useEffect(() => {
    if (propertyId && allProperties.length > 0) {
      const findSelectedProperty = allProperties.find(
        (property) => property.id == propertyId
      );
      setSelectedProperty(findSelectedProperty?.id);
    } else {
      setSelectedProperty(propertyId);
    }
  }, [propertyId, allProperties]);

  // console.log(selectedProperty);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  //   console.log(userDetails);

  // const getProperties = (id) => {
  //   const requestOptions = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-accommodation/${id}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log("result", result);

  //       if (result.success) {
  //         setAllProperties(result?.hotels);
  //       } else {
  //         setAllProperties([]);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // };

  const getProperties = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${vendorToken}`
      );
      myHeaders.append(
        "Cookie", process.env.REACT_APP_COOKIE
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodations/fetch`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      setAllProperties(result?.hotels);
    } catch (error) {
      console.error("Error fetching accommodations:", error);
    }
  };

  useEffect(() => {
    if (userDetails.id) {
      getProperties(userDetails.id);
    }
  }, [userDetails.id]);

  //   useEffect(() => {
  //     const currentPath = location.pathname;
  //     const newPath = currentPath.replace(/\/[^/]*$/, `/${selectedProperty}`);
  //     navigate(newPath);
  // }, [selectedProperty, location.pathname, navigate]);

  const handleOnchange = (e) => {
    // console.log(e.target.value);
    const property = e.target.value;

    setSelectedProperty(property);
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/\/[^/]*$/, `/${property}`);
    navigate(newPath);
  };
  return (
    <div className="p-5 ">
      <select onChange={handleOnchange} value={selectedProperty}>
        <option value="">Select Option</option>

        {allProperties.map((property, index) => (
          <option key={index} value={property.id}>
            {property?.name.length > 25
              ? `${property?.name.slice(0, 20)}...`
              : property?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ActivePropertySelectBox;
