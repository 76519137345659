import React, { useState } from "react";
import Footer from "../../CustomComponents/Footer/Footer.jsx";
// import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks.jsx";
import Highlights from "../../CustomComponents/Highlights/Highlights.jsx";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial.jsx";
import ImageSlider from "../../CustomComponents/ImageSlider/ImageSlider.jsx";
import FilterPackages from "../../CustomComponents/FilterPackages/FilterPackages.jsx";
import BannerMain from "../../CustomComponents/BannerMain/BannerMain.jsx";
import CustomSearchFormHotels from "../../CustomComponents/SearchForm/CustomSearchFormHotels/CustomSearchFormHotels.jsx";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData.jsx";
import { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AllDestinationsSlider from "../../CustomComponents/AllDestinationsSlider/AllDestinationsSlider.jsx";
import ComplexMarkerIcons from "../../CustomComponents/ComplexMarkerIcons/ComplexMarkerIcons.jsx";
import Loader from "../../CustomComponents/Loader/Loader.jsx";
import Skeleton from "react-loading-skeleton";
import Themes from "../../CustomComponents/Themes/Themes.jsx";
import NewHolidaysSearchForm from "../../CustomComponents/SearchForm/NewHolidaysSearchForm/NewHolidaysSearchForm.jsx";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles.jsx";
import BreadcrumbSchema from "../../CustomComponents/Schema/BreadcrumbSchema.js";

function Home() {
  const [loader, setLoader] = useState(false);
  const isVerifiedToken = localStorage.getItem("isVerified");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  useEffect(() => {
    // const isVerifiedToken = localStorage.getItem('isVerified');
    const AccountStatus = localStorage.getItem("AccountStatus");
    // console.log(AccountStatus, "AccountStatus")
    // const role = localStorage.getItem('role');

    // console.log(isVerifiedToken, "token");
    // console.log(role, "role");

    if (AccountStatus === "disable") {
      console.error("Error: Invalid token or undefined for agent role");
      // showErrorToast("First Fill all required ")
      navigate("/agent-account");
    }
  }, [navigate]);

  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");

  // console.log(token, "check_token");
  // console.log(userType, "checkagentdetails");
  const [customerDataa, setCustomerData] = useState(null);
  // console.log(customerDataa, "customerDataasss");
  const [footerDestinations, setFooterDestinations] = useState([]);

  const fetchAgentDetails = (token) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/agent/user-profile`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // setAgentData(data.data.agent);
        localStorage.setItem("userDetails", JSON.stringify(data.data));
        localStorage.setItem(
          "AccountStatus",
          JSON.stringify(data.data.accountStatus)
        );
      })
      .catch((error) => {
        console.error("Error fetching agent details:", error);
      });
  };

  const fetchCustomerDetails = (token) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/customer/user-profile`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCustomerData(data.data);
          localStorage.setItem("userDetails", JSON.stringify(data.data));
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  // console.log(token, userType);
  useEffect(() => {
    if (token && userType === "agent") {
      fetchAgentDetails(token);
    } else if (userType === "customer") {
      // window.location.reload()
      fetchCustomerDetails(token);
    }
  }, [token]);

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "key",
      process.env.REACT_APP_BASE_API_KEY
    );
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("token")
    // );

    const formData = new FormData();
    formData.append("limit", 10);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setFooterDestinations(result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div id="home">
      <PageMetaData
        title="Mountain Mysteries - Book Himachal Pradesh Holiday Tour Packages Online"
        description="Book Himachal Pradesh  holiday Tour Package at the best price with Mountain Mysteries. Enjoy scenic beauty, adventure, and cultural experiences. Book your dream vacation today!"
        keywords="Himachal Pradesh Tour Package Himachal Pradesh Holiday Tour"
        canonicalUrl={"https://www.mountainmysteries.com/"}
      />
      <OgMetaTitles
        title="Mountain Mysteries - Book Himachal Pradesh Holiday Tour Packages Online"
        type="Book Himachal Pradesh  holiday Tour Package at the best price with Mountain Mysteries. Enjoy scenic beauty, adventure, and cultural experiences. Book your dream vacation today!"
        url="https://www.mountainmysteries.com/"
      />
      <BreadcrumbSchema />
      <NewHolidaysSearchForm
        topRadios={["Culture", "Family", "Honeymoon", "OffBeat"]}
      />
      {/* <BannerMain /> */}
      {loader ? (
        <>
          <div className="container">
            <div className="common_padding">
              <h3>Top Destination</h3>
            </div>
            <div style={{ display: "flex" }} className="images-flex">
              {Array.from({ length: 5 }).map((_, index) => {
                return (
                  <Link
                    style={{ width: "33.33%" }}
                    className="destination_link"
                    key={index}
                  >
                    <div>
                      <Skeleton height={300} />
                    </div>
                    <div style={{ width: "100px" }}>
                      <h6>
                        <Skeleton />
                      </h6>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <AllDestinationsSlider
            footerDestinations={footerDestinations}
            heading="Top Destinations"
          />
        </>
      )}

      <FilterPackages heading="Top Holiday Packages" />
      <Themes />
      <Testimonial />
      <Highlights />
      <Footer footerDestinations={footerDestinations} />
    </div>
  );
}

export default Home;
