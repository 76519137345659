import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import AllRooms from "../../CustomComponents/Property/AllRooms/AllRooms";
import ManageRatesPlans from "../../CustomComponents/Property/AllRooms/ManageRatesPlans";

function ManageRatesPlansPage({ allPropertiesPage }) {
    return (
        <>
            <VendorHeader showSelectProperty={true} />
            <div className="sidebar_main_div">
                <SideBar allPropertiesPage={false} />
                <ManageRatesPlans />
            </div>
        </>
    );
}

export default ManageRatesPlansPage;
