import React, { useState, useEffect } from "react";
import styles from "./uploadComponent.module.scss";
import {
  FaPlus,
  FaTrashAlt,
  FaEye,
  FaClose,
  FaPdf,
  FaCsv,
  FaDoc,
  FaFileAlt,
} from "./icons/uploadIcons";
import { showSuccessToast } from "../../assets/toastUtils";

const UploadFileComponent = ({
  uploadType,
  deleteType,
  fetchType,
  modelType,
  modelId,
  upload_collection,
  fileType = "image",
  defaultFileName = "",
  defaultFile = null,
  onFileChange, // callback to send data to the parent
}) => {
const userToken = localStorage.getItem("token")
  useEffect(() => {
    fetchData();
  },[])
  const [mediaId, setMediaId] = useState(null);

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE_KEY);
 
      const formdata = new FormData();
      formdata.append("modelType", modelType);
      formdata.append("modelId", modelId);
      formdata.append("upload_collection", upload_collection);
 
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
 
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${fetchType}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.success && result?.data) {
            const fileKey = Object.keys(result.data)[0]; // Get the first key from the data object
            const fileData = result.data[fileKey];
            setMediaId(fileKey);
            if (fileData) {
              setFile(fileData);
              setFileName(fileData.name);
            } else {
              console.error("File data not found in response.");
            }
          } else {
            console.error("Unexpected response format:", result);
          }
        })
        .catch((error) => console.error("Error fetching file:", error));
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  const handleFileUpload = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE_KEY);
 
      const formData = new FormData();
      formData.append("modelType", modelType);
      formData.append("modelId", modelId);
      formData.append("upload_collection", upload_collection);
      formData.append("file_type", "document");
      formData.append("files[]", file);
 
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };
 
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${uploadType}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            showSuccessToast("File Uploaded Successfully")
            fetchData()
          }
        })
    
        .catch((error) => console.error(error));
    } catch (error) {}
  };
  const [file, setFile] = useState(defaultFile);
  const [fileName, setFileName] = useState(defaultFileName);
  const [previewOpen, setPreviewOpen] = useState(false);

  // Update the internal state when new defaults come from the parent
  useEffect(() => {
    setFile(defaultFile);
    setFileName(defaultFileName);
  }, [defaultFile, defaultFileName]);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      if (fileType === "image") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
          onFileChange && onFileChange(reader.result, uploadedFile.name); // Send the file and name back to parent
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        setFile(uploadedFile);
        onFileChange && onFileChange(uploadedFile, uploadedFile.name); // Send the file and name back to parent
      }
    }
  };
  const handleDeleteClick = async () => {
    if (!mediaId) {
      setFile(null);
      setFileName("");
      onFileChange && onFileChange(null, "");
      return;
    };
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + userToken
      );
      myHeaders.append(
        "Cookie", process.env.REACT_APP_COOKIE_KEY);
 
      const raw = JSON.stringify({
        mediaId: mediaId,
      });
 
      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
 
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${deleteType}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) =>{
          if(result.success){
            showSuccessToast(result.message);
            setFile(null);
            setFileName("");
            setMediaId(null);
            fetchData()
          }
        })
        .catch((error) => console.error(error));
    } catch (error) {
    }
  };

  const handleDelete = () => {
    setFile(null);
    setFileName("");
    onFileChange && onFileChange(null, ""); // Inform the parent that the file was deleted
  };

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const getFileIcon = () => {
    switch (fileType) {
      case "pdf":
        return <FaPdf />;
      case "csv":
        return <FaCsv />;
      case "doc":
        return <FaDoc />;
      case "txt":
        return <FaFileAlt />;
      default:
        return <FaFileAlt />;
    }
  };

  if (!fileType) return null;

  return (
    <div className="mt-10"  onClick={(e)=>{e.stopPropagation(); } }>
      <div
        className="flex flex_direction_col align_items_center"
        style={{ width: "100%", height: "fit-content" }}
      >
        <div className={styles.container} style={{ width: "100%"}}>
          {!file ? (
            <div className={styles.uploadContainer}>
              <label htmlFor="upload" className={styles.uploadLabel}>
                <FaPlus className={styles.icon} />
                <p>Upload</p>
                <input
                  id="upload"
                  type="file"
                  accept={
                    fileType === "image"
                      ? "image/jpeg, image/png"
                      : fileType === "pdf"
                      ? "application/pdf"
                      : fileType === "csv"
                      ? ".csv"
                      : fileType === "doc"
                      ? ".odt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      : fileType === "txt"
                      ? "text/plain"
                      : "*/*"
                  }
                  onChange={handleFileChange}
                  className={styles.uploadInput}
                />
              </label>
            </div>
          ) : (
            <>
              <div className={styles.imageContainer}>
                {fileType === "image" ? (
                  <img
                    src={file}
                    alt="Uploaded preview"
                    className={styles.imagePreview}
                    height={"100%"}
                    width={"100%"}
                  />
                ) : (
                  <div className={styles.fileIcon}>{getFileIcon()}</div>
                )}

                <div className={styles.buttonContainerOverlay}>
                  <button onClick={handleDeleteClick}>
                    <FaTrashAlt />
                  </button>
                  {fileType === "image" && (
                    <button onClick={handlePreview}>
                      <FaEye />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {!file ? (
          <p className={styles.uploadingText}>
            {fileName
              ? fileName
              : `or drop ${
                  fileType.toUpperCase() === "IMAGE"
                    ? "JPG, PNG"
                    : fileType.toUpperCase()
                } file here`}
          </p>
        ) : (
          <p className={styles.uploadingText}>{fileName}</p>
        )}
      </div>

      {fileType === "image" && previewOpen && (
        <div className={styles.previewPopup} onClick={handleClosePreview}>
          <div className={styles.previewCloseButton}>
            <FaClose />
          </div>
          <img src={file} alt="Image preview" className={styles.previewImage} />
        </div>
      )}

      {
        file && (
         <button onClick={handleFileUpload} className="primary_btn" style={{width: 'auto'}}>Upload</button>
        )
      }
    </div>
  );
};

export default UploadFileComponent;
