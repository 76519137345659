import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";

const CancelPagePackage = () => {
  const [orderDetails, setOrderDetails] = useState([]);

  const { id } = useParams();

  // useEffect(() => {
  //   async function fetchOrderId() {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_APP_URL}/api/v1/new/check/payment/${id}/package`
  //       );
  //       const data = await response.json();
  //       setOrderDetails(data);
  //     } catch (error) {
  //       console.error("Error fetching order ID:", error);
  //     }
  //   }
  //   fetchOrderId();
  // }, []);

  return (
    <section>
      <Header />
      <div className="container">
        <div className="row justify-content-center pb_30">
          <div className="col-md-8">
            <div className="thankyou_main activity_thanyou_page">
              <div className="text-center">
                {/* <Image src="/icons/econew.svg" width={160} height={60} alt="Eco Logo"></Image> */}
                <h1 className="mt-30">Booking Cancellation!</h1>
              </div>
              <h3>
                Dear{" "}
                {orderDetails?.booking && orderDetails?.booking.booked_from
                  ? orderDetails?.booking.booked_from
                  : "User"},
              </h3>
              <p>
                We regret to inform you that your booking has been canceled due
                to non-payment or other reasons. We understand that this may
                cause inconvenience and disappointment, and we sincerely
                apologize for any inconvenience caused.
              </p>
              <p>
                {" "}
                If you believe this cancellation is an error or if you have any
                concerns, please do not hesitate to reach out to our customer
                support team at Email:{" "}
                <a href="mailto:info@mountainmysteries.com">
                  <strong>info@mountainmysteries.com</strong>
                </a>{" "}
                or Phone:{" "}
                <a href="tel:+919805021966">
                  <strong>+91-9805021966</strong>
                </a>
                . We will be more than happy to assist you in resolving
                rebooking your stay .
              </p>
              <p>Here are the payment details for your reference:</p>
              <p>
                {" "}
                We appreciate your understanding and cooperation in this matter.
                We value your patronage and hope to have the opportunity to
                serve you in the future.
              </p>
              <p className="text-left">
                Thank you.
                <br />
                Team <strong>Mountain Mysteries</strong>
              </p>
              <div className="text-center">
                <Link to={"/"} className="primary_btn mt-10" style={{ width: 'auto' }}>
                  <span>Back to Home</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default CancelPagePackage;
