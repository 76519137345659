import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { locationOffer } from "../../constants";
import Footer from "../../CustomComponents/Footer/Footer.jsx";
// import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks.jsx";
import Highlights from "../../CustomComponents/Highlights/Highlights.jsx";
import Testimonial from "../../CustomComponents/Testimonial/Testimonial.jsx";
import BannerMain from "../../CustomComponents/BannerMain/BannerMain.jsx";
import CustomSearchFormHolidays from "../../CustomComponents/SearchForm/CustomSearchFormHolidays/CustomSearchFormHolidays.jsx";
import { showErrorToast } from "../../assets/toastUtils.js";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData.jsx";
import FilterHotels from "../../CustomComponents/FilterHotels/FilterHotels.jsx";
import AllDestinationsSlider from "../../CustomComponents/AllDestinationsSlider/AllDestinationsSlider.jsx";
import NewHotelsSearchForm from "../../CustomComponents/SearchForm/NewHotelsSearchForm/NewHotelsSearchForm.jsx";
import DestinationLinks from "../../CustomComponents/DestinationLinks/DestinationLinks.jsx";
import HotelsList from "../../CustomComponents/HotelsList/HotelsList.jsx";
import HotelChains from "../../CustomComponents/HotelChains/HotelChains.jsx";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles.jsx";

function Accommodation() {
  const [footerDestinations, setFooterDestinations] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // const isVerifiedToken = localStorage.getItem('isVerified');
    const AccountStatus = localStorage.getItem("AccountStatus");
    // console.log(AccountStatus, "AccountStatus")
    // const role = localStorage.getItem('role');

    // console.log(isVerifiedToken, "token");
    // console.log(role, "role");

    if (AccountStatus === "disable") {
      console.error("Error: Invalid token or undefined for agent role");
      // showErrorToast("First Fill all required ")
      navigate("/agent-account");
    }
  }, [navigate]);

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    setLoader(true);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("token")
    // );

    const formData = new FormData();

    formData.append("limit", 10);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
        requestOptions
      );
      const result = await response.json();
      setFooterDestinations(result?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false); // Ensure loader is turned off
    }
  };

  return (
    <div id="hotels">
      <PageMetaData
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        description="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        keywords="Hotels In Himachal Pradesh"
      />

      <OgMetaTitles
        title="The Best Hotels in Himachal Pradesh, India -  Mountain Mysteries"
        type="Book hotels in Himachal Pradesh at the best budget tariffs. Explore our curated list of luxury and budget hotels with the best available prices. Compare rates and find the perfect stay with us!"
        url="https://www.mountainmysteries.com/accommodation/"
      />
      <NewHotelsSearchForm
        topRadios={["Hotel", "B&B", "Camping", "HomeStay"]}
      />
      {/* <BannerMain /> */}
      <DestinationLinks
        footerDestinations={footerDestinations}
        heading="Our Exclusive Destinations"
        loader={loader}
      />
      {/* <FilterHotels /> */}
      <HotelsList heading="Top Properties in Himachal Pradesh" />
      <HotelChains />
      <Testimonial />
      <Highlights />
      {/* <DestinationLinks /> */}
      <Footer />
    </div>
  );
}

export default Accommodation;
