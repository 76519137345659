import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../CustomComponents/Loader/Loader";

const NewRules = () => {
    const [allrules, setAllRules] = useState([]);
    const vendorToken = localStorage.getItem("token");
    const { id } = useParams();

    const [editPolicy, setEditPolicy] = useState(false);
    const [editingKey, setEditingKey] = useState(null);
    const [ruleDetails, setRuleDetails] = useState({});

    const fetchPropertyData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            `Bearer ${vendorToken}`
        );
        myHeaders.append(
            "Cookie", process.env.REACT_APP_COOKIE
        );

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/crud/edit/${id}`, requestOptions);
            const result = await response.json();

            if (response.ok) {
                setAllRules(result?.data?.rules);
            } else {
                throw new Error(result.message || 'Something went wrong');
            }
        } catch (err) {
            console.error("Error fetching property data:", err)
        }
    };

    useEffect(() => {
        if (id) {
            fetchPropertyData();
        }
    }, [id]);

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
        ],
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];

    const handleEditorChange = (value) => {
        setRuleDetails((prev) => ({ ...prev, content: value }));
    };

    // Handle edit click
    const handleEdit = (key, value) => {
        setEditingKey(key);
        setRuleDetails({ key, content: value });
    };

    const handleSave = async () => {
        try {
            const updatedData = allrules.reduce((acc, rule) => {
                Object.entries(rule).forEach(([key, value]) => {
                    if (key === ruleDetails.key) {
                        acc[key] = ruleDetails.content; // Update the current edited content
                    } else {
                        acc[key] = value; // Include unchanged policies
                    }
                });
                return acc;
            }, {});

            const result = await updatePolicy(updatedData);

            // Update the UI
            const updatedRules = allrules.map((rule) => {
                if (rule[ruleDetails.key]) {
                    return { ...rule, [ruleDetails.key]: ruleDetails.content };
                }
                return rule;
            });

            setAllRules(updatedRules);
            setEditingKey(null);
            console.log("Policy updated successfully:", result);
        } catch (error) {
            console.error("Failed to update policy:", error);
        }
    };

    const updatePolicy = async (updatedData) => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/website/accomodation/crud/policy/update/${id}`;
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${vendorToken}`);

        const formData = new FormData();
        Object.entries(updatedData).forEach(([key, value]) => {
            formData.append(`${key}_policy`, value);
        });

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: formData,
            redirect: "follow",
        };

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("Error updating policy:", error);
            throw error;
        }
    };

    return (
        <>
            <VendorHeader showSelectProperty={true} />
            <div className="sidebar_main_div">
                <SideBar allPropertiesPage={false} />
                <section className="w-full p_20">
                    <div className="flex justify_content_between">
                        <h4>Rules</h4>
                    </div>
                    <form>
                        {allrules && allrules?.map((rule, index) => (
                            <div key={index} className="rule-container">
                                {Object.entries(rule).map(([key, value]) => (
                                    <div key={key} className="rule-item" style={{ marginBottom: "20px" }}>
                                        <div className="flex justify_content_between align_items_center">
                                            <h5 htmlFor={`${key}_description`} style={{ textTransform: "capitalize" }}>
                                                {key.replace(/_/g, " ")}
                                            </h5>
                                            {editingKey === key ? (
                                                <div className="primary_btn fit-width" onClick={handleSave}>
                                                    Save
                                                </div>
                                            ) : (
                                                <div
                                                    className="primary_btn fit-width"
                                                    onClick={() => handleEdit(key, value)}
                                                >
                                                    Edit
                                                </div>
                                            )}
                                        </div>
                                        <div className="border p_10 rounded mt-5">
                                            {editingKey === key ? (
                                                <ReactQuill
                                                    className="mt-10"
                                                    value={ruleDetails.content}
                                                    onChange={handleEditorChange}
                                                    modules={modules}
                                                    formats={formats}
                                                />
                                            ) : (
                                                <p dangerouslySetInnerHTML={{ __html: value }}></p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </form>
                    {/* <div className="rules_tab">
                        {rules.map((rule, index) => (
                            <button
                                key={index}
                                className={`secondary_btn fit-width ${selectedRule === rule ? "active-btn" : ""
                                    }`}
                                style={{ textTransform: "capitalize" }}
                                onClick={() => handleRuleClick(rule)}
                            >
                                {rule.replace(/-/g, " ")}
                            </button>
                        ))}
                    </div>
                    <div className="bg-white-div">{renderForm()}</div> */}
                </section>
            </div>
        </>
    );
};

export default NewRules;