import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { animateScroll as scroll } from "react-scroll";
import {
  calculateLOS,
  calculateNumberOfNights,
  formatDate,
  formatIndianCurrency,
  textDateFormatter,
  textDateFormatterNoAdd,
  textDateFormatterNoAddInIteration,
} from "../../utils/utils";
import Footer from "../../CustomComponents/Footer/Footer";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import Header from "../../CustomComponents/Header/Header";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import "./CheckOutHotelNew.css";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";
import GetDirectionRedirect from "../../CustomComponents/GetDirectionRedirect/GetDirectionRedirect";
import moment from "moment";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
// import LoadAtomCheckoutScript from "../../CustomComponents/PaymentGateway/LoadAtomCheckoutScript";
import imgNotFound from "../../assets/images/single-image.png";

function CheckOutHotelNew() {
  const location = useLocation();
  const {
    item: selectedPlan,
    hotelData,
    ratesData,
    bookingData,
    room_id,
  } = location?.state || {};

  const [commission, setCommission] = useState({});

  const userToken = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));

  const [propertyDiscountArray, setPropertyDiscountArray] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState([]);
  const [selectedAutomaticDiscount, setSelectedAutomaticDiscount] = useState([]);

  const [loginWithGoogleCustomer, setLoginWithGoogleCustomer] = useState(null);
  const [hotelBookingData, setHotelBookingData] = useState(null);
  const [markupData, setMarkupData] = useState([]);
  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const [payWithAgentCredit, setPayWithAgentCredit] = useState(false);
  const [payWithAdvance, setPayWithAdvance] = useState(false);

  const handleAddressData = (data) => {
    setAddressData(data);
  };

  const [filteredData, setFilteredData] = useState(null);
  const [noOfNights, setNoOfNights] = useState("");

  useEffect(() => {
    const filtered = ratesData?.rates.find((item) => item.room_id === room_id);
    setFilteredData(filtered);
  }, []);

  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
    agentAdvanceCalculations: 0,
    commissionAmount: 0,
  });

  useEffect(() => {
    const filtered = ratesData?.rates?.find((item) => item.room_id === room_id);
    setFilteredData(filtered);
  }, [ratesData?.rates]);

  const [booking, setBoooking] = useState(false);
  const handleOnBooking = () => {
    setBoooking(!booking);
  };

  const fetchMarkup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const findObject = result?.data?.find(
            (mark) => mark?.markupCustomer === "both"
          );
          if (findObject) {
            setMarkupData(findObject);
          } else {
            setMarkupData(null);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchMarkup();
  }, [ratesData?.rates]);

  const closePopup = () => {
    setBoooking(false);
  };

  const applyDiscount = (rate, discount) => {

    let updatedRate = rate;
    let discountAmount = 0;

    if (discount) {
      if (discount.type === "percentage") {
        // Calculate discount amount for percentage
        discountAmount = (rate * parseFloat(discount.amount)) / 100;
        updatedRate = rate;
      } else if (discount.type === "fixed") {
        // Discount amount for flat discount
        discountAmount = parseFloat(discount.amount);
        updatedRate = rate;
      }

      // Ensure updated rate is not less than zero
      updatedRate = updatedRate > 0 ? updatedRate : 0;
    }

    return {
      updatedRate: updatedRate,
      discountAmount:
        bookingData?.room !== 1 ? discountAmount : discountAmount * noOfNights,
    };
  };

  // const calculateBookingValues = (
  //   roomRate,
  //   // bookingDiscount,
  //   // bookingDiscountType,
  //   selectedBookingAutomaticDiscount,
  //   selectedBookingDiscount,
  //   agentDiscount,
  //   agentDiscountType,
  //   childPricesArray,
  //   noOfNights,
  //   noOfRooms,
  //   agentAdvanceCalculations,
  //   automaticDiscounts,
  //   commissionAmount,
  //   commissionType
  // ) => {

  //   // let updatedChildPrices = childPricesArray.map((price) => {
  //   //   let preCommissionChildPrice = parseFloat(price);
  //   //   let finalChildPrice = parseFloat(price);

  //   //   if (finalChildPrice <= 0) {
  //   //     return { finalChildPrice, preCommissionChildPrice };
  //   //   }

  //   //   if (markupData) {
  //   //     const markup = markupData;
  //   //     if (markup.markupType === "percentage") {
  //   //       preCommissionChildPrice += (finalChildPrice * parseFloat(markup.amount)) / 100;
  //   //       finalChildPrice += (finalChildPrice * parseFloat(markup.amount)) / 100;
  //   //     } else if (markup.markupType === "fixed") {
  //   //       preCommissionChildPrice += parseFloat(markup.amount);
  //   //       finalChildPrice += parseFloat(markup.amount);
  //   //     }
  //   //   }

  //   //   if (commissionAmount && commissionType) {
  //   //     let calculatedCommissionAmount = 0;
  //   //     if (commissionType === "percentage") {
  //   //       calculatedCommissionAmount =
  //   //         (finalChildPrice * parseFloat(commissionAmount)) / 100;
  //   //     } else if (commissionType === "fixed") {
  //   //       calculatedCommissionAmount = parseFloat(commissionAmount);
  //   //     }
  //   //     finalChildPrice -= calculatedCommissionAmount;
  //   //   }

  //   //   return finalChildPrice;
  //   // });

  //   // let childPrices = updatedChildPrices?.reduce((accumulator, currentPrice) => {
  //   //   const parsedPrice = parseFloat(currentPrice);
  //   //   return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
  //   // }, 0);

  //   let childPrices = childPricesArray?.reduce((accumulator, currentPrice) => {
  //     const parsedPrice = parseFloat(currentPrice);
  //     return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
  //   }, 0);

  //   // let childPricesWithMarkup = updatedChildPrices.reduce((accumulator, current) => {
  //   //   return accumulator + (isNaN(current.preCommissionChildPrice) ? 0 : current.preCommissionChildPrice);
  //   // }, 0);

  //   // let childPrices = updatedChildPrices.reduce((accumulator, current) => {
  //   //   return accumulator + (isNaN(current.finalChildPrice) ? 0 : current.finalChildPrice);
  //   // }, 0);

  //   // let childSavings = updatedChildPrices.reduce((accumulator, current) => {
  //   //   return accumulator + (current.preCommissionChildPrice - current.finalChildPrice);
  //   // }, 0);

  //   let newRoomRate;
  //   let roomRatePerNight;
  //   let roomRateAfterDiscount;

  //   // if (markupData) {
  //   //   const markup = markupData;
  //   //   if (markup.markupType === "percentage") {
  //   //     roomRate += (roomRate * parseFloat(markup.amount)) / 100;
  //   //   } else if (markup.markupType === "fixed") {
  //   //     roomRate += parseFloat(markup.amount);
  //   //   }
  //   // }

  //   roomRatePerNight = roomRate

  //   // let calculatedCommissionamount;
  //   // const preCommissionPrice = roomRate * +noOfNights * +noOfRooms;

  //   // if (commissionAmount && commissionType) {
  //   //   if (commissionType === "percentage") {
  //   //     calculatedCommissionamount =
  //   //       (Number(roomRate) * Number(commissionAmount)) / 100;
  //   //     roomRate = roomRate - calculatedCommissionamount;
  //   //   } else if (commissionType === "fixed") {
  //   //     calculatedCommissionamount = Number(commissionAmount);
  //   //     roomRate = roomRate - calculatedCommissionamount;
  //   //   }
  //   // }

  //   // const postCommissionPrice = roomRate * +noOfNights * +noOfRooms;
  //   // const agentSavingsRoomRate = preCommissionPrice - postCommissionPrice;

  //   newRoomRate = roomRate

  //   let extraAdultPrice = selectedPlan.extra_adult_count * selectedPlan.extra_adult;
  //   // let extraAdultPriceWithMarkup = extraAdultPrice;

  //   // if (extraAdultPrice) {
  //   //   if (markupData) {
  //   //     const markup = markupData;
  //   //     if (markup.markupType === "percentage") {
  //   //       extraAdultPriceWithMarkup += (extraAdultPrice * parseFloat(markup.amount)) / 100;
  //   //       extraAdultPrice += (extraAdultPrice * parseFloat(markup.amount)) / 100;
  //   //     } else if (markup.markupType === "fixed") {
  //   //       extraAdultPriceWithMarkup += parseFloat(markup.amount);
  //   //       extraAdultPrice += parseFloat(markup.amount);
  //   //     }
  //   //   }

  //   //   if (commissionAmount && commissionType) {
  //   //     if (commissionType === "percentage") {
  //   //       extraAdultPrice -=
  //   //         (Number(extraAdultPrice) * Number(commissionAmount)) / 100;
  //   //     } else if (commissionType === "fixed") {
  //   //       extraAdultPrice -= Number(commissionAmount);
  //   //     }
  //   //   }
  //   // }

  //   // const postCommissionExtraAdultPrice = extraAdultPrice;
  //   // const agentSavingsExtraAdult = (extraAdultPriceWithMarkup - postCommissionExtraAdultPrice) * +noOfNights;
  //   // const agentSavingsExtraChild = (childSavings) * +noOfNights;

  //   // const totalAgentSavings =
  //   //   agentSavingsRoomRate + agentSavingsExtraAdult + agentSavingsExtraChild;

  //   // const result = applyDiscount(
  //   //   roomRateParsed + childPrices + extraAdultPrice,
  //   //   automaticDiscounts
  //   // );

  //   // roomRate = result.updatedRate;

  //   // const automaticDiscountAmount = result.discountAmount * noOfNights;



  //   // let bookingDiscountAmount = 0;
  //   // if (bookingDiscountType === "percentage") {
  //   //   bookingDiscountAmount = (roomRateParsed * bookingDiscount) / 100;
  //   // } else {
  //   //   bookingDiscountAmount = parseFloat(bookingDiscount);
  //   //   if (isNaN(bookingDiscountAmount)) {
  //   //     bookingDiscountAmount = 0;
  //   //   }
  //   // }

  //   const calculateAutomaticDiscountedAmount = (roomRateParsed) => {
  //     let finalRoomRate = roomRateParsed;
  //     let totalDiscount = 0;

  //     selectedBookingAutomaticDiscount &&
  //       selectedBookingAutomaticDiscount?.length > 0 &&
  //       selectedBookingAutomaticDiscount?.forEach((discount) => {
  //         const { promotionMode, promotionAmount } = discount;

  //         if (promotionMode === "percentage") {
  //           const percentageDiscount = (finalRoomRate * parseFloat(promotionAmount)) / 100;
  //           totalDiscount += percentageDiscount;
  //           finalRoomRate -= percentageDiscount;
  //         } else {
  //           const fixedDiscount = parseFloat(promotionAmount);
  //           if (!isNaN(fixedDiscount)) {
  //             totalDiscount += fixedDiscount;
  //             finalRoomRate -= fixedDiscount;
  //           }
  //         }
  //       });

  //     return totalDiscount;
  //   };

  //   const calculateDiscountedAmount = (roomRateParsed) => {
  //     let finalRoomRate = roomRateParsed;
  //     let totalDiscount = 0;

  //     selectedBookingDiscount &&
  //       selectedBookingDiscount?.length > 0 &&
  //       selectedBookingDiscount?.forEach((discount) => {
  //         const { promotionMode, promotionAmount } = discount;

  //         if (promotionMode === "percentage") {
  //           const percentageDiscount = (finalRoomRate * parseFloat(promotionAmount)) / 100;
  //           totalDiscount += percentageDiscount;
  //           finalRoomRate -= percentageDiscount;
  //         } else {
  //           const fixedDiscount = parseFloat(promotionAmount);
  //           if (!isNaN(fixedDiscount)) {
  //             totalDiscount += fixedDiscount;
  //             finalRoomRate -= fixedDiscount;
  //           }
  //         }
  //       });

  //     return totalDiscount;
  //   };

  //   const totalBookingAutomaticDiscountAmount = calculateAutomaticDiscountedAmount(newRoomRate);
  //   const totalBookingDiscountAmount = calculateDiscountedAmount(Number(newRoomRate) - Number(totalBookingAutomaticDiscountAmount ? totalBookingAutomaticDiscountAmount : 0));

  //   const totalBookingAutomaticDiscountAmountForRoomRate = calculateAutomaticDiscountedAmount(newRoomRate);
  //   const totalBookingDiscountAmountForRoomRate = calculateDiscountedAmount(Number(newRoomRate) - Number(totalBookingAutomaticDiscountAmountForRoomRate ? totalBookingAutomaticDiscountAmountForRoomRate : 0));

  //   let agentDiscountAmount = 0;
  //   // if (agentDiscountType === "percentage") {
  //   //   agentDiscountAmount =
  //   //     ((roomRateParsed - bookingDiscountAmount) * agentDiscount) / 100;
  //   // } else {
  //   //   agentDiscountAmount = parseFloat(agentDiscount);
  //   //   if (isNaN(agentDiscountAmount)) {
  //   //     agentDiscountAmount = 0;
  //   //   }
  //   // }
  //   let totalDiscountAmount = totalBookingAutomaticDiscountAmount + totalBookingDiscountAmount;
  //   let totalDiscountAmountForRoomRate = totalBookingAutomaticDiscountAmountForRoomRate + totalBookingDiscountAmountForRoomRate;

  //   // let totalDiscountAmount =
  //   //   bookingDiscountAmount + agentDiscountAmount + automaticDiscountAmount;

  //   console.log(newRoomRate, 'newRoomRate');
  //   console.log(totalDiscountAmount, 'totalDiscountAmount');

  //   if (totalDiscountAmount) {
  //     roomRateAfterDiscount = newRoomRate - totalDiscountAmount;
  //   } else {
  //     roomRateAfterDiscount = newRoomRate;
  //   }

  //   let roomRateParsed = Number(roomRateAfterDiscount) * +noOfNights * +noOfRooms;
  //   if (isNaN(roomRateParsed)) {
  //     roomRateParsed = 0;
  //   }

  //   console.log(roomRateAfterDiscount, 'roomRateAfterDiscount');

  //   let taxSlab

  //   if (totalDiscountAmountForRoomRate) {
  //     taxSlab = (Number(newRoomRate) - totalDiscountAmountForRoomRate) > 7500 ? 18 : 12;
  //   } else {
  //     taxSlab = +newRoomRate > 7500 ? 18 : 12;
  //   }

  //   // let subtotal =
  //   //   roomRateParsed - totalDiscountAmount + (+extraAdultPrice * +noOfNights) + (+childPrices * +noOfNights);

  //   console.log(roomRateParsed, 'roomRateParsed');
  //   let subtotal =
  //     roomRateParsed + (+extraAdultPrice * +noOfNights) + (+childPrices * +noOfNights);

  //   let calculatedCommissionAmount;

  //   if (commissionAmount && commissionType) {
  //     if (commission.commissionType === "percentage") {
  //       calculatedCommissionAmount = (subtotal * commission.commissionAmount) / 100;
  //     } else if (commission.commissionType === "fixed") {
  //       calculatedCommissionAmount = commission.commissionAmount;
  //     }
  //   }
  //   if (calculatedCommissionAmount) {
  //     subtotal -= calculatedCommissionAmount;
  //   }

  //   let taxAmount = (subtotal * taxSlab) / 100;

  //   let grandTotal = subtotal + taxAmount;

  //   const grandTotalAfterAgentAdvance = grandTotal;

  //   // if(agentAdvanceCalculations?.pay_with_advance){
  //   //   grandTotal -= agentAdvanceCalculations?.advanceLimit;
  //   // }

  //   // if(agentAdvanceCalculations?.pay_with_company_credit){
  //   //   grandTotal -= agentAdvanceCalculations?.credit_balance;
  //   // }

  //   return {
  //     taxSlab,
  //     subtotal,
  //     taxAmount,
  //     grandTotal: Math.max(grandTotal, 0),
  //     discountAmt: totalDiscountAmount,
  //     totalChildAdultCost: childPrices * +noOfNights,
  //     totalAdultCost: extraAdultPrice * +noOfNights,

  //     childPrices,
  //     // bookingDiscount,
  //     agentDiscount,
  //     grandTotalAfterAgentAdvance,
  //     roomRate: roomRateParsed,
  //     automaticDiscounts,
  //     newRoomRate,
  //     extraAdultPrice,
  //     // extraAdultPriceWithMarkup,
  //     // commissionRoomRate,
  //     calculatedCommissionAmount,
  //     roomRatePerNight,
  //     roomRateAfterDiscount,
  //     // agentSavingsExtraAdult,
  //     // childPricesWithMarkup
  //   };
  // };

  const calculateBookingValues = (
    roomRate,
    // bookingDiscount,
    // bookingDiscountType,
    selectedBookingAutomaticDiscount,
    selectedBookingDiscount,
    agentDiscount,
    agentDiscountType,
    childPricesArray,
    noOfNights,
    noOfRooms,
    agentAdvanceCalculations,
    automaticDiscounts,
    commissionAmount,
    commissionType
  ) => {

    let childPrices = childPricesArray?.reduce((accumulator, currentPrice) => {
      const parsedPrice = parseFloat(currentPrice);
      return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
    }, 0);

    let newRoomRate;
    let roomRatePerNight;
    let roomRateAfterDiscount;

    roomRatePerNight = roomRate
    newRoomRate = roomRate
    let extraAdultPrice = selectedPlan.extra_adult_count * selectedPlan.extra_adult;

    const calculateAutomaticDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedBookingAutomaticDiscount &&
        selectedBookingAutomaticDiscount?.length > 0 &&
        selectedBookingAutomaticDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount = (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };

    const calculateDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedBookingDiscount &&
        selectedBookingDiscount?.length > 0 &&
        selectedBookingDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount = (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };

    const totalBookingAutomaticDiscountAmount = calculateAutomaticDiscountedAmount(newRoomRate);
    const totalBookingDiscountAmount = calculateDiscountedAmount(Number(newRoomRate) - Number(totalBookingAutomaticDiscountAmount ? totalBookingAutomaticDiscountAmount : 0));

    let agentDiscountAmount = 0;
    let totalDiscountAmount = totalBookingAutomaticDiscountAmount + totalBookingDiscountAmount;

    if (totalDiscountAmount) {
      roomRateAfterDiscount = newRoomRate - totalDiscountAmount;
    } else {
      roomRateAfterDiscount = newRoomRate;
    }

    let roomRateParsed = Number(roomRateAfterDiscount) * +noOfNights * +noOfRooms;
    if (isNaN(roomRateParsed)) {
      roomRateParsed = 0;
    }

    let taxSlab

    if (roomRateAfterDiscount) {
      taxSlab = (Number(roomRateAfterDiscount)) > 7500 ? 18 : 12;
    } else {
      taxSlab = +roomRateAfterDiscount > 7500 ? 18 : 12;
    }

    // let subtotal =
    //   roomRateParsed - totalDiscountAmount + (+extraAdultPrice * +noOfNights) + (+childPrices * +noOfNights);

    let subtotal =
      roomRateParsed + (+extraAdultPrice * +noOfNights) + (+childPrices * +noOfNights);

    let calculatedCommissionAmount;

    if (commissionAmount && commissionType) {
      if (commission.commissionType === "percentage") {
        calculatedCommissionAmount = (subtotal * commission.commissionAmount) / 100;
      } else if (commission.commissionType === "fixed") {
        calculatedCommissionAmount = commission.commissionAmount;
      }
    }
    if (calculatedCommissionAmount) {
      subtotal -= calculatedCommissionAmount;
    }

    let taxAmount = (subtotal * taxSlab) / 100;

    let grandTotal = subtotal + taxAmount;

    const grandTotalAfterAgentAdvance = grandTotal;

    // if(agentAdvanceCalculations?.pay_with_advance){
    //   grandTotal -= agentAdvanceCalculations?.advanceLimit;
    // }

    // if(agentAdvanceCalculations?.pay_with_company_credit){
    //   grandTotal -= agentAdvanceCalculations?.credit_balance;
    // }

    return {
      taxSlab,
      subtotal,
      taxAmount,
      grandTotal: Math.max(grandTotal, 0),
      discountAmt: totalDiscountAmount,
      totalChildAdultCost: childPrices * +noOfNights,
      totalAdultCost: extraAdultPrice * +noOfNights,

      childPrices,
      // bookingDiscount,
      agentDiscount,
      grandTotalAfterAgentAdvance,
      roomRate: roomRateParsed,
      automaticDiscounts,
      newRoomRate,
      extraAdultPrice,
      // extraAdultPriceWithMarkup,
      // commissionRoomRate,
      calculatedCommissionAmount,
      roomRatePerNight,
      roomRateAfterDiscount,
      // agentSavingsExtraAdult,
      // childPricesWithMarkup
    };
  };
  const calculateCreditAndBalance = (
    name,
    hotelId,
    creditChecked,
    creditLimit,
    advanceChecked,
    advanceLimit,
    grandTotal,
    checkIn,
    checkOut
  ) => {
    let responseData = {
      pay_with_company_credit: creditChecked,
      pay_with_advance: advanceChecked,
      remaining_credit_balance: creditLimit,
      remaining_advance_balance: advanceLimit,
      credit_balance: creditLimit,
      advanceLimit: advanceLimit,
      checkIn: checkIn,
      checkOut: checkOut,
      grandTotal: grandTotal,
      paidAmount: 0,
      hotelId: hotelId,
      customerName: name,
      roomDetails: [],
    };

    if (creditChecked && !advanceChecked) {
      if (creditLimit > grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_credit_balance = creditLimit - grandTotal;
        responseData.paidAmount = grandTotal;
      } else {
        responseData.balanceAmount = grandTotal - creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;
      }
      return responseData;
    }

    if (!creditChecked && advanceChecked) {
      if (advanceLimit > grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_advance_balance = advanceLimit - grandTotal;
        responseData.paidAmount = grandTotal;
      } else {
        responseData.balanceAmount = grandTotal - advanceLimit;
        responseData.remaining_advance_balance = 0;
        responseData.paidAmount = advanceLimit;
      }
      return responseData;
    }

    if (creditChecked && advanceChecked) {
      let remainingAmount = grandTotal;

      if (creditLimit >= remainingAmount) {
        responseData.paidAmount = remainingAmount;
        responseData.remaining_credit_balance = creditLimit - remainingAmount;
        responseData.remaining_advance_balance = advanceLimit;
        responseData.balanceAmount = 0;
      } else {
        remainingAmount -= creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;

        if (advanceLimit >= remainingAmount) {
          responseData.paidAmount += remainingAmount;
          responseData.remaining_advance_balance =
            advanceLimit - remainingAmount;
          responseData.balanceAmount = 0;
        } else {
          responseData.paidAmount += advanceLimit;
          responseData.remaining_advance_balance = 0;
          responseData.balanceAmount = remainingAmount - advanceLimit;
        }
      }
      return responseData;
    }
    if (!creditChecked && !advanceChecked) {
      return responseData;
    }
  };

  const agentAdvanceCalculations = calculateCreditAndBalance(
    "",
    null,
    payWithAgentCredit, /// creditChecked
    userDetails?.agent_credit?.credit_amount,
    payWithAdvance, ///advanceChecked
    userDetails?.agent_advance_payment?.balance_amount,
    bookingValues.grandTotal,
    null,
    null
  );

  useEffect(() => {
    if (filteredData) {
      const roomRate = selectedPlan.rate_per_night;
      const selectedBookingAutomaticDiscount = selectedAutomaticDiscount;
      const selectedBookingDiscount = selectedDiscount;

      // const bookingDiscount = selectedDiscount?.promotionAmount;
      // const bookingDiscountType = selectedDiscount?.promotionMode;
      const agentDiscount = userDetails?.agent_category_discount || 0;
      const agentDiscountType =
        userDetails?.agent_discount_type || "percentage";
      const commissionAmount = commission?.commissionAmount;
      const commissionType = commission?.commissionType;

      const calculatedValues = calculateBookingValues(
        +selectedPlan.rate_per_night,
        // +bookingDiscount,
        // bookingDiscountType,
        selectedBookingAutomaticDiscount,
        selectedBookingDiscount,
        // selectedBookingAutomaticDiscount,
        +agentDiscount,
        agentDiscountType,
        selectedPlan.child_price,
        +noOfNights,
        +bookingData.room,
        agentAdvanceCalculations,
        hotelData?.automatic_discounts,
        commissionAmount,
        commissionType,
      );

      setBookingValues(calculatedValues);
    }
  }, [
    filteredData,
    userDetails?.agent_category_discount,
    agentAdvanceCalculations?.pay_with_advance,
    agentAdvanceCalculations?.pay_with_company_credit,
    // selectedDiscount?.promotionAmount,
    // selectedDiscount?.promotionMode,
    selectedAutomaticDiscount,
    selectedDiscount,
    commission?.commissionAmount,
    commission?.commissionType,
    markupData?.markupType,
  ]);

  const [loader, setLoader] = useState(false);
  const [guestData, setGuestData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    accommodation_notes_information: "",
    zipcode: "",
    pincode: "",
    guest_city: "",
    guest_address: "",
    gst_number: "",
    company_number: "",
    admin_email: "",
    business_email: "",
    company_address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuestData({
      ...guestData,
      [name]: value,
    });
  };

  const handleSubmitAddress = (e) => {
    e.preventDefault();
    // Here you can send the formData to a server or save it somewhere
  };

  const guestDataHandleChange = (event) => {
    const { name, value } = event.target;
    setGuestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!bookingData || !selectedPlan || !hotelData || !ratesData) {
      navigate("/");
    }

    const calculateNoOfNights = () => {
      if (!bookingData.checkinYear) navigate("/");
      const checkin = new Date(
        bookingData.checkinYear,
        bookingData.checkinMonth - 1,
        bookingData.checkinDay
      );
      const checkout = new Date(
        bookingData.checkoutYear,
        bookingData.checkoutMonth - 1,
        bookingData.checkoutDay
      );
      const diffTime = Math.abs(checkout - checkin);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setNoOfNights(diffDays);
    };

    // const formatDate = (year, month, day) => {
    //   return `${year}-${month}-${day}`;
    // };

    const formatDate = (year, month, day) => {
      // Ensure month and day are always two digits
      const paddedMonth = month < 10 ? `0${month}` : month;
      const paddedDay = day < 10 ? `0${day}` : day;

      return `${year}-${paddedMonth}-${paddedDay}`;
    };

    if (bookingData) {
      calculateNoOfNights();

      setCheckInDate(
        formatDate(
          bookingData.checkinYear,
          bookingData.checkinMonth,
          bookingData.checkinDay
        )
      );
      setCheckOutDate(
        formatDate(
          bookingData.checkoutYear,
          bookingData.checkoutMonth,
          bookingData.checkoutDay
        )
      );

      fetchDiscountsHandler({
        checkInDateFromBookingData: formatDate(
          bookingData.checkinYear,
          bookingData.checkinMonth,
          bookingData.checkinDay
        ),
        checkOutDateFromBookingData: formatDate(
          bookingData.checkoutYear,
          bookingData.checkoutMonth,
          bookingData.checkoutDay
        ),
      });
    }

  }, [bookingData, navigate]);

  const checkDates = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return currentDate >= start && currentDate <= end;
  };

  const checkBookingDates = (
    checkinBookingDate,
    checkoutBookingDate,
    discountStartDate,
    discountEndDate
  ) => {
    const checkin = new Date(checkinBookingDate);
    const checkout = new Date(checkoutBookingDate);
    const discountStart = new Date(discountStartDate);
    const discountEnd = new Date(discountEndDate);

    return checkin <= discountEnd && checkout >= discountStart;
  };

  const isPromotionApplicable = (userType, userId, promotionApplicable) => {
    if (Array.isArray(promotionApplicable) && promotionApplicable.length === 0) {
      return true;
    }

    if (promotionApplicable) {
      return promotionApplicable?.some((applicable) => {
        return (
          applicable?.applicableTo === userType &&
          applicable?.applicableIds?.includes(userId?.toString())
        );
      });
    }
  };

  const isBlackoutDate = (blackoutDates, checkDate) => {
    const normalizedCheckDate = new Date(checkDate).toLocaleDateString("en-CA");
    return blackoutDates.some((blackout) => {
      const blackoutDate = new Date(blackout.date).toISOString().split("T")[0];
      return blackoutDate === normalizedCheckDate;
    });
  };

  const isPromotionApplicableForEntity = (
    promotionEntities,
    currentEntityType,
    currentEntityId
  ) => {
    return promotionEntities.some((entity) => {
      return (
        entity.entityType === currentEntityType &&
        entity.entityIds.some(
          (id) => id.toString() === currentEntityId?.toString()
        )
      );
    });
  };

  const evaluateConditions = (conditions, validity) => {
    const evaluateCondition = (condition, dateToCheck) => {
      const { conditionType, conditionCondition, conditionValue } = condition;
      let startDate = checkInDate;
      let endDate = checkOutDate;
      let inputDate = new Date(dateToCheck);
      let currentDate = new Date();
      let comparisonValue;
      let valueToCompare;

      const los = calculateLOS(startDate, endDate);

      if (conditionType === "booking_date") {
        comparisonValue = moment(currentDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");
      } else if (conditionType === "LOS") {
        comparisonValue = Number(los);
        valueToCompare = Number(conditionValue);
      } else {
        comparisonValue = moment(inputDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");
      }
      // console.log(comparisonValue === valueToCompare, 'llll');

      // console.log(startDate, 'startDate');
      // console.log(endDate, 'endDate');
      // console.log(inputDate, 'inputDate');
      // console.log(currentDate, 'currentDate');
      // console.log(comparisonValue, 'comparisonValue');
      // console.log(valueToCompare, 'valueToCompare');
      // console.log(los, 'los');
      switch (conditionCondition) {
        case "=":
          return comparisonValue === valueToCompare;
        case ">":
          return comparisonValue > valueToCompare;
        case "!=":
          return comparisonValue != valueToCompare;
        case ">=":
          return comparisonValue >= valueToCompare;
        case "<":
          return comparisonValue < valueToCompare;
        case "<=":
          return comparisonValue <= valueToCompare;
        default:
          return false;
      }
    };

    let result = conditions.length > 0 ? true : false;

    for (const condition of conditions) {
      const conditionResult = evaluateCondition(condition, validity);

      if (condition.logicalCondition === "OR") {
        result = result || conditionResult;
      } else {
        result = result && conditionResult;
      }
    }
    return result;
  };

  const [propertyInfo, setPropertyInfo] = useState(false);
  const [guestDetails, setGuestDetails] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(false);
  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };

  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };

  const togglePropertyDetails = () => {
    setPropertyInfo(!propertyInfo);
  };

  const toggleGuestDetails = () => {
    setGuestDetails(!guestDetails);
  };
  const toggleBusinessProfile = () => {
    setBusinessProfile(!businessProfile);
  };
  const [cancellationPolicy, setCancellationPolicy] = useState([]);

  const getPolicyHandler = (id) => {
    try {
      fetch(`https://extranet.travcatalyst.com/api/get-cancel-policy/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            handleOnBooking();
            setCancellationPolicy(data.cancelPolicy);
          } else {
            showErrorToast(data.message);
          }
        });
    } catch (error) { }
  };

  const postBookingData = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("checkIn", checkInDate);
    formdata.append("checkOut", checkOutDate);
    formdata.append("adults", data.adult);
    formdata.append("child", data.children);
    formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    formdata.append("PropertyType", "hotel");
    formdata.append("hotelId", data.hotelId);

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      //   setHotelData(result.data); // Log or handle the parsed JSON response
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error state or retry logic if needed
    }
  };

  const [formData, setFormData] = useState({
    amount: bookingValues.grandTotal,
    guest_name: guestData.guest_name,
    guest_phone: guestData.guest_phone,
    guest_email: guestData.guest_email,
    pincode: guestData.pincode,
    guest_state: guestData.guest_state,
    guest_country: guestData.guest_country,
    guest_city: guestData.guest_city,
    guest_address: guestData.guest_address,
    visitDate: checkInDate,
  });

  const handleSuccessLoginWithGoogle = (response) => {
    const token = response.credential;
    const googleCustomerDetails = jwtDecode(token);
    setLoginWithGoogleCustomer(googleCustomerDetails);
  };

  useEffect(() => {
    if (loginWithGoogleCustomer) {
      handleSubmit();
    }
  }, [loginWithGoogleCustomer]);

  const [atomAccessToken, setAtomAccessToken] = useState(null);
  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setPropertyInfo(true);
      } else {
        setPropertyInfo(true);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setPropertyInfo(false);
    } else {
      setPropertyInfo(true);
    }
  }, []);

  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const fetchDiscountsHandler = async ({
    checkInDateFromBookingData,
    checkOutDateFromBookingData,
  }) => {

    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/promotions`,
        requestOptions
      );
      const result = await response.json();
      setPropertyDiscountArray(result?.data);

      const applicableAutomaticPromotion = result?.data
        ?.filter((item) => {
          const isAutomatic = item.promotionType === "automatic";
          const isValidDate = checkDates(item.startDate, item.endDate);
          const isBookingWithinDiscount = checkBookingDates(
            checkInDateFromBookingData,
            checkOutDateFromBookingData,
            item.startDate,
            item.endDate
          );
          const isUserEligible = isPromotionApplicable(
            userType === "customer"
              ? "customers"
              : userType === "agent"
                ? "agents"
                : "",
            userDetails?.id,
            item.promotionApplicable
          );
          const blackoutDates = item.promotionBlackOutDate || [];
          const isBlackout = isBlackoutDate(blackoutDates, checkInDate);
          const isApplicableForEntity = isPromotionApplicableForEntity(
            item.promotionEntity,
            "hotel",
            bookingData?.hotelId
          );
          const isConditionsValid = evaluateConditions(
            item.promotionConditions,
            checkInDateFromBookingData
          );

          return (
            isAutomatic &&
            isValidDate &&
            isBookingWithinDiscount &&
            isUserEligible &&
            !isBlackout &&
            isApplicableForEntity &&
            isConditionsValid
          );
        })
        ?.sort((a, b) => b.stackable - a.stackable);

      let selectedPromotions = [];
      if (applicableAutomaticPromotion.length > 0) {
        const firstPromotion = applicableAutomaticPromotion[0];
        selectedPromotions.push(firstPromotion);

        if (applicableAutomaticPromotion.length > 1) {
          const secondPromotion = applicableAutomaticPromotion[1];
          if (firstPromotion.stackable && secondPromotion.stackable) {
            selectedPromotions.push(secondPromotion);
          }
        }
      }

      // if (applicableAutomaticPromotion) {
      setSelectedAutomaticDiscount(selectedPromotions);
      // }
    } catch (error) {
      console.error("Error fetching promotions:", error);
    }
  };

  // useEffect(() => {
  //   if (propertyDiscountArray.length > 0) {
  //     const automaticDiscount = propertyDiscountArray.find(
  //       (propertyDiscountArray) => propertyDiscountArray.promotionType === "automatic"
  //     );
  //     if (automaticDiscount) {
  //       setSelectedDiscount(automaticDiscount);
  //     }
  //   }
  // }, [propertyDiscountArray]);

  // useEffect(() => {
  //   fetchDiscountsHandler();
  // }, []);

  const isBookingWithinDiscount = checkBookingDates(
    "2024-09-24",
    "2024-09-25",
    "2024-09-24", // check-in date of booking
    "2024-09-30" // check-out date of booking
  );

  const checkRatePlans = (ratePlan, ratePlanArray) => {
    const ratePlanIds =
      ratePlanArray?.length > 0 ? ratePlanArray?.includes(ratePlan) : true;
    return ratePlanIds;
  };

  const checkRoomsExists = (ratePlan, ratePlanArray) => {
    const ratePlanIds =
      ratePlanArray?.length > 0 ? ratePlanArray?.includes(ratePlan) : true;
    return ratePlanIds;
  };

  const checkDayExists = (dayArray) => {
    const currentDay = new Date()?.getDay();

    const adjustedDay = currentDay === 0 ? 1 : currentDay + 1;
    return dayArray?.includes(adjustedDay);
  };

  const checkMinLOS = (checkin, checkout, minLOS) => {
    const checkinDate = new Date(checkin);
    const checkoutDate = new Date(checkout);

    return minLOS
      ? checkoutDate - checkinDate >= Number(minLOS) * 24 * 60 * 60 * 1000
      : true;
  };

  // const handleDiscountChange = (item) => {
  //   if (selectedDiscount?.id === item?.id) {
  //     setSelectedDiscount([]);
  //   } else {
  //     setSelectedDiscount(item);
  //   }
  // };

  const handleDiscountChange = (item) => {
    setSelectedDiscount([item]);
  };

  const [formErrors, setFormErrors] = useState({});
  const validate = () => {
    const errors = {};
    if (!guestData.guest_name.trim()) {
      errors.guest_name = "required";
    }
    if (!guestData.guest_phone.trim()) {
      errors.guest_phone = "required";
    }

    if (!guestData.guest_email.trim()) {
      errors.guest_email = "is required";
    }

    if (guestData.guest_phone.trim().length < 10) {
      errors.guest_phone = "required";

      showErrorToast("Please enter valid phone number without country code.");
      // return;
      // errors.guest_phone = "required";
    }
    // if (!guestData.gst_number) {
    //   errors.gst_number = "required";
    // }
    // if (!guestData.business_email) {
    //   errors.business_email = "required";
    // }
    // if (!guestData.company_number) {
    //   errors.company_number = "required";
    // }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // Payment Gateway
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);
  const [paymentGatewayLoader, setPaymentGatewayLoader] = useState(false);

  const fetchPaymentGateway = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/fetch/active`,
        {
          method: "GET",
          headers: headers,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setPaymentGateways(result.data);

      const firstActiveGateway = result.data.find(
        (gateway) => gateway.gatewayName === "NTTData"
      );
      if (firstActiveGateway) {
        setSelectedPaymentGateway(firstActiveGateway);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaymentGatewayChange = (item) => {
    setSelectedPaymentGateway(item);
  };

  useEffect(() => {
    fetchPaymentGateway();
  }, []);

  const processPayment = async (bookingData) => {
    try {
      setPaymentGatewayLoader(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
        Cookie: process.env.REACT_APP_COOKIE,
      };

      const body = JSON.stringify({
        order_amount: bookingData?.grandTotal,
        order_number: bookingData?.bookingNo,
        order_id: bookingData?.id,
        email: bookingData?.customerDetails?.email,
        phone: bookingData?.customerDetails?.phone,
        callbackUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${bookingData?.id}`,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/process/${selectedPaymentGateway?.gatewayName}/payment`,
        {
          method: "POST",
          headers: headers,
          body: body,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setAtomAccessToken(result?.data);
      setPaymentGatewayLoader(false);
    } catch (error) {
      console.error("Error processing payment:", error);
      setPaymentGatewayLoader(false);
    }
  };
  // New NTT UseEffect

  useEffect(() => {
    if (selectedPaymentGateway) {
      const scriptUrl = selectedPaymentGateway?.testMode
        ? "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js"
        : "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";

      const existingScript = document.querySelector(
        `script[src="${scriptUrl}"]`
      );

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.async = true;
        // script.onload = () => console.log("Atom Payment Gateway script loaded");
        // script.onerror = () => console.error("Failed to load Atom script");
        document.body.appendChild(script);

        // Clean up script on unmount
        return () => {
          document.body.removeChild(script);
        };
      } else if (existingScript.readyState === "complete") {
        console.log("Atom Payment Gateway script already loaded");
      }
    }
  }, [selectedPaymentGateway]);

  useEffect(() => {
    if (
      atomAccessToken &&
      hotelBookingData &&
      typeof window.AtomPaynetz !== "undefined"
    ) {
      const options = {
        merchId: process.env.REACT_APP_ATOM_MERCHANT_ID, // Use your merchant ID
        atomTokenId: atomAccessToken,
        custEmail: hotelBookingData?.customerDetails?.email,
        custMobile: hotelBookingData?.customerDetails?.phone,
        returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${hotelBookingData?.id}`,
      };

      const atom = new window.AtomPaynetz(options, "uat");

      const handlePaymentClick = (e) => {
        e.preventDefault(); // Prevent default action

        try {
          atom.invoke((status) => {
            if (status === "success") {
              // Navigate to ThankYou component on succ ess
              navigate("/thank-you", {
                state: { bookingId: hotelBookingData?.id }, // Pass data if needed
              });
            } else if (status === "failure" || status === "cancel") {
              // Handle failure or cancellation
              toast.error("Payment failed. Please try again.");
              navigate("/cancel", {
                state: { bookingId: hotelBookingData?.id },
              });
            } else {
              toast.error("Unknown payment status. Please contact support.");
            }
          });
        } catch (err) {
          console.error("Error initiating payment", err);
          toast.error("Failed to initiate payment. Please try again.");
        }
      };

      const paymentButton = document.querySelector("#paymentButton");
      if (paymentButton) {
        paymentButton.addEventListener("click", handlePaymentClick);
      }

      // Clean up event listener on unmount
      return () => {
        if (paymentButton) {
          paymentButton.removeEventListener("click", handlePaymentClick);
        }
      };
    } else {
      console.warn("AtomPaynetz is not ready or required data is missing");
    }
  }, [atomAccessToken, hotelBookingData, selectedPaymentGateway, navigate]);

  // New NTT UseEffect ^
  // useEffect(() => {
  //   if (selectedPaymentGateway) {
  //     // https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js
  //     // https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js
  //     // const scriptUrl = "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";
  //     const scriptUrl = selectedPaymentGateway?.testMode
  //       ? "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js"
  //       : "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";

  //     const existingScript = document.querySelector(
  //       `script[src="${scriptUrl}"]`
  //     );

  //     if (!existingScript) {
  //       const script = document.createElement("script");
  //       script.src = scriptUrl;
  //       script.async = true;
  //       script.onload = () => {
  //         console.log("Atom Payment Gateway script loaded");
  //       };
  //       script.onerror = () => {
  //         console.error("Failed to load Atom Payment Gateway script");
  //       };
  //       document.body.appendChild(script);
  //       return () => {
  //         document.body.removeChild(script);
  //       };
  //     } else if (existingScript.readyState === "complete") {
  //       console.log("Atom Payment Gateway script already loaded");
  //     }
  //   }
  // }, [selectedPaymentGateway]);

  // useEffect(() => {
  //   if (
  //     atomAccessToken &&
  //     hotelBookingData &&
  //     typeof window.AtomPaynetz !== "undefined"
  //   ) {
  //     const options = {
  //       // Test Mode
  //       merchId: "317159",

  //       atomTokenId: atomAccessToken,
  //       // merchId: "599204",
  //       custEmail: hotelBookingData?.customerDetails?.email,
  //       custMobile: hotelBookingData?.customerDetails?.phone,
  //       returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response`,
  //     };

  //     const atom = new window.AtomPaynetz(options, "uat");
  //     console.log("AtomPaynetz initialized", atom);
  //   } else {
  //     console.warn("AtomPaynetz is not ready or required data is missing");
  //   }
  // }, [atomAccessToken, hotelBookingData]);

  const handleMessage = async ({ data }) => {
    if (data === "cancelTransaction") {
      try {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          key: process.env.REACT_APP_BASE_API_KEY,
          Cookie: process.env.REACT_APP_COOKIE,
        };
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${hotelBookingData?.id}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              encData: "",
              order_id: hotelBookingData?.id,
              status: "cancelled",
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        navigate(
          `/thank-you?paymentGateway=${selectedPaymentGateway?.gatewayName}&bookingId=${hotelBookingData?.id}&status=cancelled`
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // commission api
  const fetchCommissions = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/commission/accommodation/${hotelData?.hotelInfo?.id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        if (data?.data?.commissionType != "none") {
          setCommission(data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userType === "agent") {
      fetchCommissions();
    }
  }, [userType]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [hotelBookingData]);

  const handleSubmit = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      key: process.env.REACT_APP_BASE_API_KEY,
      Cookie: process.env.REACT_APP_COOKIE,
    };

    const automaticDiscountToSend = (selectedAutomaticDiscount || []).map((item) => ({
      rate: item?.promotionMode || "",
      amount: item?.promotionAmount || 0,
    }));

    const selectedDiscountToSend = (selectedDiscount || []).map((item) => ({
      rate: item?.promotionMode || "",
      amount: item?.promotionAmount || 0,
    }));

    const body = JSON.stringify({
      booking_type: "accommodation",
      customer_type: userType === "agent" ? "agent" : "customer",
      customer_name: guestData?.guest_name,
      customer_email: guestData?.guest_email,
      customer_phone: guestData?.guest_phone,
      customer_address: addressData?.address,
      customer_country: addressData?.country,
      customer_state: addressData?.state,
      customer_city: addressData?.city,
      customer_zipcode: guestData?.zipcode,
      total: bookingValues?.subtotal,
      grand_total: bookingValues?.grandTotal,
      status: "hold",
      accommodation_notes_information:
        guestData?.accommodation_notes_information,
      misc_details: [],
      markup_details: [],
      tax_details: [
        { rate: bookingValues?.taxSlab, amount: bookingValues?.taxAmount },
      ],
      discount_details_information: [
        ...(automaticDiscountToSend || []),
        ...(selectedDiscountToSend || []),
      ],
      accommodations: [
        {
          accommodation_id: Number(bookingData?.hotelId),
          supplier_id: hotelData?.hotelInfo?.supplier_id,
          room_id: Number(room_id),
          name: hotelData?.hotelInfo?.name,
          destination: bookingData.location ? bookingData.location : "",
          hotel_category: hotelData?.hotelInfo?.category,
          star_rating: hotelData?.hotelInfo?.star_rating
            ? hotelData?.hotelInfo?.star_rating
            : "",
          room_type: ratesData?.room_name,
          meal_plan: selectedPlan.meal_plan ? selectedPlan.meal_plan : "ep",
          rooms: bookingData.room ? bookingData.room : "1",
          nights: noOfNights,
          adults: bookingData.adult ? Number(bookingData.adult) : "",
          extra_adult: selectedPlan?.extra_adult_count,
          child_w_bed: selectedPlan?.childWithBed
            ? selectedPlan?.childWithBed
            : 0,
          child_wo_bed: selectedPlan?.childWithoutBed
            ? selectedPlan?.childWithoutBed
            : 0,
          extra_adult_cost: selectedPlan?.extra_adult,
          child_w_bed_cost: selectedPlan?.childWithoutBedCost
            ? selectedPlan?.childWithoutBedCost
            : 0,
          child_wo_bed_cost: selectedPlan?.childWithoutBedCost
            ? selectedPlan?.childWithoutBedCost
            : 0,
          room_cost: selectedPlan.rate_per_night,
          adhoc: false,
          check_in: checkInDate,
          check_out: checkOutDate,
        },
      ],
      addons: [],
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/booking/create`,
        {
          method: "POST",
          headers,
          body,
          redirect: "follow",
        }
      );

      const result = await response.json();
      if (result.success) {
        setHotelBookingData(result.data);
        processPayment(result.data);
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(selectedDiscount, "selectedDiscount")
  // console.log(bookingData, 'bookingData');
  // console.log(bookingValues, 'bookingValues');
  return (
    <>
      {/* <LoadAtomCheckoutScript onScriptLoad={handleScriptLoad} /> */}
      <div id="checkOutHotels">
        <PageMetaData
          title="Mountain Mysteries"
          description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
          keywords="hotel management, RevCatalyst, property optimization"
        />
        <Header />
        <ReviewReservationBanner
          id="test"
          heading="Review Reservation Details"
        />
        {/* <PageLinks links={["Home", `Properties In ${bookingData.location}`, `${bookingData.hotel_name}`]} /> */}
        <section>
          <div className="container">
            <main className="justify_content_center">
              <div className="reservation-content">
                <div className="flex flex_gap_10">
                  <div className="mt-10">
                    <span
                      onClick={() => [
                        scroll.scrollToTop({
                          duration: 500,
                          smooth: "easeInOutQuad",
                        }),
                        navigate(-1),
                      ]}
                      style={{
                        background: "#cccccc7e",
                        padding: "5px 10px 10px",
                        borderRadius: "6px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                          fill="#333333"
                        ></path>
                      </svg>
                    </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <h4>
                      <b>{hotelData?.hotelInfo?.name}</b>
                    </h4>
                    <GetDirectionRedirect
                      address={hotelData?.hotelInfo?.address}
                    >
                      <small>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                            fill="#888888"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                            fill="#888888"
                          />
                        </svg>{" "}
                        {hotelData?.hotelInfo?.address}
                      </small>
                    </GetDirectionRedirect>
                  </div>
                </div>

                <div className="list_details" id="padding">
                  <div
                    className="flex justify_content_between cursor_pointer align_items_center"
                    onClick={togglePropertyDetails}
                  >
                    <h5 className="text-left pb_10">PROPERTY INFO</h5>
                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "transform 0.3s",
                        transform: propertyInfo
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <path
                        d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                        stroke="#222222"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  {propertyInfo && (
                    <>
                      <div className="hotel-data mt-10">
                        <div className="hotel_img">
                          {hotelData?.hotelImages &&
                            hotelData?.hotelImages.length > 0 ? (
                            <img
                              src={hotelData?.hotelImages[0]}
                              alt="hotel-room-image"
                            />
                          ) : (
                            <img src={imgNotFound} alt="image not found" />
                          )}
                        </div>
                        <div className="hotel-text">
                          <h6>
                            <b>{hotelData?.hotelInfo?.name}</b>
                          </h6>
                          <span>3 Star</span>
                          <GetDirectionRedirect
                            address={hotelData?.hotelInfo?.address}
                          >
                            <small>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                  fill="#888888"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                  fill="#888888"
                                />
                              </svg>{" "}
                              {hotelData?.hotelInfo?.address}
                            </small>
                          </GetDirectionRedirect>
                          <br />
                          <p className="hotel-description">
                            {hotelData?.hotelInfo?.details}
                          </p>
                        </div>
                      </div>
                      <div className="flex_align_center Checkouthotel-main flex_gap_20">
                        <div className="Checkouthotel-dates flex_align_center justify_content_between w-half mt-10">
                          <div className="">
                            <span>Check in</span>
                            <h6 className="">
                              {/* {checkInDate} */}
                              {moment(checkInDate).format("MMM Do YYYY")}
                              {/* {textDateFormatterNoAddInIteration(checkInDate)} */}
                            </h6>
                            {/* <small>2 PM</small> */}
                          </div>
                          <div className=" Checkouthotel-checkout">
                            <span>Check Out</span>
                            <h6>
                              {" "}
                              {moment(checkOutDate).format("MMM Do YYYY")}
                            </h6>
                            {/* <small>2 PM</small> */}
                          </div>
                        </div>
                      </div>
                      <div className="guestDetailsData mt-20">
                        <h5 className="text-left p_10 border-bottom-one">
                          {bookingData?.room} Room , {bookingData?.adult}{" "}
                          Adults,
                          {bookingData?.children && (
                            <span> {bookingData?.children} Child</span>
                          )}
                        </h5>
                        <div className="room_details p_10">
                          <div className="border-right-one" id="right-border-0">
                            <div>
                              <h6>
                                {" "}
                                {bookingData?.room} x {ratesData?.room_name}
                              </h6>
                            </div>
                            <div className="mt-10 ">
                              <span>
                                {bookingData?.adult} Adults,
                                {bookingData?.children && (
                                  <span> {bookingData?.children} Child</span>
                                )}{" "}
                                <small>
                                  {" "}
                                  ({selectedPlan?.meal_plan_name}){" "}
                                </small>
                              </span>
                              {/* <p>{selectedPlan?.meal_plan_name} </p> */}
                              {/* <div className="alert_text p_b_10">Free Cancellation before 30 Jun 01:59 PM</div> */}
                              <div
                                className="link_btn mt-10 cursor_pointer"
                                onClick={() =>
                                  getPolicyHandler(bookingData?.hotelId)
                                }
                              >
                                <b>Cancellation Policy</b>
                              </div>
                              {booking && (
                                <section className="popup_backdrop">
                                  <div className="cancellation_content">
                                    <div className="close_svg">
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        onClick={closePopup}
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 13L13 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                        <path
                                          d="M13 13L1 1"
                                          stroke="#AAAAAA"
                                          strokeWidth="1.2"
                                        />
                                      </svg>
                                    </div>
                                    <div className="container">
                                      <div className="overview-content mt-20">
                                        <div
                                          className="cancellation_policy"
                                          dangerouslySetInnerHTML={{
                                            __html: cancellationPolicy?.content,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </div>
                          </div>
                          <div>
                            {/* <div>
                              <li className="p_b_10">Book @ ₹0 available</li>
                              <li className="p_b_10">
                                Room With Free Cancellation
                              </li>
                              <li>No meals included</li>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="list_details p_0">
                  <div
                    className="flex justify_content_between p_20 cursor_pointer align_items_center"
                    onClick={toggleGuestDetails}
                    id="padding"
                  >
                    <h5 className="text-left">GUEST DETAILS</h5>
                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "transform 0.3s",
                        transform: guestDetails
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <path
                        d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                        stroke="#222222"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  {guestDetails && (
                    <>
                      <form action="">
                        <div
                          className="p_20 p_top_0 border-bottom-one"
                          id="padding"
                        >
                          <div className="input-flex">
                            <div className="HotelCheckout-form w-half">
                              <label htmlFor="">Full Name</label>
                              <input
                                className="HotelCheckout-Input"
                                id="guest_name"
                                type="text"
                                name="guest_name"
                                value={guestData.guest_name}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_name && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Name"
                              />
                            </div>
                            <div className="CheckoutHotel-Fields w-half">
                              <label htmlFor="">Phone</label>
                              <input
                                className="HotelCheckout-Input"
                                type="number"
                                id="guest_phone"
                                name="guest_phone"
                                value={guestData.guest_phone}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_phone && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Phone"
                              />
                            </div>
                          </div>

                          <div className="input-flex">
                            <div className="CheckoutHotel-Fields w-half">
                              <label htmlFor="">
                                Email Address{" "}
                                {/* <small>
                                  (Booking voucher will be sent to this email
                                  ID)
                                </small> */}
                              </label>
                              <input
                                className="HotelCheckout-Input"
                                id="guest_email"
                                name="guest_email"
                                value={guestData.guest_email}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.guest_email && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Email"
                              />
                            </div>
                            <div className="CheckoutHotel-Fields w-half">
                              <label htmlFor="">Zipcode</label>
                              <input
                                className="HotelCheckout-Input"
                                type="number"
                                id="zipcode"
                                name="zipcode"
                                value={guestData.zipcode}
                                onChange={guestDataHandleChange}
                                style={
                                  formErrors.zipcode && {
                                    backgroundColor: "#ff000040",
                                    border: "1px solid red",
                                  }
                                }
                                placeholder="Zipcode"
                              />
                            </div>
                          </div>

                          <div className="mt-10">
                            <label htmlFor="address">Address</label>
                            <AddressAutocomplete
                              formdata={formData}
                              onAddressChange={handleAddressData}
                            />
                          </div>

                          <div className="mt-5">
                            <label htmlFor="">Additional Details</label>
                            <textarea
                              className="HotelCheckout-Input"
                              id="accommodation_notes_information"
                              name="accommodation_notes_information"
                              value={guestData.accommodation_notes_information}
                              onChange={guestDataHandleChange}
                              required
                              placeholder="Additional Details"
                            />
                          </div>
                        </div>
                      </form>
                      {/* <div
                        onClick={toggleBusinessProfile}
                        className="p_20 flex_align_center flex_gap_10"
                        id="padding"
                      >
                        <input
                          type="checkbox"
                          className="cursor_pointer"
                          checked={businessProfile}
                        />
                        <div className="cursor_pointer">
                          Enter GST Details
                          <small> (Optional)</small>
                        </div>
                      </div>
                      {businessProfile && (
                        <form onSubmit={handleSubmit}>
                          <div className="p_20 p_top_0" id="padding">
                            <h5 className="text-left">BUSINESS PROFILE</h5>
                            <div className="input-flex mt-10">
                              <div>
                                <input
                                  className="w-full"
                                  id="gst_number"
                                  name="gst_number"
                                  value={guestData.gst_number}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.gst_number && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="GST Number"
                                />
                              </div>
                              <div>
                                <input
                                  className="w-full"
                                  id="business_email"
                                  name="business_email"
                                  value={guestData.business_email}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.business_email && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="Business Email ID"
                                />
                              </div>
                            </div>
                            <div className="input-flex">
                              <div>
                                <input
                                  className="w-full"
                                  id="company_number"
                                  type="number"
                                  name="company_number"
                                  value={guestData.company_number}
                                  onChange={guestDataHandleChange}
                                  style={
                                    formErrors.company_number && {
                                      backgroundColor: "#ff000040",
                                      border: "1px solid red",
                                    }
                                  }
                                  placeholder="Company Phone Number"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )} */}

                      {/* {userType === "agent" && (
                        <>
                          <div
                            onClick={() =>
                              setPayWithAgentCredit(!payWithAgentCredit)
                            }
                            className="px_20 flex_align_center flex_gap_10"
                            id="padding"
                          >
                            <input
                              type="checkbox"
                              className="cursor_pointer"
                              checked={payWithAgentCredit}
                            />
                            <div className="cursor_pointer">
                              Pay With Agent Credits
                              <small>
                                {" "}
                                (
                                {formatIndianCurrency(
                                  agentAdvanceCalculations?.remaining_credit_balance
                                )}
                                )
                              </small>
                            </div>
                          </div>
                          <div
                            onClick={() => setPayWithAdvance(!payWithAdvance)}
                            className="p_20 flex_align_center flex_gap_10"
                            id="padding"
                          >
                            <input
                              type="checkbox"
                              className="cursor_pointer"
                              checked={payWithAdvance}
                            />
                            <div className="cursor_pointer">
                              Pay With Advance Amount
                              <small>
                                {" "}
                                (
                                {formatIndianCurrency(
                                  agentAdvanceCalculations?.remaining_advance_balance
                                )}
                                )
                              </small>
                            </div>
                          </div>
                        </>
                      )} */}
                    </>
                  )}
                </div>

                {paymentGateways.length > 0 ? (
                  <>
                    <div className="mt-20">
                      <label htmlFor="">
                        <strong>Payment gateway</strong>
                      </label>
                      {paymentGateways &&
                        paymentGateways.map((item, index) => (
                          <>
                            <div
                              key={index}
                              className="flex discountSingleDiv justify_content_between w-100"
                            >
                              <div className="flex">
                                <label
                                  style={{
                                    textTransform: "capitalize",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  htmlFor={item?.gatewayName}
                                >
                                  <input
                                    checked={
                                      selectedPaymentGateway?.gatewayName ===
                                      item?.gatewayName
                                    }
                                    onChange={() =>
                                      handlePaymentGatewayChange(item)
                                    }
                                    type="radio"
                                    id={item?.gatewayName}
                                  />
                                  <div style={{ cursor: "pointer" }}>
                                    <div>{item.gatewayName}</div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>

                    <div className="buttons">
                      {/* {userDetails && userToken ? (
                    <button onClick={handleSubmit} className="common_btn">
                      Proceed to Payment
                    </button>
                  ) : (
                    <button
                      disabled
                      style={{ cursor: "not-allowed" }}
                      className="common_btn"
                    >
                      Please Login First
                    </button>
                  )} */}
                      <button
                        // disabled
                        onClick={handleSubmit}
                        className="common_btn "
                      >
                        Proceed to Payment
                      </button>
                    </div>
                  </>
                ) :
                  <div className="buttons">
                    <button
                      // onClick={bookPackageHandle}
                      disabled={true}
                      className="common_btn"
                      style={{ cursor: 'not-allowed' }}
                    >
                      Proceed to Payment
                    </button>
                  </div>
                }
                {/* {!userDetails && !userToken && (
                    <>
                      <p style={{ textAlign: 'center', margin: '5px 0' }}>or</p>
                      <GoogleLogin
                        onSuccess={handleSuccessLoginWithGoogle}
                        onError={() => showErrorToast('Login Failed')}
                      />
                    </>
                  )} */}
              </div>
              <aside>
                <div className="sticky">
                  <div className="common_background" id="priceBreakup">
                    {/* start */}
                    <div
                      className="flex justify_content_between "
                    // onClick={togglePaymentDetails}
                    >
                      <h5 className="text-left">Price Breakup</h5>
                      <div>
                        <button
                          className={`${activePayment === "priceSummary"
                            ? "cursor_pointer"
                            : "cursor_pointer"
                            }`}
                          onClick={() => {
                            handleButtonClick();
                            togglePaymentDetails();
                          }}
                        >
                          <small className="link_btn">
                            {" "}
                            View Full Breakup{" "}
                          </small>
                          <svg
                            width="13"
                            height="8"
                            viewBox="0 0 13 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transition: "transform 0.3s",
                              transform: paymentDetails
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          >
                            <path
                              d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                              stroke="#222222"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {activePayment === "priceSummary" && paymentDetails && (
                      <div>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Type ({bookingData?.room} R | {noOfNights} N )
                          </p>
                          <span className="room_text-right">
                            {ratesData?.room_name}
                          </span>
                        </div>
                        {/* <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                          <p>Room Rate</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(selectedPlan?.rate_per_night)}
                          </span>
                        </div> */}

                        {/* {commission && commission?.commissionAmount && (
                          <div className="flex justify_content_between mt-10 ">
                            <p>
                              Commission Amount ({commission?.commissionType})
                            </p>
                            {commission.commissionType === "fixed" && (
                              <span className="room_text-right">
                                {formatIndianCurrency(
                                  commission?.commissionAmount
                                )}
                              </span>
                            )}
                          </div>
                        )} */}

                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Rate <small>(Per Night)</small>
                          </p>
                          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            {/* {commission && Object.keys(commission).length > 0 && bookingValues?.roomRatePerNight && (
                              <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                {formatIndianCurrency(bookingValues?.roomRatePerNight)}
                              </small>
                            )} */}
                            <b>
                              {formatIndianCurrency(bookingValues.newRoomRate)}
                            </b>
                          </span>
                        </div>

                        {/* {commission && commission?.commissionAmount && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>Your Rate</p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                bookingValues?.commissionRoomRate
                              )}
                            </span>
                          </div>
                        )} */}
                        {agentAdvanceCalculations.pay_with_company_credit && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>Agent Credit</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.credit_balance -
                                agentAdvanceCalculations?.remaining_credit_balance
                              )}
                            </span>
                          </div>
                        )}

                        {agentAdvanceCalculations.pay_with_advance && (
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>Agent Advance</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.advanceLimit -
                                agentAdvanceCalculations?.remaining_advance_balance
                              )}
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Total Amount to be paid</b>
                          </h6>
                          <h6>
                            <b>
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.balanceAmount ||
                                  agentAdvanceCalculations?.balanceAmount === 0
                                  ? agentAdvanceCalculations?.balanceAmount
                                  : bookingValues?.grandTotal
                              )}
                              {/* {formatIndianCurrency(agentAdvanceCalculations?.balanceAmount)} */}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}

                    {activePayment === "priceDetails" && (
                      <div>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Type
                            {/* ({bookingData?.room} R | {noOfNights} N ) */}
                          </p>
                          <span className="room_text-right">
                            {ratesData?.room_name}
                          </span>
                        </div>

                        {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && (
                          <div className="mt-10">
                            <p><b>Property Discount{selectedAutomaticDiscount?.length > 1 ? 's' : ''}</b></p>
                            {selectedAutomaticDiscount?.map((discount, index) => (
                              <div className="flex justify_content_between mt-2" key={discount.id}>
                                <span className="discount-name">{discount.promotionName}</span>
                                <span className="room_text-right green">
                                  {discount.promotionMode === "percentage"
                                    ? `${+discount.promotionAmount}%`
                                    : `${formatIndianCurrency(discount.promotionAmount)}`}
                                </span>
                              </div>
                            ))}

                            {selectedDiscount && selectedDiscount?.length > 0 && selectedDiscount?.map((discount, index) => (
                              <div className="flex justify_content_between mt-2" key={discount.id}>
                                <span className="discount-name">{discount.promotionName}</span>
                                <span className="room_text-right green">
                                  {discount.promotionMode === "percentage"
                                    ? `${+discount.promotionAmount}%`
                                    : `${formatIndianCurrency(discount.promotionAmount)}`}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* {bookingValues?.discountAmt > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Discount Amt.{" "}
                              <small> ({noOfNights} Nights)</small>
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(bookingValues?.discountAmt)}
                            </span>
                          </div>
                        )} */}

                        <div className="flex justify_content_between mt-10">
                          <p>
                            Room Rate <small>(Per Night)</small>
                          </p>
                          {/* <span className="room_text-right">
                            {formatIndianCurrency(bookingValues.newRoomRate)}
                          </span> */}

                          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && bookingValues.newRoomRate && bookingValues.roomRateAfterDiscount && (
                              <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                {formatIndianCurrency(bookingValues?.roomRatePerNight)}
                              </small>
                            )}
                            <b>
                              {formatIndianCurrency(bookingValues.roomRateAfterDiscount ? bookingValues.roomRateAfterDiscount : bookingValues.newRoomRate)}
                            </b>
                          </span>
                        </div>

                        {(bookingData?.room > 1 || noOfNights > 1) && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              {noOfNights} Night * {bookingData?.room} Rooms
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                bookingValues.roomRateAfterDiscount *
                                bookingData?.room *
                                noOfNights
                              )}
                            </span>
                          </div>
                        )}

                        {selectedPlan.extra_adult_count > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              {selectedPlan.extra_adult_count} Extra Adult{" "}
                              <small>
                                (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {formatIndianCurrency(
                                      bookingValues?.extraAdultPriceWithMarkup
                                    )}
                                    {" "}
                                  </span>
                                )} */}


                                {formatIndianCurrency(
                                  bookingValues?.extraAdultPrice
                                )}{" "}
                                * {noOfNights} Night){" "}
                              </small>{" "}
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                bookingValues?.totalAdultCost
                              )}
                            </span>
                          </div>
                        )}

                        {bookingValues?.childPrices > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              {bookingData.children} Extra Child{" "}
                              <small>
                                {" "}
                                (
                                {/* {commission && Object.keys(commission).length > 0 && (
                                  <span style={{ color: 'red', textDecoration: 'line-through' }}>
                                    {formatIndianCurrency(
                                      bookingValues?.childPricesWithMarkup
                                    )}
                                    {" "}
                                  </span>
                                )} */}
                                {formatIndianCurrency(
                                  bookingValues?.childPrices
                                )}{" "}
                                * {noOfNights} Night){" "}
                              </small>{" "}
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(
                                bookingValues?.totalChildAdultCost
                              )}
                            </span>
                          </div>
                        )}

                        {userDetails?.agent_category_discount && (
                          <div className="flex justify_content_between mt-10 green">
                            <p>
                              Discount % <small>(TAC)</small>
                            </p>
                            <span className="room_text-right green">
                              {Number(userDetails?.agent_category_discount)}%
                            </span>
                          </div>
                        )}
                        {/* {bookingValues?.discountAmt > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>Discount Amt.</p>
                            <span className="room_text-right">
                              {formatIndianCurrency(bookingValues?.discountAmt)}
                            </span>
                          </div>
                        )} */}
                        {/* {bookingValues?.automaticDiscounts?.amount && (
                          <div className="flex justify_content_between mt-10 green">
                            <p>
                              Discount <small>(Automatic Discount)</small>
                            </p>
                            <span className="room_text-right green">
                              {bookingValues?.automaticDiscounts?.type ===
                                "percentage"
                                ? `${+bookingValues?.automaticDiscounts
                                  ?.amount}%`
                                : `${formatIndianCurrency(
                                  bookingValues?.automaticDiscounts?.amount
                                )}`}
                            </span>
                          </div>
                        )} */}

                        {/* {selectedDiscount &&
                          Object.keys(selectedDiscount).length > 0 && (
                            <div className="flex justify_content_between mt-10">
                              <p>Selected Discount</p>
                              <span className="room_text-right green">
                                {selectedDiscount?.promotionMode ===
                                  "percentage"
                                  ? `${+selectedDiscount?.promotionAmount}%`
                                  : `${formatIndianCurrency(
                                    selectedDiscount?.promotionAmount
                                  )}`}
                              </span>
                            </div>
                          )} */}


                        {/* <div className="flex justify_content_between mt-10">
                        <p>totalChildAdultCost</p>
                        <span className="room_text-right">
                          {formatIndianCurrency(bookingValues?.totalChildAdultCost)}
                          {formatIndianCurrency(bookingValues?.childPrices)}
                        </span>
                      </div> */}

                        {commission && commission?.commissionAmount && (
                          <div className="flex justify_content_between mt-10">
                            <p>Commission{' '}
                              <small>
                                ( {commission.commissionType === "fixed" ? (
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      commission?.commissionAmount
                                    )}
                                  </span>
                                ) : (
                                  <span className="room_text-right">
                                    {commission?.commissionAmount}%
                                  </span>
                                )})
                              </small>
                            </p>
                            {formatIndianCurrency(Number(bookingValues?.calculatedCommissionAmount))}
                            {/* {commission.commissionType === "fixed" ? (
                              <span className="room_text-right">
                                {formatIndianCurrency(
                                  commission?.commissionAmount
                                )}
                              </span>
                            ) : (
                              <span className="room_text-right">
                                {commission?.commissionAmount}%
                              </span>
                            )} */}
                          </div>
                        )}

                        {/* 
                        {commission && Object.keys(commission).length > 0 && bookingValues?.calculatedCommissionAmount && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Your Savings
                            </p>
                            <span className="room_text-right">
                              {formatIndianCurrency(Number(bookingValues?.calculatedCommissionAmount))}
                            </span>
                          </div>
                        )} */}

                        <div className="flex justify_content_between mt-10">
                          <p>Subtotal</p>
                          {/* <span className="room_text-right">
                            {formatIndianCurrency(bookingValues.subtotal)}
                          </span> */}

                          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            {commission && Object.keys(commission).length > 0 && bookingValues?.calculatedCommissionAmount && (
                              <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                {formatIndianCurrency(
                                  Number(bookingValues?.subtotal) + Number(bookingValues?.calculatedCommissionAmount)
                                )}
                              </small>
                            )}
                            <b>
                              {formatIndianCurrency(bookingValues?.subtotal)}
                            </b>
                          </span>
                        </div>

                        <div className="flex justify_content_between mt-10">
                          <p>Tax Amount ({bookingValues?.taxSlab}%)</p>
                          <span className="room_text-right">
                            {formatIndianCurrency(bookingValues.taxAmount)}
                          </span>
                        </div>

                        {agentAdvanceCalculations.pay_with_company_credit && (
                          <div className="flex mt-10 justify_content_between p_b_10">
                            <p>Agent Credit</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.credit_balance -
                                agentAdvanceCalculations?.remaining_credit_balance
                              )}
                            </span>
                          </div>
                        )}

                        {agentAdvanceCalculations.pay_with_advance && (
                          <div className="flex justify_content_between border-bottom-one p_b_10">
                            <p>Agent Advance</p>
                            <span className="room_text-right">
                              -{" "}
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.advanceLimit -
                                agentAdvanceCalculations?.remaining_advance_balance
                              )}
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <h6>
                            <b>Grand Total</b>
                          </h6>
                          <h6>
                            <b>
                              {formatIndianCurrency(
                                agentAdvanceCalculations?.balanceAmount ||
                                  agentAdvanceCalculations?.balanceAmount === 0
                                  ? agentAdvanceCalculations?.balanceAmount
                                  : bookingValues?.grandTotal
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* discounts */}

                  {propertyDiscountArray.length > 0 && (
                    <>
                      {propertyDiscountArray.some((item) => {
                        const isCouponCode =
                          item?.promotionType === "coupon" ? true : false;

                        const isValidDate = checkDates(
                          item.startDate,
                          item.endDate
                        );

                        const isBookingWithinDiscount = checkBookingDates(
                          checkInDate,
                          checkInDate,
                          item.startDate,
                          item.endDate
                        );
                        const isUserEligible = isPromotionApplicable(
                          userType === "customer"
                            ? "customers"
                            : userType === "agent"
                              ? "agents"
                              : "",
                          userDetails?.id,
                          item.promotionApplicable
                        );

                        const blackoutDates = item.promotionBlackOutDate || [];
                        const isBlackout = isBlackoutDate(
                          blackoutDates,
                          checkInDate
                        );
                        const isApplicableForEntity =
                          isPromotionApplicableForEntity(
                            item.promotionEntity,
                            "hotel",
                            bookingData?.hotelId
                          );
                        const isConditionsValid = evaluateConditions(
                          item.promotionConditions,
                          checkInDate
                        );

                        return (
                          isCouponCode &&
                          isValidDate &&
                          isBookingWithinDiscount &&
                          isUserEligible &&
                          !isBlackout &&
                          isApplicableForEntity &&
                          isConditionsValid
                        );
                      }) && (
                          <div
                            className="common_background"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="flex justify_content_between cursor_pointer">
                              <div className="flex justify_content_between w-100">
                                <div>
                                  <h5 className="text-left">Discounts</h5>
                                </div>
                                {selectedDiscount && (
                                  <button
                                    className="view_btn"
                                    onClick={() => setSelectedDiscount([])}
                                  >
                                    clear
                                  </button>
                                )}
                              </div>
                            </div>
                            <div>
                              {propertyDiscountArray.map((item, index) => {
                                const isCouponCode =
                                  item?.promotionType === "coupon" ? true : false;

                                const isValidDate = checkDates(
                                  item.startDate,
                                  item.endDate
                                );

                                const isBookingWithinDiscount = checkBookingDates(
                                  checkInDate,
                                  checkInDate,
                                  item.startDate,
                                  item.endDate
                                );
                                const isUserEligible = isPromotionApplicable(
                                  userType === "customer"
                                    ? "customers"
                                    : userType === "agent"
                                      ? "agents"
                                      : "",
                                  userDetails?.id,
                                  item.promotionApplicable
                                );

                                const blackoutDates =
                                  item.promotionBlackOutDate || [];
                                const isBlackout = isBlackoutDate(
                                  blackoutDates,
                                  checkInDate
                                );
                                const isApplicableForEntity =
                                  isPromotionApplicableForEntity(
                                    item.promotionEntity,
                                    "hotel",
                                    bookingData?.hotelId
                                  );
                                const isConditionsValid = evaluateConditions(
                                  item.promotionConditions,
                                  checkInDate
                                );

                                return (
                                  isCouponCode &&
                                  isValidDate &&
                                  isBookingWithinDiscount &&
                                  isUserEligible &&
                                  !isBlackout &&
                                  isApplicableForEntity &&
                                  isConditionsValid && (
                                    <div
                                      key={index}
                                      className="flex discountSingleDiv justify_content_between w-100"
                                    >
                                      <div className="flex">
                                        <label
                                          style={{
                                            textTransform: "capitalize",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          htmlFor={item?.promotionName}
                                        >
                                          {" "}
                                          <input
                                            // checked={
                                            //   selectedDiscount?.id === item?.id
                                            // }
                                            // onChange={() =>
                                            //   handleDiscountChange(item)
                                            // }
                                            // type="radio"
                                            // id={item?.promotionName}


                                            checked={selectedDiscount?.some((discount) => discount.id === item.id)}
                                            onChange={() => handleDiscountChange(item)}
                                            type="radio"
                                            id={item.promotionName}
                                          />
                                          <div>
                                            <div>
                                              {item?.promotionName?.replace(
                                                /-/g,
                                                " "
                                              )}
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                      <div>
                                        {item?.promotionMode === "fixed" ? (
                                          formatIndianCurrency(
                                            item?.promotionAmount
                                          )
                                        ) : (
                                          <>{item?.promotionAmount}%</>
                                        )}
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </>
                  )}

                  <OurQuery />
                </div>
              </aside>

              {/* {mobilePriceBreakup && (
                <>
                  <div className="flex justify_content_between total_amount align_items_center">
                    <div>
                      <h6 className="font_12">Total Amount to be paid </h6>
                      <h6>
                        <span
                          className={` ${
                            Number(bookingValues?.grandTotal) <= 0 &&
                            "filterBlur3"
                          }`}
                        >
                          {formatIndianCurrency(bookingValues?.grandTotal)}{" "}
                          <svg
                            onClick={() => setPriceBreakupPopup(true)}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0,0,256,256"
                            width="20px"
                            height="20px"
                            fillRule="nonzero"
                          >
                            <g
                              fill="#ffffff"
                              fillRule="nonzero"
                              stroke="none"
                              strokeWidth="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                              style={{ mixBlendMode: "normal" }}
                            >
                              <g transform="scale(8,8)">
                                <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </h6>{" "}
                    </div>
                    {priceBreakupPopup && (
                      <>
                        <div className="bg_back_drop_overlay">
                          <div
                            className="common_background common_background_parent_sticky"
                            id="PriceBreak"
                          >
                            <div className="flex justify_content_end p_b_10 ">
                              <svg
                                onClick={() => setPriceBreakupPopup(false)}
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 12.5L13 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                                <path
                                  d="M13 12.5L1 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                              </svg>
                            </div>
                            <div className="flex justify_content_between align_items_center text-black">
                              <h4
                                style={{
                                  fontSize: "14px",
                                  padding: "10px 0px",
                                }}
                              >
                                Fare Summary
                              </h4>

                              <div onClick={togglePaymentDetails}>
                                <button
                                  className={`${
                                    activePayment === "priceSummary" ? "" : ""
                                  }`}
                                  onClick={handleButtonClick}
                                >
                                  <small className="link_btn">
                                    {activePayment === "priceSummary"
                                      ? "View Breakup"
                                      : "Minimize Breakup"}{" "}
                                  </small>
                                  <svg
                                    width="13"
                                    height="8"
                                    viewBox="0 0 13 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      transition: "transform 0.3s",
                                      transform: paymentDetails
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    }}
                                  >
                                    <path
                                      d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                      stroke="#222222"
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            {activePayment === "priceSummary" &&
                              paymentDetails && (
                                <div>
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>
                                        {" "}
                                        Room Type ({bookingData?.room} R |{" "}
                                        {noOfNights} N )
                                      </b>
                                    </p>
                                    <span className="room_text-right">
                                      {ratesData?.room_name}
                                    </span>
                                  </div>
                                  <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                    <p className="text-black">
                                      <b>Room Rate</b>
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        selectedPlan?.rate_per_night
                                      )}
                                    </span>
                                  </div>
                                  {agentAdvanceCalculations.pay_with_company_credit && (
                                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                      <p className="text-black">
                                        <b>Agent Credit</b>
                                      </p>
                                      <span className="room_text-right">
                                        -{" "}
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.credit_balance -
                                            agentAdvanceCalculations?.remaining_credit_balance
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  {agentAdvanceCalculations.pay_with_advance && (
                                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                                      <p className="text-black">
                                        <b>Agent Advance</b>
                                      </p>
                                      <span className="room_text-right">
                                        -{" "}
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.advanceLimit -
                                            agentAdvanceCalculations?.remaining_advance_balance
                                        )}
                                      </span>
                                    </div>
                                  )}

                                  <div className="flex justify_content_between mt-10">
                                    <h6 className="text-black">
                                      <b>Total Amount to be paid</b>
                                    </h6>
                                    <h6 className="text-black">
                                      <b>
                                        {formatIndianCurrency(
                                          agentAdvanceCalculations?.balanceAmount ||
                                            agentAdvanceCalculations?.balanceAmount ===
                                              0
                                            ? agentAdvanceCalculations?.balanceAmount
                                            : bookingValues?.grandTotal
                                        )}
                                      </b>
                                    </h6>
                                  </div>
                                </div>
                              )}

                            {activePayment === "priceDetails" && (
                              <div>
                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Room Type </b>({bookingData?.room} R |{" "}
                                    {noOfNights} N )
                                  </p>
                                  <span className="room_text-right">
                                    {ratesData?.room_name}
                                  </span>
                                </div>

                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Room Rate</b> <small>(Per Night)</small>
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      selectedPlan?.rate_per_night
                                    )}
                                  </span>
                                </div>
                                {userDetails?.agent_category_discount && (
                                  <div className="flex justify_content_between mt-10 green">
                                    <p className="text-black">
                                      <b> Discount % </b>
                                      <small>(TAC)</small>
                                    </p>
                                    <span className="room_text-right green">
                                      {Number(
                                        userDetails?.agent_category_discount
                                      )}
                                      %
                                    </span>
                                  </div>
                                )}
                                {bookingValues?.discountAmt > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>Discount Amt.</b>
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        bookingValues?.discountAmt
                                      )}
                                    </span>
                                  </div>
                                )}
                                {bookingValues?.childPrices > 0 && (
                                  <div className="flex justify_content_between mt-10">
                                    <p className="text-black">
                                      <b>{bookingData.children} Extra Child </b>
                                      <small>
                                        {" "}
                                        (
                                        {formatIndianCurrency(
                                          bookingValues?.childPrices
                                        )}{" "}
                                        * {noOfNights} Night){" "}
                                      </small>{" "}
                                    </p>
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        bookingValues?.totalChildAdultCost
                                      )}
                                    </span>
                                  </div>
                                )}
                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Subtotal</b>
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      bookingValues.subtotal
                                    )}
                                  </span>
                                </div>

                                <div className="flex justify_content_between mt-10">
                                  <p className="text-black">
                                    <b>Tax Amount</b> ({bookingValues?.taxSlab}
                                    %)
                                  </p>
                                  <span className="room_text-right">
                                    {formatIndianCurrency(
                                      bookingValues.taxAmount
                                    )}
                                  </span>
                                </div>

                                {agentAdvanceCalculations.pay_with_company_credit && (
                                  <div className="flex mt-10 justify_content_between p_b_10">
                                    <p className="text-black">
                                      <b>Agent Credit</b>
                                    </p>
                                    <span className="room_text-right">
                                      -{" "}
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.credit_balance -
                                          agentAdvanceCalculations?.remaining_credit_balance
                                      )}
                                    </span>
                                  </div>
                                )}
                                {agentAdvanceCalculations.pay_with_advance && (
                                  <div className="flex justify_content_between border-bottom-one p_b_10">
                                    <p className="text-black">
                                      <b>Agent Advance</b>
                                    </p>
                                    <span className="room_text-right">
                                      -{" "}
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.advanceLimit -
                                          agentAdvanceCalculations?.remaining_advance_balance
                                      )}
                                    </span>
                                  </div>
                                )}

                                <div className="flex justify_content_between mt-10 border-top pt_10">
                                  <h6 className="text-black">
                                    <b>Grand Total</b>
                                  </h6>
                                  <h6 className="text-black">
                                    <b>
                                      {formatIndianCurrency(
                                        agentAdvanceCalculations?.balanceAmount ||
                                          agentAdvanceCalculations?.balanceAmount ===
                                            0
                                          ? agentAdvanceCalculations?.balanceAmount
                                          : bookingValues?.grandTotal
                                      )}
                                    </b>
                                  </h6>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )} */}
            </main>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default CheckOutHotelNew;

// razer pay
// useEffect(() => {
//   const script = document.createElement('script');
//   script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//   script.async = true;

//   const handleLoad = () => console.log('Razorpay script loaded!');
//   const handleError = (e) => {
//     console.error('Failed to load Razorpay script', e);
//     // setError(e);
//   };

//   script.addEventListener('load', handleLoad);
//   script.addEventListener('error', handleError);

//   document.body.appendChild(script);

//   return () => {
//     script.removeEventListener('load', handleLoad);
//     script.removeEventListener('error', handleError);
//     document.body.removeChild(script);
//   };
// }, []);

// const handleConfirmAndPayClick = async () => {
//   const responseData = calculateCreditAndBalance(
//     agent.agent_name,
//     ID,
//     creditChecked,
//     creditLimit,
//     advanceChecked,
//     advanceLimit,
//     priceBreakup,
//     agentSearchData.start_date,
//     agentSearchData.end_date
//   );

//   const roomDetails = {
//     room_id: selectedRoom.room_id,
//     roomTypeName: selectedRoom.room_name,
//     ratePlanId: selectedRatePlan.rate_plan_id,
//     adult: agentSearchData.adults,
//     taxType: "exclusive",
//     roomPlan: selectedRatePlan.meal_plan,
//     rooms: noOfRooms,
//     nights: noOfNights,
//     roomRate: roomRate,
//     children: agentSearchData.number_of_children,
//     checkIn: agentSearchData.start_date,
//     checkOut: agentSearchData.end_date,
//     taxRate: priceBreakup.taxSlab,
//     taxAmount: priceBreakup.taxAmount,
//     disAmount: priceBreakup.bookingDiscountAmount || 0,
//     disRate: propertyDiscount || 0,
//     totalAmount: priceBreakup.subtotal,
//     netTotal: priceBreakup.grandTotal,
//     tacRate: agentDiscount || 0,
//     tacAmount: priceBreakup.agentDiscountAmount || 0,
//     extraAdultPrice: selectedRatePlan.extra_adult || 0,
//     extraAdult: extraAdultCount || 0,
//   };
//   responseData.roomDetails = [roomDetails];

//   console.log(responseData, "responseData");

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/push-booking`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${userToken}`,
//         },
//         body: JSON.stringify(responseData),
//       }
//     );

//     if (!response.ok) {
//       const errorData = await response.json();
//       console.error(`HTTP error! status: ${response.status}`, errorData);
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const data = await response.json();

//      if(data.data.balance_amount === 0){
//          showSuccessToast('Payment Successful');
//          navigate('/bookings');
//      }else{
//       redirectToPaymentPage(data.data);
//       setShowReservationsDetails(false);
//      }

//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// const redirectToPaymentPage = (bookingData) => {
//   if (!window.Razorpay) {
//     console.error('Razorpay script is not loaded');
//     return;
//   }
//   // localStorage.
//   const options = {
//     key: process.env.REACT_APP_RAZOR_KEY,
//     amount: bookingData.balance_amount * 100,
//     currency: 'INR',
//     order_id: bookingData.razorpayOrderId,
//     name: selectedProperty.property_name,
//     description: `Payment against Booking - #${bookingData.bookingId}`,
//     image: "path/to/image/ecologo.svg",
//     prefill: {
//       name: "John Doe",
//       contact: "1234567890",
//       email: "john.doe@example.com"
//     },
//     handler: function (response) {
//          console.log(response ,"response_response")
//         const data = {
//           reservationId: bookingData.bookingId,
//           status: 'confirmed',
//           paidAmount: bookingData.balance_amount,
//           gatewayOrderId: response.razorpay_order_id,
//           gatewayPaymentId: response.razorpay_payment_id,
//           gatewayPaymentSignature: response.razorpay_signature,
//           gatewayMethod: 'Razorpay',
//           gatewayCurrency: 'INR'
//         };

//         const queryString = new URLSearchParams(data).toString();
//         navigate(`/payment?${queryString}`);

//     },
//     modal: {
//       ondismiss: function () {
//         const data = {
//           reservationId: bookingData.bookingId,
//           status: 'cancelled',
//           paidAmount: 0,
//           gatewayOrderId: 'NULL',
//           gatewayPaymentId: 'NULL',
//           gatewayPaymentSignature: 'NULL',
//           gatewayMethod: 'NULL',
//           gatewayCurrency: 'NULL'
//         };

//         const queryString = new URLSearchParams(data).toString();
//         navigate(`/cancel-payment?${queryString}`);
//       }
//     },
//     theme: {
//       color: '#00aa49'
//     }
//   };

//   const rzp = new window.Razorpay(options);
//   rzp.open();
// };

// Old Promotion Code
// {
//   propertyDiscountArray.length > 0 &&
//   propertyDiscountArray.some((item) => {
//     const isValidDate = checkDates(
//       item.start_date,
//       item.end_date
//     );

//     const isBookingWithinDiscount = checkBookingDates(
//       checkInDate, // check-in date of booking
//       checkOutDate, // check-out date of booking
//       item.start_date, // discount start date
//       item.end_date // discount end date
//     );
//     const isValidCheckRatePlans = checkRatePlans(
//       selectedPlan?.meal_plan.toLowerCase(),
//       item.all_conditions.selected_rate_plans
//     );
//     const isValidCheckRooms = checkRoomsExists(
//       ratesData?.room_id,
//       item.all_conditions.selected_rooms
//     );
//     const isDayExists = checkDayExists(
//       item?.all_conditions?.stay_dates
//     );
//     const isMinLos = checkMinLOS(
//       checkInDate,
//       checkOutDate,
//       item?.all_conditions?.minLos
//     );

//     return (
//       isValidDate &&
//       isBookingWithinDiscount &&
//       isValidCheckRatePlans &&
//       isValidCheckRooms &&
//       isDayExists
//       // &&
//       // isMinLos
//     );
//   }) && (
//     <>
//       <div
//         className="common_background"
//         style={{ marginTop: "10px" }}
//       >
//         <div className="flex justify_content_between cursor_pointer">
//           <div className="flex justify_content_between w-100">
//             <div>
//               <h5 className="text-left">Discounts</h5>
//             </div>
//             {selectedDiscount && (
//               <button
//                 className="view_btn"
//                 onClick={() => setSelectedDiscount(null)}
//               >
//                 clear
//               </button>
//             )}
//           </div>
//         </div>

//         <div>
//           {propertyDiscountArray.length > 0 &&
//             propertyDiscountArray
//               .filter((item) => item?.status === "enable")
//               .map((item, index) => {
//                 const isCouponCode =
//                   item?.method === "code" ? true : false;

//                 const isValidDate = checkDates(
//                   item.start_date,
//                   item.end_date
//                 );
//                 const isBookingWithinDiscount =
//                   checkBookingDates(
//                     checkInDate, // check-in date of booking
//                     checkOutDate, // check-out date of booking
//                     item.start_date, // discount start date
//                     item.end_date // discount end date
//                   );
//                 const isValidCheckRatePlans = checkRatePlans(
//                   selectedPlan?.meal_plan.toLowerCase(),
//                   item.all_conditions.selected_rate_plans
//                 );

//                 const isValidCheckRooms = checkRoomsExists(
//                   ratesData?.room_id,
//                   item.all_conditions.selected_rooms
//                 );
//                 const isDayExists = checkDayExists(
//                   item?.all_conditions?.stay_dates
//                 );
//                 const isMinLos = checkMinLOS(
//                   checkInDate,
//                   checkOutDate,
//                   item?.all_conditions?.minLos
//                 );

//                 return (
//                   isValidDate &&
//                   isBookingWithinDiscount &&
//                   isValidCheckRatePlans &&
//                   isValidCheckRooms &&
//                   isDayExists && (
//                     // isMinLos &&
//                     <div
//                       key={index}
//                       className="flex discountSingleDiv justify_content_between w-100"
//                     >
//                       <div className="flex">
//                         <label
//                           style={{
//                             textTransform: "capitalize",
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                           htmlFor={
//                             item?.all_conditions
//                               ?.promotion_name
//                           }
//                         >
//                           {" "}
//                           <input
//                             checked={
//                               selectedDiscount?.id ===
//                               item?.id
//                             }
//                             onChange={() =>
//                               handleDiscountChange(item)
//                             }
//                             type="radio"
//                             id={
//                               item?.all_conditions
//                                 ?.promotion_name
//                             }
//                           />
//                           <div>
//                             <div>
//                               {isCouponCode
//                                 ? item?.discount_code?.replace(
//                                   /-/g,
//                                   " "
//                                 )
//                                 : item?.name.replace(
//                                   /-/g,
//                                   " "
//                                 )}
//                             </div>
//                             <div>
//                               {item?.all_conditions?.promotion_name?.replace(
//                                 /-/g,
//                                 " "
//                               )}
//                             </div>
//                           </div>
//                         </label>
//                       </div>
//                       <div>
//                         {item?.type === "fixed" ? (
//                           formatIndianCurrency(item?.amount)
//                         ) : (
//                           <>{item?.amount}%</>
//                         )}
//                       </div>
//                     </div>
//                   )
//                 );
//               })}
//         </div>
//       </div>
//     </>
//   )
// }

// Old Fetch Promotion
// const fetchDiscountsHandler = (propertyId) => {
//   setLoader(true);
//   const requestOptions = {
//     // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
//     headers: {
//       "Content-Type": "application/json",
//       key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//     method: "GET",
//     redirect: "follow",
//   };

//   fetch(
//     `${process.env.REACT_APP_APP_URL}/api/v1/promotion/list/${propertyId}`,
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((result) => {
//       // console.log("result", result);

//       if (result.success) {
//         setPropertyDiscountArray(result?.discounts);
//         // setLoader(false);
//       } else {
//         // setLoader(false);
//         // setDiscounts([]);
//       }
//     })
//     .catch((error) => console.error(error));
// };
// const handleSubmit = async (e) => {
//   e?.preventDefault(); // Prevent default form submission behavior
//   const valid = validate();
//   setTimeout(() => {
//     setFormErrors({});
//   }, 1500);
//   if (!valid) {
//     showErrorToast("Please fill the above details.");
//     return;
//   }

//   setFormErrors({});

//   setLoader(true);

//   try {
//     const formatedDate = new Date(checkInDate).toISOString().split("T")[0];

//     const myHeaders = new Headers();
//     myHeaders.append("Accept", "application/json");
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("key", process.env.REACT_APP_API_KEY);

//     const rateData = ratesData?.rates;

//     const bookingPaxes = rateData.map((rate) => {
//       let paxes = [];

//       const childPrices = rate.child_price || [];
//       if (Array.isArray(childPrices)) {
//         childPrices.forEach((price) => {
//           const is_complimentary = price === "0.00" ? 1 : 0;
//           paxes.push({
//             pax_type: "child",
//             no_of_pax: 1,
//             rate: price,
//             child_age: bookingData.childAge[0] || 0,
//             is_complimentary: is_complimentary,
//           });
//         });
//       }

//       for (let i = 0; i < rate.extra_adult_count; i++) {
//         paxes.push({
//           pax_type: "adult",
//           no_of_pax: 1,
//           rate: rate.extra_adult,
//           child_age: bookingData.childAge[0] || 0,
//           is_complimentary: 0,
//         });
//       }

//       return paxes;
//     });

//     const raw = JSON.stringify({
//       pay_with_agent_credit:
//         agentAdvanceCalculations?.pay_with_company_credit,
//       pay_with_advance: agentAdvanceCalculations?.pay_with_advance,
//       remaining_credit_balance:
//         agentAdvanceCalculations?.remaining_credit_balance,
//       remaining_advance_balance:
//         agentAdvanceCalculations?.remaining_advance_balance,
//       hotelId: bookingData?.hotelId,
//       agent_id: userDetails?.AgentId,
//       // customer_id: userDetails?.CustomerId,
//       customer_id: loginWithGoogleCustomer?.name,

//       check_in: checkInDate,
//       check_out: checkOutDate,
//       guest_name: guestData.guest_name,
//       guest_phone: guestData.guest_phone,
//       guest_email: guestData.guest_email,
//       pincode: guestData.pincode,
//       guest_state: addressData.state,
//       guest_country: addressData.country,
//       guest_city: addressData.city,
//       guest_address: addressData.address,
//       gst_type: "exclusive",
//       sub_total: bookingValues.subtotal,
//       grand_total: bookingValues.grandTotal,
//       paid_amount: agentAdvanceCalculations?.paidAmount,
//       balance_amount:
//         agentAdvanceCalculations?.balanceAmount || bookingValues.grandTotal,
//       total_tax_amount: bookingValues.taxAmount,
//       total_discount_amount: bookingValues.discountAmt,
//       company_number: guestData?.company_number,
//       company_email: guestData.business_email,
//       gst_number: guestData?.gst_number,
//       roomInputData: [
//         {
//           room_id: selectedPlan?.room_id,
//           room_name: ratesData?.room_name,
//           rate_plan_id: selectedPlan?.rate_plan_id,
//           room_plan: selectedPlan?.meal_plan,
//           no_of_rooms: bookingData?.room,
//           no_nights: noOfNights,
//           room_rate: selectedPlan?.rate_per_night,
//           no_adult: bookingData?.adult,
//           no_child: bookingData.children,
//           discount_rate: bookingValues.bookingDiscount || 0,
//           tac_rate: bookingValues.agentDiscount,
//           discount_amount: bookingValues.discountAmt,
//           tax_rate: bookingValues?.taxSlab,
//           tax_amount: bookingValues.taxAmount,
//           tax_type: "exclusive",
//           room_total_amount: selectedPlan?.rate_per_night,
//           room_grand_total: bookingValues.grandTotal,
//           check_in: checkInDate,
//           check_out: checkOutDate,
//           booking_paxs: bookingPaxes.length > 0 ? bookingPaxes[0] : [],
//         },
//       ],
//     });

//     const requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//     };

//     const response = await fetch(
//       `${process.env.REACT_APP_APP_URL}/api/v1/online/booking/checkout`,
//       requestOptions
//     );

//     const data = await response.json();
//     if (data.success) {
//       setLoader(false);
//       setTicketData(data?.booking);

//       if (
//         data.razorpayOrderId ||
//         data.booking.id ||
//         data.booking.property_id
//       ) {
//         try {
//           const checkoutUrl = `${process.env.REACT_APP_APP_URL}/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=http://www.mountainmysteries.com/thank-you/`;
//           // navigate(checkoutUrl);
//           window.location.href = checkoutUrl;

//           // if (checkoutData.success) {
//           // } else {
//           //   showErrorToast(checkoutData.message || "Checkout failed");
//           // }
//         } catch (error) {
//           console.error("Checkout error:", error);
//           showErrorToast("An error occurred during the checkout process.");
//         }
//       } else {
//         showErrorToast(
//           "Something went wrong! Please check your details and try again."
//         );
//       }
//       if (
//         data.razorpayOrderId ||
//         data.booking.id ||
//         data.booking.property_id
//       ) {
//         try {
//           const checkoutUrl = `${process.env.REACT_APP_APP_URL}/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.mountainmysteries.com/thank-you/`;

//           const checkoutResponse = await fetch(checkoutUrl, {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               Accept: "application/json",
//             },
//           });

//           const checkoutData = await checkoutResponse.json();

//           if (checkoutData.success) {
//             window.location.href = "https://www.ecohospitality.in/thankyou/";
//           } else {
//             showErrorToast(checkoutData.message || "Checkout failed");
//           }
//         } catch (error) {
//           console.error("Checkout error:", error);
//           showErrorToast("An error occurred during the checkout process.");
//         }
//       } else {
//         showErrorToast(
//           "Something went wrong! Please check your details and try again."
//         );
//       }
//       // if (data.razorpayOrderId || data.booking.id || data.booking.property_id) {
//       //   try {
//       //     const checkoutUrl = `https://extranet.travcatalyst.com/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.ecohospitality.in/thankyou/`;

//       //     const checkoutResponse = await fetch(checkoutUrl, {
//       //       method: "GET",
//       //       headers: {
//       //         "Content-Type": "application/json",
//       //         Accept: "application/json",
//       //       },
//       //     });

//       //     const checkoutData = await checkoutResponse.json();

//       //     if (checkoutData.success) {
//       //       window.location.href = "https://www.ecohospitality.in/thankyou/";
//       //     } else {
//       //       showErrorToast(checkoutData.message || "Checkout failed");
//       //     }
//       //   } catch (error) {
//       //     console.error("Checkout error:", error);
//       //     showErrorToast("An error occurred during the checkout process.");
//       //   }
//       // } else {
//       //   showErrorToast("Something went wrong! Please check your details and try again.");
//       // }
//       // if (data.razorpayOrderId || data.booking.id || data.booking.property_id) {
//       //   try {
//       //     const checkoutUrl = `https://extranet.travcatalyst.com/api/v1/get/data/checkout/process/${data.razorpayOrderId}/${data.booking.id}/${data.booking.accommodation_id}?thankyoupageurl=https://www.ecohospitality.in/thankyou/`;

//       //     const checkoutResponse = await fetch(checkoutUrl, {
//       //       method: "GET",
//       //       headers: {
//       //         "Content-Type": "application/json",
//       //         Accept: "application/json",
//       //       },
//       //     });

//       //     const checkoutData = await checkoutResponse.json();

//       //     if (checkoutData.success) {
//       //       window.location.href = "https://www.ecohospitality.in/thankyou/";
//       //     } else {
//       //       showErrorToast(checkoutData.message || "Checkout failed");
//       //     }
//       //   } catch (error) {
//       //     console.error("Checkout error:", error);
//       //     showErrorToast("An error occurred during the checkout process.");
//       //   }
//       // } else {
//       //   showErrorToast("Something went wrong! Please check your details and try again.");
//       // }
//     } else {
//       setLoader(false);
//       showErrorToast(data.message || "Something Went Wrong");
//     }
//   } catch (error) {
//     setLoader(false);
//     console.error("Submission error:", error);
//     showErrorToast("An error occurred during the booking process.");
//   }
// };
{
  /* {propertyDiscountArray.length > 0 && (
                    <>
                      <div
                        className="common_background"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="flex justify_content_between cursor_pointer">
                          <div className="flex justify_content_between w-100">
                            <div>
                              <h5 className="text-left">Discounts</h5>
                            </div>
                            {selectedDiscount && (
                              <button
                                className="view_btn"
                                onClick={() => setSelectedDiscount(null)}
                              >
                                clear
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          {propertyDiscountArray
                            .map((item, index) => {
                              const isCouponCode =
                                item?.method === "code" ? true : false;

                              const isValidDate = checkDates(
                                item.startDate,
                                item.endDate
                              );

                              const isBookingWithinDiscount =
                                checkBookingDates(
                                  checkInDate,
                                  checkInDate,
                                  item.startDate,
                                  item.endDate
                                );
                              const isUserEligible = isPromotionApplicable(
                                userType === "customer" ? "customers" : userType === "agent" ? "agents" : "",
                                userDetails?.id,
                                item.promotionApplicable
                              );

                              const blackoutDates = item.promotionBlackOutDate || [];
                              const isBlackout = isBlackoutDate(blackoutDates, checkInDate);
                              const isApplicableForEntity = isPromotionApplicableForEntity(item.promotionEntity, "hotel", bookingData?.hotelId);
                              const isConditionsValid = evaluateConditions(item.promotionConditions, checkInDate);
                              return (
                                isValidDate &&
                                isBookingWithinDiscount &&
                                isUserEligible &&
                                !isBlackout &&
                                isApplicableForEntity &&
                                isConditionsValid &&
                                (
                                  <div
                                    key={index}
                                    className="flex discountSingleDiv justify_content_between w-100"
                                  >
                                    <div className="flex">
                                      <label
                                        style={{
                                          textTransform: "capitalize",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        htmlFor={
                                          item?.promotionName
                                        }
                                      >
                                        {" "}
                                        <input
                                          checked={
                                            selectedDiscount?.id ===
                                            item?.id
                                          }
                                          onChange={() =>
                                            handleDiscountChange(item)
                                          }
                                          type="radio"
                                          id={
                                            item?.promotionName
                                          }
                                        />
                                        <div>
                                          <div>
                                            {item?.promotionName?.replace(
                                              /-/g,
                                              " "
                                            )}
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                    <div>
                                      {item?.promotionMode === "fixed" ? (
                                        formatIndianCurrency(item?.promotionAmount)
                                      ) : (
                                        <>{item?.promotionAmount}%</>
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                        </div>

                      </div>
                    </>
                  )} */
}
